import axios from 'axios'
import ENDPOINT from '../data/ENDPOINT'
import SecurityHeaders from '../data/SecurityHeaders'

const GetSingleVideo = async ({payload})=>{

const data = new FormData()
data.append("video_id",payload.video_id)
if(payload.user){
     data.append("session",payload.user)
}
const res = await 

axios.post(ENDPOINT+`get-single-tutorial.php`,data,SecurityHeaders)
if(res){
    
     return res.data
}

}

export default GetSingleVideo