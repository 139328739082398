import * as React from 'react';
import VideoIcon from '@mui/icons-material/VideoLibraryOutlined';
import HomeIcon from '@mui/icons-material/Home';
import { Link } from 'react-router-dom';
import SchoolIcon from '@mui/icons-material/SchoolOutlined';
import AccountIcon from '@mui/icons-material/AccountCircleOutlined';
import DetectDevice from '../data/DetectDevice';
import { AuthContext } from '../context/AuthContext';
import { Avatar } from '@mui/material';
import SaveUserEvent from '../actions/SaveUserEvent';
import { useActionsContext } from '../context/ActionsContext';
import GetPageName from '../actions/GetPageName';


export default function BottomNavigationBar({ account, access,footer }) {
  const [value, setValue] = React.useState(0);

  const { search, sidebar } = useActionsContext()
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const { user } = React.useContext(AuthContext)
  React.useEffect(() => {
    const timer = setInterval(() => {
      setpage(JSON.parse(sessionStorage.getItem("page")));
    }, 300);
    return () => {
      clearInterval(timer);
    };
  }, []);
  const [page, setpage] = React.useState(JSON.parse(sessionStorage.getItem("page")))
  const device = DetectDevice()


  React.useEffect(()=>{


  },[page])
const newpage = GetPageName()

  const hiddenPages = ["Comments", "Event", "Login", "Notifications",
    "Messages", "My Course", "My Classes", "Recordings", "Assignments", "Projects", "Placement cell", "Rewards"
    , "Trainer Support", "My Internships", "My Workshops",
    "Settings", "Badges", "Badge Details", "Payments", "My Account", "Withdrawls",
    "My Posts", "Saved Posts", "Followings", "Followers", "Recommendations", "Followers", "Following", "Requests sent",
    "Requests received", "Wallet", "Edit Profile", "Change Password", "Notes", "Refer & Earn",
    "About Company", "Refund Policy", "Terms", "Privacy Policy", "Watch", "Live Courses",
    "Offline Courses", "Specialization Courses", "Student Pass", "VCP", "Challenges", "Quizzes", "Community Challenges", "Interview Preparation",
    "Reviews", "Features", "Resources", "Watch Shorts", "Test Topics",
    "Internship", "Cheat Sheets", "Course Rewards", "Selfpaced Courses", "Hacking Internship", "Hacking Workshop", "Campus Ambassador Internship"];
  return (
    <>
    
    
{footer===false?<>


</>:

<>


{newpage==="shorts"||newpage==="shorts"?
<>

</>:<>


<hr className="m-0"></hr>

<div className="legal-section  py-3">

<div className="container pb-3 d-flex flex-wrap justify-content-center">

<div className="col-lg-4 mt-3 col-12 ">
 <div className="d-flex align-items-center">

   <img src="https://skillsuprise.com/resources/images/msme.png" className="iso me-2"></img>

   <div>
     <h6 className="mb-0 fw-bold sm-16">Ministry of MSME</h6>
     <p className='sm-12'>Govt of India Registered Company</p>
   </div>
 </div>
</div>


<div className="col-lg-4 col-12 mt-3 ">


 <div className="d-flex align-items-center">
   <img src="https://skillsuprise.com/resources/images/iso_logo.png" className="iso me-2"></img>

   <div>
     <h6 className="mb-0 fw-bold sm-16">ISO 9001-2015</h6>
     <p className='sm-12'>Certified Company</p>
   </div>
 </div>
</div>


<div className="col-lg-4 col-12 mt-3 ">
 <div className="d-flex">
   <img src="https://skillsuprise.com/resources/images/award.png" className="iso me-2"></img>

   <div>
     <h6 className="mb-0 fw-bold sm-16">Awarded as Best Service Provider in Education</h6>
     <p className='sm-12'>@Global Business & Education Awards 2023</p>
   </div>
 </div>
</div>

</div>

</div>


</>}

 {device === "mobile" && !hiddenPages.includes(page) ? <>
   {/*HIDE MOBILE BOTTOM NAVIGATION ON UNWANTED PAGES */}
   {search || sidebar ?
     <>



     </> :
     <>
       <div className={`bottom-nav d-flex w-100 align-items-center ${page==='Shorts'?'bg-dark dark-bottom-nav':'bg-light'}`}>
         <div className="d-flex bottom-nav-items align-items-center w-100 justify-content-between">


           <Link
             onClick={() => SaveUserEvent(
               {
                 payload: {
                   session: user != null || user != undefined ? user.SessionId : null,

                   page: window.location.pathname,
                   action: "BottomNavigationBar-openhome"
                 }
               })
             }

             to="/" className={page === null || page === "NewsFeed" || page === "Community" ? "col text-center active" : "col text-center"}>
             <HomeIcon />
             <p>Home</p>
           </Link>






           <Link to="/shorts"
onClick={()=>SaveUserEvent(
 {payload:{session: user!=null||user!=undefined?user.SessionId:null,
      
   page:window.location.pathname,
   action:"BottomNavigationBar-openshorts"
 }})
} className={page==="Shorts"?"col text-center active":"col text-center"}>
<VideoIcon/>
<p>Shorts</p>
</Link> 

           {access ?
             <>
               <Link

                 onClick={() => SaveUserEvent(
                   {
                     payload: {
                       session: user != null || user != undefined ? user.SessionId : null,

                       page: window.location.pathname,
                       action: "BottomNavigationBar-openmycourses"
                     }
                   })
                 }
                 to="/my-courses" className={page === "My Courses" ? "col text-center active" : "col text-center"}>
                 <SchoolIcon />
                 <p>My Courses</p>
               </Link>

             </> :
             <>
               <Link
                 onClick={() => SaveUserEvent(
                   {
                     payload: {
                       session: user != null || user != undefined ? user.SessionId : null,

                       page: window.location.pathname,
                       action: "BottomNavigationBar-opencourses"
                     }
                   })
                 }
                 to="/courses" className={page === "Courses" ? "col text-center active" : "col text-center"}>
                 <SchoolIcon />
                 <p>Courses</p>
               </Link>


             </>}



           <Link
             onClick={() => SaveUserEvent(
               {
                 payload: {
                   session: user != null || user != undefined ? user.SessionId : null,

                   page: window.location.pathname,
                   action: "BottomNavigationBar-openvideos"
                 }
               })
             }
             to="/videos" className={page === "Videos" ? "col text-center active" : "col text-center"}>
             <VideoIcon />
             <p>Videos</p>
           </Link>




           <Link
             onClick={() => SaveUserEvent(
               {
                 payload: {
                   session: user != null || user != undefined ? user.SessionId : null,

                   page: window.location.pathname,
                   action: "BottomNavigationBar-opendashboard"
                 }
               })
             }

             to="/dashboard" className={page === "Dashboard" ? "col text-center active" : "col text-center"}>
             {account ?
               <>
                 <Avatar className="nav-avatar" src={account.profile_pic} />

                 <p>Dashboard</p>
               </> : <>
                 <AccountIcon />
                 <p>Login</p>
               </>}

           </Link>
         </div>
       </div>

     </>}

 </> : <>


 </>}
 {device === "mobile" && page !== "Comments" || page !== "Workshop" ?
   <>

   </> : null}
</>}

    </>

  );
}
