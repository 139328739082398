import axios from 'axios'
import { useState } from 'react'
import ENDPOINT from '../../data/ENDPOINT'
import SecurityHeaders from '../../data/SecurityHeaders'

const ToggleWatchLater = async ({payload})=>{

const data = new FormData()
data.append("video_id",payload.video_id)
if(payload.user){
     data.append("session",payload.user)
}

const res = await 

axios.post(ENDPOINT+`add-watch-later.php`,data,SecurityHeaders)
if(res){
    
     return res.data
}

}



export default ToggleWatchLater