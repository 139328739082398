import React from 'react'
import { useEffect } from 'react'
import { Link } from 'react-router-dom'

import DetectDevice from '../../data/DetectDevice'
const CoursesCategory = ({live,offline,selfpaced,specialization}) => {
const device = DetectDevice()
   
  return (
    <div >
     
 

 
<div className="container sm-p-15 ">



<div className="d-flex animate  p-2 align-items-center pb-3 flex-wrap dashed-border-grey justify-content-evenly">



{live?
<>

<div className="col-lg-5  sm-mt-20  sm-p-0 p-2">
<div className="fade-in-bottom delay05s">
<Link className="w-100  bg-category" to="/live-online-courses">
  <div className="d-flex br-5 bg-category text-white w-100">
<div className=" p-3">
<h2 className="secondary-text sm-18 truncate truncate1">Online Courses</h2>
<p className="text-light sm-14">Learn from the comforts of your home in a most interactive online mode</p>

<button className="btn btn-lg btn-sm mt-4 btn-primary rounded-pill">Browse Courses</button>
</div>

  <img className="w-30" src="https://skillsuprise.com/resources/images/live-selectioned.png"/>


  </div>

</Link>
</div>




</div>
</>:null}












{offline?
<>

<div className="col-lg-5   sm-mt-20  sm-p-0 p-2">
<div className="fade-in-bottom delay05s">
<Link className="w-100  bg-category" to="/offline-courses">
  <div className="d-flex br-5 bg-category text-white w-100">
<div className=" p-3">
<h2 className="secondary-text sm-18 truncate truncate1">Offline Courses</h2>
<p className="text-light sm-14">Learn in a most interactive environment 
with expertized trainers at our office </p>

<button className="btn btn-lg btn-sm  mt-4 btn-primary rounded-pill">Browse Courses</button>
</div>

  <img className="w-30" src="https://skillsuprise.com/resources/images/offline-selectioned.png"/>


  </div>

</Link>

</div>



</div>
</>:null}







{specialization?
<>

<div className="col-lg-5  sm-mt-20  sm-p-0 p-2">

<div className="fade-in-bottom delay07s">
<Link className="w-100 bg-category" to="/specialization-courses">
  <div className="d-flex br-5 bg-category text-white w-100">
<div className=" p-3">
<h2 className="secondary-text sm-18 truncate truncate1">Specialization Courses</h2>
<p className="text-light sm-14">3-4 months long courses with guaranteed internship / placement opportunities. </p>

<button className="btn mt-4 btn-lg btn-sm  btn-primary rounded-pill">Browse Courses</button>
</div>

  <img className="w-30" src="https://skillsuprise.com/resources/images/specialization-selectioned.png"/>


  </div>

</Link>

</div>




</div>
</>:null}








{selfpaced?
<>

<div className="col-lg-5  sm-mt-20  sm-p-0 p-2">
<div className="fade-in-bottom delay07s">
<Link className="w-100 bg-category" to="/selfpaced-courses">
  <div className="d-flex br-5 bg-category text-white w-100">
<div className=" p-3">
<h2 className="secondary-text sm-18 truncate truncate1">Pre-recorded Courses</h2>
<p className="text-light sm-14 ">Learn anytime, anywhere by watching pre-recorded course tutorials. </p>

<button className="btn mt-4 btn-lg btn-sm  btn-primary rounded-pill">Browse Courses</button>
</div>

  <img className="w-30" src="https://skillsuprise.com/resources/images/selfpaced-selectioned.png"/>


  </div>

</Link>

</div>



</div>
</>:null}





</div>


     </div>


    </div>
  )
}

export default CoursesCategory