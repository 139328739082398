import React, { useContext, useEffect, useState } from "react";
import "./assets/css/style.css";

import "./assets/css/bootstrap.min.css";
import "./assets/css/mobile.css";
import HomePage from "./pages/home/HomePage";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import VideosPage from "./pages/tutorials/VideosPage";
import WorkshopsPage from "./pages/workshops/WorkshopsPage";
import DefaultTemplate from "./pages/temaplates/DefaultTemplate";
import CourseDetails from "./pages/courses/CourseDetails";

import Dashboard from "./pages/dashboard/Dashboard";
import MyInternships from "./pages/account/MyInternships";
import InternshipsPage from "./pages/internships/InternshipsPage";
import LoginPage from "./pages/auth/LoginPage";
import WorkshopDetails from "./pages/workshops/WorkshopDetails";
import OfflineCourses from "./pages/courses/OfflineCourses";
import OnlineCourses from "./pages/courses/OnlineCourses";
import SelfpacedCourses from "./pages/courses/SelfpacedCourses";
import SpecializationCourses from "./pages/courses/SpecializationCourses";
import { AuthContext } from "./context/AuthContext";
import MyCourses from "./pages/account/MyCourses";
import MyWorkshops from "./pages/account/MyWorkshops";
import AccountPage from "./pages/account/AccountPage";

import CampusAmbassadorPage from "./pages/campus-ambassador/CampusAmbassadorPage";
import GetAccount from "./actions/GetAccount";
import Community from "./pages/community/Community";
import StudentsReviews from "./pages/reviews/StudentsReviews";
import MyCourseDashboard from "./pages/account/MyCourseDashboard";
import NotificationSettings from "./pages/settings/NotificationSettings";
import TrainerSupport from "./pages/account/mycourses/TrainerSupport";
import Recordings from "./pages/account/mycourses/Recordings";
import MyAssignments from "./pages/account/mycourses/MyAssignments";
import MyProjects from "./pages/account/mycourses/MyProjects";
import PlacementCell from "./pages/account/mycourses/PlacementCell";
import ReportCard from "./pages/account/mycourses/ReportCard";
import MyCourseRewards from "./pages/account/mycourses/MyCourseRewards";
import CourseLeaderboard from "./pages/account/mycourses/CourseLeaderboard";
import CourseCommunity from "./pages/account/mycourses/CourseCommunity";
import MyInterviews from "./pages/account/interviews/MyInterviews";
import WatchTutorials from "./pages/tutorials/WatchTutorials";
import InternshipDetails from "./pages/internships/InternshipDetails";
import Localbase from "localbase";
import CourseNotes from "./pages/account/mycourses/CourseNotes";

import UpcomingClassesPage from "./pages/account/mycourses/ClassesPage";
import SetPage from "./data/SetPage";
import CommentsPage from "./pages/community/CommentsPage";
import SignupPage from "./pages/auth/SignupPage";
import InitiateReferralCode from "./pages/referral-program/InitiateReferralCode";
import ReferralProgram from "./pages/referral-program/ReferralProgram";
import TermsPage from "./pages/company/TermsPage";
import PrivacyPolicyPage from "./pages/company/PrivacyPolicyPage";
import RefundPolicyPage from "./pages/company/RefundPolicyPage";
import AboutCompanyPage from "./pages/company/AboutCompanyPage";
import Login from "./pages/authentication/Login";
import SignUp from "./pages/authentication/SignUp";
import NotificationsPage from "./pages/notifications/NotificationsPage";
import ChatPage from "./pages/chat/ChatPage";
import AllCourses from "./pages/courses/AllCourses";
import ConfirmRegistrationPage from "./pages/authentication/ConfirmRegistrationPage";
import ContactPage from "./pages/contact/ContactPage";
import Settings from "./pages/settings/Settings";
import AccountSecurity from "./pages/account/Security";
import PaymentsPage from "./pages/account/PaymentsPage";
import PasswordReset from "./pages/authentication/PasswordReset";
import FollowersPage from "./pages/connections/FollowersPage";
import FollowingsPage from "./pages/connections/FollowingPage";
import AllConnections from "./pages/connections/AllConnections";
import PersonalBadgesPage from "./pages/badges/PersonalBadgesPage";
import ScratchCardDetails from "./pages/referral-program/ScratchCardDetails";
import CourseRewardsPage from "./pages/account/mycourses/CourseRewardsPage";
import RequestsSentPage from "./pages/connections/RequestsSentPage";
import RequestsReceivedPage from "./pages/connections/RequestsReceivedPage";
import MyPosts from "./pages/community/MyPosts";
import SavedPosts from "./pages/community/SavedPosts";
import PersonalBadgeInfo from "./pages/badges/PersonalBadgeInfo";
import WatchHistory from "./pages/tutorials/WatchHistory";
import WatchLaterPage from "./pages/tutorials/WatchLaterPage";
import FavouriteVideos from "./pages/tutorials/FavouriteVideos";

import EditUserInfo from "./pages/account/EditUserInfo";
import PasswordChange from "./pages/account/PasswordChange";
import Wallet from "./pages/account/Wallet";
import WatchLessons from "./pages/tutorials/WatchLessons";
import CourseBadgeInfo from "./pages/badges/CourseBadgeInfo";
import PersonalBadgeEarned from "./pages/badges/PersonalBadgeEarned";
import CourseBadgeEarned from "./pages/badges/CourseBadgeEarned";
import StudentPassPage from "./pages/studentpass/StudentPassPage";
import InterviewPreparations from "./pages/interviewpreeparation/InterviewPreparations";
import QuizPage from "./pages/interviewpreeparation/QuizPage";
import FeaturesPage from "./pages/studentpass/FeaturesPage";
import LabPage from "./pages/lab/LabPage";
import ExamPage from "./pages/exam/ExamPage";
import CourseMilestones from "./pages/account/mycourses/CourseMilestones";
import CourseResources from "./pages/account/mycourses/CourseResources";
import MockTest from "./pages/mock-test/MockTest";
import MockTestSubjects from "./pages/mock-test/MockTest";
import TestTopics from "./pages/mock-test/TestTopics";
import CodingCheatsheets from "./pages/interviewpreeparation/CourseCheatsheets";
import TechnicalQuizzes from "./pages/interviewpreeparation/TechnicalQuizzes";
import MockTestCategories from "./pages/interviewpreeparation/MockTestCategories";
import PublicFollowings from "./pages/connections/PublicFollowings";
import PublicFollowers from "./pages/connections/PublicFollowers";
import JobAlerts from "./pages/jobalerts/JobAlerts";
import CourseLeaderboardPage from "./pages/account/mycourses/CourseLeaderboardPage";
import SearchPage from "./pages/search/SearchPage";
import NotesListPage from "./pages/courses/NotesListPage";
import WatchShort from "./pages/tutorials/WatchShort";
import ShortsPage from "./pages/tutorials/ShortsPage";
import RedirectNext from "./actions/RedirectNext";
import HackingScholarship from "./pages/scholarship/HackingScholarship";
import FreeCourses from "./pages/courses/FreeCourses";
import TestAnswers from "./pages/mock-test/TestAnswers";
import HackingWorkshop from "./pages/scholarship/HackingWorkshop";
import AmbassadorDashboard from "./pages/cai/AmbassadorDashboard";
import AmazonWorkshop from "./pages/workshops/Fullstack-Workshop/AmazonWorkshop";
import UiUxWorkshop from "./pages/workshops/ui-ux-workshop/UiUxWorkshop";
import DataScienceWorkshop from "./pages/workshops/data-science/DataScienceWorkshop";
import TeamLeaderboard from "./pages/account/mycourses/TeamLeaderboard";
import VerifyCertificate from "./pages/certificates/VerifyCertificate";
import CollageHome from "../src/pages/Collage/home/Home";
import HackingWorkshopConfirmation from "./pages/scholarship/HackingWorkshopConfirmation";
import FirebaseChat from "./pages/chat/FirebaseChat";
import axios from "axios";
import ENDPOINT from "./data/ENDPOINT";

import SecurityHeaders from "./data/SecurityHeaders";
import { getMessaging, onMessage } from "firebase/messaging";
import firebaseConfig, { app } from "./firebase-Config";
import GoogleSignin from "./pages/authentication/GoogleSignin";
import CourseCertifications from "./pages/certifications/CourseCertifications";
import BusinessOverview from "./pages/Collage/home/BusinessOverview";
import TrainerDetailsPage from "./pages/trainer/TrainerDetailsPage";
import TeachWithSkillsUprise from "./pages/trainer/TeachWithSkillsUprise";
import SupportPage from "./pages/support/SupportPage";
import PlacementTerms from "./pages/company/PlacementTerms";
import JobHuntOne from "./pages/job-hunt/JubHuntOne";
import FreePdfView from "./pages/freeresources/FreePdfView";
import FreeResources from "./pages/freeresources/FreeResources";
import JobSearchComponent from "./pages/job-hunt/JobSearchComponent";
import JobDetailsPage from "./pages/job-hunt/JobDetailsPage";
import CaiConformation from "./pages/cai/CaiConformation";
import UnlockFreeCourse from "./pages/courses/UnlockFreeCoures";
import UploadFreeCourseAccessPic from "./pages/courses/UploadFreeCourseAccessPic";
import SummerInternship from "./pages/internships/SummerInternship";
import SummerInternshipOne from "./pages/internships/SummerInternshipOne";
import PremiumSale from "./pages/premium/PremiumSale";
import InputForm from "./pages/resume-builder/InputForm";
import Test2 from './Test2'
import Gallery from "./pages/gallery/Gallery";
import TEST from "./pages/courses/Test";
import BatchProgress from "./pages/account/mycourses/BatchProgress";
import Book from "./Book";
import SingleBook from "./SingleBook";
import SinglePage from "./SinglePage";
import ImageGallery from "./ImageGallery";
import styled from "styled-components";
import Ourteam from "./pages/company/Ourteam";
import ScratchCards from "./pages/referral-program/ScratchCards";
import UnregisteredReferralPage from "./pages/referral-program/UnregisteredReferralPage";
import StudentsPlacement from "./pages/company/StudentsPlacement";

function App({ children }) {
  const { user } = useContext(AuthContext);
  const [loggedin, setloggedin] = useState(false);

  const [footer,setfooter] = useState(true)

  let db = new Localbase("db");
  const [account, setaccount] = useState(null);
  const NewGetAccount = async () => {
    const res = await GetAccount({ user });
    if (res) {
      if (res[0].status === "success") {
        db.collection("paths").add(
          {
            account: res[0].data,
          },
          "account",
        );

        setaccount(res[0].data);
      } else if (
        res[0].status === "session" &&
        res[0].message === "session expired"
      ) {
        localStorage.setItem("user", null);
        db.collection("paths").doc("account").delete();
        window.location.replace("/");
      } else if (
        res[0].status === "error" &&
        res[0].message === "session expired"
      ) {
        localStorage.setItem("user", null);
        db.collection("paths").doc("account").delete();

        window.location.replace("/");
      } else {
        db.collection("paths").doc("account").delete();
      }
    }
  };

  const [title, settitle] = useState(null);

  const CreateTempSession = async () => {
    const data = new FormData();
    data.append("id", 78);
    const res = await axios.post(
      ENDPOINT + "create-temporary-session.php",
      data,
      SecurityHeaders,
    );

    if (res) {
      if (res.data.status === "success") {
        localStorage.setItem("uid", JSON.stringify(res.data.session));
      }
    }
  };
  useEffect(() => {
    if (user === null || user === "null") {
      setloggedin(false);
      sessionStorage.setItem("user", null);
      db.collection("paths").doc("user").delete();

      let tempsession = JSON.parse(localStorage.getItem("uid"));
    //  console.log(tempsession);
      if (
        tempsession === null ||
        tempsession === undefined ||
        tempsession === "" ||
        tempsession === "null"
      ) {
        CreateTempSession();
      }
    } else {
      setloggedin(true);
      db.collection("paths")
        .doc("account")
        .get()
        .then((data) => {
          setaccount(data.account);
        })
        .catch((error) => { });
      NewGetAccount();
    }
  }, []);

  const [access, setaccess] = useState(false);

  useEffect(() => {
    if (account) {
      if (account.purchases !== null) {
        if (account.purchases.length > 0) {
          setaccess(true);
        }
      }
    }
  }, [account, user, loggedin]);

  const [fluid, setfluid] = useState(false);

  useEffect(() => {
    let page = sessionStorage.getItem("page");

    if (page && page === "Recordings") {
      setfluid(true);
    }

    if(page&&page==="Book"){
      setfooter(false)
    
    }else{
      setfooter(true)
    }
  }, [window.location.pathname]);

  document.addEventListener("DOMContentLoaded", function () {
    // Get all section elements
    const sections = document.querySelectorAll("section");

    // Add a click event listener to all navigation links
    sections.forEach(function (section) {
      section.addEventListener("click", function () {
        // Scroll to the selected section smoothly
        window.scrollTo({
          top: section.offsetTop,
          behavior: "smooth",
        });
      });
    });
  });

  useEffect(() => {
    window.addEventListener("contextmenu", function (e) {
      // e.preventDefault(); // Disable right-click
    });

    window.addEventListener("keydown", function (e) {
      if (
        ((e.ctrlKey || e.metaKey) && (e.key === "p" || e.keyCode === 80)) ||
        ((e.ctrlKey || e.metaKey) && (e.key === "s" || e.keyCode === 83))
      ) {
        e.preventDefault(); // Disable Ctrl+P
      }
    });
  }, []);


  const animateFunction = (entries, observer) => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        // If the element is intersecting with the viewport, execute your animation function here
        executeAnimation();
        observer.unobserve(entry.target); // Stop observing once the animation is executed
      }
    });
  };

  const executeAnimation = () => {
    // Your animation logic goes here
    console.log("Element is in viewport! Execute your animation function.");

    const items = document.getElementsByClassName("animate")

    const screenheight = window.innerHeight;
    for (var i = 0; i <= items.length; i++) {
      const currentelement = items[i]

      if (currentelement) {
        const elementposition = parseInt(currentelement.getBoundingClientRect().top.toFixed())

        if (elementposition < screenheight) {
          if (items[i].classList.contains("animation-active")) {



          } else {
            items[i].classList.add("animation-active")
          }
        }

      }





    }

  };

  useEffect(() => {
    const items = document.querySelectorAll(".animate");
    const observerOptions = {
      root: null, // Use the viewport as the root
      rootMargin: '0px', // No margin
      threshold: 0.2, // Trigger when 50% of the element is visible
    };

    const observer = new IntersectionObserver(animateFunction, observerOptions);

    items.forEach(item => {
      observer.observe(item);
    });

    // Cleanup the observer when the component unmounts
    return () => {
      observer.disconnect();
    };
  }, []); // Empty dependency array ensures the effect runs once on mount




  return (
    <div className="w-100">
      <Router>
        <Switch>
          
         

          <Route exact path="/login">
            {user ? (
              <>
                <Dashboard account={account} access={access} />
              </>
            ) : (
              <>
                <Login />
              </>
            )}
          </Route>

          <Route exact path="/signup">
            {user ? (
              <>
                <Dashboard account={account} access={access} />
              </>
            ) : (
              <>
                <SignUp />
              </>
            )}
          </Route>

          <Route exact path="/short/:video_id">
          <NoNavbar>

       <WatchShort account={account} secondaryview={false} access={access}  />
       </NoNavbar>
          
       
     </Route>

          <Route exact path="/shorts">
       
       <NoNavbar>
       <ShortsPage account={account} secondaryview={false} access={access} />
       
       </NoNavbar>
            </Route>
          <DefaultTemplate  account={account} access={access} fluid={fluid}>


          <Route exact path="/job-search">
            <JobSearchComponent />
          </Route>
          <Route exact path="/payment/:payment_id">
            <TEST/>
          </Route>
          <Route exact path="/team">
            <Ourteam/>
          </Route>
          <Route exact path="/gallery">
           <div className="pt-5">
           <Gallery />
           </div>
          </Route>

        
         
          <Route exact path="/books">
            <SingleBook />
          </Route>
  
          <Route exact path="/book/:book_name">
            <SinglePage/>
          </Route>


          
          <Route exact path="/careers">
            <InternshipsPage/>
          </Route>

          <Route exact path="/image">
            <ImageGallery/>
          </Route>


          <Route exact path="/premium">
            <PremiumSale />
          </Route>

          <Route exact path="/unlock-course-steps">
            <UnlockFreeCourse />
          </Route>

          <Route exact path="/upload-file/:share">
            <UploadFreeCourseAccessPic />
          </Route>
            <Route exact path="/dashboard">
              {user ? (
                <>
                  <Dashboard page="account" account={account} access={access} />
                </>
              ) : (
                <Redirect to="/login"></Redirect>
              )}
            </Route>

            <Route exact path="/my-posts">
              {user ? (
                <>
                  <MyPosts page="account" account={account} access={access} />
                </>
              ) : (
                <Redirect to="/login"></Redirect>
              )}
            </Route>

            <Route exact path="/saved-posts">
              {user ? (
                <>
                  <SavedPosts
                    page="account"
                    account={account}
                    access={access}
                  />
                </>
              ) : (
                <Redirect to="/login"></Redirect>
              )}
            </Route>

            <Route exact path="/password-reset">
              <PasswordReset page="account" account={account} access={access} />
            </Route>

            {/* <Route exact path="/watch-history">
              {user ? (
                <>
                  <WatchHistory
                    page="account"
                    account={account}
                    access={access}
                  />
                </>
              ) : (
                <Redirect to="/login"></Redirect>
              )}
            </Route> */}



            <Route exact path="/course-certifications">
              {user ? (
                <>
                  <CourseCertifications
                    page="account"
                    account={account}
                    access={access}
                  />
                </>
              ) : (
                <Redirect to="/login"></Redirect>
              )}
            </Route>



            <Route exact path="/google-signin/:token/:uid">

              <>
                <GoogleSignin
                  page="account"
                  account={account}
                  access={access}
                />
              </>


            </Route>

            <Route exact path="/free-resources">

              <>
                <FreeResources />
              </>

            </Route>

            <Route exact path="/watch-later">
              {user ? (
                <>
                  <WatchLaterPage
                    page="account"
                    account={account}
                    access={access}
                  />
                </>
              ) : (
                <Redirect to="/login"></Redirect>
              )}
            </Route>

            <Route exact path="/watch-later">
              {user ? (
                <>
                  <WatchLaterPage
                    page="account"
                    account={account}
                    access={access}
                  />
                </>
              ) : (
                <Redirect to="/login"></Redirect>
              )}
            </Route>

            <Route exact path="/favourite-videos">
              {user ? (
                <>
                  <FavouriteVideos
                    page="account"
                    account={account}
                    access={access}
                  />
                </>
              ) : (
                <Redirect to="/login"></Redirect>
              )}
            </Route>

            <Route exact path="/badge/:badge_id">
              {user ? (
                <>
                  <PersonalBadgeInfo
                    page="account"
                    account={account}
                    access={access}
                  />
                </>
              ) : (
                <Redirect to="/login"></Redirect>
              )}
            </Route>

            <Route exact path="/course-badge/:badge_id">
              {user ? (
                <>
                  <CourseBadgeInfo
                    page="account"
                    account={account}
                    access={access}
                  />
                </>
              ) : (
                <Redirect to="/login"></Redirect>
              )}
            </Route>

            <Route exact path="/student-pass">
              <StudentPassPage
                page="account"
                account={account}
                access={access}
              />
            </Route>

            <Route exact path="/ethical-hacking-internship">
              <HackingScholarship
                page="account"
                account={account}
                access={access}
              />
            </Route>

            <Route exact path="/hacking-workshop">
              <HackingWorkshop
                page="account"
                account={account}
                access={access}
              />
            </Route>
            <Route exact path="/cai-conformation">
              <CaiConformation
                page="account"
                account={account}
                access={access}
              />
            </Route>

            <Route exact path="/workshop-confirmation">
              <HackingWorkshopConfirmation
                page="account"
                account={account}
                access={access}
              />
            </Route>

            <Route exact path="/badge-earned/:badge_id">
              {user ? (
                <>
                  <PersonalBadgeEarned
                    page="account"
                    account={account}
                    access={access}
                  />
                </>
              ) : (
                <Redirect to="/login"></Redirect>
              )}
            </Route>

            <Route exact path="/course-badge-earned/:badge_id">
              {user ? (
                <>
                  <CourseBadgeEarned
                    page="account"
                    account={account}
                    access={access}
                  />
                </>
              ) : (
                <Redirect to="/login"></Redirect>
              )}
            </Route>

            <Route exact path="/">
              <HomePage account={account} access={access} page={"home"} />
            </Route>

            <Route exact path="/newsfeed">
              {user ? (
                <>
                  <Community account={account} access={access} />
                </>
              ) : (
                <Redirect to="/login"></Redirect>
              )}
            </Route>

            <Route exact path="/u/:username">
              {user ? (
                <>
                  <AccountPage account={account} access={access} />
                </>
              ) : (
                <Redirect to="/login"></Redirect>
              )}
            </Route>

            <Route exact path="/students-placement" >
            <StudentsPlacement/>
            </Route>           

            <Route exact path="/u/:username/:followers">
              {user ? (
                <>
                  <PublicFollowers account={account} access={access} />
                </>
              ) : (
                <Redirect to="/login"></Redirect>
              )}
            </Route>
            <Route exact path="/u/:username/:following">
              {user ? (
                <>
                  <PublicFollowings account={account} access={access} />
                </>
              ) : (
                <Redirect to="/login"></Redirect>
              )}
            </Route>

            <Route exact path="/u/:username/posts">
              {user ? (
                <>
                  <AccountPage account={account} access={access} />
                </>
              ) : (
                <Redirect to="/login"></Redirect>
              )}
            </Route>

            <Route exact path="/job-alerts">
              {user ? (
                <>
                  <JobAlerts account={account} access={access} />
                </>
              ) : (
                <Redirect to="/login"></Redirect>
              )}
            </Route>

            <Route exact path="/my-internships">
              {user ? (
                <>
                  <MyInternships
                    account={account}
                    access={access}
                    page={"My Internships"}
                  />
                </>
              ) : (
                <Redirect to="/login"></Redirect>
              )}
            </Route>

            <Route exact path="/internships">
              <InternshipsPage />
            </Route>

            <Route exact path="/internship/:internship_id">
              <InternshipDetails />
            </Route>
            <Route exact path="/job-details/:job_id">
              <JobDetailsPage />
            </Route>
            <Route exact path="/notifications">
              {user ? (
                <>
                  <NotificationsPage />
                </>
              ) : (
                <>
                  <Redirect to="/login"></Redirect>
                </>
              )}
            </Route>

            <Route exact path="/chat/:conversationid">
              {user ? (
                <>
                  <ChatPage />
                </>
              ) : (
                <>
                  <Redirect to="/login"></Redirect>
                </>
              )}
            </Route>

            <Route exact path="/chat">
              {user ? (
                <>
                  <ChatPage />
                </>
              ) : (
                <>
                  <Redirect to="/login"></Redirect>
                </>
              )}
            </Route>

            <Route exact path="/videos">
              <VideosPage account={account} access={access} />
            </Route>

           

            <Route exact path="/watch/:video_id">
              {user ? (
                <>
                  <WatchTutorials />
                </>
              ) : (
                <>
                  <Redirect to="/login"></Redirect>
                </>
              )}
            </Route>

         
            <Route exact path="/events">
              <WorkshopsPage />
            </Route>

            <Route exact path="/event/:workshop_url">
              <WorkshopDetails />
            </Route>

            <Route exact path="/offline-courses">
              <OfflineCourses />
            </Route>

            <Route exact path="/free-courses">
              <FreeCourses />
            </Route>

            <Route exact path="/notification-settings">
              {user ? (
                <>
                  <NotificationSettings account={account} />
                </>
              ) : (
                <Redirect to="/login"></Redirect>
              )}
            </Route>

            <Route exact path="/payments">
              {user ? (
                <>
                  <PaymentsPage account={account} />
                </>
              ) : (
                <Redirect to="/login"></Redirect>
              )}
            </Route>

            <Route exact path="/wallet">
              {user ? (
                <>
                  <Wallet account={account} />
                </>
              ) : (
                <Redirect to="/login"></Redirect>
              )}
            </Route>

            <Route exact path="/transactions">
              {user ? (
                <>
                  <Wallet account={account} />
                </>
              ) : (
                <Redirect to="/login"></Redirect>
              )}
            </Route>

            <Route exact path="/security-settings">
              {user ? (
                <>
                  <AccountSecurity account={account} />
                </>
              ) : (
                <>
                  <Redirect to="/login"></Redirect>
                </>
              )}
            </Route>

            <Route exact path="/settings">
              {user ? (
                <>
                  <Settings account={account} />
                </>
              ) : (
                <>
                  <Redirect to="/login"></Redirect>
                </>
              )}
            </Route>

            <Route exact path="/online-courses">
              <OnlineCourses />
            </Route>

            <Route exact path="/selfpaced-courses">
              <SelfpacedCourses />
            </Route>

            <Route exact path="/specialization-courses">
              <SpecializationCourses />
            </Route>

            <Route exact path="/course/:course_url/:mode">
              <CourseDetails />
            </Route>

            <Route exact path="/my-courses">
              {user ? (
                <>
                  <MyCourses />
                </>
              ) : (
                <Redirect to="/login"></Redirect>
              )}
            </Route>

            <Route exact path="/my-workshops">
              {user ? (
                <>
                  <MyWorkshops />
                </>
              ) : (
                <Redirect to="/login"></Redirect>
              )}
            </Route>

            <Route exact path="/account">
              {user ? (
                <>
                  <AccountPage />
                </>
              ) : (
                <Redirect to="/login"></Redirect>
              )}
            </Route>

            <Route exact path="/badges">
              {user ? (
                <>
                  <PersonalBadgesPage />
                </>
              ) : (
                <Redirect to="/login"></Redirect>
              )}
            </Route>

            <Route exact path="/referral-program">
              {user ? (
                <>
                  <ReferralProgram />
                </>
              ) : (
         <UnregisteredReferralPage/>
              )}
            </Route>

            <Route exact path="/referral=:code">
              <InitiateReferralCode />
            </Route>

            <Route exact path="/scratch-card/:id">
              {user ? (
                <>
                  <ScratchCardDetails account={account} access={access} />
                </>
              ) : (
                <Redirect to="/login"></Redirect>
              )}
            </Route>

            <Route exact path="/campus-ambassador-internship">
              <CampusAmbassadorPage />
            </Route>

            <Route exact path="/campus-ambassador">
              {user ? (
                <>
                  <AmbassadorDashboard account={account} access={access} />
                </>
              ) : (
                <Redirect to="/login"></Redirect>
              )}
            </Route>

            <Route exact path="/courses">
              <AllCourses />
            </Route>

            <Route exact path="/reviews">
              <StudentsReviews />
            </Route>

            <Route exact path="/course-dashboard/:course_url/:batch_id">
              {user ? (
                <>
                  <MyCourseDashboard account={account} access={access} />
                </>
              ) : (
                <>
                  <Redirect to="/login"></Redirect>
                </>
              )}
            </Route>

            <Route exact path="/trainer-support/:course_url/:batch_id">
              {user ? (
                <>
                  <TrainerSupport account={account} access={access} />
                </>
              ) : (
                <>
                  <Redirect to="/login"></Redirect>
                </>
              )}
            </Route>


            <Route exact path="/support">
              <SupportPage />
            </Route>

            <Route exact path="/job-hunt">
              <JobHuntOne />
            </Route>

            <Route exact path="/placement-guarantee-terms-and-conditions">
              <PlacementTerms />
            </Route>
            <Route exact path="/placement-cell/:course_url/:batch_id">
              {user ? (
                <>
                  <PlacementCell />
                </>
              ) : (
                <>
                  <Redirect to="/login"></Redirect>
                </>
              )}
            </Route>

            <Route exact path="/live-online-courses">
              <OnlineCourses />
            </Route>

            <Route exact path="/report-card/:course_url">
              {user ? (
                <>
                  <ReportCard />
                </>
              ) : (
                <>
                  <Redirect to="/login"></Redirect>
                </>
              )}
            </Route>

            <Route exact path="/classes/:course_url/batch:batch_id">
              {user ? (
                <>
                  <UpcomingClassesPage account={account} />
                </>
              ) : (
                <>
                  <Redirect to="/login"></Redirect>
                </>
              )}
            </Route>

            <Route exact path="/course-rewards/:course_url">
              {user ? (
                <>
                  <CourseRewardsPage />
                </>
              ) : (
                <>
                  <Redirect to="/login"></Redirect>
                </>
              )}
            </Route>

            <Route exact path="/leaderboard/:course_url/:batch_id">
              {user ? (
                <>
                  <CourseLeaderboardPage />
                </>
              ) : (
                <>
                  <Redirect to="/login"></Redirect>
                </>
              )}
            </Route>

            <Route exact path="/requests-sent">
              {user ? (
                <>
                  <RequestsSentPage account={account} access={access} />
                </>
              ) : (
                <Redirect to="/login"></Redirect>
              )}
            </Route>

            <Route exact path="/recommendations">
              {user ? (
                <>
                  <AllConnections account={account} access={access} />
                </>
              ) : (
                <Redirect to="/login"></Redirect>
              )}
            </Route>

            <Route exact path="/requests-received">
              {user ? (
                <>
                  <RequestsReceivedPage account={account} access={access} />
                </>
              ) : (
                <Redirect to="/login"></Redirect>
              )}
            </Route>

            <Route exact path="/interviews">
              {user ? (
                <>
                  <MyInterviews />
                </>
              ) : (
                <>
                  <Redirect to="/login"></Redirect>
                </>
              )}
            </Route>

            <Route exact path="/course-community/:course_url">
              {user ? (
                <>
                  <CourseCommunity />
                </>
              ) : (
                <>
                  <Redirect to="/login"></Redirect>
                </>
              )}
            </Route>

            <Route exact path="/milestones/:course_url/:batch_id">
              {user ? (
                <>
                  <CourseMilestones />
                </>
              ) : (
                <>
                  <Redirect to="/login"></Redirect>
                </>
              )}
            </Route>

            <Route exact path="/newsfeed/:post_id">
              {user ? (
                <>
                  <CommentsPage account={account} access={access} />
                </>
              ) : (
                <>
                  <Redirect to="/login"></Redirect>
                </>
              )}
            </Route>

            <Route exact path="/features">
              <FeaturesPage />
            </Route>

            <Route exact path="/realtime">
              <FirebaseChat account={account} />
            </Route>

            <Route exact path="/notes/:course_url">
              {user ? (
                <>
                  <CourseNotes />
                </>
              ) : (
                <>
                  <Redirect to="/login"></Redirect>
                </>
              )}
            </Route>

            <Route exact path="/notes">
              <NotesListPage user={user} account={account} />
            </Route>

            <Route exact path="/lab">
              {user ? (
                <>
                  <LabPage />
                </>
              ) : (
                <>
                  <Redirect to="/login"></Redirect>
                </>
              )}
            </Route>

            <Route exact path="/projects/:course_url/:batch_id">
              {user ? (
                <>
                  <MyProjects />
                </>
              ) : (
                <>
                  <Redirect to="/login"></Redirect>
                </>
              )}
            </Route>

            <Route exact path="/assignments/:course_url/:batch_id">
              {user ? (
                <>
                  <MyAssignments account={account} />
                </>
              ) : (
                <>
                  <Redirect to="/login"></Redirect>
                </>
              )}
            </Route>

            <Route exact path="/resources/:course_url/:batch_id">
              {user ? (
                <>
                  <CourseResources />
                </>
              ) : (
                <>
                  <Redirect to="/login"></Redirect>
                </>
              )}
            </Route>

            <Route exact path="/recordings/:course_url/:batch_id/:videoid">
              {user ? (
                <>
                  <Recordings secondaryview={false} />
                </>
              ) : (
                <Redirect to="/login"></Redirect>
              )}
            </Route>

            <Route exact path="/referral-code/:code">
              <InitiateReferralCode />
            </Route>

            <Route exact path="/refer-and-earn">
              {user ? (
                <>
                  <ReferralProgram />
                </>
              ) : (
                <>
                  <Redirect to="/login"></Redirect>
                </>
              )}
            </Route>

            <Route exact path="/terms-and-conditions">
              <TermsPage />
            </Route>

            <Route exact path="/privacy-policy">
              <PrivacyPolicyPage />
            </Route>

            <Route exact path="/refund-policy">
              <RefundPolicyPage />
            </Route>

            <Route exact path="/about">
              <AboutCompanyPage />
            </Route>

            <Route exact path="/contact">
              <ContactPage />
            </Route>

            <Route exact path="/vcp/:code" userdata={user}>
              <ConfirmRegistrationPage />
            </Route>

            <Route exact path="/followers" userdata={user}>
              {user ? (
                <>
                  <FollowersPage account={account} access={access} />
                </>
              ) : (
                <Redirect to="/login"></Redirect>
              )}
            </Route>

            <Route exact path="/change-password" userdata={user}>
              {user ? (
                <>
                  <PasswordChange />
                </>
              ) : (
                <Redirect to="/login"></Redirect>
              )}
            </Route>

            <Route exact path="/timeline">
              {user ? (
                <>
                  <Community account={account} access={access} />
                </>
              ) : null}
            </Route>

            <Route exact path="/quizzes">
              <QuizPage account={account} access={access} />
            </Route>

            <Route exact path="/mock-tests">
              <MockTestCategories account={account} access={access} />
            </Route>
            <Route exact path="/batch-progress/:course_url/:batch_id">
              <BatchProgress account={account} access={access} />
            </Route>

            <Route exact path="/mock-test/:course_url">
              <MockTestSubjects account={account} access={access} />
            </Route>
            <Route exact path="/mock-answers">
              <TestAnswers account={account} access={access} />
            </Route>

            <Route exact path="/exam/:test_type/:course_url/:test_code">
              {user ? (
                <>
                  <MockTest account={account} access={access} />
                </>
              ) : (
                <>
                  {() => RedirectNext({ payload: "/my-exams" })}
                  <Redirect to="/login"></Redirect>
                </>
              )}
            </Route>

            <Route exact path="/test-topics/:test_type/:course_url">
              <TestTopics account={account} access={access} />
            </Route>

            <Route exact path="/technical-quizzes">
              <TechnicalQuizzes account={account} access={access} />
            </Route>


            <Route exact path="/resource/:resource_url">
              <FreePdfView account={account} access={access} />
            </Route>

            <Route exact path="/cheat-sheets">
              <CodingCheatsheets account={account} access={access} />
            </Route>

            <Route exact path="/search/key=:query">
              <SearchPage account={account} access={access} />
            </Route>

            <Route exact path="/interview-preparation">
              <InterviewPreparations account={account} access={access} />
            </Route>

            <Route exact path="/ui-ux-workshop">
              <UiUxWorkshop />
            </Route>
            <Route exact path="/data-science-workshop">
              <DataScienceWorkshop />
            </Route>
            <Route exact path="/trainer/:username">
              <TrainerDetailsPage />
            </Route>

            <Route exact path="/data-science-workshop">
              <DataScienceWorkshop />
            </Route>

            <Route exact path="/full-stack-workshop">
              <AmazonWorkshop />
            </Route>
            <Route
              exact
              path="/course-dashboard/:course_url/:batch_id/:team_id"
            >
              <TeamLeaderboard />
            </Route>
            <Route exact path="/verify-certificate">
              <VerifyCertificate />
            </Route>
            <Route exact path="/campus">

              <CollageHome />
            </Route>



            <Route exact path="/business">

              <BusinessOverview />
            </Route>



            <Route exact path="/lessons/:course_url/:chapter_id/:topic_id">
              {user ? (
                <>
                  <WatchLessons account={account} access={access} />
                </>
              ) : (
                <>
                  {() => RedirectNext({ payload: "/my-courses" })}
                  <Redirect to="/login"></Redirect>
                </>
              )}
            </Route>

            <Route exact path="/exam/:code">
              {user ? (
                <>
                  <ExamPage account={account} access={access} />
                </>
              ) : (
                <>
                  {() => {
                    RedirectNext({ payload: "/my-exams" });
                  }}
                  <Redirect to="/login"></Redirect>
                </>
              )}
            </Route>
            <Route exact path="/following" userdata={user}>
              {user ? (
                <>
                  <FollowingsPage account={account} access={access} />
                </>
              ) : (
                <Redirect to="/login"></Redirect>
              )}
            </Route>
            <Route exact path="/edit-profile" userdata={user}>
              {user ? (
                <>
                  <EditUserInfo access={access} account={account} />
                </>
              ) : (
                <>
                  {() => RedirectNext({ payload: "/edit-profile" })}
                  <Redirect to="/login"></Redirect>
                </>
              )}
            </Route>



            {/*TESTING SECTION STARTS*/}


            {/*TESTING SECTION STARTS*/}
          </DefaultTemplate>
        </Switch>
      </Router>
    </div>
  );
}

export default App;


const NoNavbar = styled.div`

.main{
margin-top:0px !important;
padding-top:0px;

}


`
