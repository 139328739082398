import React from 'react'

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import defaultStyle from '../community/defaultStyle';
import ModalStyle from '../../data/ModalStyle';
import Close from '@mui/icons-material/Close';

const IntroModal = ({visibility,video,HandleClose,title,poster}) => {
  return (
    <div>

<Modal
  open={visibility}
  onClose={HandleClose}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
  verti
>
  <Box sx={ModalStyle} className='col-lg-7  col-md-7 col-sm-10 col-12'>
   
    <div className="d-flex justify-content-between">
        <div>
        <h4>{title?title:null}</h4>
    
      
        </div>

      
      <Close className='click' onClick={HandleClose}/>
    </div>
    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
    <video className="w-100 bg-dark" poster={poster} src={video?video:null} muted autoPlay></video>
    </Typography>
  </Box>
</Modal>
    </div>
  )
}

export default IntroModal