import axios from 'axios'
import ENDPOINT from '../../data/ENDPOINT'
import SecurityHeaders from '../../data/SecurityHeaders'

const  SubmitCourseQuiz = async ({payload})=>{

const data = new FormData()


 
     
data.append("option",payload.option)
data.append("quiz_id",payload.quiz_id)
          data.append("session",payload.user)
       

const res = await 
axios.post(ENDPOINT+`submit-course-quiz.php`,data,SecurityHeaders)
if(res){
    
     return res.data
}

}




export default SubmitCourseQuiz