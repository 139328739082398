import React from 'react'
import ModalStyle from '../../data/ModalStyle'
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Box } from '@mui/material';
import { Close } from '@material-ui/icons';
const WatchDemoModal = ({show,HandleClose,title,video,poster}) => {
  return (
<>

<Modal
  open={show}
  onClose={HandleClose}

>
  <Box sx={ModalStyle}  className="col-lg-4 col-md-5 br-5 col-sm-6 col-11 p-2">
<div className="d-flex justify-content-between">
     <h4>{title?title:""}</h4>
     <Close className="text-danger" type="button" onClick={HandleClose}/>
</div>
    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
    <video className="w-100"  poster={poster?poster:null}  autoPlay src={video?video:null}></video>
    </Typography>
  </Box>
</Modal>
</>
  )
}

export default WatchDemoModal