import { Avatar } from '@mui/material'
import React, { useState } from 'react'
import { useEffect } from 'react'
import { useContext } from 'react'
import { Link, useParams } from 'react-router-dom'
import GetCourseBatch from '../../../actions/GetCourseBatch'
import { AuthContext } from '../../../context/AuthContext'
import Localbase from 'localbase'
import axios from 'axios'
import ENDPOINT from '../../../data/ENDPOINT'
import SecurityHeaders from '../../../data/SecurityHeaders'
import AnimatePage from '../../../actions/animations/AnimatePage'
const TeamLeaderboard = ({courseUrl,batchId,teamId,limit}) => {

  




const [leader,setLeader] = useState(null)

const getTeamLeader = async ()=>{

    const data = new FormData();

    data.append('course_url', courseUrl);
    data.append('batch_id',batchId);
    data.append('team_id',teamId);
    data.append('limit',limit?limit:5)

  setloading(true)

    const res = await axios.post(ENDPOINT+`team-leaderboard.php`,data,SecurityHeaders)

    if(res){
       setloading(false)
      AnimatePage()
        return setLeader(res.data.data)
    }
}




useEffect(()=>{

getTeamLeader()


},[courseUrl,batchId])

const [loading,setloading] = useState(false)
  return (
    <div className="w-100 section animate  mt-4 border rounded p-2">
   
    {leader?
    <>
      <div className="d-flex justify-content-between">
          <h4>Leaderboard</h4>

         <Link to={"/leaderboard/"+courseUrl+"/"+batchId}>View all</Link>
         
              
 
         
      </div>
     <hr className="mt-1"/>
    {leader.map((user,index)=>(

     <>
 <div key={index} className={"d-flex w-100 justify-content-between align-items-center mt-3 mb-3 fade-in-bottom delay"+(index+1)+"s"}>
    <Link to={"/u/"+user.user_data.username} className="d-flex align-items-center text-dark col">
    <Avatar src={user.user_data.profile_pic} className="mr-5"/>
     <div>
          <h6 className="pb-0 truncate truncate1 sm-16">{user.user_data.first_name+" "+user.user_data.last_name}</h6>
          <p className="truncate truncate1 text-grey extra-small">@{user.user_data.username}</p>
     </div>
    </Link>
    <div className="mr-15 text-grey">
    <p>Rank {index+1}</p>
    <p className="extra-small">{user.marks?user.marks:0} points</p>
    </div>
    
   
     </div>
     
     </>
    ))}
    
    </>:
    
    
    <>
    
  {loading?
  <>
  
  
  </>:
  <>
  
  <h4>No Students Found !</h4>
    <p>It seems like no student have joined the community yet. </p>
  </>}
    </>
    
    }


    
     

    </div>
  )
}

export default TeamLeaderboard