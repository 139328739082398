

import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import GetClasses from '../../../actions/course/GetClasses'
import { AuthContext } from '../../../context/AuthContext'
import SetPage from '../../../data/SetPage'
import noclassicon from '../../../assets/images/no-courses.svg'
import RecordingItem from '../../tutorials/RecordingItem'
import ClassItem from './ClassItem'
import { Avatar, Skeleton } from '@mui/material'
import DetectDevice from '../../../data/DetectDevice'
import GetTiming from '../../../data/GetTiming'

const UpcomingClassesPage = ({account}) => {

const {user} = useContext(AuthContext)

const params = useParams()

const course_url = params.course_url
const batch_id = params.batch_id
const [classes,setclasses] = useState(null)
const NewGetClasses = async ()=>{

const res = await GetClasses({payload:{
user:user.SessionId,
status:"incomplete",
course_url:course_url,
batch_id:batch_id
}})

if(res){
  setloading(false)
  if(res.status==="success"){


setclasses(res.data)
  }
   //  //console.log("classes "+JSON.stringify(res))
}
} 

let style = {height:60,width:60}
let device = DetectDevice()
useEffect(()=>{
  SetPage("My Classes")

  if(device==="mobile"){
    style = {height:50,width:50}
  }

var d = new Date()
//console.log(d.getHours())

NewGetClasses()
},[])
const time = GetTiming()
const [loading,setloading] = useState(true)
  return (
    <div className="container sm-p-0">{account&&classes?
<>

<div className={"classes-cover container  d-flex align-items-center "+time}>

<div className="d-flex align-items-center text-light">
<Avatar sx={{height:60,width:60}} src={account.profile_pic}/>
<div className="ms-2">
<h3 className="mb-1 pb-0 sm-16">Good {time} {account.first_name} !</h3>
<h6>You have {classes?classes.length:0} upcoming {classes&&classes.length>1?"Classes":"Class"}</h6>
</div>
</div>
</div>


</>:
<>

</>}

<div className=" container min-vh-100 pt-3  mb-3">



<div className="d-flex row  flex-wrap">


{classes?
<>
{classes.map((item,index)=>(
  <>
<div className="col-lg-6 col-md-12 col-sm-12  col-12">
<ClassItem key={item.id} item={item} index={(index+1)}/>
</div>

  </>
))}

</>:<>

<div className="container">

{loading?
<>
<div className="d-flex flex-wrap justify-content-between">

<div className="col-lg-6 col-12 mt-2 mb-2 p-3">
<Skeleton className="w-100" variant="rectangular" height={170}/>

</div>
<div className="col-lg-6 col-12 mt-2 mb-2 p-3">
<Skeleton className="w-100" variant="rectangular" height={170}/>

</div>

<div className="col-lg-6 col-12 mt-2 mb-2 p-3">
<Skeleton className="w-100" variant="rectangular" height={170}/>

</div>


<div className="col-lg-6 col-12 mt-2 mb-2 p-3">
<Skeleton className="w-100" variant="rectangular" height={170}/>

</div>


<div className="col-lg-6 col-12 mt-2 mb-2 p-3">
<Skeleton className="w-100" variant="rectangular" height={170}/>

</div>


<div className="col-lg-6 col-12 mt-2 mb-2 p-3">
<Skeleton className="w-100" variant="rectangular" height={170}/>

</div>


<div className="col-lg-6 col-12 mt-2 mb-2 p-3">
<Skeleton className="w-100" variant="rectangular" height={170}/>

</div>

<div className="col-lg-6 col-12 mt-2 mb-2 p-3">
<Skeleton className="w-100" variant="rectangular" height={170}/>

</div>
</div>

</>:
<>
<div className="col-lg-5 mx-auto text-center">

<img src={noclassicon} className="w-100 mb-4"/>
<h3>No Upcoming Classes</h3>
<p className="mt-3">There are no upcoming classes scheduled for this course. Please contact your trainer for more details.</p>
</div>

</>}

</div>


</>}





</div>
  



    </div>






    </div>
  )
}

export default UpcomingClassesPage