
import React, { useContext, useState } from 'react';
import axios from 'axios';

import {Toast } from 'react-bootstrap'
import { AuthContext } from '../../context/AuthContext';
import ENDPOINT from '../../data/ENDPOINT';
import SecurityHeaders from '../../data/SecurityHeaders';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';


const UploadFreeCourseAccessPic = () => {

    const params = useParams();
    const payload = params.share
    const { user } = useContext(AuthContext);
    const [IdPhoto, setIdPhoto] = useState('');
    const [ToastTitle,setToastTitle] = useState(null);
    const [ToastMessage,setToastMessage] = useState(null)
    const [toast,setToast] = useState(false)
   
   

    const UploadPhoto = async () => {
        try {
            const data = new FormData();
            data.append('session', user.SessionId);
            data.append('photo', IdPhoto);
            data.append('course_id',133243)
            data.append(payload,payload)

            const res = await axios.post(ENDPOINT + "free-course-request.php", data, SecurityHeaders);
            if (res.data) {
               
               if(res.data.status ==="success"){
                setToast(true)
                setToastTitle("Successfully Upload Id")
      window.location.replace("/unlock-course-steps")

               }
                
            }
            else{
                setToast(true)
                setToastTitle("Error Upload Id") 
                setToastMessage("Something went wrong")
            }
        } catch (error) {
            console.log("Error upload student id photo", error);
        }
    }
    

    return (
        <div className='vh-100 d-flex justify-content-center align-items-center'>


            <div className='bg-light col-lg-6  mt-5  bg-white shadow rounded p-3 '>
                <h3 className='text-center'>Upload Screen Short</h3>
                <hr />
                <p className='text-center mt-3'>Access you course</p>

                <div className='text-center p-2'>
                    <form className='d-block'>
                       
                        <input onChange={(e) => setIdPhoto(e.target.files[0])} type="file" />
                        <div className='d-flex justify-content-center mt-3 w-100'>
                           {
                            IdPhoto? <button onClick={(e) => {
                                UploadPhoto();
                                e.preventDefault();
                            }} className='btn btn-primary w-75'>upload</button>:null
                           }
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default UploadFreeCourseAccessPic;
