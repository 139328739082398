import React from 'react'
import { Helmet } from 'react-helmet'

const CourseDetailsLocalSEO = ({course_url,mode}) => {
  return (
<>

{course_url==="website-development"?
<>
<Helmet>‍
    <title>{"Website development "+mode+" Course - Training | Skills Uprise"}</title>‍
    <meta name="description" content="Grab your dream job as a website developer by learning website development in just 40 days. Join our comprehensive website development training program today" />       
   
  </Helmet>
</>:


<>

</>}



</>
  )
}

export default CourseDetailsLocalSEO