import React from 'react'
import Rating from '@mui/material/Rating';
import { Link } from 'react-router-dom';
const SliderCourseItem = ({course}) => {
  return (
    <Link to={"/course/"+course.course_url+"/"+course.mode} className="slider-course-item text-dark">
      <img className="w-100 slider-cover" src={course.course_cover_url}/>
      <div className="p-2">
<h6 className="truncate truncate2 sm-14">{course.course_title}</h6>

<div className="d-flex mt-1 align-items-center">
<Rating className="sm-16" size="small"  name="read-only" value={course.rating} readOnly />
<p className="sm-12 mb-0 ms-2">({course.ratings + " ratings"})</p>
</div>
      </div>
    </Link>
  )
}

export default SliderCourseItem
