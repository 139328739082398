import React, { useEffect } from 'react'
import { useState } from 'react'
import BottomNavigationBar from '../../components/BottomNavigationBar'
import Footer from '../../components/Footer'
import Navbar from '../../components/Navbar'
import DetectDevice from '../../data/DetectDevice'

const DefaultTemplate = ({children,account,access, fluid}) => {

  useEffect(() => {
    window.scrollTo(0, 0)

 
  }, [])


const [footer,setfooter] = useState(true)

  useEffect(()=>{

    var pathname = window.location.pathname
    
  if(pathname==="/hacking-workshop"){
  setsecondaryview(false)
  }
  else if(pathname.includes("/course/")||pathname.includes("/recordings/")){
  setsecondaryview(false)
  }
  
  else{
  setsecondaryview(true)
  }


  if(pathname.includes("book")){
    setsecondaryview(false)
    setfooter(false)
  }
  else{
    setfooter(true)
  }
  },[window.location.pathname])
const device = DetectDevice()
  const [secondaryview,setsecondaryview] = useState(true)
  return (
    <div className={secondaryview&&device!=="mobile"?"main":"main nosecondary"}>
<Navbar account={account} secondaryview={secondaryview}  fluid={fluid}/>

{children}
<BottomNavigationBar footer={footer} access={access} account={account}/>
{footer===false?
<>

</>:
<Footer footer={footer}  account={account}/>
}
    </div>
  )
}

export default DefaultTemplate