import axios from "axios";
import React, { useEffect, useState } from "react";

import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { Link } from "react-router-dom";
import { PlayCircle } from "@mui/icons-material";
import ENDPOINT from "../../../data/ENDPOINT";
import SecurityHeaders from "../../../data/SecurityHeaders";
import SetPage from "../../../data/SetPage";
import ModalStyle from "../../../data/ModalStyle";
import { useParams } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";

const CourseResources = ({ account }) => {
  const params = useParams();
  const course_url = params.course_url;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [categories, setcategories] = useState(null);

  const GetQuizCategories = async () => {
    const data = new FormData();
    data.append("course_url", course_url);
    const res = await axios.post(
      ENDPOINT + "get-course-resources.php",
      data,
      SecurityHeaders
    );

    if (res) {
      if (res.data.status === "success") {
          setInititalData(res.data.data)
          setResponse(res.data.data)
        setcategories(res.data.data);
      }
    }
  };

  useEffect(() => {
    GetQuizCategories();
    SetPage("Resources");
  }, []);

  const [modal, setmodal] = useState(false);

  const [initialData, setInititalData] = useState(null);

  const [response, setResponse] = useState(initialData);
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearch = (e) => {
    const term = e.target.value.toLowerCase();

    const filteredData = initialData.filter((item) => {
      return item.title.toLowerCase().includes(term);
    });

    setResponse(filteredData);
    setSearchTerm(term);
  };

  return (
    <>
      <Modal
        open={modal}
        onClose={() => {
          setmodal(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="col-lg-4 col-md-5 col-sm-6 col-11 p-2" sx={ModalStyle}>
          <div className="modal-header">
            <h4> Student Access Only !</h4>
            <CloseIcon className="click" onClick={() => setmodal(false)} />
          </div>

          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {account ? (
              <>Please register a course to access this section</>
            ) : (
              <>Login into your account to access this section</>
            )}

            <div className="d-flex justify-content-between mt-4">
              <button
                onClick={() => setmodal(false)}
                className="btn btn-sm  btn-outline-danger rounded-pill"
              >
                Cancel
              </button>
              {account ? (
                <>
                  <Link
                    to="/courses"
                    className="btn btn-primary btn-sm  rounded-pill"
                  >
                    Browse Courses
                  </Link>
                </>
              ) : (
                <>
                  <Link
                    to="/login"
                    className="btn btn-primary btn-sm  rounded-pill"
                  >
                    Login
                  </Link>
                </>
              )}
            </div>
          </Typography>
        </Box>
      </Modal>

      <div className="min-vh-100 interview-praparation pb-5">
        <div className="container d-none d-sm-flex align-items-center justify-content-between pt-3 pb-0">
          <h2>Course Resources</h2>
          <div className="d-flex justify-content-end mb-3">
            <div className="d-flex p-1 border border-1 align-items-center col-12 ">
            <SearchIcon className="ri-search-line  text-secondary" />
              <input
                onChange={handleSearch}
                type="text"
                className="border border-0"
                placeholder="Search"
              />
      
            </div>
          </div>
        </div>

        <hr className="d-none d-sm-block" />

        <div className="container mt-2 d-flex flex-wrap">
          {response ? (
            <>
              {response.map((item, index) => (
                <>
                  <div className="col-lg-4 col-md-4 col-sm-6 col-12 p-2">
                    <div
                      className={
                        " quiz-category p-3 bl-item bl-item-" + (index + 1)
                      }
                    >
                      <h5 className="sm-16 truncate truncate2">{item.title}</h5>

                      <p className="text-secondary truncate truncate2 sm-13">
                        {item.description}{" "}
                      </p>

                      <div className="d-flex justify-content-between mt-3">
                        <span></span>

                        {item.type === "download" ? (
                          <>
                            <a
                              className="btn btn-primary rounded-pill sm-13 btn-sm"
                              target="_blank"
                              href={item.url}
                            >
                              Download
                            </a>
                          </>
                        ) : (
                          <>
                            {item.type === "reference" ? (
                              <>
                                <a
                                  className="btn btn-primary rounded-pill sm-13 btn-sm"
                                  target="_blank"
                                  href={item.url}
                                >
                                  Read More
                                </a>
                              </>
                            ) : (
                              <>
                                <a
                                  className="btn btn-primary rounded-pill sm-13 btn-sm"
                                  target="_blank"
                                  href={item.url}
                                >
                                  Read More
                                </a>
                              </>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </>
              ))}
            </>
          ) : (
            <>
              <div className="text-center col-lg-3 mx-auto col-sm-4 col-md-4 col-11">
                <img
                  className="w-100"
                  src="https://skillsuprise.com/resources/images/no-resources.svg"
                />
                <h3>No Resources Available</h3>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default CourseResources;