import React from 'react'
import Modal from '@mui/material/Modal';

import Close from '@mui/icons-material/Close'
import ModalStyle from '../../data/ModalStyle';
import Box from '@mui/material/Box';
import CustomVideoPlayer from '../elements/CustomVideoPlayer';
const VideoPlayerModal = ({visibility,HandleClose,video}) => {
  return (
<>

<Modal
  open={visibility}
  onClose={HandleClose}

>
  <Box sx={ModalStyle}  className="col-lg-4 col-md-5 br-5 col-sm-6 col-11 p-2">
<div className="p-2">

    <div className="d-flex">
        <Close className='click' onClick={HandleClose}/>
    </div>
    <CustomVideoPlayer  video={video} nosound={false}/>
</div>
  </Box>
</Modal>


</>
  )
}

export default VideoPlayerModal