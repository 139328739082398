import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';
import React, { useContext, useEffect, useState } from 'react';
import ScratchCards from './ScratchCards';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import {Link} from 'react-router-dom'
import CircularProgress from '@mui/material/CircularProgress';
import {
     EmailIcon,
     EmailShareButton,
     FacebookIcon,
     FacebookShareButton,
    
  
     LinkedinIcon,
    
     LinkedinShareButton,
  
    
     TelegramIcon,
  
    
     TelegramShareButton,
     
     TwitterIcon,
     
     TwitterShareButton,
  
     WhatsappIcon,
  
     WhatsappShareButton,
  
   } from "react-share";
   import axios from 'axios'
   import Localbase from 'localbase'
import { AuthContext } from '../../context/AuthContext';
import ENDPOINT from '../../data/ENDPOINT';
 import { useParams } from 'react-router-dom';
 import Close from '@mui/icons-material/Close';
import { Helmet } from 'react-helmet';
const InitiateReferralCode = ({props}) => {

const params = useParams();
const referral_code = params.code;
const {user} = useContext(AuthContext)
useEffect(() => {
  window.scrollTo(0, 0)
}, [])
const [redirecting,setredirecting] = useState(false)
const db = new Localbase('db');
const [referral,setreferral] = useState(null)
const CheckReferral = async ()=>{


const formData = new FormData();


formData.append('referral_code',referral_code);
const res = await axios.post(`${ENDPOINT}check-referral.php`,formData,{
  headers:{
    'content-type':'multipart/form-data'
  }
})

if(res){
  if(res.data.status==="success" && res.data.data!==null){
    setreferral(res.data.data[0])
db.collection("paths").add({
  rf_code:referral_code
},'rf_code')
setmodal(true)
  }
  else{
    setmodal(true)
  }
  //  //  //console.log("checked "+JSON.stringify(res))
}
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',

  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};
useEffect(() => {

CheckReferral()
}, [])

const [modal,setmodal] = useState(false);

  return(

<>
<Helmet>‍
        <title>Referral Program Registration - Skills Uprise</title>‍
        <meta name="description" content="Skills Uprise" />       
        <meta name="twitter:card" content="" />   
        <meta name="twitter:site" content="@skillsuprise" />    
        <meta name="twitter:creator" content="@skillsuprise" />                
        <meta name="twitter:title" content="" />   
        <meta name="twitter:description" content="" />  
        <meta name="twitter:image" content=""/>     
        <meta property="og:title" content="" />  
        <meta property="og:description" content="" />        
        <meta property="og:image" content=""/>
        <meta property="og:url" content=""/>
        <meta property="og:site_name" content="Skills Uprise" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta property="fb:app_id" content="ID_APP_FACEBOOK" />
      </Helmet>
<Modal
        hideBackdrop
        open={modal}
        onClose={()=>setmodal(false)}
      >
        <Box className="p-3" sx={{ ...style, width: 400 }}>
{referral?
<>
<div className="flex space-between">
       <div><h4>Referral Code Verified</h4></div>

<Close type="button" onClick={()=>{setmodal(false);window.location.replace("/")}} className="text-danger"/>
          </div>

         
          <hr/>
         
          <p>Earn Upto ₹ 500 by
           joining Skills Uprise using  {referral.first_name+" "+referral.last_name}'s referral code ({referral_code}).</p>
         
         <hr/>
          <div className="flex align-items-center mb-3">
           <Avatar src={referral.profile_pic}/>
            <div className="ml-2">
              <h6 className="mb-0 pb-1">{referral.first_name+" "+referral.last_name}</h6>
              <p className="mb-0">Referral Code : {referral_code}</p>
            </div>
           </div>
          <Link to="/signup"  onClick={()=>{setredirecting(true)}} className="btn btn-primary w-100">{redirecting?"Redirecting...":"Continue"}</Link>
         
      
</>:
<>
<div className="flex space-between">
       <div><h5>Verification Successful</h5></div>

<Close type="button" onClick={()=>{setmodal(false);window.location.replace("/")}} className="text-danger"/>
          </div>
          <Link to="/signup"  onClick={()=>{setredirecting(true)}} className="btn btn-primary w-100">{redirecting?"Redirecting...":"Proceed to Signup"}</Link>
         
</>}

      </Box>
      </Modal>

{referral?
<>
<div className="min-vh-100 bg-white">


</div>
</>
:
<>

</>}

<div className="container-fluid min-vh-90 pt-0 p-0 mb-5 pb-5">



<div className="pt-5 container-fluid">
  <div className="text-center pt-5">
       
   
     <p>
Verifying your referral code...</p>
     <div className="justify-center">
     <CircularProgress />
     </div>

  </div>




</div>











</div>

<div >




</div>





</>


  );
};

export default InitiateReferralCode;
