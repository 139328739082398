import React, { useEffect, useState } from 'react'
import { useContext } from 'react'
import { useParams } from 'react-router-dom'
import GetAssignments from '../../../actions/course/GetAssignments'
import { AuthContext } from '../../../context/AuthContext'
import AssignmentDescriptionAccordion from './AssignmentDescriptionAccordion'
import SetPage from '../../../data/SetPage'
import { Accordion, Alert, Avatar, Skeleton } from '@mui/material'
import AssignmentsChart from './AssignmentsChart'
import ModalStyle from '../../../data/ModalStyle'
import AssignmentSubmit from './AssignentSubmit'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import noassignments from '../../../assets/images/no-assignments.svg'
import CloseIcon from '@mui/icons-material/Close';
import { Close } from '@mui/icons-material'
import dateFormat from 'dateformat'
import progressicon from '../../../assets/icons/progress-report.svg'
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import DetectDevice from '../../../data/DetectDevice'
import axios from 'axios'
import ENDPOINT from '../../../data/ENDPOINT'
import SecurityHeaders from '../../../data/SecurityHeaders'
import LinearProgressWithLabel from '../../../components/progress/LinearProgressWithLabel'
import { Check, Lock } from '@material-ui/icons'
import CircularProgress from '../../dashboard/CircularProgress'
import styled from 'styled-components';
import SearchIcon from '@mui/icons-material/Search';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Link } from 'react-router-dom/cjs/react-router-dom.min'

const MyAssignments = ({ account }) => {

  const params = useParams()
  const course_url = params.course_url
  const batch_id = params.batch_id
  const { user } = useContext(AuthContext)
  const [report, setreport] = useState(null)
  const [assignments, setassignents] = useState([])


  const NewAssignments = async () => {
    const res = await GetAssignments({
      payload: {
        user: user.SessionId,
        course_url: course_url,
        batch_id: batch_id
      }
    })
    if (res) {
      setloading(false)
      ////console.log(" assignments"+JSON.stringify(res))
      if (res.status === "success" && res.data !== null) {
        //  //console.log("Report "+JSON.stringify(res))
        setassignents(res.data)
        setreport(res.report[0])
        setInitialData(res.data)
        setResponse(res.data);
      }

    }
  }

  console.log(report)
  useEffect(() => {
    SetPage("Assignments")
    window.scrollTo(0, 0)

  }, [])
  const [currenttask, setcurrenttask] = useState(null)
  const device = DetectDevice()
  useEffect(() => {
    NewAssignments()
    if (device === "mobile") {
      setmore(false)
    }
  }, [])

  const InitiateSubmission = ({ id }) => {
    setcurrenttask(id)
    setsubmit(true)
  }

  const [more, setmore] = useState(true)
  const [loading, setloading] = useState(true)
  const [submit, setsubmit] = useState(false)

  const [submissions, setsubmissions] = useState([])
  const BatchSubmission = async () => {
    const data = new FormData()
    data.append("session", user.SessionId)
    data.append("course_url", course_url)
    data.append("batch_id", batch_id)

    const res = await axios.post(ENDPOINT + "batch-assignment-submissions-overview.php", data, SecurityHeaders)

    if (res) {

      if (res.data.status === "success") {
        setsubmissions(res.data.data)

      }
    }
  }

  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false)

  useEffect(() => {
    BatchSubmission()
  }, [])


  const [showDescriptions, setShowDescriptions] = useState(
    new Array(assignments.length).fill(false)
  );

  const toggleDescription = (index) => {

    const updatedShowDescriptions = [...showDescriptions];
    updatedShowDescriptions[index] = !updatedShowDescriptions[index];
    setShowDescriptions(updatedShowDescriptions);

  };

  const [initialData, setInitialData] = useState(null);

  const [response, setResponse] = useState(initialData);
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearch = (e) => {
    const term = e.target.value.toLowerCase();

    const filteredData = initialData.filter((item) => {
      return item.title.toLowerCase().includes(term);
    });

    setResponse(filteredData);
    setSearchTerm(term);
  };
  return (
    <div className="min-vh-100 pb-5 ">



      {loading ?
        <>

          <div className="d-flex container flex-wrap justify-content-between mt-3">


            <div className="col-lg-6 d-flex flex-wrap justify-content-start ">
              <Skeleton height={200} variant="rectangular" className="w-100 mt-2 mb-2 " />

              <Skeleton height={200} variant="rectangular" className="w-100  mt-2 mb-2 " />

              <Skeleton height={200} variant="rectangular" className="w-100  mt-2 mb-2 " />


            </div>


            <div className="col-lg-5">

              <Skeleton height={600} variant="rectangular" className="w-100 min-vh-80 " />

            </div>

          </div>


        </> :
        <>


          <Modal
            open={submit}
            onClose={() => { setsubmit(false) }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={ModalStyle} className="col-lg-4 col-md-5 col-sm-6 col-11 rounded p-3">
              <div className="modal-header">
                <h4>Upload Assignment</h4>
                <Close className="click" onClick={() => setsubmit(false)} />
              </div>
              <hr />
              <AssignmentSubmit id={currenttask} HandleClose={() => setsubmit(false)} Refresh={NewAssignments} />
            </Box>
          </Modal>




          <StyledAssignmentPage>
            <div className='container'>
              <div className='my-2 my-md-5 d-flex col-12 align-items-center justify-content-between flex-wrap '>
                <h2 className='mb-0 pb-0 d-none d-md-block'>Assignments</h2>

                <div className="d-flex flex-wrap justify-content-end col-lg-6 col-md-6 col-sm-12 col-12">
                  <div className="search-box align-items-center d-flex border border-1  col-12 col-sm-10 col-md-6 col-lg-5 ">
                  
                  <SearchIcon className="ri-search-line ps-2 text-secondary" />
                    <input
                      onChange={handleSearch}
                      type="text"
                      className="form-control border border-0"
                      placeholder="Search Assignment"
                    />
                    {/* <i className="ri-search-line search-icon" /> */}
               
                  </div>
<Link to={"/batch-progress/"+course_url+"/"+batch_id} className="btn btn-primary d-none d-md-block">View Batch Progress</Link>

                </div>
              
 

             </div>

              <div className=' p-0 p-md-3 rounded-3'>

                {/* <div onClick={() => setShow1(!show1)} className='box2 mt-4 rounded-1 p-3  mb-5 d-flex justify-content-between'>
                  <h5>{account.first_name ? account.first_name : null} Your Batch Progress</h5>
                  {
                    show1 ?
                      <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />
                  }
                </div> */}
                {show1 ?
                  <div className='d-flex justify-content-between'>
                    <div className='col-lg-6 overflow-y invisible-scrollbar'>

                      {
                        submissions ? <>  {submissions.map((item, index) => (
                          <div className='col-12 ' key={index}>



                            <div className=' col-12 '>
                              <div className='d-flex hover  rounded-4 p-3'>
                                <Avatar src={item.profile_pic} alt="Remy Sharp" sx={{ width: 55, height: 55 }} />
                                <div className='ms-2 w-100'>
                                  <h6 className='m-0 p-0'>{item.first_name + " " + item.last_name}</h6>
                                  <p className='m-0 p-0'>{item.submission_count + " / " + report.total_assignments} Submitted</p>
                                  <div class="progress" role="progressbar" aria-label="Example 1px high" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100" style={{ height: '06px' }}>
                                    <div class="progress-bar" style={{ width: '50%' }}></div>
                                  </div>
                                </div>
                              </div>
                            </div>






                          </div>

                        ))}</> : null
                      }

                    </div>
                    <div className='col-lg-5 bg-white shadow mb-5'>
                      <div className='p-4'>
                        <h5 className='mb-3'>
                          How to earn your points
                        </h5>
                        <ol>
                          <li>Make sure you submit assignment on or before deadline to gain more points</li>
                          <li>Also reach your milestones to get more points</li>
                        </ol>
                      </div>
                    </div>


                  </div>
                  : null}

                <div className='shadow box m-0 m-md-3 p-3  rounded-4 d-flex justify-content-evenly  flex-wrap'>
                  {report ?
                    <>
                      <div className='col-5 col-md-2'>
                        <h5 className='ms-3'>{report.total_assignments}</h5>
                        <p>Total</p>
                      </div>
                      <div className='border-right'></div>
                      <div className='col-5 col-md-2'>
                        <h5 className='ms-4'>{report.submitted}</h5>
                        <p>Submitted</p>
                      </div>
                      <div className='border-right d-none d-md-block'></div>
                      <div className='col-5 col-md-2'>
                        <h5 className='ms-4'>{report.completed ? report.completed : 0}</h5>
                        <p>Eliminated</p>
                      </div>
                      <div className='border-right'></div>
                      <div className='col-5 col-md-2'>
                        <h5 className='ms-4'>{report.rejected?report.rejected:0}</h5>
                        <p>Rejected</p>
                      </div>
                      {/* <div className='border-right'></div>
            <div>
              <h5 className='ms-3'>3</h5>
              <p>Pending</p>
            </div> */}
                    </> : null

                  }
                </div>

                <div className='d-flex flex-wrap p-0 p-md-4 '>

                  {response ? <>  {response.map((project, index) => (
                    <div key={index} className='col-lg-4 col-md-6 col-sm-10 col-xs-12 col-12 p-0 p-md-3 mt-3 mt-md-0 '>
                      <div className='box1 rounded-3 p-3 p-md-4 inset'>
                        <h5>{project.title}</h5>
                        <div className='d-flex mt-3'>
                          <h6>Deadline:</h6>
                          <p className='ms-2'>{dateFormat(project.deadline, "dS mmm , HH:MM tt")}</p>
                        </div>
                        <div className='d-flex'>
                          <h6>Marks:</h6>
                          <p className='ms-2'>{project.marks}</p>
                        </div>
                       {project.lock_status==="locked"?
                       <> </>:
                       <> <div onClick={() => toggleDescription(index)} className='d-flex justify-content-between'>
                       <h6>Description</h6>
                       {
                         showDescriptions[index] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />


                       }
                     </div></>}

                        {
                          <div className={`ass-description ${showDescriptions[index] ? 'show' : ''}`}>
                            <div className='border border-1 border-black p-2'>

                              <p>{project.description}
                              </p>
                            </div>
                          </div>
                        }
                        {project.status === "submitted" ?
                          <>
                            <button disabled className="btn btn-success mt-2 btn-sm">Submitted</button>



                          </> :
                          <>
           {project.lock_status==="locked"?
           <> 
           
           <button disabled={true} onClick={() => { InitiateSubmission({ id: project.assignment_id }) }} className="btn mt-2 btn-primary btn-sm"><Lock/> Locked</button>


            </>
          
          :
          <>
          
          <button onClick={() => { InitiateSubmission({ id: project.assignment_id }) }} className="btn mt-2 btn-primary btn-sm">Submit Project</button>


          </>}
                          </>}


                      </div>
                    </div>
                  ))}</> : null

                  }



                </div>
              </div>









            </div>
          </StyledAssignmentPage>



        </>}




    </div>
  )
}

export default MyAssignments

const StyledAssignmentPage = styled.div`
.box{
  background-color:#EBF5FF}

.box1{
  background-color:#D7E7FF}

.box1{
  border-top:3px solid blue}

.border-right{
  border-right:2px solid black}

.search {
  padding-left: 40px;}

.search-icon{
    font-size: 20px;
    position: absolute;
    top: 0%;
    height: 100%;
    display: flex;
    align-items: center;}

h5,p{
  margin:0px;
  padding:0px;}

.box2{
 background-color:#ffffff} 
 

 `