import React, { useEffect } from 'react'

import resetimage from '../../assets/reset-password.svg'
import { useRef } from 'react'
import { useState } from 'react'
import TextField from '@mui/material/TextField';
import ENDPOINT from '../../data/ENDPOINT'
import { Modal,Button, Toast, Form } from 'react-bootstrap'
import axios from 'axios';
import SecurityHeaders from '../../data/SecurityHeaders';
import { useContext } from 'react';
import { AuthContext } from '../../context/AuthContext';
import SetPage from '../../data/SetPage';
const PasswordChange = ({props}) => {

const {user} = useContext(AuthContext)

const [email,setemail] = useState(null)
const [password,setpassword] = useState(null)
const [confpassword,setconfpassword] = useState(null)
const [code,setcode] = useState(null)
const [toast,settoast] = useState(false);
const [input,setinput] =  useState(false)

const [toastTitle,settoastTite] = useState(null);
const [toastMessage,settoastMessage] = useState(null);

const closeToast = ()=>{
    settoast(false);
}

const  ResetPassword = async (e)=>{
e.preventDefault()

const data = new FormData()
data.append("session",user.SessionId)
const res = await axios.post(ENDPOINT+"user-password-reset-otp.php",data,SecurityHeaders)

if(res){
     //  //console.log("otp sending "+JSON.stringify(res))
    if(res.data[0].status==="success"){
setotpsent(true)
        setinput(true)
        setemail(res.data[0].email)
        settoastTite("Success !");
        settoastMessage("OTP sent");
        settoast(true);
    }else{
        settoastTite("Sending OTP failed !");
        settoastMessage("Please try again");
        settoast(true);
    }
}

  
  }    ;













  const  changePassword = async (e)=>{
e.preventDefault()
    fetch(`${ENDPOINT}change-pasword.php?email=${email}&password=${password}&code=${code}`)
     
    .then(r=>r.json())
    .then(result=>{
         
         if(result[0].status==="success"){
          settoastTite("Password Changed !");
          settoastMessage("Login into your account");
          settoast(true);
          window.location.replace("/dashboard");
         }  
   else if((result[0].status==="error")&&(result[0].message==="invalid otp")){
    settoastTite("Invalid OTP !");
    settoastMessage("Please enter correct OTP");
    settoast(true);
   }
   else if((result[0].status==="error")&&(result[0].message==="reset error")){
    settoastTite("Something went wrong !");
    settoastMessage("Please try again");
    settoast(true);
   }
   else{
    settoastTite("Something went wrong !");
    settoastMessage("Please try again");
    settoast(true);
   }
        
    })
    
    }    ;


useEffect(()=>{
SetPage("Change Password")
},[])

const [otpsent,setotpsent] = useState(false)

    return (
       <>
       
       <div className="w-100 min-vh-100">





       <div className="col-lg-4 col-md-5 col-sm-6 col-12 mt-5 sm-shadow-none mx-auto br-5 border rounded-2 p-3">

<div>
 <h5>Change Password</h5>

 </div>


{otpsent?
<>
<Form onSubmit={(e)=>changePassword(e)}>

<p>Please enter the otp been sent to {email} to change your password</p>

<Form.Group className="mb-3 mt-4" controlId="formBasicEmail">
<Form.Control type="number" value={code} onChange={(e)=>setcode(e.target.value)} placeholder="Enter 6 digit OTP" minLength={6} maxLength={6} required/>
</Form.Group>

<Button variant="primary" type="submit" className="w-100" >
Change Password
</Button>
</Form>
</>:
<>

<Form onSubmit={(e)=>{ResetPassword(e);}}>
<Form.Group className="mb-3" controlId="formBasicPassword">
<Form.Control type="password" value={password}  onChange={(e)=>setpassword(e.target.value)}  placeholder="New Password" minLength={6} maxLength={30} required />
</Form.Group>

<Form.Group className="mb-3" controlId="formBasicPassword">
<Form.Control type="password" value={confpassword}  onChange={(e)=>setconfpassword(e.target.value)}  placeholder="Confirm New Password" minLength={6} maxLength={30} required />
</Form.Group>

<Button variant="primary" className="w-100" type="submit" >
Change Password
</Button>
</Form>
</>}






</div>











         








    <div className="position-absolute fixed-bottom justify-center justify-content-center right-0 toast-container">

<div  className="mb-2 col-lg-3 col-md-3 col-sm-4 col-10">
         
         <Toast show={toast} onClose={closeToast} delay={5000} autohide className="space-between bg-warning">
           <Toast.Header>
             <img
               src="holder.js/20x20?text=%20"
               className="rounded me-2"
               alt=""
             />
             <strong className="me-auto">{toastTitle}</strong>
            
           </Toast.Header>
           <Toast.Body>{toastMessage}</Toast.Body>
         </Toast>
       </div>
       

</div>


               
               </div> 
       </>
    )
}

export default PasswordChange
