import React, { useEffect } from 'react'
import {useState} from 'react'
import axios from 'axios'
import ENDPOINT from '../../data/ENDPOINT'
import {useParams} from 'react-router-dom'
import Form from 'react-bootstrap/Form'
import {useRef} from 'react'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Toast from 'react-bootstrap/Toast'
import {Link} from 'react-router-dom'
import SetPage from '../../data/SetPage'
import CloseIcon from '@mui/icons-material/Close';
import ModalStyle from '../../data/ModalStyle'
const ConfirmRegistrationPage = () => {

     const params =useParams();

const first = useRef();
const second = useRef();
const third = useRef();
const fourth = useRef();
const fifth = useRef();
const sixth = useRef();

     const activation_code = params.code;

const [code,setcode] = useState(null)

const [input1,setinput1] = useState(null)
const [input2,setinput2] = useState(null)
const [input3,setinput3] = useState(null)
const [input4,setinput4] = useState(null)
const [input5,setinput5] = useState(null)
const [input6,setinput6] = useState(null)

const [modaltitle,setmodaltitle] = useState(null);
const [modalmessage,setmodalmessage] = useState(null)
const redirect = sessionStorage.getItem("next") || "/dashboard";
const msg = sessionStorage.getItem("msg") || null;

const [modal,setmodal] = useState(false)

const [isFetching,setisFetching] = useState(false);
//verify otp action

     const verifyOtp = async (e)=>{
          setisFetching(true)
          e.preventDefault();
          let otp = (first.current.value)+(second.current.value)+(third.current.value)+(fourth.current.value)+(fifth.current.value)+(sixth.current.value)
          
      
          const formData = new FormData();
          formData.append('code',otp);
          formData.append('key',activation_code); 
          const res = await axios.post(ENDPOINT+"verify-otp.php", formData,{
              headers: {
                  'content-type': 'multipart/form-data'
              }
          });
          if(res){
              setisFetching(false)
          //  //console.log("otp is "+otp+" "+JSON.stringify(res))
           if(res.data.status==="success"){
                if(res.data.account_status==="invalid_otp"){
                    setmodaltitle("Invalid OTP !")
                    setmodalmessage("Please enter 6 digit OTP sent to your email.")
                    setmodal(true)
                   setmodal(true)
                } 
      else if(res.data.account_status==="activated"){
           setmodaltitle("Verification Successful !")
           setmodalmessage("Redirecting...")
           setmodal(true)
        localStorage.setItem("user",JSON.stringify(res.data.session[0]));
        
        if((sessionStorage.getItem("next")==="null")||(sessionStorage.getItem("next")==="")||(sessionStorage.getItem("next")===null||sessionStorage.getItem("next")===undefined)){
          window.location.replace("/dashboard") 
          sessionStorage.setItem("next","");
        }else{
          window.location.replace(redirect)
          sessionStorage.setItem("next","");  
        }
          sessionStorage.setItem("next","");




      }
      else if(res.data.account_exists===false){
          window.location.replace("/vcp/"+res.data.verification_code)
      }
      else if(res.data.verification_code){
          window.location.replace("/vcp/"+res.data.verification_code)
      }
      
           }
           else{
          
           }
          }
          }
          const key1 = document.getElementById("first");
          const key2 = document.getElementById("second");
          const key3 = document.getElementById("third");
       const key4 = document.getElementById("fourth")
       const key5 = document.getElementById("fifth");
       const key6 = document.getElementById("sixth")
          const shift2 = ()=>{
               const key2 = document.getElementById("second");
            key2.focus()
            giveValue()
                      }
                      const shift3 = ()=>{
                         const key3 = document.getElementById("third");
                           key3.focus();
                           giveValue()
                      }
          const shift1 = ()=>{
               const key1 = document.getElementById("first");
key1.focus()
giveValue()
          }
          const shift4 = ()=>{
               const key4 = document.getElementById("fourth")
               key4.focus();
               giveValue()
          }
          const shift5 = ()=>{
               const key5 = document.getElementById("fifth");
            key5.focus()
            giveValue()
                      }
                      const shift6 = ()=>{
                         const key6 = document.getElementById("sixth")
       
                           key6.focus();
                           giveValue()
                      }
 
 

const giveValue = ()=>{
    
          const inputs = document.querySelectorAll('#otp > *[id]');
          for (let i = 0; i < inputs.length; i++) { inputs[i].addEventListener('keydown', function(event) { if (event.key==="Backspace" ) { inputs[i].value='' ; if (i !==0) inputs[i - 1].focus(); } else { if (i===inputs.length - 1 && inputs[i].value !=='' ) { return true; } else if (event.keyCode> 47 && event.keyCode < 58) { inputs[i].value=event.key; if (i !==inputs.length - 1) inputs[i + 1].focus(); event.preventDefault(); } else if (event.keyCode> 64 && event.keyCode < 91) { inputs[i].value=String.fromCharCode(event.keyCode); if (i !==inputs.length - 1) inputs[i + 1].focus(); event.preventDefault(); } } });
      } } 
   
      const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
     
        boxShadow: 24,
        pt: 2,
        px: 4,
        pb: 3,
      };





      const sendOTP = async ()=>{
          
    // //  //  //console.log("otp sent")
    setsendingotp(true)
          const formData = new FormData();
     
          formData.append('vcp',activation_code); 
          const res = await axios.post(ENDPOINT+"resend-otp.php", formData,{
              headers: {
                  'content-type': 'multipart/form-data'
              }
          });
          if(res){
             
              setsendingotp(false)
         
           if(res.data.status==="success"){
              
                    settoasttitle("OTP Sent")
                    settoastmessage("Please check your registered email.")
                    settoast(true)
                  
      
           
          }
          else{
               settoasttitle("Something went wrong")
               settoastmessage("Please try after sometime.")
               settoast(true)
             
          }
          }
     }

const [toast,settoast] = useState(false);

const [toasttitle,settoasttitle] = useState(null);
const [toastmessage,settoastmessage] = useState(null)



useEffect(()=>{
SetPage("VCP")
},[])

const [sendingotp,setsendingotp] = useState(false)
     return (
          <>




<Modal
  open={modal}
  onClose={()=>{setmodal(false)}}
 
>
  <Box sx={ModalStyle} className="br-5 p-3">

    <div className="modal-header">
    <h3 className="mb-0 pb-0">{modaltitle}</h3>
     <CloseIcon onClick={()=>setmodal(false)}/>
    </div>
    <hr/>
    <p >
    {modalmessage}
    </p>


    <div className="mt-4">
     <p>Have trouble verifying your account <Link to="/contact">Contact Us</Link></p>
    </div>
    <Modal />
  </Box>
</Modal>










<div className="toast-container">

<Toast className="bg-warning" onClose={() => settoast(false)} show={toast} delay={3000} autohide>
          <Toast.Header>
            <img
              src="holder.js/20x20?text=%20"
              className="rounded me-2"
              alt=""
            />
            <strong className="me-auto">{toasttitle}</strong>
         
          </Toast.Header>
          <Toast.Body>{toastmessage}</Toast.Body>
        </Toast>

</div>


               <div className="container vcf min-vh-90 justify-content-center align-items-center">

<div className="col-lg-4 mx-auto col-md-6 col-sm-8 col-12 shadow h-fit-content text-center p-3">
     <img src="https://skillsuprise.com/resources/images/verification-girl.svg" alt="verification_image" className="w-50 mb-3"/>
<h3>Verification Pending </h3>
<p>we have sent a 6 digit OTP to your registered email and phone number</p>
<Form onSubmit={verifyOtp}>
<div class="position-relative">
        <div class="  text-center">
            <div id="otp" class="inputs d-flex flex-row justify-content-center mt-2"> 
            <input class="m-2 text-center form-control rounded" type="text" ref={first} id="first" maxlength="1" onChange={shift2}  required  />
            <input class="m-2 text-center form-control rounded" type="text" ref={second} id="second" maxlength="1" onChange={shift3}   required  />
            <input class="m-2 text-center form-control rounded" type="text" ref={third}  id="third" maxlength="1"  onChange={shift4}   required />
            <input class="m-2 text-center form-control rounded" type="text" ref={fourth}  id="fourth" maxlength="1" onChange={shift5}   required  />
            <input class="m-2 text-center form-control rounded" type="text" ref={fifth}  id="fifth" maxlength="1"  onChange={shift6}   required /> 
            <input class="m-2 text-center form-control rounded" type="text" ref={sixth}  id="sixth" maxlength="1" required  />
            </div>
          
        </div>
        
    </div>





 
<button className="btn w-100 mt-3 btn-primary" type="submit" disabled={isFetching}>{isFetching?"Verifying...":"Verify OTP"}</button>

  
</Form>
<div className="text-center justify-center mt-4">
     <div className="flex sm-14 text-center justify-content-center">
     <p>Didn't receive the code ?</p><p type="button" className="text-primary ml-2" disabled={sendingotp} onClick={()=>sendOTP()}>{sendingotp?"Sending...":"Resend OTP"}</p>
     
     

     
     
     </div>


    
</div>

<hr/>
<div className="justify-center">
<div className="flex sm-14 mt-2 text-center justify-content-center">
     <p>Have trouble logging in ?</p><Link to="/contact" type="button" className="text-primary ml-2" onClick={()=>sendOTP()}>Contact Us</Link>
     
     

     
     
     </div>
</div>
</div>
               </div>
          </>
     )
}

export default ConfirmRegistrationPage
