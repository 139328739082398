import { Avatar } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import GetCourses from '../../actions/GetCourses'
import GetSacVideos from '../../actions/GetSacVideos'

const SacEvents = ({mode}) => {



  var scrollAmount ;

  
   const buttonclick = ()=> {
        var container = document.getElementById('container');
        sideScroll(container,'right',15,300,10);
    };
    
    
    const back = ()=> {
        var container = document.getElementById('container');
        sideScroll(container,'left',15,300,10);
    };
    
    function sideScroll(element,direction,speed,distance,step){
        scrollAmount = 0;
        var slideTimer = setInterval(function(){
            if(direction === 'left'){
                element.scrollLeft -= step;
            } else {
                element.scrollLeft += step;
            }
            scrollAmount += step;
            if(scrollAmount >= distance){
                window.clearInterval(slideTimer);
            }
        }, speed);
    }
    

const [loading,setloading] = useState(false)


useEffect (()=>{

NewVideos()
},[])
    const NewVideos = async()=>{
      setloading(true)
      
      
      
          const res = await GetSacVideos(
            query?
            {payload:{limit:limit,query:query}}
            :
            {payload:{limit:limit}}
          )
          
          if(res){
            setloading(false)
          }
          if(res.status==="success"){
           
            setvideos(res.videos)
            if(res.videos){
      
              if(res.videos.length===res.total_videos){
                setnomore(true)
              }
              settotalvideos(res.videos.length)
            }
          
           
            setsuggestions(res.suggestions)

      
      
      
           
          }



        }
        const [query,setquery] = useState(null)
const [limit,setlimit] = useState(12)
const [totalvideos,settotalvideos] = useState(null)
const [nomore,setnomore] = useState(false)
        const [videos,setvideos] = useState(null)
        const [suggestions,setsuggestions] = useState(null)
  return (
    <div className="container d-flex flex-wrap">

      <div className="d-flex w-100 justify-content-between">

<div>
<h3 className="text-light">Learn more than just a course</h3>
    
  
</div>

      <div class="slider-buttons">

<img id="slideBack"  onClick={back} alt="" type="button" src={"previewsvg"}/> 
    <img id="slide" onClick={buttonclick} type="button"  src={"nextsvg"} alt=""/>

</div>
      </div>
     
<div id="container" className="d-flex w-100 overflow-x sac-scroll pt-3 pb-3">

{videos?
<>
{videos.map((item)=>(
<>

<Link to={"/student-activity-club/"+item.video_id} className=" sac-item shadow">
<img className="w-100 h-100" src={item.cover_image}></img>
  <div className="event-content ">
    <h6 className="truncate  truncate2">{item.title}</h6>

  </div>

</Link>

</>

))}

</>:null}




</div>


    </div>
  )
}

export default SacEvents