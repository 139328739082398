import React from 'react'
import brillio from '../../assets/images/brillio-logo.png'
import wipro from '../../assets/images/wipro-logo.jpg'
import capgemini from '../../assets/images/capgemini-logo.png'
import nalcologo from '../../assets/images/nalco-logo.png'
import pepcoding from '../../assets/images/pep-coding.png'
import bosch from '../../assets/images/bosch-logo.avif'
import dassault from '../../assets/images/dassault-logo.png'
import byjus from '../../assets/images/byjus-logo.png'
import icici from '../../assets/images/icici-logo.png'
const PlacementCompanies = () => {
  return (
    <div className="w-100 wrapper">
      

  <div className="text-center">
  <h2>Our students work at leading companies</h2>
      <p className="d-none d-sm-block">Through our guaranteed internship & placement programs</p>

  </div>
      <div className="mt-3 marquee ">
      <img src="http://media.corporate-ir.net/media_files/IROL/17/176060/Oct18/Amazon%20logo.PNG" className="placement-company"/>

      <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/9/95/Infosys_logo.svg/1280px-Infosys_logo.svg.png" className="placement-company"/>

      <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/b1/Tata_Consultancy_Services_Logo.svg/2560px-Tata_Consultancy_Services_Logo.svg.png" className="placement-company"/>
      <img src={byjus} className="placement-company"/>
      <img src={icici} className="placement-company"/>
      <img src="https://1000logos.net/wp-content/uploads/2021/09/Cognizant-Logo.png" className="placement-company"/>
      <img src={wipro} className="placement-company"/>
      <img src={bosch} className="placement-company"/>

      <img src={brillio} className="placement-company"/>

      <img src={capgemini} className="placement-company"/>
      <img src={nalcologo} className="placement-company"/>

      <img src={pepcoding} className="placement-company"/>

      <img src={dassault} className="placement-company"/>

      <img src="http://media.corporate-ir.net/media_files/IROL/17/176060/Oct18/Amazon%20logo.PNG" className="placement-company"/>

<img src="https://upload.wikimedia.org/wikipedia/commons/thumb/9/95/Infosys_logo.svg/1280px-Infosys_logo.svg.png" className="placement-company"/>

<img src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/b1/Tata_Consultancy_Services_Logo.svg/2560px-Tata_Consultancy_Services_Logo.svg.png" className="placement-company"/>
<img src={byjus} className="placement-company"/>
<img src={icici} className="placement-company"/>
<img src="https://1000logos.net/wp-content/uploads/2021/09/Cognizant-Logo.png" className="placement-company"/>
<img src={wipro} className="placement-company"/>
<img src={bosch} className="placement-company"/>

<img src={brillio} className="placement-company"/>

<img src={capgemini} className="placement-company"/>
<img src={nalcologo} className="placement-company"/>

<img src={pepcoding} className="placement-company"/>

<img src={dassault} className="placement-company"/>

     
      </div>
    </div>
  )
}

export default PlacementCompanies
