import React, { useState } from 'react'
import TextareaAutosize from '@mui/material/TextareaAutosize';
import TextField from '@mui/material/TextField';
import axios from 'axios';
import ENDPOINT from '../../data/ENDPOINT';
import SecurityHeaders from '../../data/SecurityHeaders';
import {Toast } from 'react-bootstrap';

const CollegeContactForm = ({visibility,HandleClose,type}) => {

const [email,setEmail] = useState(null)
const [phone,setPhone] = useState(null)

const [students,setStudents] = useState(null)
const [message,setMessage] = useState(null)

const [institutionName,setInstitutionName] = useState(null)
const [institutionType,setInstitutionType] = useState(null)
const [department,setDepartment] = useState(null)
const [role,setRole] = useState(null)
const [firstName,setFirstName] = useState(null)
const [lastName,setLastName] = useState(null)


const [ToastTitle,setToastTitle] = useState(null);
const [ToastMessage,setToastMessage] = useState(null)
const [toast,settoast] = useState(false)
const NewContact = async ()=>{
     const data = new FormData();
     data.append('name',firstName+" "+lastName);
     data.append('message',message);
     data.append('phone',phone);
     data.append('email',email);
     data.append('institution_name',institutionName);
     data.append('institution_type',institutionType);
     data.append('role',role);
     data.append('department',department);
     data.append('students',students);

     const res = await axios.post(ENDPOINT+'submit-college-request.php',data,SecurityHeaders);

     if(res&&res.data.status==="success"){
      setToastTitle("Message sent successfully !")
      setToastMessage("Our support team will reach you within next 24 hours.")
   settoast(true)
          
     }
     else{
      setToastTitle("Something went wrong !")
      setToastMessage("Please try again after sometime")
      settoast(true)
     }
}
  return (
    <>
    
 <div className=" ">

 <div className="toast-container">
  
  <Toast show={toast} onClose={()=>settoast(false)} delay={4000} autohide className="space-between bg-warning">
        <Toast.Header>
          <img
            src="holder.js/20x20?text=%20"
            className="rounded me-2"
            alt=""
          />
          <strong className="me-auto">{ToastTitle}</strong>
         
        </Toast.Header>
        <Toast.Body>{ToastMessage}</Toast.Body>
      </Toast>


  </div>
 
<div className="d-flex">
    
    <div className="col-12">
 
    <form onSubmit={(e)=>{e.preventDefault();NewContact()}}>

<div className="">

  <div className="d-flex">
  <TextField
  id="outlined-name"
  label="First Name"
  className="w-100 me-2 mb-3"
  value={firstName}
  required
  type="text"
  minLength={3}
  maxLength={30}
  onChange={(e)=>setFirstName(e.target.value)}
/>


<TextField
  id="outlined-name"
  label="Last Name"
  className="w-100 ms-2 mb-3"
  value={lastName}
  required
  type="text"
  minLength={3}
  maxLength={30}
  onChange={(e)=>setLastName(e.target.value)}
/>

  </div>



 </div>

 <div className="mb-3 d-flex">

 
 <TextField
  id="outlined-name"
  label="Email"
  className="w-100 me-2 "
  value={email}
  required
  type="email"
  minLength={3}
  maxLength={30}
  onChange={(e)=>setEmail(e.target.value)}
/>

<TextField
  id="outlined-name"
  label="Phone"
  className="w-100 ms-2 "
  value={phone}
  required
  type="number"
  minLength={3}
  maxLength={30}
  onChange={(e)=>setPhone(e.target.value)}
/>

 </div>



<div className="d-flex mt-3">
<select required onChange={(e)=>setInstitutionType(e.target.value)} className='form-control me-2'>

  <option >Institution Type</option>
  <option value={"university/college"}>University/4 Year College</option>
  <option value={"degree college"}>Degree College</option>
  <option value={"other"}>Other</option>
</select>

<TextField
  id="outlined-name"
  label="Institution Name"
  className="w-100 ms-2 "
  value={institutionName}
  required
 
  minLength={3}
  maxLength={30}
  onChange={(e)=>setInstitutionName(e.target.value)}
/>

</div>



<div className="d-flex mt-3 ">
<select required onChange={(e)=>setRole(e.target.value)} className='form-control me-2'>

  <option > Role</option>
  <option value={"presedent"}>Presedent/Vice President</option>
  <option value={"chancellor"}>Chancellor/Vice Chancellor</option>

  <option value={"dean"}>Dean</option>
  <option value={"department head"}>Department Head</option>
  <option value={"director"}>Director</option>
  <option value={"professor"}>Professor</option>
  <option value={"student"}>Student</option>
</select>

<select onChange={(e)=>setDepartment(e.target.value)} className='form-control ms-2'>

  <option >Department</option>
  <option value={"computer science engineering"}>Computer Science Engineering</option>
  <option value={"information technology"}>Information Technology</option>
  <option value={"mechanical engineering"}>Mechanical Engineering</option>
  <option value={"other"}>Other</option>
</select>
</div>


<select onChange={(e)=>setStudents(e.target.value)} className='form-control mt-3'>

  <option >Expected Number of Students</option>
  <option value={"1-4"}>1 - 4</option>
  <option value={"5-10"}>5 - 10</option>
  <option value={"11-25"}>11 - 25</option>
  <option value={"25-50"}>25 - 50</option>
  <option value={"50-100"}>50 - 100</option>
  <option value={"100-250"}>100 - 250</option>
  <option value={"250-500"}>250 - 500</option>
  <option value={"500-1000"}>500 - 1000</option>
  <option value={"1000+"}>1000+</option>
</select>

 <TextareaAutosize
 className="w-100 p-2 my-3"
 value={message}
 type="text"
 onChange={(e)=>setMessage(e.target.value)}
  minRows={3}
  placeholder="Enter your message..."

/>

<button type="submit" className="btn btn-primary w-100">Submit</button>


</form>
    </div>
</div>



 
    
   </div>
 
    </>
  )
}

export default CollegeContactForm