import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import GetEnrolledCourses from '../../actions/GetEnrolledCourses'
import { AuthContext } from '../../context/AuthContext'
import Localbase from 'localbase'
import SetPage from '../../data/SetPage'
import { Skeleton } from '@mui/material'
import { Helmet } from 'react-helmet'
const MyCourses = () => {
  useEffect(() => {
    window.scrollTo(0, 0)

 
  }, [])
     const {user} = useContext(AuthContext)
const [mycourses,setmycourses]= useState(null)
let db = new Localbase("db")
const NewGetMyCourses = async()=>{

  const res = await GetEnrolledCourses({user,limit:20})
  if(res){
    setloading(false)
    if(res.status==="success"){
db.collection("paths").add({
  enrolled_courses:res.data
},"enrolled_courses")
      setmycourses(res.data)
    }
     //  //console.log("my courses "+JSON.stringify(res))
  }
}
useEffect(()=>{
  SetPage("My Courses")

 db.collection("paths").doc("enrolled_courses").get().then(data=>{
  setmycourses(data.enrolled_courses)
 }).catch(error=>{
  
 })
  NewGetMyCourses({user})
},[])

const [loading,setloading] = useState(true)
  return (
    <div className="min-vh-90 pb-5">
     <Helmet>‍
        <title>My Courses - Skills Uprise</title>‍
        <meta name="description" content="Skills Uprise" />       
        <meta name="twitter:card" content="" />   
        <meta name="twitter:site" content="@skillsuprise" />    
        <meta name="twitter:creator" content="@skillsuprise" />                
        <meta name="twitter:title" content="" />   
        <meta name="twitter:description" content="" />  
        <meta name="twitter:image" content=""/>     
        <meta property="og:title" content="" />  
        <meta property="og:description" content="" />        
        <meta property="og:image" content=""/>
        <meta property="og:url" content=""/>
        <meta property="og:site_name" content="Skills Uprise" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta property="fb:app_id" content="ID_APP_FACEBOOK" />
      </Helmet>
     <div className="container h-fit pt-3 pt-md-4 d-flex flex-wrap">

{mycourses?
<>
{mycourses.map((course)=>(

     <>
  <Link to={"/course-dashboard/"+course.course_url+"/"+course.batch_id} className="col-lg-4 h-100 sm-mb-20 sm-p-0 col-md-4 col-sm-6 col-12 p-0 p-md-3">
<div className="border rounded  h-100">
<img className="enrolled-course-cover rounded rounded-top rounded-bottom-0" src={course.course_cover}/>
  <div className="p-3">

  <h5 className="truncate2 truncate sm-truncate2">{course.course_title}</h5>

  <div className="d-flex sm-14 mt-2 justify-content-between">

  <p className="col"><b>Student ID :</b> {course.student_id}</p>
  <p className="col"><b>Batch ID:</b> {course.batch_id}</p>
  </div>



<div className="d-flex sm-14 justify-content-between">

<p className="col"><b>Team ID :</b> {course.team_id}</p>
  <p className="col"><b>Progress :</b> {course.progress}%</p>
</div>


{/* <div className="mt-3 d-flex justify-content-between">
  <Link to={"/course/"+course.course_url+"/"+course.mode} className="btn text-primary btn-sm">View Details</Link>
<vr/>
<Link to={"/course-dashboard/"+course.course_url+"/"+course.batch_id} className="btn text-primary btn-sm">Course Dashboard</Link>

</div> */}
  </div>
  <hr className='m-0'/>
  <Link className="btn-primary rounded-top-0 w-100 btn rounded-bottom" to={"/course-dashboard/"+course.course_url+"/"+course.batch_id} >Course Dashboard</Link>

</div>
          </Link>
     </>
))}
</>:


<>
{loading?
<>
<div className="grid lg-grid-3 md-grid-3 grid-1">

<Skeleton variant="rectangular"  height={200} />

<Skeleton variant="rectangular"  height={200} />

<Skeleton variant="rectangular"  height={200} />

<Skeleton variant="rectangular"  height={200} />

<Skeleton variant="rectangular"  height={200} />

<Skeleton variant="rectangular"  height={200} />

</div>

</>:
<>

<div className="col-lg-6 mx-auto text-center">
<img src="https://skillsuprise.com/resources/images/no-courses.svg" className="w-50 mx-auto"/>


<div className="text-center mt-3">
<h4>No Enrolled Courses</h4>
<p>Please register a course to start your training</p>
<Link to="/courses" className="btn btn-primary rounded-pill mt-4">Browse Courses</Link>
</div>

</div>


</>}

</>

}
        

       


     </div>
    </div>
  )
}

export default MyCourses