import React from 'react'
import Modal from '@mui/material/Modal';

import Close from '@mui/icons-material/Close'
import ModalStyle from '../../data/ModalStyle';
import Box from '@mui/material/Box';
import CustomVideoPlayer from '../elements/CustomVideoPlayer';
const SimpleNotificationModal = ({visibility,handleClose,data}) => {
  return (
<>

<Modal
  open={visibility}
  onClose={handleClose}

>
  <Box sx={ModalStyle}  className="col-lg-4 col-md-4 br-5 col-sm-11 col-11 p-2">
<div className="p-2">

 <div className="text-center">
<h4> {data.title}</h4>
<p>{data.description}</p>
 </div>

 <hr></hr>
 <div className="text-center w-100">
 <button className='btn btn-link ' onClick={handleClose}>{data.button_text}</button>
 </div>
</div>
  </Box>
</Modal>


</>
  )
}

export default SimpleNotificationModal