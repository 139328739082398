import { Avatar, Skeleton } from '@mui/material'
import React, { useState } from 'react'
import { useEffect } from 'react'
import { useContext } from 'react'
import { Link, useParams } from 'react-router-dom'
import { AuthContext } from '../../context/AuthContext'
import Localbase from 'localbase'
import axios from 'axios'
import ENDPOINT from '../../data/ENDPOINT'
import Modal from '@mui/material/Modal';
import ModalStyle from '../../data/ModalStyle'
import { Close } from '@mui/icons-material'
import Box from '@mui/material/Box';
import ConnectionItemSidebarSuggestion from '../connections/ConnectionItemSidebarSuggestion'
import AnimatePage from '../../actions/animations/AnimatePage'
const CommunitySuggestions = ({account}) => {

const params = useParams()
     const course_url = params.course_url

let db = new Localbase('db')
const {user} = useContext(AuthContext)
const [batch,setbatch] = useState(null)
const [query,setquery] = useState(null)
const  Connections = async ()=>{
     const data = new FormData()
     data.append('session',user.SessionId)
     if(query){
         data.append('query',query)
     }
     data.append('limit',"5")
           const res = await axios.post(ENDPOINT+"connections.php",data,{headers:{
               'content-type':'multipart/form-data'
           }})
   
   
           if(res){
               setloading(true)
                //  //console.log("friends "+JSON.stringify(res))
               if(res.data.status==="success"){
      setbatch(res.data.connections) 
      AnimatePage()
   }
   
           }
           
       }
   useEffect(()=>{
      
     
   Connections();
          
   }
   
   ,[])
   const [alert,setalert] = useState(false)



const Follow = ()=>{
  
}

const [loading,setloading] = useState(true)
  return (
    <div className="w-100 section sm-mt-50">
   




   <Modal
  open={alert}
  onClose={()=>setalert(false)}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
  <Box sx={ModalStyle} className="p-3 br-5 col-11 col-sm-8 col-md-4 col-lg-4">
   <div className="d-flex justify-content-between">
    <h4 className="text-danger">Access Denied !</h4>
    <Close  onClick={()=>setalert(false)}/>
   </div>
   <hr/>
   <div>
    <p>Register a course to connect with friends and learn together.</p>
   </div>
   <div className="d-flex justify-content-between mt-4">
    <button onClick={()=>setalert(false)} className="btn btn-outline-danger rounded-pill">Cancel</button>
    <Link to="/courses" className="btn btn-primary rounded-pill">Browse Courses</Link>
   </div>
  </Box>
</Modal>













    {batch?
    <>
      <div className="d-flex justify-content-between align-items-center">
          <h5>Recommendations</h5>
          <Link className="pb-0 mb-0" to="/recommendations">View All</Link>
     </div>
     <hr className="mt-1"/>

     <div className="d-flex">

     </div>
    {batch.map((user,index)=>(

     <>
 {account.purchases!==null&&account.purchases.length>0?
 <>
<ConnectionItemSidebarSuggestion className={"fade-in-bottom delay"+(index+1)+"s"} connection={user}/>
 
 </>:
 <>
 <div key={user.user_id}  onClick={()=>setalert(true)} className={"d-flex click w-100 justify-content-between align-items-center mt-3 mb-3 fade-in-bottom delay"+(index+1)+"s"}>
    <div  className="d-flex align-items-center text-dark col">
    <Avatar src={user.profile_pic} className="mr-5"/>
     <div>
          <h6 className="pb-0 truncate truncate1">{user.first_name+" "+user.last_name}</h6>
          <p className="truncate truncate1 text-grey extra-small">@{user.username}</p>
     </div>
    </div>
    
    <div>
    <button className="btn-sm btn btn-outline-primary rounded-pill">Follow</button>
    </div>
    
      </div>
 
 
 </>}
     
     </>
    ))}
    
    </>:
    
    
    <>


    <div className="w-100">
  

{loading?
<>
<div className="d-flex justify-content-between align-items-center">
          <h5>Recommentations</h5>
          <Link className="pb-0 mb-0" to="/recommendations">View All</Link>
     </div>


     <div className="d-flex mt-2 w-100 b-2">
     <Avatar/>
     <div className="ml-2 w-100">
<Skeleton height={20} className=" mb-1 w-100"/>

<Skeleton height={10} className=" mb-1 w-100"/>

     </div>
</div>



<div className="d-flex mt-2 w-100 b-2">
     <Avatar/>
     <div className="ml-2 w-100">
<Skeleton height={20} className=" mb-1 w-100"/>

<Skeleton height={10} className=" mb-1 w-100"/>

     </div>
</div>



<div className="d-flex mt-2 w-100 b-2">
     <Avatar/>
     <div className="ml-2 w-100">
<Skeleton height={20} className=" mb-1 w-100"/>

<Skeleton height={10} className=" mb-1 w-100"/>

     </div>
</div>



<div className="d-flex mt-2 w-100 b-2">
     <Avatar/>
     <div className="ml-2 w-100">
<Skeleton height={20} className=" mb-1 w-100"/>

<Skeleton height={10} className=" mb-1 w-100"/>

     </div>
</div>

<div className="d-flex mt-2 w-100 b-2">
     <Avatar/>
     <div className="ml-2 w-100">
<Skeleton height={20} className=" mb-1 w-100"/>

<Skeleton height={10} className=" mb-1 w-100"/>

     </div>
</div>
</>:
<>
<h4>No Students Found !</h4>
    <p>It seems like no student have joined the community yet. </p>
  
</>}
     
    </div>
    
    </>
    
    }


    
     

    </div>
  )
}

export default CommunitySuggestions