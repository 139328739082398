import React, { useEffect, useState } from 'react'
import GetInternships from '../../actions/GetInternships'
import InternshipItem from './InternshipItem'
import Localbase from 'localbase'

import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import DirectionsIcon from '@mui/icons-material/Directions';
import SetPage from '../../data/SetPage'
import Checkbox from '@mui/material/Checkbox';
import { Alert, Skeleton } from '@mui/material';
import { Link } from 'react-router-dom';
import CourseSuggestions from '../community/CourseSuggestions';
import WorkshopsSuggestions from '../dashboard/WorkshopsSuggestions';
import DetectDevice from '../../data/DetectDevice';
const JobsList = () => {
let db = new Localbase("db")
const [internships,setinternships] = useState(null)
const NewGetInternships = async()=>{
setloading(true)
  const res= await GetInternships()
  if(res.status==='success'){
    setloading(false)
setinternships(res.data)
db.collection("paths").add({
  internships:res.data
},"internships")

  }
}
useEffect(()=>{
  SetPage("Internships")
  db.collection("paths").doc("internships").get().then(data=>{
    setinternships(data.internships)

      }).catch(error=>{
    
      })
NewGetInternships()
},[])
const [loading,setloading] = useState(false)
const device = DetectDevice()
  return (
    <>
    <div className="  mb-5">
    


{internships?
<>
{internships.map((internship)=>(

  <>
<InternshipItem className="w-100" internship={internship}/>
  </>
))}
</>:<>


<Skeleton variant="rectangle" height={100} className="w-100 mt-2 mb-2"/>

<Skeleton variant="rectangle" height={100} className="w-100 mt-2 mb-2"/>

<Skeleton variant="rectangle" height={100} className="w-100 mt-2 mb-2"/>

<Skeleton variant="rectangle" height={100} className="w-100 mt-2 mb-2"/>

<Skeleton variant="rectangle" height={100} className="w-100 mt-2 mb-2"/>

<Skeleton variant="rectangle" height={100} className="w-100 mt-2 mb-2"/>

</>}



</div>













    </>
  )
}

export default JobsList