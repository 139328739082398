import React from 'react'
import divider from '../assets/images/divider.png'
const ThemeDivider = () => {
  return (
    <div className="text-center mt-2 mb-3">
<img src={divider}/>


    </div>
  )
}

export default ThemeDivider