import React from 'react'
import nodata from '../assets/images/no-data.svg'
const NoData = () => {
  return (
    <div className="w-100">
<img src={nodata} className="w-100"/>
    </div>
  )
}

export default NoData