import React from 'react'
import { useParams } from 'react-router'
import Examportal from './ExamPortal'

const ExamPage = () => {
     const params = useParams()
     const examcode = params.code


  return (
    <div className="w-100">
     
<Examportal/>
     
     
     </div>
  )
}

export default ExamPage