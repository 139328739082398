import React, { useContext, useEffect, useState } from 'react'
import GetEnrolledInternships from '../../actions/GetEnrolledInternships'
import { AuthContext } from '../../context/AuthContext'
import EnrolledInternshipItem from './EnrolledInternshipItem'
import Localbase from 'localbase'
import SetPage from '../../data/SetPage'
import NoData from '../../templates/NoData'
import { Link } from 'react-router-dom'
import ENDPOINT from '../../data/ENDPOINT'
import axios from 'axios'
import { Alert } from '@mui/material'
import dateFormat from 'dateformat'
import { Helmet } from 'react-helmet'
const MyInternships = () => {
  useEffect(() => {
    window.scrollTo(0, 0)

 
  }, [])
  let db = new Localbase("db")
const {user} = useContext(AuthContext)
const [internships,setinternships] = useState(null)
  const NewMyInternships = async()=>{
    const res = await GetEnrolledInternships({user,limit:20})
    if(res){
      if(res[0].status==="success"&&res[0].data!=="no internships"){
        setinternships(res[0].data)
        db.collection("paths").add({
          my_internships:res[0].data
     },"my_internships")
      }
     
    }
  }
  useEffect(()=>{
    SetPage("My Internships")
    db.collection("paths").doc("my_internships").get().then(data=>{
      setinternships(data.my_internships)
    }).catch(error=>{
      
    })
NewMyInternships()
getInterviews()
  },[])

  const getInterviews = async (status)=>{
        

    const formData = new FormData();
    
    
   
    formData.append('session',user.SessionId);
    formData.append('status','pending');
    formData.append('limit',4);
    


    const res = await axios.post(ENDPOINT+"my-interviews.php", formData,{
        headers: {
            'content-type': 'multipart/form-data'
        }
    });
    if(res){
   
     if(res.data.status==="success"){
      setinterviews(res.data.data)
     }
     else{
    
     }
    }
    }
    
    

  const [interviews,setinterviews] = useState(null)
  return (
    <div>
     <Helmet>‍
        <title>My Internships - Skills Uprise</title>‍
        <meta name="description" content="Skills Uprise" />       
        <meta name="twitter:card" content="" />   
        <meta name="twitter:site" content="@skillsuprise" />    
        <meta name="twitter:creator" content="@skillsuprise" />                
        <meta name="twitter:title" content="" />   
        <meta name="twitter:description" content="" />  
        <meta name="twitter:image" content=""/>     
        <meta property="og:title" content="" />  
        <meta property="og:description" content="" />        
        <meta property="og:image" content=""/>
        <meta property="og:url" content=""/>
        <meta property="og:site_name" content="Skills Uprise" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta property="fb:app_id" content="ID_APP_FACEBOOK" />
      </Helmet>
     <div className="container min-vh-90 w-100 pt-3 pb-5 d-flex flex-wrap justify-content-between">
<div className="col-lg-7  col-12 col-sm-12">

{internships?
<>
{internships.map((internship)=>(
  <>
<EnrolledInternshipItem internship={internship}/>
  </>
))}

</>:


<>

<div className="w-75 mx-auto">
<NoData/>
<div className="text-center mt-3">
  <h3>No Applications Found</h3>
  <p>Find internships and job opportunitites that fits your profile.</p>


  <Link to="/internships" className="btn btn-primary rounded-pill mt-3">Browse Internships</Link>
</div>
</div>


</>}



</div>


{/*interview schedules*/}
<div className="col-lg-4 d-none d-md-block h-fit br-4 bg-white p-3">
    {interviews?

<>
<h4 className="text-orangered sm-22">Upcoming Interview's</h4>
<p>Have any questions ? <a target="_blank" rel="noreferrer" href="mailto:hr@skillsuprise.com">Contact Recruitment Team</a></p>


{interviews?
<>
{interviews.map((interview)=>(


<>
<div className="border-1 p-3 br-4 mb-3">
<h5 className="sm-18">{interview.title}</h5>
<p>{interview.internship_title}</p>
<p><b>Schedule : </b>{dateFormat(interview.date, "dS mmm yy, hh:mm tt") }</p>
{interview.category==="telephonic"?
<>
</>:
<>

<a href={interview.link} target="_blank" rel="noreferrer" className="btn btn-primary w-100">Join Interview</a>

</>}

</div>

</>
))}

<div className="justify-center mt-3 text-center">
    
<Link to="/interviews" className="text-center sm-14  ">View All Interviews</Link>

</div>
</>
:
<>

</>}

</>:
<>
{/*no interviews*/}

<div>
<h4>Note !</h4>
<Alert severity="info">
Skills Uprise will never ask for any registration fee/security deposits to apply for internship & job opportunities from students/applicants.
 We also request our partnered companies & recruiters to follow the same. 
Please report to us at <a href="mailto:hr@skillsuprise.com"> report@skillsuprise.com</a> incase of any such incidents. 


</Alert>



</div>


</>}




</div>


    </div>


    </div>
  )
}

export default MyInternships