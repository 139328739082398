import axios from 'axios'
import ENDPOINT from '../data/ENDPOINT'
import SecurityHeaders from '../data/SecurityHeaders'

const SaveUserEvent = async ({payload})=>{

const data = new FormData()
if(payload.session!=null|payload.session!=undefined){
     data.append("session",payload.session)
}
if(payload.action){
     data.append("action",payload.action)
}
if(payload.page){
     data.append("page",payload.page)
}


const res = await 
axios.post(ENDPOINT+`save-user-event.php`,data,SecurityHeaders)
if(res){
    
     return res.data
}

}


export default SaveUserEvent