import React from 'react'

const PriceFormat = (value) => {

   return  (parseInt(value)).toLocaleString('en-US', {
    style: 'currency',currency: 'INR', minimumFractionDigits: 0,
    maximumFractionDigits: 0})

}

export default PriceFormat