import React from "react";
import { useState } from "react";
import axios from "axios";
import { useEffect } from "react";
import { useContext } from "react";
import ENDPOINT from "../../data/ENDPOINT";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import TextareaAutosize from "@mui/material/TextareaAutosize";

import { AuthContext } from "../../context/AuthContext";
import { CircularProgress, Snackbar } from "@material-ui/core";
import Cities from "../../context/Cities";
import SetPage from "../../data/SetPage";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import NotificationSettings from "../settings/NotificationSettings";

import preferences from '../../assets/preferences.svg'
import { Link } from "react-router-dom/cjs/react-router-dom.min";
const EditUserInfo = ({ stand }) => {
  const { user } = useContext(AuthContext);

  const [account, setaccount] = useState(null);
  useEffect(() => {
    const getaccount = async () => {
      const data = new FormData();
      data.append("session", user.SessionId);

      const res = await axios.post(ENDPOINT + "get-user-info.php", data, {
        headers: {
          "content-type": "multipart/form-data",
        },
      });

      if (res) {
        if (res.data[0].status === "success") {
          setaccount(res.data[0].data);

          const result = res.data[0].data;
          setfname(result.first_name);
          setlname(result.last_name);
          setcity(result.city);
          setaddress(result.address);
          setemail(result.email);
          setphone(result.phone_number);
          setwhatsapp(result.whatsapp_number);
        } else {
        }
      }
    };

    if (user !== null || user !== "null") {
      getaccount();
    }
  }, [user]);

  const [fname, setfname] = useState(null);
  const [lname, setlname] = useState(null);
  const [city, setcity] = useState(null);
  const [address, setaddress] = useState(null);

  const [status, setstatus] = useState(false);

  const [snackbar, setsnackbar] = useState(false);
  const [snackbarMessage, setsnackbarMessage] = useState(null);

  const [email, setemail] = useState(null);
  const [phone, setphone] = useState(null);
  const [gender, setgender] = useState(null);
  const [whatsapp, setwhatsapp] = useState(null);

  const saveProfile = async (e) => {
    e.preventDefault();

    const formData = new FormData();

    //formData.append('whyus',document.getElementById('whyus').value)

    formData.append("session", user.SessionId);

    formData.append("first_name", fname);
    formData.append("last_name", lname);
    formData.append("city", city);
    formData.append("address", address);
    formData.append("whatsapp", whatsapp);
    formData.append("gender", gender);

    const res = await axios.post(ENDPOINT + "edit-profile.php", formData, {
      headers: {
        "content-type": "multipart/form-data",
      },
    });
    if (res) {
      if (res.data[0].status === "success") {
        setsnackbarMessage("Profile updated successfully");
        setsnackbar(true);
      } else {
      }
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    SetPage("Edit Profile");
  }, []);

  return (
    <div className="container mt-5 pb-5 mb-5 d-flex flex-wrap justify-content-center">
      <div className="col-lg-5 col-md-5 col-sm-12 col-12 p-3 sm-p-0 border border-md-1 mx-auto  br-5">
        {/*account form*/}
        {account ? (
          <>
            <div className="w-100 ">
              <div className="toast-container">
                <Snackbar
                  open={snackbar}
                  autoHideDuration={6000}
                  onClose={() => setsnackbar(false)}
                  message={snackbarMessage}
                />
              </div>


              <div className="w-100">
                <form onSubmit={saveProfile}>
                  <div class="col-12 d-flex flex-wrap w-100 justify-content-evenly">
                    <div class="form-group col-md-6 pe-2  col-12  col-sm-12 mt-4">
                      <TextField
                        value={fname}
                        onChange={(e) => setfname(e.target.value)}
                        fullWidth
                        label="First Name"
                        required
                      />
                    </div>

                    <div class="form-group col-md-6 ps-2  col-12  mt-4">
                      <TextField
                        value={lname}
                        onChange={(e) => setlname(e.target.value)}
                        fullWidth
                        label="Last Name"
                        required
                      />
                    </div>
                  </div>


                  <div class=" col-md-12  col-12 mt-4">
                    <TextField
                      value={email}
                      fullWidth
                      disabled
                      variant="filled"
                      label="Primary Email"
                    />
                  </div>
                  <div class=" col-md-12  col-12 mt-4">
                    <TextField
                      value={phone}
                      fullWidth
                      variant="filled"
                      label="Primary Phone"
                      disabled
                    />
                  </div>




                  <div className="d-flex">
                    <div className="form-group mt-4 pe-2 col-lg-6">
                      <Autocomplete
                        disablePortal
                        className="w-100"
                        sx={{ width: 300 }}
                        value={gender}
                        options={["Male", "Female", "Others"]}
                        onChange={(event, newValue) => {
                          setgender(newValue);
                        }}
                        inputValue={gender}
                        onInputChange={(event, newInputValue) => {
                          setgender(newInputValue);
                        }}
                        renderInput={(params) => (
                          <TextField {...params} label="Gender" />
                        )}
                      />
                    </div>


                    <div class="form-row col-12 ps-2 mt-4 col-lg-6">
                      <div class="form-group col-12">
                        <Autocomplete
                          disablePortal
                          id="combo-box-demo"
                          className="w-100"
                          sx={{ width: 300 }}
                          value={city}
                          options={Cities}
                          onChange={(event, newValue) => {
                            setcity(newValue);
                          }}
                          inputValue={city}
                          onInputChange={(event, newInputValue) => {
                            setcity(newInputValue);
                          }}
                          renderInput={(params) => (
                            <TextField {...params} label="City" />
                          )}
                        />
                      </div>
                    </div>

                  </div>

                  <div class="form-group  mt-3">
                    <TextareaAutosize
                      aria-label="Address"
                      minRows={3}
                      value={address}
                      className="w-100"
                      fullWidth
                      placeholder="Address"
                      onChange={(e) => setaddress(e.target.value)}
                    />
                  </div>

                  <div class="form-group"></div>
                  <button type="submit" class="btn  mt-3 w-100 btn-primary">
                    {status ? (
                      <CircularProgress className="text-white" size={20} />
                    ) : (
                      ""
                    )}
                    Save Changes
                  </button>
                </form>
              </div>
            </div>
          </>
        ) : (
          ""
        )}
        {/*account form*/}
      </div>
      <div className="col-lg-5 pt-5 col-12 justify-content-center">
    <div className="col-lg-8 mx-auto text-center">
    <img src={preferences} className="w-100" alt=""></img>
    <p>If you'd like to learn more about privacy , you can read about it in the <Link to="/privacy-policy">Privacy Policy</Link></p>
    </div>
</div>
      {/* <div className="col-lg-6 col-md-6 col-sm-12 col-12 my-auto  ">

        <div className="shadow bg-white rounded col-lg-7 mx-auto p-3 ">
          <div class="w-100  mt-4">
            <div className="d-flex">
              <TextField
                value={whatsapp}
                onChange={(e) => setwhatsapp(e.target.value)}
                fullWidth
                label="WhatsApp Number"
              />

            </div>
            <div className="d-flex justify-content-center">
              <button className="btn btn-success mt-3 mx-auto">Verify</button>

            </div>
          </div>
<NotificationSettings/>
        </div>

      </div> */}
    </div>

  );
};

export default EditUserInfo;
