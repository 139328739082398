import { Avatar } from '@mui/material';
import React, { useContext, useState } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import dateFormat from 'dateformat';
import JoinClass from '../../../actions/course/JoinClass';
import { AuthContext } from '../../../context/AuthContext';
const ClassesCalendar = ({ classSchedules }) => {
  const [hoveredDate, setHoveredDate] = useState(null);
const {user} = useContext(AuthContext)
  const tileContent = ({ date, view }) => {
    // Check if the date has a class schedule
    const hasClassSchedule = classSchedules.some(schedule => {
      const scheduleDate = new Date(schedule.class_starts);
      return date.getDate() === scheduleDate.getDate() &&
             date.getMonth() === scheduleDate.getMonth() &&
             date.getFullYear() === scheduleDate.getFullYear();
    });

    // If the date has a class schedule, return the circular element
    return hasClassSchedule && view === 'month' && <div className="circle" />;
  };

  const tileClassName = ({ date }) => {
    // Check if the date has a class schedule
    const hasClassSchedule = classSchedules.some(schedule => {
      const scheduleDate = new Date(schedule.class_starts);
      return date.getDate() === scheduleDate.getDate() &&
             date.getMonth() === scheduleDate.getMonth() &&
             date.getFullYear() === scheduleDate.getFullYear();
    });

    // If the date has a class schedule, add the 'green' class
    return hasClassSchedule ? 'class-green' : '';
  };

  const handleMouseOver = ({ date }) => {
    // Find the class schedule for the hovered date
    const schedule = classSchedules.find(schedule => {
      const scheduleDate = new Date(schedule.class_starts);
      return date.getDate() === scheduleDate.getDate() &&
             date.getMonth() === scheduleDate.getMonth() &&
             date.getFullYear() === scheduleDate.getFullYear();
    });

    // Set the hovered date and schedule
    setHoveredDate(schedule);
  };

  const handleMouseLeave = () => {
    // Clear the hovered date and schedule when mouse leaves
    setHoveredDate(null);
  };

  return (
    <div>
  
{classSchedules?
<>

<div className="border p-3 rounded">
  
<h4 className='mb-3'>Upcoming Classes</h4>

{classSchedules.map((item)=>(
  <>
  <div className="d-flex w-100 dashboard-class-item align-items-center">

  <div className="d-flex w-100">
<div className="class-date-box d-flex align-items-center justify-content-center flex-column text-light text-center">
  <h6 className='m-0 p-0'>{dateFormat(item.class_starts,"dS")}</h6>
  <hr className='m-0 p-0 text-secondary'></hr>
  <p className='m-0 p-0 text-secondary time'>{dateFormat(item.class_starts,"hh:MM tt")}</p>
</div>
<div>
  <h6 className='mb-0'>{item.class_title}</h6>
  <p className='truncate truncate-1'>{item.class_description&&(item.class_description!==null&&item.description!=="null")?item.class_description:"NA"}</p>
</div>
</div>
<a href={item.meeting_link} target='_blank' onClick={()=>JoinClass({payload:{
  course_url:item.course_url,
  batch_id:item.batch_id,
  user:user.SessionId
  ,class_id:item.id

}})} className='btn btn-sm w-fit h-fit  btn-outline-primary rounded-pill'>Join Class</a>

</div>
  </>
))}




</div>

</>:
<>



</>}


 
  <div className="d-none">
  <Calendar
        tileContent={tileContent}
        tileClassName={tileClassName}
        onMouseOver={handleMouseOver}
        onMouseLeave={handleMouseLeave}
      />
      {hoveredDate && (
        <div className="popover">
          <p>Class Time: {hoveredDate.class_starts}</p>
        </div>
      )}
  </div>
    </div>
  );
};

export default ClassesCalendar;
