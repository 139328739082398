
import React, { useState } from 'react'
import axios from 'axios'
import ENDPOINT from '../../data/ENDPOINT'
import { useContext } from 'react';
import { AuthContext } from '../../context/AuthContext';
import { Link, useParams } from 'react-router-dom';
import {useEffect} from 'react'
import ConnectionsList from './ConnectionsList';
import Localbase from 'localbase';

import ConnectionsTemplate from './ConnectionsTemplate';
import SetPage from '../../data/SetPage';
import { CircularProgress } from '@mui/material';
import { Helmet } from 'react-helmet';
const FollowingsPage = ({post,account,access}) => {
const params = useParams()
const type = params.type;
const {user}= useContext(AuthContext)
useEffect(()=>{
SetPage("Following")
},[])
const db = new Localbase("db")
const [connections,setconnections] = useState(null)
const [noconnections,setnoconnections] = useState(true)

    const getConnections = async (account)=>{
       setloading(true)
        const formData = new FormData();
        formData.append('session',user.SessionId);
      
        
        const res = await axios.post(ENDPOINT+"following.php", formData,{
            headers: {
                'content-type': 'multipart/form-data'
            }
        });
        if(res){
            setloading(false)
          //  //console.log("conn "+JSON.stringify(res))
         if(res.data.status==="success" && res.data.following!=null){
             setnoconnections(false)
setconnections(res.data.following)
db.collection("paths").add({
    following:res.data.following
},"following")
         }
         else if(res.data.status==="success" && res.data.following===null){
      setnoconnections(true)
         }
        }
        }




useEffect(()=>{
    db.collection("paths").doc("following").get().then(data=>{
        setconnections(data.following)
    }).catch(error=>{
        
    })
    
  
getConnections()

},[type])











const [loading,setloading] = useState(false)









                const updateConnection = async (status,connection)=>{
             
     
                    const formData = new FormData();
                    
                    
                    //formData.append('whyus',document.getElementById('whyus').value)
                    
                    formData.append('session',user[0].SessionId);
                    
                      formData.append('id',connection.id);
                      formData.append('status',"accepted");
                    
                    const res = await axios.post(ENDPOINT+"update-connection-request.php", formData,{
                        headers: {
                            'content-type': 'multipart/form-data'
                        }
                    });
                    if(res){
                      if(res.data.status==="success"){
                
                     }
                     else if(res.data.status==="error"){
                     
                     }
                    }
                    }
               

    return (
        <><Helmet>‍
        <title>Following - Skills Uprise</title>‍
        <meta name="description" content="Skills Uprise" />       
        <meta name="twitter:card" content="" />   
        <meta name="twitter:site" content="@skillsuprise" />    
        <meta name="twitter:creator" content="@skillsuprise" />                
        <meta name="twitter:title" content="" />   
        <meta name="twitter:description" content="" />  
        <meta name="twitter:image" content=""/>     
        <meta property="og:title" content="" />  
        <meta property="og:description" content="" />        
        <meta property="og:image" content=""/>
        <meta property="og:url" content=""/>
        <meta property="og:site_name" content="Skills Uprise" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta property="fb:app_id" content="ID_APP_FACEBOOK" />
      </Helmet>
      
       <ConnectionsTemplate>
       <div className="col-lg-8 col-md-8 col-sm-12 col-12">


{connections?
<>
<div className="grid-2 sm-grid-4 grid lg-grid-5">
<ConnectionsList  connections={connections} access={access}/>
</div>

</>:
<>
{loading?
<>
<div className="justify-content-center pt-5 pb-5">
    <CircularProgress/>
</div>
</>:
<>
<div className="w-100 justify-content-center d-flex pt-5">


<div className="col-lg-7 text-center">
<h4>No Followings</h4>
<p>Register a course to connect with students from various colleges across the globe.</p>


</div>

</div>
</>}




</>}

</div>
       </ConnectionsTemplate>
        </>
    )
}

export default FollowingsPage
