import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import axios from 'axios';
import ENDPOINT from '../../data/ENDPOINT';
import SecurityHeaders from '../../data/SecurityHeaders';
import { AuthContext } from '../../context/AuthContext';
import styled from 'styled-components';
 
const UnlockFreeCourse = () => {

    const { user } = useContext(AuthContext)

    const [submissions, setSubmissions] = useState('')
    const CheckSubmit = async () => {
        try {
            const data = new FormData();
            data.append('session', user.SessionId);

            const res = await axios.post(ENDPOINT + "check-free-course-access-submission.php", data, SecurityHeaders);

            if (res) {
                if (res.data.status === "success") {

                    setSubmissions(res.data.data);
                }
            }
        }
        catch (error) {
            console.log("Check Submissions Error", error);
        }
    }

    useEffect(() => {

        CheckSubmit()
    }, []);

    // console.log(submissions);

    return (
        <>




          <FreeStyled>
          <div className='bg-free-course vh-100'>
                <div className='text-white text-center fs p-5 font-size'>
                    <h1>Unlock Course</h1>
                    <p className='fs-3 text-light'>Follow these 4 steps to unlock your course for free</p>
                </div>
                <div className='container d-flex flex-wrap size pb-5'>
                    <div className="col-lg-3 col-md-6 col-sm-6 col-12 p-3">
                        <div className='bg-white shadow rounded'>
                            <h3 className='text-white text-center bg-success rounded-top p-3'>Step 1</h3>
                            <div className='text-center p-4'>
                                <p>Follow our<br /> Facebook pages<br />

                                    @manojkoravangi<br />

                                    @skillsuprise</p>
                            </div>
                            <div className='p-3'>
                                {
                                  submissions&& submissions.facebook ?
                                        <button disabled className='btn btn-primary rounded w-100'>Submitted</button>
                                        : <Link to={"/upload-file/" + "facebook"} className='btn btn-primary rounded w-100'>Submit</Link>

                                }

                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6 col-12 p-3">
                        <div className='bg-white shadow rounded'>
                            <h3 className='text-white text-center rounded-top bg-success p-3'>Step 2</h3>
                            <div className='text-center p-4'>
                                <p>Follow our<br /> Instagram pages<br />

                                    @manojkoravangi<br />

                                    @skillsuprise</p>
                            </div>
                            <div className='p-3'>

                                {
                                   submissions&& submissions.instagram ?
                                        <button disabled className='btn btn-primary rounded w-100'>Submitted</button>
                                        :
                                        <Link disabled to={"/upload-file/" + "instagram"} className='btn btn-primary rounded w-100'>Submit</Link>

                                }
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6 col-12 p-3">
                        <div className='bg-white shadow rounded'>
                            <h3 className='text-white text-center rounded-top bg-success p-3'>Step 3</h3>
                            <div className='text-center p-4'>
                                <p>Follow our<br /> Linkedin pages<br />

                                    @manojkoravangi<br />

                                    @skillsuprise</p>
                            </div>
                            <div className='p-3'>

                                {
                                  submissions&&  submissions.linkedin ?
                                        <button disabled className='btn btn-primary rounded w-100'>Submitted</button>
                                        :
                                        <Link to={"/upload-file/" + "linkedin"} className='btn btn-primary rounded w-100'>Submit</Link>


                                }

                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6 col-12 p-3">
                        <div className='bg-white shadow rounded'>
                            <h3 className='text-white text-center rounded-top bg-success p-3'>Step 4</h3>
                            <div className='text-center p-4 size'>
                                <p>Follow our<br /> Twitter pages<br />

                                    @manojkoravangi<br />

                                    @skillsuprise</p>
                            </div>
                            <div className='p-3'>

                                {
                                  submissions&&  submissions.twitter ?
                                        <button disabled className='btn btn-primary rounded w-100'>Submitted</button>
                                        :
                                        <Link to={"/upload-file/" + "twitter"} className='btn btn-primary rounded w-100'>Submit</Link>



                                }

                            </div>
                        </div>
                    </div>
                </div>
            </div>
          </FreeStyled>
        </>
    )
}

export default UnlockFreeCourse

export const FreeStyled = styled.div`
.bg-free-course{
    background-color: #0a1a44;
   
}
.fs h1{
    font-size: 70px;
    font-weight: 700;
    font-family:inherit;
    color: #d8e6ed;
}
@media only screen and (min-width:100px) and (max-width:992px){
    .size p{
        font-size: 15px!important;
        text-align: center;
    } 
    .bg{
        height: 100%;
    }
    .font-size h1,p{
        font-size:30px;
    }
}
`