import React from 'react'
import Skeleton from '@mui/material/Skeleton';
const LoadingTemplate = () => {
  return (
    <div className="container pt-5 pb-5">
<Skeleton className="mb-2 mt-2" variant="text"  height={50} />

<Skeleton className="mb-2 mt-2" variant="text"  height={50} />

<Skeleton className="mb-2 mt-2" variant="text"  height={50} />

<Skeleton className="mb-2 mt-2" variant="text"  height={50} />

<Skeleton className="mb-2 mt-2" variant="text"  height={50} />

<Skeleton className="mb-2 mt-2" variant="text"  height={50} />

<Skeleton className="mb-2 mt-2" variant="text"  height={50} />

<Skeleton className="mb-2 mt-2" variant="text"  height={50} />

  

<Skeleton className="mb-2 mt-2" variant="text"  height={50} />

<Skeleton className="mb-2 mt-2" variant="text"  height={50} />

<Skeleton className="mb-2 mt-2" variant="text"  height={50} />

<Skeleton className="mb-2 mt-2" variant="text"  height={50} />


  
    </div>
  )
}

export default LoadingTemplate