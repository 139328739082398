import React, { useEffect, useState } from 'react'
import ENDPOINT from '../../data/ENDPOINT'
import SecurityHeaders from '../../data/SecurityHeaders'
import axios from 'axios'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'

const DashboardPopularCourses = ({limit}) => {

  
const [courses,setcourses] = useState(null)
const GetCourse = async()=>{
const data = new FormData()
data.append("limit",limit?limit:4)
data.append("category","dashboard")
data.append("mode","live")
const res = await axios.post(ENDPOINT+"course-suggestions.php",data,SecurityHeaders)

if(res){
     setloading(false)
     setcourses(res.data)
      //  //console.log("courses are "+JSON.stringify(res))
}



}

useEffect(()=>{
GetCourse()
},[])
const [loading,setloading] = useState(true)
  return (
    <div  className='p-3 mt-3 border rounded'>
   <h4>Popular Courses</h4>
        {/* https://skillsuprise.com/data/images/popular-python-online.png */}
   {/* https://skillsuprise.com/data/images/popular-fullstack-specialization.png */}
   {/* https://skillsuprise.com/data/images/popular-ceh-specialization.png */}
   {/* https://skillsuprise.com/data/images/popular-ceh-online.png */}
        
        <div className="d-flex mt-2 flex-wrap">

{courses?<>

{courses.map((course)=>(
  <>

<div className="col-6 pe-1">
  <Link to={"course/"+course.course_url+"/"+course.mode}>
  
  <div className="popular-item">
<img src={course.dashboard_image} className='w-100 h-100 rounded'></img>
</div>
  
  </Link>

</div>
  </>
))}
</>:
<>


</>}





        </div>
        
        
        
        
        
        </div>
  )
}

export default DashboardPopularCourses