import axios from 'axios'
import { useContext } from 'react'
import React from 'react'
import ENDPOINT from '../data/ENDPOINT'
import SecurityHeaders from '../data/SecurityHeaders'

const GetAccount = async ({user})=>{

const data = new FormData()

data.append("session",user.SessionId)
const res = await 
axios.post(ENDPOINT+"get-account.php",data,SecurityHeaders)
if(res){
   
     return res.data
}

}
export default GetAccount