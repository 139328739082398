import React from 'react'

const GetTiming = () => {

    const date = new Date()
    const hour = date.getHours()
    const minutes = date.getMinutes()
    let time = "morning";
    if(hour===0||hour<12){
time = "morning";
    }
    else if (hour>=12 && hour<16){
        time = "afternoon"
    }
    else if (hour>=16 && hour<21){
time = "evening"
    }
    else if (hour>=21&&hour<=23){
        time ="night"
    }
  return (
  time
  )
}

export default GetTiming