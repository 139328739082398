import axios from 'axios'
import ENDPOINT from '../data/ENDPOINT'
import SecurityHeaders from '../data/SecurityHeaders'

const GetWorkshops = async ({limit})=>{

const data = new FormData()
if(limit){
     data.append("limit",limit)
}

const res = await 
axios.post(ENDPOINT+`get-workshops.php`,data,SecurityHeaders)
if(res){
    
     return res.data
}

}
export default GetWorkshops