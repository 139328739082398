import React, { useEffect } from 'react'
import SetPage from '../../../data/SetPage'

const MyInterviews = () => {
  useEffect(() => {
    window.scrollTo(0, 0)

 
  }, [])
  useEffect(()=>{
SetPage("Interviews")
  },[])
  return (
    <div>MyInterviews</div>
  )
}

export default MyInterviews