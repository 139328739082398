import React, { useContext, useState } from 'react'

import { FormControlLabel, Switch } from '@material-ui/core'

import preferences from '../../assets/preferences.svg'
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';
import axios from 'axios';
import ENDPOINT from '../../data/ENDPOINT';
import { Toast } from 'react-bootstrap';
const AccountSecurity = () => {


const {user} = useContext(AuthContext)

const [key,setkey] = useState(null);
const [value,setvalue] = useState(false)

const [status,setstatus]= useState(false);
const [toast,settoast] = useState(false)

const [loginnotification,setloginnotification] = useState(true);
const [classes,setclasses] = useState(true);
const [rewards,setrewards] = useState(true)
const [assignments,setassignments] = useState(true)

const updatePreferences = async (status)=>{
        

  const formData = new FormData();
  
  
  //formData.append('whyus',document.getElementById('whyus').value)
  
  formData.append('session',user.SessionId);
  
    formData.append('key',key);
    formData.append('value',value);
  

  const res = await axios.post(ENDPOINT+"update-preferences.php", formData,{
      headers: {
          'content-type': 'multipart/form-data'
      }
  });
  if(res){

   if(res.data.status==="success"){
     setstatus(true)
    settoast(true)
   }
   else{
     settoast(false)
  settoast(true)
   }
  }
  }
  



    const IOSSwitch = styled((props) => (
        <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
      ))(({ theme }) => ({
        width: 42,
        height: 26,
        padding: 0,
        '& .MuiSwitch-switchBase': {
          padding: 0,
          margin: 2,
          transitionDuration: '300ms',
          '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
              backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
              opacity: 1,
              border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
              opacity: 0.5,
            },
          },
          '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
          },
          '&.Mui-disabled .MuiSwitch-thumb': {
            color:
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[600],
          },
          '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
          },
        },
        '& .MuiSwitch-thumb': {
          boxSizing: 'border-box',
          width: 22,
          height: 22,
          marginleft:10,
          margintop:5
        },
        '& .MuiSwitch-track': {
          borderRadius: 26 / 2,
          backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
          opacity: 1,
          transition: theme.transitions.create(['background-color'], {
            duration: 500,
          }),
        },
      }));
      








    return (
     

<div className="container mx-auto p-2">


<div className="position-fixed bottom-60 left-0 container-fluid justify-center">


<Toast className={status?"bg-success":"bg-warning"} onClose={() => settoast(false)} show={toast} delay={3000} autohide>
          <Toast.Header >
          
          </Toast.Header>

          <Toast.Body>{status?"Changes Saved Successfully !":"Something went wrong !"}</Toast.Body>
          </Toast>
</div>


<div className="flex-wrap mb-5 p-3 ">



<div className="col-lg-6 mt-0">

<h4>Choose when and how to be notified</h4>

<div className="flex space-between align-items-center mt-4">
    <div>
<h6>Login Notifications</h6>
<p>Send an email notification whenever my account is logged in into a new device.</p>
        </div>
        <div>
        
        <FormControlLabel
        control={<IOSSwitch sx={{ m: 1 }} color="primary" defaultChecked  checked={loginnotification} onChange={()=>{setloginnotification(!loginnotification);setkey("login_notification");setvalue(loginnotification);updatePreferences(); }}  />}
             />
             </div>
</div>

<div className="flex space-between align-items-center mt-2">
    <div>
<h6 className="mb-0">Upcoming Classes</h6>
<p className="mb-0">Send an email notification whenever a new class is schedules to you.</p>
        </div>
        <div>
        <FormControlLabel
        control={<IOSSwitch sx={{ m: 1 }} color="primary" defaultChecked  checked={classes} onChange={()=>{setclasses(!classes);setkey("upcoming_classes");setvalue(classes);updatePreferences(); }} />}
             />
            </div>
</div>

<div className="flex space-between align-items-center mt-2">
    <div>
<h6 className="mb-0">Recordings</h6>
<p className="mb-0">Send an email notification whenever class recordings are uploaded.</p>
        </div>
        <div>
        <FormControlLabel
        control={<IOSSwitch sx={{ m: 1 }} color="primary" defaultChecked />}
             />
            </div>
</div>





<div className="flex space-between align-items-center">
    <div>
<h6>Rewards & Achievements</h6>
<p>Send an email notification whenever a reward is gifted or when milestones are accomplished.</p>
        </div>
        <div>
        <FormControlLabel
        control={<IOSSwitch sx={{ m: 1 }} color="primary" defaultChecked  checked={rewards} onChange={()=>{setrewards(!rewards);setkey("reward_earned");setvalue(rewards);updatePreferences(); }}  />}
             />
            </div>
</div>





<div className="flex space-between align-items-center mt-2">
    <div>
<h6 className="mb-0">Assignments</h6>
<p className="mb-0">Send an email notification whenever assignments are given to you/your batch.</p>
        </div>
        <div>
        <FormControlLabel
        control={<IOSSwitch sx={{ m: 1 }} color="primary" defaultChecked  checked={assignments} onChange={()=>{setassignments(!assignments);setkey("assignment_added");setvalue(assignments);updatePreferences(); }} />}
             />
            </div>
</div>

<div className="flex space-between align-items-center mt-2">
    <div>
<h6 className="mb-0">Projects</h6>
<p className="mb-0">Send an email notification whenever projects are given to you/your batch.</p>
        </div>
        <div>
        <FormControlLabel
        control={<IOSSwitch sx={{ m: 1 }} color="primary" defaultChecked />}
             />
            </div>
</div>




<div className="flex space-between align-items-center mt-2">
    <div>
<h6 className="mb-0">Workshops</h6>
<p className="mb-0">Send an email when a workshop you have registered starts.</p>
        </div>
        <div>
        <FormControlLabel
        control={<IOSSwitch sx={{ m: 1 }} color="primary" defaultChecked />}
             />
            </div>
</div>





<div className="flex space-between align-items-center mt-2">
    <div>
<h6 className="mb-0">Internships</h6>
<p className="mb-0">Send an email notification whenever your internship application status changes.</p>
        </div>
        <div>
        <FormControlLabel
        control={<IOSSwitch sx={{ m: 1 }} color="primary" defaultChecked />}
             />
            </div>
</div>






<div className="flex space-between align-items-center mt-2">
    <div>
<h6 className="mb-0">Certificates</h6>
<p className="mb-0">Send an email whenever a new certificate is issued to you.</p>
        </div>
        <div>
        <FormControlLabel
        control={<IOSSwitch sx={{ m: 1 }} color="primary" defaultChecked />}
             />
            </div>
</div>






</div>

<div className="col-lg-6 justify-center">
    <div className="w-50 text-center">
    <img src={preferences} className="w-100" alt=""></img>
    <p>If you'd like to learn more about privacy , you can read about it in the <Link to="/privacy-policy">Privacy Policy</Link></p>
    </div>
</div>



</div>




</div>



    
    )
}

export default AccountSecurity


