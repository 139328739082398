import { Chip, CircularProgress, Rating, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import ContactSection from '../../components/ContactSection'
import ThemeDivider from '../../components/ThemeDivider'

import AlumniSection from '../home/AlumniSection'
import InputIcon from '@mui/icons-material/Input';

import Button from '@mui/material/Button';
import Gallery from '../gallery/Gallery';
import Reviews1 from '../reviews/Reviews1';
import Reviews2 from '../reviews/Reviews2';
import { CheckBox } from '@material-ui/icons';
import Toast from 'react-bootstrap/Toast';
import { CheckBoxRounded, Close, PlayCircle } from '@mui/icons-material'
import process from '../../assets/images/admission-process.png'
import DetectDevice from '../../data/DetectDevice'

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import ModalStyle from '../../data/ModalStyle'
import SetPage from '../../data/SetPage'

import axios from 'axios'
import ENDPOINT from '../../data/ENDPOINT'
import SecurityHeaders from '../../data/SecurityHeaders'

import { WhatsApp } from '@material-ui/icons'
import { Helmet } from 'react-helmet'
import WorkshopReviews from '../reviews/WorkshopReviews'
import SummerInternshipReviews from './SummerInternshipReviews'
const SummerInternshipOne = () => {

    const device = DetectDevice()
    const [register,setregister] = useState(false)
    const [term,setterm] = useState("short")
    const [demo,setdemo] = useState(false)
useEffect(()=>{
SetPage("Hacking Internship")
},[])
    const [batch,setbatch] = useState("batch1")




const [list,setlist] = useState(["manoj","kumar"])




const [name,setname] = useState(null)
const [email,setemail] = useState(null)
const [phone,setphone] = useState(null)
const [firstname,setfirstname] = useState(null)
const [lastname,setlastname] = useState(null)
const [loading,setloading] = useState(false)
const [plan,setplan] = useState("basic")
const Register =  async()=>{
    setloading(true)
setsubmit(true)
const data = new FormData()
data.append("name",firstname+" "+lastname)
data.append("email",email)
data.append("phone",phone)
data.append("term",term)
data.append("batch",batch)
data.append("plan",plan)
const res= await axios.post(ENDPOINT+"hacking-internship-register.php",data,SecurityHeaders)
if(res){
    setloading(false)
setsubmit(false)
//console.log(res.data)
    if(res.data[0].status==="success"){

settoasttitle("Application Successful")
settoastmessage("Our team will contact you soon .")
setstatusmodal(true)
settoast(true)
setregister(false)
    }
    else{
        settoasttitle("Something went wrong !")
        settoastmessage("Please try again after sometime")
        settoast(true)
    }
}



}












const [input,setinput] = useState(null)

const [submit,setsubmit] = useState(false)

const [toast,settoast] = useState(false)
const [toasttitle,settoasttitle] = useState(null)
const [toastmessage,settoastmessage] = useState(null)
const [statusmodal,setstatusmodal] = useState(false)

const [open, setOpen] = useState(false);
const handleOpen = () => {
  setOpen(true);
};
const handleClose = () => {
  setOpen(false);
};

const [qrCode,setQrCode] = useState()


const Plan = (payload)=>{
  setQrCode(payload)
}

  return (
    <div className="w-100">
     

     <Helmet>

<title>Summer Internship Program | Skills Uprise</title>
<meta name="description" content="Get trained and intern by getting trained with Skills Uprise "/>

</Helmet>

<div className="toast-container">

<Toast className="bg-warning" onClose={() => settoast(false)} show={toast} delay={3000} autohide>
          <Toast.Header>
         
            <strong className="me-auto">{toasttitle}</strong>
        
          </Toast.Header>
          <Toast.Body>{toastmessage}</Toast.Body>
        </Toast>

</div>





<Modal
        open={statusmodal}
        onClose={()=>{setstatusmodal(false)}}
      >
        <Box sx={ModalStyle} className="col-lg-4 rounded col-md-5 col-sm-6 col-11 p-3">
          <Typography id="modal-modal-title" className="d-flex justify-content-between" variant="h6" component="h2">
         Application Successful !
         <Close className="click" onClick={()=>setstatusmodal(false)}/>
          </Typography>
       
       <p>Your application was successfully submitted. Our team will reach you within 48 hours to assist your further.</p>
        </Box>
      </Modal>















<div>

<Modal
        open={register}
        onClose={()=>{setregister(false)}}
      >
        <Box sx={ModalStyle} className="col-lg-4 rounded col-md-5 col-sm-6 col-11 p-3">
          <Typography id="modal-modal-title" className="d-flex justify-content-between" variant="h6" component="h2">
         Apply for Internship
         <Close className="click" onClick={()=>setregister(false)}/>
          </Typography>
       
<form method="post" onSubmit={(e)=>{e.preventDefault();Register()}}>
    
<TextField className="form-control mt-2 mb-2"
          type="text"  
          onChange={(e)=>setfirstname(e.target.value)} 
          label="First Name"
        required
        />

<TextField type="text"  className="form-control mt-2 mb-2"
          onChange={(e)=>setlastname(e.target.value)} 
         required
         label="Last Name"
       
       />
<TextField className="form-control mt-2 mb-2"
          onChange={(e)=>setemail(e.target.value)} 
         type="email"
         label="Email"
         
       required
       />
<TextField onChange={(e)=>setphone(e.target.value)} className="form-control mt-2 mb-2"
         type="number"
         required
         label="Phone Number"
       
       />
       <select required className="form-control mt-2 mb-2" value={term} onChange={(e)=>{setterm(e.target.value)}}>
<option>Internship Duration</option>

<option value="short"> 1 Month</option>
<option value="mid">2 Months</option>
<option value="long">3 Months</option>
       </select>

    

       <button  type="submit" disabled={loading} className="btn btn-primary w-100 mt-4 workshop-progress  align-content-center justify-content-center d-flex">{submit?<><CircularProgress sx={{color:"white"}}/> Submitting... </>:"Submit Application"}</button>
     
    </form>   </Box>
      </Modal>




      <Modal
        open={demo}
        onClose={()=>{setdemo(false)}}
      >
        <Box sx={ModalStyle} className="col-lg-4 col-md-5 col-sm-6 col-11 p-3">
          <Typography id="modal-modal-title" className="d-flex justify-content-between" variant="h6" component="h2">
        Internship Demo
         <Close className="click" onClick={()=>setdemo(false)}/>
          </Typography>
       
      
<video src="https://skillsuprise.com/data/videos/hacking-internship.mp4" controls className="w-100"></video>

  </Box>
      </Modal>


      <div className="container-fluid min-vh-80 pt-2 pt-md-5 pb-5  hacking-internship-bg bg-dark text-light ">
       

       <div className="container flex-wrap flex-column-reverse flex-md-row justify-content-between d-flex">
<div className="col-lg-6 mt-4 mt-md-0">
<h1>Summer Internship Program</h1>
<div className="d-flex my-1">
    
<Rating name="read-only" value={4.5} precision={0.5} readOnly />

<h6>4.8/5 (10,000+ Ratings)</h6>
</div><p className="text-desc mt-2">Learn | Intern | Get a Job <span className="hacking-span">Join our integrated training and internship program to land your dream</span></p>
    
   <hr></hr>

<ul>
<li className="mt-3 text-secondary">Certified Training & Internship</li>
   
    <li className="mt-3 text-secondary">100% Practical Training</li>
    <li className="mt-3 text-secondary">Real-time Projects & Internship</li>

</ul>
 <div className="d-flex mt-4">
    <a href="https://wa.me/?number=919490174876&text=Internship%20Details%Needed&type=custom_url&app_absent=0" target="_blank" className="btn me-4 btn-success"><WhatsApp/> Contact Us</a>
   
   {register?
   <>
   
   
   </>:
   <>
       <button onClick={()=>setregister(true)} className="btn btn-orange-red bottom-fixed-button">Apply For Internship</button>
 
   </>}
   
</div>
</div>

<div className="col-lg-5 p-2 h-fit bg-white rounded">
<img className="w-100" src="https://skillsuprise.com/data/images/summer-internship.png"/>
</div>
       </div>

    

        </div>





        












  

    <div className='container-fluid pt-5' >
       
       <div className="text-center ">
         
       <h2>Internships We Offer</h2>
       
       <div className="col-lg-3 mx-auto">
         <hr className='w-100'></hr>
       </div>
       
       
       </div>
       <div className="container d-flex pb-5  flex-wrap">
       
         <div className="col-lg-4 p-3 col-12">
       
           <div className="">
           <div className='rounded-2  w-100 mt-3 text-center' style={{ backgroundColor: "#156cad" }}>
                       <div className='d-flex text-white p-3 justify-content-between align-items-center' >
                         <div>
                           <h6 className='pb-0 mb-0'>Ethical hacking & cyber security</h6>
                         </div>
                      <div>
                      <InputIcon />
                      </div>
                       </div>
                     </div>
           </div>
       
         </div>
       
         <div className="col-lg-4 p-3 col-12">
       
       <div className="">
       <div className='rounded-2  w-100 mt-3 text-center' style={{ backgroundColor: "#156cad" }}>
                   <div className='d-flex text-white p-3 justify-content-between align-items-center' >
                     <div>
                       <h6 className='pb-0 mb-0'>Full stack website development</h6>
                     </div>
                  <div>
                  <InputIcon />
                  </div>
                   </div>
                 </div>
       </div>
       
       </div>
       
       
       <div className="col-lg-4 p-3 col-12">
       
       <div className="">
       <div className='rounded-2  w-100 mt-3 text-center' style={{ backgroundColor: "#156cad" }}>
                   <div className='d-flex text-white p-3 justify-content-between align-items-center' >
                     <div>
                       <h6 className='pb-0 mb-0'>Data Science</h6>
                     </div>
                  <div>
                  <InputIcon />
                  </div>
                   </div>
                 </div>
       </div>
       
       </div>
       
       
       <div className="col-lg-4 p-3 col-12">
       
       <div className="">
       <div className='rounded-2  w-100 mt-3 text-center' style={{ backgroundColor: "#156cad" }}>
                   <div className='d-flex text-white p-3 justify-content-between align-items-center' >
                     <div>
                       <h6 className='pb-0 mb-0'>Artificial Intelligence and Machine Learning</h6>
                     </div>
                  <div>
                  <InputIcon />
                  </div>
                   </div>
                 </div>
       </div>
       
       </div>
       
       
       
       <div className="col-lg-4 p-3 col-12">
       
       <div className="">
       <div className='rounded-2  w-100 mt-3 text-center' style={{ backgroundColor: "#156cad" }}>
                   <div className='d-flex text-white p-3 justify-content-between align-items-center' >
                     <div>
                       <h6 className='pb-0 mb-0'>Drone Development</h6>
                     </div>
                  <div>
                  <InputIcon />
                  </div>
                   </div>
                 </div>
       </div>
       
       </div>
       
       
       
  
       
       
       <div className="col-lg-4 p-3 col-12">
       
       <div className="">
       <div className='rounded-2  w-100 mt-3 text-center' style={{ backgroundColor: "#156cad" }}>
                   <div className='d-flex text-white p-3 justify-content-between align-items-center' >
                     <div>
                       <h6 className='pb-0 mb-0'>Internet of Things</h6>
                     </div>
                  <div>
                  <InputIcon />
                  </div>
                   </div>
                 </div>
       </div>
       
       </div>
       
       
       <div className="col-lg-4 p-3 col-12">
       
       <div className="">
       <div className='rounded-2  w-100 mt-3 text-center' style={{ backgroundColor: "#156cad" }}>
                   <div className='d-flex text-white p-3 justify-content-between align-items-center' >
                     <div>
                       <h6 className='pb-0 mb-0'>App Development</h6>
                     </div>
                  <div>
                  <InputIcon />
                  </div>
                   </div>
                 </div>
       </div>
       
       </div>


       <div className="col-lg-4 p-3 col-12">
       
       <div className="">
       <div className='rounded-2  w-100 mt-3 text-center' style={{ backgroundColor: "#156cad" }}>
                   <div className='d-flex text-white p-3 justify-content-between align-items-center' >
                     <div>
                       <h6 className='pb-0 mb-0'>Digital Marketing</h6>
                     </div>
                  <div>
                  <InputIcon />
                  </div>
                   </div>
                 </div>
       </div>
       
       </div>



       <div className="col-lg-4 p-3 col-12">
       
       <div className="">
       <div className='rounded-2  w-100 mt-3 text-center' style={{ backgroundColor: "#156cad" }}>
                   <div className='d-flex text-white p-3 justify-content-between align-items-center' >
                     <div>
                       <h6 className='pb-0 mb-0'>Video Editing</h6>
                     </div>
                  <div>
                  <InputIcon />
                  </div>
                   </div>
                 </div>
       </div>
       
       </div>
       <div className="col-lg-4 p-3 col-12">
       
       <div className="">
       <div className='rounded-2  w-100 mt-3 text-center' style={{ backgroundColor: "#156cad" }}>
                   <div className='d-flex text-white p-3 justify-content-between align-items-center' >
                     <div>
                       <h6 className='pb-0 mb-0'>Human Resource Management</h6>
                     </div>
                  <div>
                  <InputIcon />
                  </div>
                   </div>
                 </div>
       </div>
       
       </div>
       <div className="col-lg-4 p-3 col-12">
       
       <div className="">
       <div className='rounded-2  w-100 mt-3 text-center' style={{ backgroundColor: "#156cad" }}>
                   <div className='d-flex text-white p-3 justify-content-between align-items-center' >
                     <div>
                       <h6 className='pb-0 mb-0'>Software Testing</h6>
                     </div>
                  <div>
                  <InputIcon />
                  </div>
                   </div>
                 </div>
       </div>
       
       </div>
       <div className="col-lg-4 p-3 col-12">
       
       <div className="">
       <div className='rounded-2  w-100 mt-3 text-center' style={{ backgroundColor: "#156cad" }}>
                   <div className='d-flex text-white p-3 justify-content-between align-items-center' >
                     <div>
                       <h6 className='pb-0 mb-0'>UI/UX Development</h6>
                     </div>
                  <div>
                  <InputIcon />
                  </div>
                   </div>
                 </div>
       </div>
       
       </div>
       </div>
              
               </div>
       


    
               <div className="mt-5 mb-5">


<div className="container d-flex flex-wrap justify-content-between">

<div className="col-lg-6   br-5 p-4 sm-p-0">
<img src="https://skillsuprise.com/assets/certificates/hacker.png" className="w-100"/>
       
</div>

<div className="col-lg-6 sm-mt-20  br-5 p-4 sm-p-0">
<h2>Training & Internship Certificate</h2>
<p>An X-Factor to your resume and the best way to prove your skills to land your dream job in cyber security</p>


<div className="d-flex align-items-center mt-3">
    <CheckBoxRounded  className="text-success mr-2"/>
    <h6  className="pb-0 mb-0">Certificate of training</h6>
</div>

<div className="d-flex align-items-center mt-3">
    <CheckBoxRounded  className="text-success mr-2"/>
    <h6  className="pb-0 mb-0">Certificate of Internship</h6>
</div>

<div className="d-flex align-items-center mt-3">
    <CheckBoxRounded  className="text-success mr-2"/>
    <h6  className="pb-0 mb-0">Best Intern Certificate (top performers)</h6>
</div>

<div className="d-flex align-items-center mt-3">
    <CheckBoxRounded  className="text-success mr-2"/>
    <h6  className="pb-0 mb-0">Recommendation Letter (top performers)</h6>
</div>
</div>
</div>
    
    </div>


    <div className="my-5">
<SummerInternshipReviews/>


</div>

    <div className="w-100 batches-bg  ">
  <div className="container  pt-5   ">
            <div className="text-center">
                <h1>Pricing Plans</h1>
            </div>
            <ThemeDivider/>
            <div className="d-flex justify-content-center ">



            <div  className='mx-auto pb-5'>
     
          <div className='d-flex justify-content-between container mx-auto flex-wrap'>
            <div className='col-lg-4 p-3 col-md-8 col-sm-12 col-12  p-3'>
              <div className='bg-white rounded-2 p-3 border shadow'>
               <div className='text-center'>
                <h3>Basic</h3>
                <h6 style={{color:"#a038f2"}}>₹ 3,000</h6>
               </div>
               <hr></hr>
               
               <div className=' col-12 '>
               <ul>
  
<ul>
  <li>1 Month training + Internship (You can do an internship anytime during your graduation)</li>
  <li>3 Projects</li>
  <li>Training certificate</li>
  <li>Internship Certificate</li>
  <li>Lifetime course access</li>
  <li>Placement training</li>
  <li>1 Mock Interview</li>
  <li>Group Discussion</li>
  <li>Resume Building training</li>
  <li>LinkedIn Profile Optimization</li>
</ul>
                </ul>
               </div>
                 <div className='text-center '>
                 <button onClick={()=>{setplan("basic");setregister(true)}} className='btn text-white w-100 plan-bg'>Register Now</button> 
                  </div> 
              </div>
            </div>









            <div className='col-lg-4 text-light p-3 col-md-8 col-sm-12 col-12  p-3'>
              <div className='plan-bg rounded-2 p-3 border shadow'>
               <div className='text-center'>
                <h3>Gold</h3>
                <h6 >₹ 10,000</h6>
               </div>
               <hr></hr>
               
               <div className=' col-12 '>
               <ul>
  <li>20 Courses</li>
  <li>1 Month Internship x 4 (You can do 4 internships anytime during your graduation, e.g., 1st internship in 3rd year and 2nd internship in 4th year)</li>
  <li>9 Real-time Projects</li>
  <li>Training certificate</li>
  <li>Internship Certificate</li>
  <li>Lifetime course access</li>
  <li>5 Mock Interviews</li>
  <li>5 Placement Training sessions</li>
  <li>4 Group Discussions during internships</li>
  <li>Resume Building Training</li>
  <li>LinkedIn Profile Optimization</li>
</ul>
               </div>
                 <div className='text-center '>
                 <button onClick={()=>{setplan("gold");setregister(true)}} className='btn btn-light w-100' >Register Now</button> 
                 </div> 
              </div>
            </div>


            <div className='col-lg-4 p-3 col-md-8 col-sm-12 col-12  p-3'>
              <div className='bg-white rounded-2 p-3 border shadow'>
               <div className='text-center'>
                <h3>Premium</h3>
                <h6 >₹ 6,000</h6>
               </div>
               <hr></hr>
               
               <div className=' col-12 '>
               
               <ul>
  <li>3 Courses</li>
 
  <li>1 Month Internship x 2 (You can do 2 internships anytime during your graduation, e.g., 1st internship in 3rd year and 2nd internship in 4th year)</li>
  <li>5 Real-time Projects</li>
  <li>Training certificate</li>
  <li>Internship Certificate</li>
  <li>Lifetime course access</li>
  <li>2 Placement Training sessions</li>
  <li>2 Mock Interviews</li>
  <li>2 Group Discussions</li>
  <li>Resume Building Training</li>
  <li>LinkedIn Profile Optimization</li>
</ul>
               </div>
                 <div className='text-center '>
                 <button onClick={()=>{setplan("premium");setregister(true)}} className='btn plan-bg text-light w-100' >Register Now</button> 
                </div> 
              </div>
            </div>



          </div>
        </div>









                <div className="col-lg-12 d-flex flex-wrap d-none p-3 ">
   
<div className="col-lg-4 col-md-4 col-sm-12 col-12 p-2">

<div className="border  br-5">

    <div className="bg-dark rounded-top  hacking-line2 text-light p-3">
    <h3 className="mb-0 text-light pb-0">Batch - 1</h3>
    <p className="text-light mt-2"><b>Batch Starts :</b> 5th May</p>
    </div>
<div className="p-3">

<p>Registration Ends: 3rd May</p>

<div className="d-flex align-items-center mt-1 mb-1">
    <h6 className="mb-0 pb-0 me-2">Morning Slot :</h6><h6 className=" mb-0 pb-0 text-danger">Almost filled</h6> 
</div>


<div className="d-flex align-items-center mt-2 mb-1">
    <h6 className="mb-0 pb-0 me-2">Afternoon Slot :</h6><h6  className=" pb-0 mb-0 text-primary">Few seats left</h6> 
</div>

<div className="d-flex align-items-center mt-1 mb-1">
    <h6 className="mb-0 pb-0 me-2">Evening Slot :</h6><h6 className=" pb-0 mb-0 text-success">Available</h6>
</div>


<button className="btn btn-rounded-pill w-100 btn-primary mt-4"  onClick={()=>{setterm("short");setregister(true)}}>Apply for Internship</button>

</div>
</div>

</div>
<div className="col-lg-4 col-md-4 col-sm-12 col-12 p-2">

<div className="border br-5">

    <div className="bg-dark rounded-top hacking-line2  text-light p-3">
    <h3 className="mb-0 text-light pb-0">Batch - 2</h3>
    <p className="text-light mt-2"><b>Batch Starts :</b> 15th May</p>
    </div>
<div className="p-3">

<p>Registration Ends: 13th May </p>

<div className="d-flex align-items-center mt-1 mb-1">
    <h6 className="mb-0 pb-0 me-2">Morning Slot :</h6><h6 className=" mb-0 pb-0 text-danger">Almost filled</h6> 
</div>


<div className="d-flex align-items-center mt-2 mb-1">
    <h6 className="mb-0 pb-0 me-2">Afternoon Slot :</h6><h6  className=" pb-0 mb-0 text-primary">Few seats left</h6> 
</div>

<div className="d-flex align-items-center mt-1 mb-1">
    <h6 className="mb-0 pb-0 me-2">Evening Slot :</h6><h6 className=" pb-0 mb-0 text-success">Available</h6>
</div>


<button className="btn btn-rounded-pill w-100 btn-primary mt-4"  onClick={()=>{setterm("short");setregister(true)}}>Apply for Internship</button>

</div>
</div>

</div>   









<div className="col-lg-4 col-md-4 col-sm-12 col-12 p-2">

<div className="border br-5">

    <div className="bg-dark rounded-top hacking-line2 rounded-top text-light p-3">
    <h3 className="mb-0 text-light pb-0">Batch - 3</h3>
    <p className="text-light mt-2"><b>Batch Starts :</b> 27th May</p>
    </div>
<div className="p-3">

<p>Registration Ends: 26th May</p>

<div className="d-flex align-items-center mt-1 mb-1">
    <h6 className="mb-0 pb-0 me-2">Morning Slot :</h6><h6 className=" mb-0 pb-0 text-danger">Almost filled</h6> 
</div>


<div className="d-flex align-items-center mt-2 mb-1">
    <h6 className="mb-0 pb-0 me-2">Afternoon Slot :</h6><h6  className=" pb-0 mb-0 text-primary">Few seats left</h6> 
</div>

<div className="d-flex align-items-center mt-1 mb-1">
    <h6 className="mb-0 pb-0 me-2">Evening Slot :</h6><h6 className=" pb-0 mb-0 text-success">Available</h6>
</div>


<button className="btn btn-rounded-pill w-100 btn-primary mt-4"  onClick={()=>{setterm("short");setregister(true)}}>Apply for Internship</button>

</div>
</div>

</div>
                </div>
            
            </div>
        </div>
        
  </div>









<div className="container    d-flex flex-wrap d-none">

<div className="col-lg-8 process p-3 ">
<div className="d-flex">

    <div className="p-3">
        <h2>Admission Process</h2>
   
    
    </div>
</div>
<div className="d-flex  justify-content-between flex-wrap">

<div className="col-lg-6 col-md-6 col-sm-12 col-12 p-2  mt-2 mb-2">
<div className="d-flex  item align-items-center rounded-pill p-2">
<div className="circle me-2">
 <h5 className="mb-0 pb-0">1</h5>
</div>
<p  className="mb-0 pb-0">Apply for internship</p>
 </div>

</div>


<div className="col-lg-6 col-md-6 col-sm-12 col-12 p-2  mt-2 mb-2">
<div className="d-flex  item align-items-center rounded-pill p-2">
<div className="circle me-2">
 <h5 className="mb-0 pb-0">2</h5>
</div>
<p  className="mb-0 pb-0">Attend a free demo</p>
 </div>

</div>



<div className="col-lg-6 col-md-6 col-sm-12 col-12 p-2  mt-2 mb-2">
<div className="d-flex  item align-items-center rounded-pill p-2">
<div className="circle me-2">
 <h5 className="mb-0 pb-0">3</h5>
</div>
<p  className="mb-0 pb-0">Confirm batch & timings</p>
 </div>

</div>





<div className="col-lg-6 col-md-6 col-sm-12 col-12 p-2  mt-2 mb-2">
<div className="d-flex  item align-items-center rounded-pill p-2">
<div className="circle me-2">
 <h5 className="mb-0 pb-0">4</h5>
</div>
<p  className="mb-0 pb-0">Start learning & build your career</p>
 </div>

</div>


</div>
 

</div>

<div className="col-lg-4 p-3 hacker-bg bg-dark text-light col-sm-4 col-12 d-flex align-items-center justify-content-center">
<div className="text-center">
    <h3 className="text-light">Don't know how to start ?</h3>
    <button className="btn btn-primary  rounded-pill mt-3" onClick={()=>setdemo(true)}><PlayCircle/> Watch Demo</button>
</div>
</div>


</div>

 





        <div className="container d-none">
         <AlumniSection/>
   
        </div>
        

        <div className="pb-5">
        <ContactSection/>
        </div>

</div>
        
        </div>
  )
}

export default SummerInternshipOne