import axios from 'axios'
import React from 'react'
import ENDPOINT from '../../data/ENDPOINT'
import SecurityHeaders from '../../data/SecurityHeaders'
import { useParams } from 'react-router-dom/cjs/react-router-dom'
import { useEffect } from 'react'

const GoogleSignin = () => {

const params = useParams()

const session = params.session
const uid = params.uid
const token = params.token
  return (
    <div>GoogleSignin





    </div>
  )
}

export default GoogleSignin