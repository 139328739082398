import React, { useEffect, useState } from 'react'
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import linkedinicon from '../../assets/icons/linkedin.svg'
import axios from 'axios';
import GetWorkshopDetails from '../../actions/GetWorkshopDetails';
import { useParams } from 'react-router-dom';
import certificateicon from '../../assets/icons/certificate-icon.svg'
import trophy from '../../assets/icons/trophy.svg'
import badgeicon from '../../assets/icons/badge-icon.svg'
import WorkshopDates from './WorkshopDates';
import divider from '../../assets/bg/divider.png'
import SetPage from '../../data/SetPage';
import RegisterWorkshop from '../../actions/workshops/RegisterWorkshop'
import ModalStyle from '../../data/ModalStyle'
import { TextField } from '@mui/material'
import Offcanvas from 'react-bootstrap/Offcanvas'
import ContactSection from '../../components/ContactSection'
import Box from '@mui/material/Box';
import {
  EmailIcon,
  EmailShareButton,

  LinkedinIcon,
  LinkedinShareButton,


  TelegramIcon,


  TelegramShareButton,

  TwitterIcon,

  TwitterShareButton,

  WhatsappIcon,

  WhatsappShareButton,

} from "react-share";
import SchoolIcon from '@mui/icons-material/School';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import WorkshopRegistrationForm from './WorkshopRegistrationForm'
import DetectDevice from '../../data/DetectDevice'
import { VerticalTimeline } from 'react-vertical-timeline-component/dist-modules';
import VerticalTimelineComponent from './VerticalTimelineComponent';
import { Close } from '@material-ui/icons';
import WorkshopLoadingTemplate from './WorkshopLoadingTemplate';
import { Helmet } from 'react-helmet';
import { useActionsContext } from '../../context/ActionsContext';
const WorkshopDetails = ({ props }) => {
  const params = useParams()
  const workshop_url = params.workshop_url
  const [workshop, setworkshop] = useState(null)
  const {search} = useActionsContext()
  const NewWorkshopDetails = async ({ workshop_url }) => {

    const res = await GetWorkshopDetails({ workshop_url })
    if (res.status === "success") {

      setworkshop(res.data[0])
    }
    else {

    }
  }
  useEffect(() => {
    SetPage("Workshop")
    NewWorkshopDetails({ workshop_url })
  }, [])
  const device = DetectDevice()
  const [workshop_id, setworkshop_id] = useState(null)
  const InitiateRegistration = () => {
    setworkshop_id(workshop.workshop_id)
    setregistermodal(true)
  }
  const [registermodal, setregistermodal] = useState(false)
  return (
    <div className="sm-pb-50">



      <Helmet>‍
        <title>Skills Uprise - Event Details</title>‍
        <meta name="description" content="Login into your account and learn the best skills from our curated courses" />
        <meta name="twitter:card" content="" />
        <meta name="twitter:site" content="@skillsuprise" />
        <meta name="twitter:creator" content="@skillsuprise" />
        <meta name="twitter:title" content="" />
        <meta name="twitter:description" content="" />
        <meta name="twitter:image" content="" />
        <meta property="og:title" content="" />
        <meta property="og:description" content="" />
        <meta property="og:image" content="" />
        <meta property="og:url" content="" />
        <meta property="og:site_name" content="Skills Uprise" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta property="fb:app_id" content="ID_APP_FACEBOOK" />
      </Helmet>



      {device === "mobile" ?
        <>

          <Offcanvas className="register-canvas" placement="bottom" show={registermodal} onHide={() => setregistermodal(false)} {...props}>


            <div className="d-flex p-3 justify-content-between align-items-center">
              <h4>Registration Form</h4>
              <Close onClick={() => setregistermodal(false)} className="click" />
            </div>

            <WorkshopRegistrationForm workshop_id={workshop_id} />

          </Offcanvas>



        </> :
        <>

          <Modal
            open={registermodal}
            onClose={() => setregistermodal(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"

          >
            <Box className="col-lg-4 p-3 col-12" sx={ModalStyle}>
              <div className="d-flex justify-content-between align-items-center">
                <h4>Registration Form</h4>
                <Close onClick={() => setregistermodal(false)} className="click" />
              </div>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <WorkshopRegistrationForm />
              </Typography>
            </Box>
          </Modal>



        </>

      }















      {workshop ?

        <>
          <div className="workshop-crumb">

          </div>


          <div className="container justify-content-between p-3 d-flex flex-wrap shadow-none shadow-sm shadow-lg br-5 workshop-info ">


            <div className="col-lg-6 col-12">
              <h3>{workshop.workshop_title}</h3>

              <div className="mt-3">
                <h5 className="text-primary">Date & Time :</h5>
                <h6>27th July 22 @ 6pm</h6>
              </div>
              <div className="mt-3">
                <h5 className="text-primary">Venue :</h5>
                <h6>Zoom Meetings</h6>
              </div>

              <h5 className="mb-1 text-primary">Share this Event</h5>
              <div className="flex  ">
                <WhatsappShareButton size={30} round={true} url={"https://skillsuprise.com/workshop/" + workshop}><WhatsappIcon size={30} type="button" round={true} url={"https://skillsuprise.com/workshop/" + workshop} className="mr-2" /></WhatsappShareButton>
                <LinkedinShareButton size={30} round={true} url={"https://skillsuprise.com/workshop/" + workshop}><LinkedinIcon size={30} type="button" round={true} url={"https://skillsuprise.com/workshop/" + workshop} className="mr-2"></LinkedinIcon></LinkedinShareButton>
                <TelegramShareButton size={30} round={true} url={workshop}><TelegramIcon size={30} type="button" round={true} url={"https://skillsuprise.com/workshop/" + workshop} className="mr-2" /></TelegramShareButton>
                <TwitterShareButton size={30} round={true} url={"https://skillsuprise.com/workshop/" + workshop}><TwitterIcon size={30} type="button" round={true} url={"https://skillsuprise.com/workshop/" + workshop} className="mr-2" /></TwitterShareButton>
                <EmailShareButton size={30} round={true} url={"https://skillsuprise.com/workshop/" + workshop}><EmailIcon size={30} type="button" round={true} url={"https://skillsuprise.com/workshop/" + workshop} className="mr-2" /></EmailShareButton>


              </div>
            </div>
            <div className="col-lg-5 sm-mt-20 col-12">

              <div className="d-flex">
                <div className="speaker-text">
                  <p className="speaker">S</p>
                  <p className="speaker">P</p>
                  <p className="speaker">E</p>
                  <p className="speaker">A</p>
                  <p className="speaker">K</p>
                  <p className="speaker">E</p>
                  <p className="speaker">R</p>
                </div>
                <img src="https://skillsuprise.com/data/images/trainer.png" className="trainer-dp mr-10" />
                <div>
                  <h6>Manoj Kumar Koravangi</h6>
                  <p>Founder & CEO (awetecks)</p>
                  <div className="d-flex align-items-center mt-3">

                    <a target="_blank" href="https://www.linkedin.com/in/manojkoravangi/">  <img src={linkedinicon} className="icon30 mr-10 " />View Profile</a>
                  </div>
                </div>
              </div>
              <hr />
              <div>
                <h5>Contact </h5>
                <div className="d-flex flex-wrap">
                  <a href="tel:919490174876" className="d-flex mr-10">

                    <PhoneInTalkIcon />
                    <p>+91-9490174876</p>

                  </a>

                  <a href="tel:919381683945" className="d-flex">

                    <PhoneInTalkIcon />
                    <p>+91-9381683948</p>

                  </a>
                </div>
              </div>
              <hr />



              {registermodal||search?<>
              
              
              </>:
              <>
                <button onClick={() => InitiateRegistration()} className="btn sm-br-0 btn-workshop-register ">Register for Free</button>
         
              </>}
               </div>
          </div>




          {workshop.description ?
            <>
              <div className="container pt-5 mt-5 pb-5 text-center">
                <h1>Description</h1>
                <div class="divider text-center">
                  <img src={divider} alt="divider"></img>
                </div>
                <div className="col-lg-7 mx-auto text-center">
                  <p>{workshop.workshop_description}</p>
                </div>
              </div>


            </>
            : null}



          <div className="container justify-content-between pt-5  d-flex flex-wrap">



            <div className='d-flex justify-content-between col-lg-12 flex-wrap   '>
              <div className='col-lg-5 col-md-12 col-sm-10 col-12  '>
                <img className='w-100' src={workshop.image} />
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12 col-12  sm-pt-30">
                <h2>What you will learn</h2>
                <hr />
                {workshop.final_topics.map((topic) => (

                  <>
                    <div className=" d-flex mb-2">

                      <svg className="mr-2 mt-1" stroke="currentColor" fill="rgb(26, 156, 0)" stroke-width="0" viewBox="0 0 16 16" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm10.03 4.97a.75.75 0 0 1 .011 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.75.75 0 0 1 1.08-.022z"></path></svg>

                      <p className="mb-0">{topic}</p>
                    </div>
                  </>

                ))}

              </div>
            </div>



          </div>

          {
            workshop.prize1 && workshop.prize2 && workshop.prize3 ?

              <div className="container">
                <div className="w-100   mt-5 pt-4 pb-5  mx-auto  mb-5">
                  <h2 className="sm-25 ">What you'll get</h2>


                  <>






                  </>

                  <div className="flex-wrap d-flex justify-content-between mt-3">

                    <div className=" col-lg-4 col-md-4 col-sm-3 col-12   p-2">
                      <div className="benifit-item  d-flex br-4 p-3">

                        <img src={trophy} />

                        <div>
                          <h5 className="text-light">{workshop.prize1}</h5>
                          <p className="text-light sm-14">{workshop.condition1}</p>
                        </div>
                      </div>
                    </div>



                    <div className=" col-lg-4 col-md-4 col-sm-3 col-12  p-2">

                      <div className="benifit-item d-flex br-4 p-3">

                        <img src={badgeicon} />
                        <div>

                          <h5 className="text-light ">{workshop.prize2}</h5>
                          <p className="text-light sm-14">{workshop.condition2}</p>

                        </div>
                      </div>

                    </div>



                    <div className=" col-lg-4 col-md-4 col-sm-3 col-12 p-2">

                      <div className="benifit-item d-flex br-4 p-3">

                        <img src={certificateicon} />
                        <div>


                          <h5 className="text-light">{workshop.prize3}</h5>
                          <p className="text-light sm-14">{workshop.condition3}</p>

                        </div>
                      </div>
                    </div>

                  </div>






                </div>


              </div>
              : <></>
          }




          <div className="container mb-5 mt-5 pb-5">


            <div className="text-center">
              <h2>Additional Benifits</h2>
            </div>
            <div class="divider">
              <img src={divider} alt="divider"></img>
            </div>
            <div className="flex-wrap-reverse col-lg-10 justify-content-between mx-auto d-flex mt-5">



              <div className="col-lg-6 sm-mt-10">
                {workshop.final_benifits.map((benifit) => (

                  <>
                    <div className="d-flex mb-2">

                      <svg className="mr-2 mt-1" stroke="currentColor" fill="rgb(26, 156, 0)" stroke-width="0" viewBox="0 0 16 16" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm10.03 4.97a.75.75 0 0 1 .011 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.75.75 0 0 1 1.08-.022z"></path></svg>

                      <p className="mb-2">{benifit}</p>
                    </div>
                  </>

                ))}
              </div>

              <div className="col-lg-5 col-md-4 col-sm-10 col-12">


                <img alt="certificate" src={workshop.certificate ? workshop.certificate : "https://skillsuprise.com/assets/certificates/ethical-haker.png"} className="w-100" />

              </div>



            </div>

          </div>


          {workshop.scholarship === "1" ?
            <>







              {/*workshop banner*/}



              <div className="pt-5 sm-br-0 sm-mb-50 container mx-auto br-5  bg-dark black-cta pb-5 mt-5 mb-5">

                <div className="flex-wrap container-fluid">
                  <div className="col-lg-8 text-light">
                    <h2 className="sm-18 text-white">Get Scholarship Upto ₹ {workshop.scholarship_amount} </h2>
                    <h3 className="sm-18 text-white">& Get FREE Trainings</h3>

                    <h6 className="font-weight-normal sm-16 d-none md-d-block">Attend the workshop and attempt the scholarship exam to avail this offer.</h6>
                  </div>
                  <div className="col-lg-4 align-items-center flex">

                    <button onClick={() => InitiateRegistration()} className="btn-warning  btn h-fit">Register Now for Free</button>

                  </div>
                </div>

              </div>


            </>
            : null}













          <div className="container-fluid pt-5 ">
            <div className="text-center">
              <h3 className="sm-20">Important Dates</h3>

              <div className="divider">
                <img src={divider} />
              </div>
            </div>
            {workshop ?

              <>
                <WorkshopDates workshop={workshop} />
              </>
              : null}
          </div>



          <div className="pb-5 mt-5">
            <ContactSection />



          </div>














        </> :


        <>

          <WorkshopLoadingTemplate />

        </>


      }



    </div>
  )
}

export default WorkshopDetails