import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


export default function ProjectDescriptionAccordion({description}) {
  return (
    <div className="bg-transparent">
      <Accordion className="no-shadow bg-transparent p-0">
        <AccordionSummary
        className="p-0"
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>
               <div className="d-flex align-items-center">
            
           <p className="pb-0 sm-13">Description</p>
               </div>
            
                  </Typography>
        </AccordionSummary>
        <AccordionDetails className="p-0">
          <Typography>
           <p className="sm-12">{description}
           </p>
          </Typography>
        </AccordionDetails>
      </Accordion>
      
      
    </div>
  );
}
