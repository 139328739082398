import React, { useEffect } from 'react'
import SetPage from '../../../data/SetPage'
import PlacementSupport from './PlacementSupport'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

const PlacementCell = () => {
  const params = useParams();


const courseurl = params.course_url;
const batch_id = params.batch_id;

useEffect(()=>{

SetPage("Placement cell")
},[])

  return (
    <div>
     

<PlacementSupport courseurl={courseurl} batchId={batch_id}  />


     </div>
  )
}

export default PlacementCell