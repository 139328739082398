import React from 'react'
import axios from 'axios';
import { useState } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

import styled from 'styled-components';
import ENDPOINT from '../../context/ENDPOINT';
import { CircularProgress } from '@material-ui/core';
import ModalStyle from '../../data/ModalStyle';
import { Close } from '@mui/icons-material';
const ApplyCai = () => {

  const [file, setfile] = useState(null)
  const [status, setstatus] = useState(false)
  const UPLOAD_ENDPOINT = `${ENDPOINT}cai-register.php`;

  const onSubmit = async (e) => {
    setstatus(true)
    e.preventDefault()
    let res = await uploadFile(file);

    if (res) {
      setstatus(false)
    }

    if (res.data.status === "success") {
      setstatus(false)
      setmodaltitle("Application successful !")
      setmodalmessage("Your application was successfully submitted. Our team will reach you soon to assist you further.")
      setmodal(true)

    } else {
      setmodaltitle("Something went wrong !")
      setmodalmessage("Please try again after sometime")
      setmodal(true)
      setstatus(false)
      document.getElementById('whyus').value = "";
      document.getElementById('reason').value = "";
      document.getElementById('workability').value = "";
      document.getElementById('availability').value = "";


    }
  }
  const onChange = (e) => {
    setfile(e.target.files[0])
  }




  const [uploadprogress, setuploadprogress] = useState(0)
  const uploadFile = async (file) => {

    const config = {
      onUploadProgress: function (progressEvent) {
        var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)

        setuploadprogress(percentCompleted)



      }
    }
    const formData = new FormData();
    const user = JSON.parse(localStorage.getItem("user"));

    formData.append('resume', file);
    formData.append('whyus', document.getElementById('whyus').value)
    formData.append('reason', document.getElementById('reason').value)
    formData.append('workability', document.getElementById('workability').value)
    formData.append('availability', document.getElementById('availability').value)
    formData.append('modeofwork', "wfh")
    formData.append('session', user.SessionId);

    const res = await axios.post(UPLOAD_ENDPOINT, formData, config, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    });

    if (res) {

      window.location.replace("/cai-conformation")

    }
  }

  const [modal, setmodal] = useState(false)
  const [modaltitle, setmodaltitle] = useState(null)
  const [modalmessage, setmodalmessage] = useState(null)
  return (

    <>


      <Modal
        open={modal}
        onClose={() => setmodal(false)}
        aria-labelledby="modal-modal-title"

        aria-describedby="modal-modal-description"
      >
        <Box className="col-lg-4" sx={ModalStyle}>


          <div className="text-center">

            <div className="flex space-between">
              <h4 className="sm-18">{modaltitle}</h4>
              <Close type="button" className="text-danger" onClick={() => setmodal(false)} />
            </div>
            <hr />
            <p>{modalmessage}</p>

            <div className="d-flex justify-content-end">
              <button className="btn btn-danger" onClick={() => setmodal(false)}>Close</button>

            </div>
          </div>

        </Box>
      </Modal>

      <ApplyInternshipStyled>

        <form onSubmit={onSubmit} className="position-relative">

          <p>1. Why do you want to work with us ?</p>
          <input className="p-2 w-100" id="whyus" placeholder="Enter your answer" required></input>
          <p>2. Why should we hire you ?</p>
          <textarea className="p-2 w-100" id="reason" name="reason" placeholder="Enter your answer" required></textarea>


          <p>3. Are you available for the specified internship duration ?</p>
          <input className="p-2 w-100" id="availability" name="availability" placeholder="example: Yes i'm available" required></input>


          <p>4. How many hours can you work in a day?</p>
          <input className="p-2 w-100" id="workability" name="workability" placeholder="example: 4 to 5 hours" required></input>





          <p className="mb-1 mt-4">5.Upload your latest resume</p>


          <input type="file" className="" placeholder="Photo/Video" accept="application/pdf" onChange={onChange} />
          <br />

          <button className="btn btn-primary w-100 mt-2" type="submit">{status ? <CircularProgress className="text-white " size={20} /> : ""}{uploadprogress > 0 ? <>Uploading {uploadprogress}% ...</> : "Confirm Application"}</button>



        </form>



      </ApplyInternshipStyled>
    </>

  )
}



export default ApplyCai;

const ApplyInternshipStyled = styled.div`

input, textarea{
  border: 1px solid lightgrey;
  border-radius:5px;
  margin-bottom: 10px;
}

`