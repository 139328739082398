import Localbase from 'localbase'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import FooterCoursesList from '../actions/FooterCoursesList'
import DetectDevice from '../data/DetectDevice'

import pinteresticon from '../assets/icons/pinterest.jpg'

import whatsappicon from '../assets/icons/whatsappicon.jpg'
import facebookicon from '../assets/icons/facebook.jpg'
import linkedinicon from '../assets/icons/linkedinicon.jpg'
import instagramicon from '../assets/icons/instagram.jpg'
import twittericon from '../assets/icons/twitter.jpg'
import youtubeicon from '../assets/icons/youtube.jpg'
import GetPageName from '../actions/GetPageName'
const Footer = ({account}) => {

     const [device,setdevice] = useState("desktop")
     const [selflist,setselflist] = useState(null)
     const [offlinelist,setofflinelist] = useState(null)
     const [specializations,setspecializations] = useState(null)
     const [livelist,setlivelist] = useState(null)

const page=GetPageName()
let db = new Localbase("db")
const NewOfflineList = async({mode})=>{
     const res = await FooterCoursesList({
          payload:{
               mode:mode
          }
     })
     if(res){
          if(res.status==="success"){
               if(mode==="offline"){
                    setofflinelist(res.data)
                    db.collection("paths").add({
                         offline_list:res.data
                    },"offline_list")
                    
               }
               if(mode==="live"){
                    setlivelist(res.data)
                    db.collection("paths").add({
                         live_list:res.data
                    },"live_list")
               }
               if(mode==="selfpaced"){
                    setselflist(res.data)
                    db.collection("paths").add({
                         selfpaced_list:res.data
                    },"selfpaced_list")
               }
               if(mode==="specialization"){
                    setspecializations(res.data)
                    db.collection("paths").add({
                         specializations_list:res.data
                    },"specializations_list")
               }
          }
        
     }
}


     useEffect(()=>{
     
     
     db.collection("paths").doc("offline_list").get().then(data=>{
          setofflinelist(data.offline_list)
     })
     .catch(error=>{

     })
     db.collection("paths").doc("live_list").get().then(data=>{
          setlivelist(data.live_list)
     })
     .catch(error=>{
          
     })

     db.collection("paths").doc("selfpaced_list").get().then(data=>{
          setselflist(data.selfpaced_list)
     })
     .catch(error=>{
          
     })
     db.collection("paths").doc("specializations_list").get().then(data=>{
          setspecializations(data.specializations_list)
     })
     .catch(error=>{
          
     })
     
     
     
          const newdevice = DetectDevice()




       if(newdevice==="mobile"){
        setdevice("mobile")}
        else{setdevice("desktop")
     NewOfflineList({mode:"offline"})
     NewOfflineList({mode:"selfpaced"})
     NewOfflineList({mode:"live"})
     NewOfflineList({mode:"specialization"})
     
     
     }

     },[])
  return (

     <>
     
  {page==="shorts"?
  <>
  
  
  
  </>:
  <>
  
  
  <div className="w-100 footer bg-dark text-whtie pt-5 pb-5">

<div className="container d-flex flex-wrap pb-5 pb-md-3">


{device==="mobile"?
<>

<div className="col-lg-4 text-light col-12 p-3">
<h5  className="text-white">Courses We Offer</h5>
<hr className="w-50"/>
<div className="mt-2 text-grey bg-transparent mb-2">
<Link to="/offline-courses"  className="text-grey">Offline Courses</Link>
</div>
<div className="mt-2 text-grey bg-transparent mb-2">
<Link to="/online-courses"  className="text-grey">Online Courses</Link>
</div>
<div className="mt-2 text-grey bg-transparent mb-2">
<Link to="/selfpaced-courses"  className="text-grey">Pre-recoded Courses</Link>
</div>
<div className="mt-2 text-grey bg-transparent mb-2">
<Link to="/specialization-courses"  className="text-grey">Specialization Courses</Link>
</div>
<div className="mt-2 text-grey bg-transparent mb-2">
<Link to="/free-courses"  className="text-grey">Free Courses</Link>
</div>
</div>

</>:
<>

<div className="col-lg-4 text-white p-3">
    
{offlinelist?
<>
<div >
     
<h5 className="text-white">Offline Courses</h5>
<hr className="w-50"/>
{offlinelist.map((course,index)=>(
<>
{index<=4?
<>
<div className="mt-2 text-grey bg-transparent mb-2">
<Link to={"/course/"+course.course_url+"/offline"}  className="text-grey">{course.course_title}</Link>
</div>
</>:
<>

</>}
</>
))}

</div>
</>:null}



 

</div>

<div className="col-lg-4  text-white  p-3">
{livelist?
<>
<div >
     
<h5  className="text-white">online Courses</h5>
<hr className="w-50"/>
{livelist.map((course,index)=>(
     <>
{index<=4?
<>
<div className="mt-2 text-grey bg-transparent mb-2">
<Link to={"/course/"+course.course_url+"/live"}  className="text-grey">{course.course_title}</Link>
</div>
</>:
<>

</>}
</>
))}

</div>
</>:null}

</div>




<div className="col-lg-4 text-white  p-3">
{selflist?
<>
<div >
     
<h5  className="text-white">Pre-recorded Courses</h5>
<hr className="w-50"/>
{selflist.map((course,index)=>(
     <>
{index<=4?
<>
<div className="mt-2 text-grey bg-transparent mb-2">
<Link to={"/course/"+course.course_url+"/selfpaced"}  className="text-grey">{course.course_title}</Link>
</div>
</>:
<>

</>}
</>
))}

</div>
</>:null}

</div>





<div className="col-lg-4 text-white  p-3">
{specializations?
<>
<div >
     
<h5  className="text-white">Specialization Courses</h5>
<hr className="w-50"/>
{specializations.map((course)=>(
<>
<div className="mt-2 text-grey bg-transparent mb-2">
<Link  to={"/course/"+course.course_url+"/specialization"}  className="text-grey">{course.course_title}</Link>
</div>
</>
))}

</div>
</>:null}
</div>

</>}


<div className="col-lg-4 text-white  p-3">

<div >
     
<h5  className="text-white">About </h5>
<hr className="w-50"/>
<div className="mt-2 text-grey bg-transparent mb-2">
<Link to="/about" className="text-grey">About Company</Link>
</div>
<div className="mt-2 text-grey bg-transparent mb-2">
<Link to="/terms-and-conditions" className="text-grey">Terms & Conditions</Link>
</div>
<div className="mt-2 text-grey bg-transparent mb-2">
<Link to="/privacy-policy" className="text-grey">Privacy Policy</Link>
</div>
<div className="mt-2 text-grey bg-transparent mb-2">
<a href="https://awetecks.com" target="_blank" className="text-grey">Copy Rights 2024 | Awetecks </a>
</div>

<div className="mt-2 text-grey bg-transparent mb-2">
<a href="https://awetecks.com" target="_blank" className="text-grey">Aweteck Services Pvt.Ltd</a>
</div>
</div>

</div>


<div className="col-lg-4 p-3 text-white">
<h5  className="text-white">Follow us on social media </h5>
<hr className="w-75"/>
<div className="d-flex mt-3 md-mt-0">
<a  target="_blank"  href="https://www.linkedin.com/company/skillsuprise">

<img src={linkedinicon} className="footer-social"/>
</a>
<a  target="_blank"  href="https://www.instagram.com/skillsuprise">
<img src={instagramicon} className="footer-social"/>
</a>

<a   target="_blank"  href="https://www.youtube.com/@skillsuprise/">
<img  src={youtubeicon} className="footer-social"/> 
</a>


<a target="_blank" href="https://api.whatsapp.com/send/?phone=919490174876&text=">
<img src={whatsappicon} className="footer-social"/>
</a>


<a  target="_blank"  href="https://www.facebook.com/skillsuprise">

<img src={facebookicon} className="footer-social"/>
</a>


<a  target="_blank"  href="https://twitter.com/skillsuprise">
<img src={twittericon} className="footer-social"/>
</a>


</div>
<div className="d-flex">


     
</div>


</div>


</div>


    </div>
  </>}
     
     </>
    
  )
}

export default Footer