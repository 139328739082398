import React, { useEffect, useState } from 'react'
import GetInternships from '../../actions/GetInternships'
import InternshipItem from './InternshipItem'
import Localbase from 'localbase'

import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import DirectionsIcon from '@mui/icons-material/Directions';
import SetPage from '../../data/SetPage'
import Checkbox from '@mui/material/Checkbox';
import { Alert, Skeleton } from '@mui/material';
import { Link } from 'react-router-dom';
import CourseSuggestions from '../community/CourseSuggestions';
import WorkshopsSuggestions from '../dashboard/WorkshopsSuggestions';
import DetectDevice from '../../data/DetectDevice';
import { Helmet } from 'react-helmet';
const InternshipsPage = () => {
let db = new Localbase("db")
const [internships,setinternships] = useState(null)
const NewGetInternships = async()=>{
setloading(true)
  const res= await GetInternships()
  if(res.status==='success'){
    setloading(false)
setinternships(res.data)
db.collection("paths").add({
  internships:res.data
},"internships")

  }
}

useEffect(()=>{
  SetPage("Internships")
  db.collection("paths").doc("internships").get().then(data=>{
    setinternships(data.internships)

      }).catch(error=>{
    
      })
NewGetInternships()
},[])
const [loading,setloading] = useState(false)
const device = DetectDevice()
  return (
    <>


<Helmet>‍
        <title>Internships & Job Opportunities | Careers - Skills Uprise</title>‍
        <meta name="description" content="Login into your account and learn the best skills from our curated courses" />       
        <meta name="twitter:card" content="" />   
        <meta name="twitter:site" content="@skillsuprise" />    
        <meta name="twitter:creator" content="@skillsuprise" />                
        <meta name="twitter:title" content="" />   
        <meta name="twitter:description" content="" />  
        <meta name="twitter:image" content=""/>     
        <meta property="og:title" content="" />  
        <meta property="og:description" content="" />        
        <meta property="og:image" content=""/>
        <meta property="og:url" content=""/>
        <meta property="og:site_name" content="Skills Uprise" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta property="fb:app_id" content="ID_APP_FACEBOOK" />
      </Helmet>

    <div className="container  pt-3 pb-5 d-flex flex-wrap justify-content-between mb-5">
    <div className="col-lg-7 col-md-12 col-sm-12 col-12 sm-mx-auto">


{internships?
<>
{internships.map((internship,index)=>(

  <>
<InternshipItem className={"w-100"} internship={internship}/>
  </>
))}
</>:<>


<Skeleton variant="rectangle" height={100} className="w-100 mt-2 mb-2"/>

<Skeleton variant="rectangle" height={100} className="w-100 mt-2 mb-2"/>

<Skeleton variant="rectangle" height={100} className="w-100 mt-2 mb-2"/>

<Skeleton variant="rectangle" height={100} className="w-100 mt-2 mb-2"/>

<Skeleton variant="rectangle" height={100} className="w-100 mt-2 mb-2"/>

<Skeleton variant="rectangle" height={100} className="w-100 mt-2 mb-2"/>

</>}



</div>












    <div className="col-lg-4 d-none d-sm-block h-fit p-3 ">

{device==="mobile"?
<>

</>:<>
<CourseSuggestions/>

{/* <WorkshopsSuggestions/> */}

</>}


</div>



    </div>


    </>
  )
}

export default InternshipsPage