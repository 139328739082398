import React, { useEffect, useRef, useState,useContext } from 'react'
import axios from 'axios'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import ENDPOINT from './data/ENDPOINT'
import SecurityHeaders from './data/SecurityHeaders'
import SetPage from './data/SetPage'
import { AuthContext } from './context/AuthContext'

const SinglePage = () => {
    const params = useParams()
    const [pages, setPages] = useState(null);
    const pageRefs = useRef([]);
    const [access,setaccess] = useState(true);
    const {user} = useContext(AuthContext)

    const GetPages = async () => {
        const data = new FormData();
        data.append('book_name', params.book_name);
       if(user&&user.SessionId){
        data.append('session',user.SessionId)
       }
        const res = await axios.post(ENDPOINT + "get-pages.php", data, SecurityHeaders);
        if (res) {
            if (res.data.status === "success") {
                setPages(res.data.data);
            }else{
                setPages(null)
            }
        }
    }

    useEffect(() => {
        SetPage("Book")
        GetPages();
    }, []);

    const scrollToPage = (index) => {
        setcurrentpage(index)
        pageRefs.current[index].scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
const [currentpage,setcurrentpage] = useState(0)
    useEffect(()=>{

    },[currentpage])

    return (
      <>
      
      {access?
      <>   <div className='container vh-90 d-flex p-0'>
  {pages?
  <>
      <div className='d-none d-md-block col-lg-3  p-2 vh-80 pt-2'>
   

      <div className='overflow-y  bg-white book-thumbnails ' style={{  overflowY: 'auto' }}>
             {pages.map((item, index) => (
                 <div key={index} onClick={() => {scrollToPage(index);setcurrentpage(index)}} style={{ cursor: 'pointer' }}>
                     <div className=' rounded mb-1  '>
                      
                         <div className='col-12 '>
                             <img className={`col-12 w-100  border  rounded ${currentpage===index?" border-2 shadow shadow-primary border-primary":"border-1 border-secondary"}`} src={item.image_url} alt={`Page ${item.page_no}`} />
                         </div>
                         <p className={`m-0 p-0 text-center ${currentpage===index?"text-bold":""}`}>Page: {item.page_no}</p>
                     </div>
                 </div>
             ))}
         </div>
      </div>
         <div className='flow book-pages bg-transparent overflow-y col-12 col-lg-9 vh-90' style={{  overflowY: 'auto' }}>
             {pages.map((item, index) => (
                 <div key={index} ref={el => pageRefs.current[index] = el}>
                     <div className='col-lg-12  book-images bg-transparent'>
                         <img className='col-12 mb-md-2 mb-1  w-100  border border-1  border-secondary rounded-0 rounded-md-1' src={item.image_url} alt={`Page ${item.page_no}`} />
                     </div>
                 </div>
             ))}
         </div>
  
  </>:
  <>
   <div className="min-vh-90">

<div className="text-center mx-auto pt-5 mt-5 col-lg-6 col-12">
<h4>Access Denied</h4>
<p>You don't have access to this content. <br></br>Login into your account or register a course to get access to this content.</p>
</div>
      </div>
  
  </>}
     </div></>:
      
      <>
      
     
      </>}
      </>
    )
}

export default SinglePage
