import axios from 'axios'
import ENDPOINT from '../data/ENDPOINT'
import SecurityHeaders from '../data/SecurityHeaders'

const CourseDashboardStats= async ({payload})=>{

const data = new FormData()
data.append('session',payload.user)
data.append("course_url",payload.course_url)
data.append("batch_id",payload.batch_id)
const res = await 
axios.post(ENDPOINT+`course-dashboard.php`,data,SecurityHeaders)
if(res){
    
     return res.data
}

}


export default CourseDashboardStats