import {  CircularProgress, Rating, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import ContactSection from '../../components/ContactSection'
import divider from '../../assets/bg/divider.png'
import Toast from 'react-bootstrap/Toast';
import { CheckBoxRounded, Close, PlayCircle } from '@mui/icons-material'
import DetectDevice from '../../data/DetectDevice'

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import ModalStyle from '../../data/ModalStyle'
import SetPage from '../../data/SetPage'

import axios from 'axios'
import ENDPOINT from '../../data/ENDPOINT'
import SecurityHeaders from '../../data/SecurityHeaders'

import { WhatsApp } from '@material-ui/icons'
import WorkshopTimeline from './WorkshopTimeline'
import { Helmet } from 'react-helmet'
import WorkshopReviews from '../reviews/WorkshopReviews'
import ThemeDivider from '../../components/ThemeDivider';
import { useActionsContext } from '../../context/ActionsContext';
const HackingWorkshop = () => {

    const device = DetectDevice()
    const [register,setregister] = useState(false)
    const [term,setterm] = useState("short")
    const [demo,setdemo] = useState(false)
useEffect(()=>{
SetPage("Hacking Workshop")
},[])
    const [batch,setbatch] = useState("batch1")
const {search} =useActionsContext()



const [list,setlist] = useState(["manoj","kumar"])




const [name,setname] = useState(null)
const [email,setemail] = useState(null)
const [phone,setphone] = useState(null)
const [firstname,setfirstname] = useState(null)
const [lastname,setlastname] = useState(null)
const [loading,setloading] = useState(false)
const Register =  async()=>{
    setloading(true)
setsubmit(true)
const data = new FormData()
data.append("name",firstname+" "+lastname)
data.append("email",email)
data.append("phone",phone)



const res= await axios.post(ENDPOINT+"hacking-workshop-register.php",data,SecurityHeaders)
if(res){
    setloading(false)
setsubmit(false)
//console.log(res.data)
    if(res.data[0].status==="success"){


        window.location.replace("/workshop-confirmation")
settoasttitle("Application Successful")
settoastmessage("Our team will contact you soon .")

settoast(true)
setregister(false)
    }
    else{
        settoasttitle("Something went wrong !")
        settoastmessage("Please try again after sometime")
        settoast(true)
    }
}



}












const [input,setinput] = useState(null)

const [submit,setsubmit] = useState(false)

const [toast,settoast] = useState(false)
const [toasttitle,settoasttitle] = useState(null)
const [toastmessage,settoastmessage] = useState(null)
const [statusmodal,setstatusmodal] = useState(false)
  return (
    <div className="w-100">
     
     <Helmet>

<title>Ethical Hacking & Cyber Security National Workshop | Skills Uprise</title>
<meta name="description" content="National level workshop on ethical hacking and cyber security  hosted by Skills Uprise "/>

</Helmet>


<div className="toast-container">

<Toast className="bg-warning" onClose={() => settoast(false)} show={toast} delay={3000} autohide>
          <Toast.Header>
         
            <strong className="me-auto">{toasttitle}</strong>
        
          </Toast.Header>
          <Toast.Body>{toastmessage}</Toast.Body>
        </Toast>

</div>





<Modal
        open={statusmodal}
        onClose={()=>{setstatusmodal(false)}}
      >
        <Box sx={ModalStyle} className="col-lg-4 rounded col-md-5 col-sm-6 col-11 p-3">
          <Typography id="modal-modal-title" className="d-flex justify-content-between" variant="h6" component="h2">
      <div>
      <h3>Join our WhatsApp Group !</h3>



      </div>
         <Close className="click" onClick={()=>setstatusmodal(false)}/>
          </Typography>
          <p>Your application was successfully submitted. Our team will reach you within 48 hours to assist your further. Also join our WhatsApp group for realtime updates about the workshop.</p>
       
<hr></hr>


<div className="d-flex justify-content-between">
    <button onClick={()=>setstatusmodal(false)} className="btn btn-danger rounded-pill"><Close/> Close</button>
    <a href="https://chat.whatsapp.com/BbXuJfDHUSBE6scdifhgs5" target="_blank" className="btn btn-success rounded rounded-pill"><WhatsApp/> Join WhatsApp Group</a>
</div>
      
      
      
        </Box>
      </Modal>















<div>

<Modal
        open={register}
        onClose={()=>{setregister(false)}}
      >
        <Box sx={ModalStyle} className="col-lg-4 rounded col-md-5 col-sm-6 col-11 p-3">
          <Typography id="modal-modal-title" className="d-flex justify-content-between" variant="h6" component="h2">
         Register Now !
         <Close className="click" onClick={()=>setregister(false)}/>
          </Typography>
       
<form method="post" onSubmit={(e)=>{e.preventDefault();Register()}}>
    
<TextField className="form-control mt-2 mb-2"
          type="text"  
          onChange={(e)=>setfirstname(e.target.value)} 
          label="First Name"
        required
        />

<TextField type="text"  className="form-control mt-2 mb-2"
          onChange={(e)=>setlastname(e.target.value)} 
         required
         label="Last Name"
       
       />
<TextField className="form-control mt-2 mb-2"
          onChange={(e)=>setemail(e.target.value)} 
         type="email"
         label="Email"
         
       required
       />
<TextField onChange={(e)=>setphone(e.target.value)} className="form-control mt-2 mb-2"
         type="number"
         required
         label="Phone (WhatsApp Number)"
       
       />
       <div className="px-1">
       <p className='sm-12'><b>Note : </b> All the Workshop details, notes, softwares and certificates will be shared through the registered WhatsApp number.</p>

       </div>
     

       <button  type="submit" disabled={loading} className="btn btn-primary w-100 d-flex mt-4 workshop-progress  align-content-center justify-content-center">{submit?<><CircularProgress sx={{height:14,color:"white"}}/>Registering... </>:"Confirm Registration"}</button>
     
    </form>   </Box>
      </Modal>




      <Modal
        open={demo}
        onClose={()=>{setdemo(false)}}
      >
        <Box sx={ModalStyle} className="col-lg-4 col-md-5 col-sm-6 col-11 p-3">
          <Typography id="modal-modal-title" className="d-flex justify-content-between" variant="h6" component="h2">
        Promo
         <Close className="click" onClick={()=>setdemo(false)}/>
          </Typography>
       
      
<video src="https://skillsuprise.com/data/videos/hacking-internship.mp4" controls className="w-100"></video>

  </Box>
      </Modal>


      <div className="container-fluid animate min-vh-80 pt-2 pt-md-5 pb-5  hacking-internship-bg bg-dark text-light ">
       

       <div className="container flex-wrap flex-column-reverse flex-md-row justify-content-between d-flex">
<div className="col-lg-6 mt-4 mt-md-0">
    <h3 className='fade-in-bottom delay02s'>National Workshop on</h3>
<h1 className='fade-in-bottom delay02s'>Ethical Hacking & Cyber Security</h1>


<div className="d-flex my-1">
    
<Rating name="read-only" className='fade-in-bottom delay03s' value={4.5} precision={0.5} readOnly />

<h6 className='fade-in-bottom delay03s'>4.8/5 (5,498 Ratings)</h6>
</div><p className="text-desc mt-2 fade-in-bottom delay03s">Learn Hacking from the best trainer who <span className="hacking-span">Trained DSP Rank Officers</span>, College Students and Corporate Teams.</p>
    
   <hr></hr>

<ul>
       
<li className="mt-3 text-secondary fade-in-bottom delay04s">Free Training & Internship (for eligible candidates)</li>
    <li className="mt-3 text-secondary delay05s fade-in-bottom">Pre-placement opportunity</li>
<li className="mt-3 text-secondary delay06s fade-in-bottom">Certificate of participation</li>

<li className="mt-3 text-secondary delay07s fade-in-bottom">Access to Premium hacking tools</li>

<li className="mt-3 text-secondary delay08s fade-in-bottom">Access to Premium E-Books</li>

</ul>
{register||search?
<>


</>:
<>
<div className="d-flex mt-4 mb-sm-4  mb-4 mb-lg-0 mb-md-4">
     <button onClick={()=>setregister(true)} className="btn btn-success d-block d-md-none bottom-fixed-button fade-in-bottom delay09s">Register Now for Free</button>

    <button onClick={()=>setregister(true)} className="btn btn-orange-red bottom-fixed-button fade-in-bottom delay09s">Register Now for Free</button>
 </div>
</>}

</div>

<div className="col-lg-5 p-2 h-fit bg-white rounded">
<img className="w-100" src="https://skillsuprise.com/resources/images/trainer.png"/>
</div>
       </div>

    

        </div>



        <div className="container  mt-0 mt-md-5 process justify-content-between   d-flex flex-wrap ">
   

<div className="col-lg-8 col-md-12  p-3 sm-p-0">
<div className="d-flex">

    <div className="p-3">
        <h2>Get Free Training & Internship </h2>
   <p >Attend 5 hour workshop and get a chance to work with CID (Crime Investigation Department)</p>
    
    </div>
</div>
<div className="d-flex animate justify-content-between flex-wrap">

<div className="col-lg-6 col-md-6 col-sm-12 col-12 p-2  mt-2 mb-2">
<div className="d-flex fade-in-bottom delay03s item align-items-center rounded-pill p-2">
<div className="circle me-2">
 <h5 className="mb-0 pb-0">1</h5>
</div>
<div>
<p  className="mb-0 pb-0   ">Register for workshop</p>
</div>
 </div>

</div>


<div className="col-lg-6 col-md-6 col-sm-12 col-12 p-2  mt-2 mb-2">
<div className="d-flex fade-in-bottom delay04s item align-items-center rounded-pill p-2">
<div className="circle me-2">
 <h5 className="mb-0 pb-0">2</h5>
</div>
<p  className="mb-0 pb-0">Attend the workshop</p>
 </div>

</div>



<div className="col-lg-6 col-md-6 col-sm-12 col-12 p-2  mt-2 mb-2">
<div className="d-flex fade-in-bottom delay05s item align-items-center rounded-pill p-2">
<div className="circle me-2">
 <h5 className="mb-0 pb-0">3</h5>
</div>
<p  className="mb-0 pb-0">Attempt final test</p>
 </div>

</div>





<div className="col-lg-6 justify-content-between col-md-6 col-sm-12 col-12 p-2  mt-2 mb-2">
<div className="d-flex fade-in-bottom delay06s  item align-items-center rounded-pill p-2">
<div className="circle me-2">
 <h5 className="mb-0 pb-0">4</h5>
</div>
<p  className="mb-0 pb-0">Get more than 35 marks to get free training & internship</p>
 </div>

</div>


</div>
 

</div>
<div className=" d-none d-sm-none d-md-none d-lg-flex d-xl-flex align-items-center justify-content-center col-lg-4 p-3 d-flex hacker-bg bg-dark text-light col-sm-8 mx-auto col-md-4 col-12  align-items-center justify-content-center">
<img className="bg-dark w-100 rounded" src="https://skillsuprise.com/resources/images/ceh-training-certification.png"></img>
</div>

</div>



<div className="container  py-5 text-center mt-5">
   <div className="text-center">{device==="mobile"?
   <>
   
   <h2>Get Benifits Worth  ₹ 70,000<br></br> For FREE</h2>
   </>:
   <>
   
   <h2>Get Benifits Worth  ₹ 70,000 For FREE</h2>
   </>}
   </div>
    <ThemeDivider/>
    <div className="d-flex animate flex-wrap">


<div className="col-lg-4 p-3">

    <div className="shadow  rounded">
<img src='https://skillsuprise.com/resources/images/ceh-certificate.webp' className='w-100 fade-in-bottom rounded-top'></img>
<div className="p-2">
    <h4 className='fade-in-bottom delay02s'>CEH V12 Training &<br></br> Certification</h4>
    <h5><s className='text-danger fade-in-bottom delay03s'>₹ 30,000</s> FREE</h5>
</div>
    </div>
</div>



<div className="col-lg-4 p-3">

    <div className="shadow rounded">
<img src='https://skillsuprise.com/resources/images/hacking-tools.png' className='w-100 fade-in-bottom rounded delay04s  rounded-top'></img>
<div className="p-2">
    <h4 className='fade-in-bottom delay05s'>Premium Hacking Tools<br></br> & Licenses</h4>
    <h5><s className='text-danger fade-in-bottom delay06s'>₹ 20,000</s> FREE</h5>
</div>
    </div>
</div>


<div className="col-lg-4 p-3">

    <div className="shadow rounded">
<img src='https://skillsuprise.com/resources/images/books-labs.png' className='w-100 delay05s fade-in-bottom rounded-top'></img>
<div className="p-2">
    <h4 className='fade-in-bottom delay06s'>Online Hacking Lab <br></br>& Premium Hacking Books</h4>
    <h5 className='dealy07s fade-in-bottom'><s className='text-danger'>₹ 20,000</s> FREE</h5>
</div>
    </div>
</div>

    </div>
</div>


<div className="container py-5 mt-0">
    <div className="text-center">
        <h1>Learn to Hack From Expert Trainers</h1>
<p className="text-center d-none d-md-block">Get a chance to build professional connections with industry experts</p>
        <div class="divider">
<img src={divider}  alt="divider"></img>
</div>

    </div>



<div className="d-flex animate mt-3 justify-content-center flex-wrap">

<div className="col-lg-2 col-md-6 col-sm-6 col-6 p-2">

<div className=" rounded fade-in-bottom delay04s border">
    <img className="w-100 rounded-top" src="https://skillsuprise.com/resources/images/manoj.png"/>
<div className="p-2 text-center">
<h5>Mr. Manoj Kumar</h5>

<p className="text-small">Founder & CEO <br></br> Skills Uprise</p>
<div className="text-center">

<p className="rounded mt-2 mx-auto rounded-pill bg-primary w-fit text-white px-2 sm-12 pb-0 pb-md-1 ">4+ years</p>

</div></div>
</div>

</div>

    <div className="col-lg-2 col-md-6 col-sm-6 col-6 p-2">

        <div className=" rounded fade-in-bottom delay06s border">
            <img className="w-100 rounded-top" src="https://skillsuprise.com/resources/images/venugopal.png"/>
<div className="p-2 text-center">
<h5>Mr. Venugopal</h5>

<p className="text-small">Sub Inspector <br></br> AP Police</p>
<div className="text-center">

<p className="rounded mt-2 mx-auto rounded-pill bg-primary w-fit text-white px-2 sm-12 pb-0 pb-md-1">19+ years</p>

</div></div>
        </div>

    </div>


    <div className="col-lg-2 col-md-6 col-sm-6 col-6 p-2">

<div className=" rounded border fade-in-bottom delay08s">
    <img className="w-100 rounded-top" src="https://skillsuprise.com/resources/images/bharath.png"/>
<div className="p-2 text-center">
<h5>Mr. Bharath</h5>

<p className="text-small">Founder & CEO <br></br> Lyfaux Technologies</p>
<div className="text-center">

<p className="rounded mt-2 mx-auto rounded-pill bg-primary w-fit text-white px-2 sm-12 pb-0 pb-md-1 ">9+ years</p>

</div></div>
</div>

</div>


<div className="col-lg-2 col-md-6 col-sm-6 col-6 p-2">

<div className=" rounded border fade-in-bottom delay09s">
    <img className="w-100 rounded-top" src="https://skillsuprise.com/resources/images/alekhya.png"/>
<div className="p-2 text-center">
<h5>Ms. Alekhya</h5>

<p className="text-small">Malware Researcher <br></br> LTIMindtree</p>
<div className="text-center">

<p className="rounded mt-2 mx-auto rounded-pill sm-12 bg-primary w-fit text-white px-2 pb-0 pb-md-1 ">5+ years</p>

</div></div>
</div>

</div>

</div>
</div>
    


        <div className="container animate br-5 pb-5 mt-0 mt-0 md-mt-5 pt-0 pt-md-5 mb-0 mb-md-5">
    
        
           
          <div className="d-flex pt-0 pt-md-5 mb-5 flex-wrap justify-content-between">
<div className="col-lg-6">
<img className="w-100 fade-in-left delay1s" src="https://skillsuprise.com/resources/images/notebook.jpg"/>
</div>
<div className="col-lg-6 ps-5 sm-p-0 sm-mt-20">
<h2 className="uppercase fade-in-bottom delay02s">Master Hackers Machine &<br></br>Learn Real Hacking</h2>
            <p className="text-secondary fade-in-bottom delay02s">Learn to hack by working with 100+ hacking tools, techniques and practical labs</p>


<div className="d-flex fade-in-bottom delay03s align-items-center mt-3">
    <CheckBoxRounded className="text-success mr-2"/>
    <h6 className="pb-0 ml-2 mb-0 text-secondary">Live expert lead workshop</h6>
</div>
<div className="d-flex fade-in-bottom delay04s align-items-center mt-3">
    <CheckBoxRounded className="text-success mr-2"/>
    <h6 className="pb-0 ml-2 mb-0 text-secondary">Beginner friendly teaching</h6>
</div>
<div className="d-flex fade-in-bottom delay05s align-items-center mt-3">
    <CheckBoxRounded className="text-success mr-2"/>
    <h6  className="pb-0 mb-0 text-secondary">Real-world hacking demonstration</h6>
</div>

<div className="d-flex fade-in-bottom delay06s align-items-center mt-3">
    <CheckBoxRounded className="text-success mr-2"/>
    <h6  className="pb-0 ml-2 mb-0 text-secondary">Access to hacking Lab</h6>
</div>

<div className="d-flex fade-in-bottom delay07s align-items-center mt-3">
    <CheckBoxRounded className="text-success mr-2"/>
    <h6  className="pb-0 mb-0 text-secondary">Premium tools, softwares & notes</h6>
</div>


<div className="d-flex fade-in-bottom delay08s align-items-center mt-3">
    <CheckBoxRounded className="text-success mr-2"/>
    <h6  className="pb-0 mb-0 text-secondary">Build professional hackers network</h6>
</div>
</div>

          </div>
            
        </div>








        <div className=" container col-lg-9 col-md-10 col-sm-12 col-12 mx-auto   container">



<div className="container hacking-line bg-dark text-light mt-0 md-mt-5 pt-5  pb-5 mb-5 shadow br-5">




<div className="container sm-p-0 d-flex flex-wrap">
    <div className="col-lg-8 col-12">
    <h2 className='sm-22'>Learn Practically & Hack Really!</h2>
<p className="text-light">Real-time and real-world hacking demonstration to teach you how to hack like a pro.</p>

    </div>

    <div className="col-lg-4 mt-sm-3 d-none  align-items-center justify-content-center">
<button onClick={()=>setdemo(true)} className="rounded-circle mt-3 play-hacking btn-lg"><PlayCircle className="text-light"/></button>
    </div>

</div>

</div>





<div className="d-flex br-5 mt-5 flex-wrap text-center flex-wrap justify-content-between">






<div className="col-lg-4 p-3">
    <div className=" p-0 br-5 border ">
    <img src="https://skillsuprise.com/resources/images/hack-system.png" className="w-100 rounded-top"/>
    <div className="p-3">
    <p className="hacking-span w-fit text-light mx-auto my-2">Demo 1</p>
     
    <p >You will learn to hack laptops and systems by using advanced hacking tools and techniques </p>
 
    </div>
      </div>
 
</div>







<div className="col-lg-4 p-3">
    <div className=" p-0 br-5 border ">
    <img src="https://skillsuprise.com/resources/images/hack-wifi.png" className="w-100 rounded-top"/>

    <div className="p-3">
    <p className="hacking-span w-fit text-light mx-auto my-2">Demo 2</p>
     
   
    <p>You will learn to hack wifi's and wireless networks using kali linux and few automated tools</p>
 
    </div>
      </div>
 
</div>


<div className="col-lg-4 p-3">
    <div className=" p-0 br-5 border ">
    <img src="https://skillsuprise.com/resources/images/hack-mobile.png" className="w-100 rounded-top"/>

    <div className="p-3">
    <p className="hacking-span w-fit text-light mx-auto my-2">Demo 3</p>
     
 
    <p>You will learn to hack mobiles using kali linux and latest hacking techniques</p>
 
    </div>
      </div>
 
</div>


<div className="col-lg-4 p-3">
    <div className=" p-0 br-5 border ">
    <img src="https://skillsuprise.com/resources/images/secure-company.png" className="w-100 rounded-top"/>

    <div className="p-3">
    <p className="hacking-span w-fit text-light mx-auto my-2">Demo 4</p>
     
  
    <p>You will learn to hack real websites with the modern hacking techniques</p>
 
    </div>
      </div>
 
</div>




<div className="col-lg-4 p-3">
    <div className=" p-0 br-5 border ">
    <img src="https://skillsuprise.com/resources/images/fb-hacking.png" className="w-100 rounded-top"/>

    <div className="p-3">
    <p className="hacking-span w-fit text-light mx-auto my-2">Demo 5</p>
   
    <p>You will learn the best 100% successful way to hack your target facebook accounts</p>
 
    </div>
      </div>
 
</div>




<div className="col-lg-4  p-3">
    <div className=" p-0 br-5 border ">
    <img src="https://skillsuprise.com/resources/images/instagram-hacking.png" className="w-100 rounded-top"/>

    <div className="p-3">
    <p className="hacking-span w-fit text-light mx-auto my-2">Demo 6</p>
     
    <p>You will learn the right techniques to hack into instagram accounts with best hacking tools</p>
 
    </div>
      </div>
 
</div>

</div>


    </div>




    <div className="mt-5 mb-5">


<div className="container  d-flex flex-wrap justify-content-between">

<div className="col-lg-6   br-5 p-4 sm-p-0">
<img src="https://skillsuprise.com/resources/images/workshop-certificate.png" className="w-100"/>
       
</div>

<div className="col-lg-6 sm-mt-20  br-5 p-4 sm-p-0">
<h2>Get Certified with Us !</h2>
<p className="text-secondary">Stand out of the crowd with our certificate. Our certificate is an X-Factor to your resume to prove your skills to land your dream job in cyber security.</p>


<div className="d-flex align-items-center mt-3">
    <CheckBoxRounded  className="text-success mr-2"/>
    <h6  className="pb-0 mb-0 text-secondary">Certificate of Participation</h6>
</div>

<div className="d-flex align-items-center mt-3">
    <CheckBoxRounded  className="text-success mr-2"/>
    <h6  className="pb-0 mb-0 text-secondary">Certificate of Training (for eligible students)</h6>
</div>

<div className="d-flex align-items-center mt-3">
    <CheckBoxRounded  className="text-success mr-2"/>
    <h6  className="pb-0 mb-0 text-secondary">Internship Certificate(for eligible students)</h6>
</div>

<div className="d-flex align-items-center mt-3">
    <CheckBoxRounded  className="text-success mr-2"/>
    <h6  className="pb-0 mb-0 text-secondary">Recommendation Letter (top performers)</h6>
</div>


<div className="mt-4">
    <button onClick={()=>setregister(true)} className="btn btn-orange-red ">Register Now for FREE !</button>
</div>
</div>
</div>
    
    </div>





  











 

    
  


<div className=" mt-4">
    <WorkshopReviews/>

    <div className="text-center">
    <button onClick={()=>setregister(true)} className="btn fade-in-bottom d-none delay09s me-4 btn-success"><WhatsApp/> Contact Us</button>
  
    </div>
</div>
        


</div>
        
        </div>
  )
}

export default HackingWorkshop