import { Chip, CircularProgress } from '@mui/material'
import axios from 'axios'
import React from 'react'

import { useEffect } from 'react'
import { useState } from 'react'


import { useContext } from 'react'
import { Link } from 'react-router-dom'

import styled from 'styled-components'
import { AuthContext } from '../../context/AuthContext'
import ENDPOINT from '../../data/ENDPOINT'
import SecurityHeaders from '../../data/SecurityHeaders'
import SetPage from '../../data/SetPage'
const PaymentsPage = ({account}) => {

const {user} = useContext(AuthContext)


 const [loading,setloading] = useState(true)
    const [payments,setpayments] = useState(null);
    const  GetPayments = async ()=>{
       

        const data = new FormData()
        data.append("session",user.SessionId)


        const res = await axios.post(ENDPOINT+"get-payments.php",data,SecurityHeaders)


        if(res){
            if(res.data.status==="success"){
                
                setpayments(res.data.data)
                setloading(false)
            }
             //  //console.log("payments "+JSON.stringify(res))
        setloading(false)
        }

        
        }    ;

    useEffect(()=>{
       
    SetPage("Payments")
     GetPayments()
           
    }
    
    ,[])
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    




    return (


    
<PaymentsStyled className="pt-2 pt-3  w-100 pb-5">


<div className="container   min-vh-100">
    



{loading?
<>
<div className="justify-content-center pt-5 pb-5 d-flex align-items-center">
<div className="text-center">
<CircularProgress/>
<p>Loading...</p>
</div>
</div>

</>:
<>
{ payments?payments[0].status!=="error"?payments[0].data!==null?
<>
<div className="flex flex-wrap">
{payments.map(payment=>(




<>

<div className="col-lg-4 p-4 sm-p-0  sm-mb-3 mb-3">

<div className="payment-item h-fit border ">
<img className="w-100 " src={payment.course_cover_url} alt=""></img>
<div className="enrolled-info  p-2">
<h5 className="truncate truncate2">{payment.course_title}</h5>
<div className="d-flex  mt-3 mb-2">

<p><b>Amount : </b> ₹ {payment.purchase_price} </p>

<Chip className="bg-success text-white ms-3" size="small" label={payment.purchase_status}/>
</div>



<div className="">
<p className="w-100"> <b>{payment.purchase_status==="success"?"Registered on :":"Initiated on:"}</b> {payment.date}</p>


</div>






<div className="d-flex mt-3 w-100 justify-content-between">
    <Link to="/contact" className="btn btn-link">Contact Us</Link>
{payment.purchase_status==="pending"?

<Link to={"/payment/"+payment.purchase_id} className="btn btn-primary  rounded-pill btn-sm" target="_blank">Continue Payment</Link>
:
<Link to={"payment/"+payment.purchase_id} className="btn btn-link " target="_blank">View Receipt</Link>
}
</div>
</div>


</div>

</div>




</>


))}
</div>
</>:"error":"batch unset"
:

<div className="text-center container-fluid bg no-courses p-20">

<div className="col-lg-12 justify-center text-center text-dark col-md-12 col-sm-12">


<div className="col-lg-5 mx-auto">
<img className="w-50" src="https://skillsuprise.com/resources/images/empty-cart.svg" alt=""></img>

<h2 className="mt-5">No payments !</h2>
<h6>It seems like you have not urchased any courses or student pass</h6>

</div>

   
</div>


</div>





}



</>}


</div>





</PaymentsStyled>

    )
}

export default PaymentsPage


export const PaymentsStyled = styled.div`


.plans-section{
    background-image: none;
}
li{
    color: #202020;
}


`




