import React from 'react'
import onlineicon from '../../assets/icons/online-class.svg'
import offlineicon from '../../assets/icons/offline-class.png'
import recordings from '../../assets/icons/recordings.svg'
import group from '../../assets/icons/group.svg'
import tasks from '../../assets/icons/tasks.svg'
import community from '../../assets/icons/community-icon.svg'
import assignments from '../../assets/icons/assignments-icon.svg'
import downloadicon from '../../assets/icons/download.svg'
import placement from '../../assets/icons/placement.svg'
import feedback from '../../assets/icons/feedback.svg'
import revision from '../../assets/icons/revision.svg'
import divider from '../../assets/bg/divider.png'
import classrecordings from '../../assets/icons/class-recordings.svg'
import { useEffect } from 'react'
import { Helmet } from 'react-helmet'
const FeaturesList = ({mode,course}) => {

     const Animate = ()=>{
          const items = document.getElementsByClassName("animate")
         
          const screenheight = window.innerHeight;
          for (var i =0;i<=items.length;i++){
        const currentelement = items[i]
        
        if(currentelement){
          const elementposition = parseInt(currentelement.getBoundingClientRect().top.toFixed())
         
          if(elementposition<screenheight){
            if(items[i].classList.contains("animation-active")){
          
            }else{
              items[i].classList.add("animation-active")
            }
          }
        
        }

        
        
        
           
          }
        }
   
        
        useEffect(()=>{
//window.addEventListener("scroll",Animate)
        },[])

  return (
    <div className="w-100 features-list   bg-dark">
<Helmet>‍
        <title>Explore Our Website's Features: Unleash the Full Potential of Your Learning Experience - Skills Uprise</title>‍
        <meta name="description" content="Discover Our Website's Features: Explore a World of Possibilities - From Learning Resources to Interactive Tools. Maximize Your Experience Today!" />       
        <meta name="twitter:card" content="" />   
        <meta name="twitter:site" content="@skillsuprise" />    
        <meta name="twitter:creator" content="@skillsuprise" />                
        <meta name="twitter:title" content="" />   
        <meta name="twitter:description" content="" />  
        <meta name="twitter:image" content=""/>     
        <meta property="og:title" content="" />  
        <meta property="og:description" content="" />        
        <meta property="og:image" content=""/>
        <meta property="og:url" content=""/>
        <meta property="og:site_name" content="Skills Uprise" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta property="fb:app_id" content="ID_APP_FACEBOOK" />
      </Helmet>

<div className="text-center">
     <h1 className="prime-title secondary-text">Extra-Ordinary Features</h1>
     <h2 className="sec-title primary-text">For Exceptional Results</h2>

     <div class="divider">
<img src={divider}  alt="divider"></img>
</div>
</div>

<div className="container animate sm-p-0 flex-wrap d-flex justify-content-between">


{mode==="free"&&course.course_category!="integrated"?
<>


</>:
<>

<div className="col-lg-4 fade-in-bottom delay2s anim-3-1 col-md-6 col-sm-12 col-12 p-3">
<div className="feature-item slide-in-down shadow d-flex p-3 br-4">
     <img src={offlineicon}/>
<div>
<h5 className="m-0 p-0">Offline trainings</h5>
<p>To learn under our guidance</p>
</div>
</div>
</div>


<div className="col-lg-4 delay2s  fade-in-bottom anim-3-2 col-md-6 col-sm-12 col-12 p-3">
<div className="feature-item  slide-in-down shadow d-flex p-3 br-4">
     <img src={onlineicon}/>
<div>
<h5 className="m-0 p-0">Live online classes</h5>
<p>To learn from home</p>
</div>
</div>
</div>



<div className="col-lg-4 delay2s fade-in-bottom anim-3-3 col-md-6 col-sm-12 col-12 p-3">
<div className="feature-item slide-in-down  shadow d-flex p-3 br-4">
     <img src={classrecordings}/>
<div>
<h5 className="m-0 p-0">Daily class recordings</h5>
<p>To revise concepts</p>
</div>
</div>
</div>


</>}

<div className="col-lg-4  delay2s  fade-in-bottom anim-3-4 col-md-6 col-sm-12 col-12 p-3">
<div className="feature-item shadow d-flex p-3 br-4">
     <img src={recordings}/>
<div>
<h5 className="m-0 p-0">Pre-recorded videos</h5>
<p>Learn anytime, anywhere</p>
</div>
</div>
</div>


<div className="col-lg-4  delay3s  fade-in-bottom anim-3-5 col-md-6 col-sm-12 col-12 p-3">
<div className="feature-item shadow d-flex p-3 br-4">
     <img src={group}/>
<div>
<h5 className="m-0 p-0">Group discussions</h5>
<p>To develop soft skills</p>
</div>
</div>
</div>



<div className="col-lg-4  delay3s  fade-in-bottom anim-3-6 col-md-6 col-sm-12 col-12 p-3">
<div className="feature-item shadow d-flex p-3 br-4">
     <img src={community}/>
<div>
<h5 className="m-0 p-0">5k+ Students community</h5>
<p>To learn together</p>
</div>
</div>
</div>



<div className="col-lg-4  delay3s  fade-in-bottom anim-3-7 col-md-6 col-sm-12 col-12 p-3">
<div className="feature-item shadow d-flex p-3 br-4">
     <img src={tasks}/>
<div>
<h5 className="m-0 p-0">Teams & group tasks</h5>
<p>Learn real skills for industry needs</p>
</div>
</div>
</div>



<div className="col-lg-4 delay3s  fade-in-bottom anim-3-8 col-md-6 col-sm-12 col-12 p-3">
<div className="feature-item shadow d-flex p-3 br-4">
     <img src={revision}/>
<div>
<h5 className="m-0 p-0">Live course revision</h5>
<p>To master skills better</p>
</div>
</div>
</div>



<div className="col-lg-4  delay4s  fade-in-bottom anim-3-9 col-md-6 col-sm-12 col-12 p-3">
<div className="feature-item shadow d-flex p-3 br-4">
     <img src={assignments}/>
<div>
<h5 className="m-0 p-0">Assignments & realtime projects</h5>
<p>To make you industry ready</p>
</div>
</div>
</div>



<div className="col-lg-4  delay4s  fade-in-bottom anim-3-10 col-md-6 col-sm-12 col-12 p-3">
<div className="feature-item shadow d-flex p-3 br-4">
     <img src={feedback}/>
<div>
<h5 className="m-0 p-0">Individual feedback</h5>
<p>To improvise your growth</p>
</div>
</div>
</div>



<div className="col-lg-4 delay4s  fade-in-bottom anim-3-11 col-md-6 col-sm-12 col-12 p-3">
<div className="feature-item shadow d-flex p-3 br-4">
     <img src={placement}/>
<div>
<h5 className="m-0 p-0">Placement trainings</h5>
<p>To easily land your dream job</p>
</div>
</div>
</div>



<div className="col-lg-4 delay4s fade-in-bottom anim-3-12 col-md-6 col-sm-12 col-12 p-3">
<div className="feature-item shadow d-flex p-3 br-4">
     <img src={downloadicon}/>
<div>
<h5 className="m-0 p-0">Downloadable content</h5>
<p>Learn anywhere, anytime</p>
</div>
</div>
</div>
</div>
    </div>
  )
}

export default FeaturesList