import React, { useState } from 'react'
import DetectDevice from '../../data/DetectDevice'
import ModalStyle from '../../data/ModalStyle'
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { Link } from 'react-router-dom';
import { Close } from '@material-ui/icons';
const AdditionalOptions = () => {
     const device = DetectDevice()


     const [loginprompt,setloginprompt] = useState(false)
  return (
    <>

<Modal
  open={loginprompt}
  onClose={()=>setloginprompt(false)}

>
  <Box sx={ModalStyle}  className="col-lg-3 col-md-4 br-5 col-sm-6 col-8 p-2">
<div className="d-flex justify-content-between">
     <h4>Login into your account</h4>
     <Close className="text-danger" type="button" onClick={()=>setloginprompt(false)}/>
</div>
<hr/>
    <Typography id="modal-modal-description">
    <p>Login into your account to access this section</p>


    <div className="d-flex mt-4 justify-content-between">

  <button className="btn col-5 btn-outline-danger rounded-pill" onClick={()=>setloginprompt(false)}>Cancel</button>
  <Link to="/login" className="btn col-5 btn-primary rounded-pill" >Login</Link>
    </div>
    </Typography>
  </Box>
</Modal>


<div className="p-0 sm-mb-20   w-100 grid grid-2 lg-grid-2">






<div className="   text-white mock-bg bg-image sm-mt-0 br-5 p-3 d-flex flex-wrap">
     <div className="">
     <h4 className="sm-16">Mock Tests &<br/>
      Challenges</h4>

     <h6  className="sm-10 d-none ">Cracking a job made much more easier !</h6>

<div className="mt-3">
     <Link to="/mock-tests" className="btn sm-12  btn-sm btn-outline-light rounded-pill">View Tests</Link>
</div>
     </div>

</div>

<div className="   text-white interview-bg bg-image  sm-mt-0 br-5 p-3 d-flex flex-wrap">
     <div className="">
     <h4 className="sm-16">Interview <br/> Preparation</h4>

     <h6  className="sm-10 d-none ">Be industry ready with on-demand skills!</h6>

<div className="mt-3">
     <Link to="/interview-preparation"  className="btn sm-12  btn-sm btn-outline-light rounded-pill">Start Preparation</Link>
</div>
     </div>

</div>


<div className="   text-white coding-bg bg-contain sm-mt-0 br-5 p-3 d-flex flex-wrap">
     <div className="">
     <h4 className="sm-16">Course<br/> Cheat Sheets</h4>

     <h6  className="sm-10 d-none">Share knowledge & make money !</h6>

<div className="mt-3">
     <Link to="/cheat-sheets" className="btn sm-12 btn-sm btn-outline-light rounded-pill">View Cheat Sheets</Link>
</div>
     </div>

</div>






<div className="  text-white bg-image quiz-bg br-5 p-3 d-flex flex-wrap">
     <div className="">
     <h4 className="sm-16">Technical<br/> Quizzes</h4>

     <h6  className="sm-10 d-none ">Win exciting cash rewards everyday!</h6>

<div className="mt-3">
     <Link to="/technical-quizzes" className="btn btn-sm sm-12  btn-outline-light rounded-pill">View Quizzes</Link>
</div>
     </div>

</div>


</div>

    </>
  )
}

export default AdditionalOptions