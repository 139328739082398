import React from 'react'

const FormatViews = ({views}) => {

let count = parseInt(views)

let view = views

if(count){
    if(count>1000){
        view = (count/1000)
        }
        
        var p = Math.pow(10, 1);
            return (Math.round(view * p) / p)+"k";
        
}else{
    return 0
}

}

export default FormatViews