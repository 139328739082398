import axios from 'axios'
import ENDPOINT from '../data/ENDPOINT'
import SecurityHeaders from '../data/SecurityHeaders'

const GetStudentsTimeline = async ({payload})=>{

const data = new FormData()
data.append("course_url",payload.course_url)
if(payload.limit){
     data.append("limit",payload.limit)
}
data.append("session",payload.user)
const res = await 
axios.post(ENDPOINT+`students-posts.php`,data,SecurityHeaders)
if(res){
    
     return res.data
}

}

export default GetStudentsTimeline