import axios from 'axios'
import ENDPOINT from '../data/ENDPOINT'
import SecurityHeaders from '../data/SecurityHeaders'

const GetMyWorkshops = async ({user})=>{

const data = new FormData()

data.append("session",user.SessionId)
const res = await 
axios.post(ENDPOINT+`get-my-workshops.php`,data,SecurityHeaders)
if(res){
    
     return res.data
}

}
export default GetMyWorkshops