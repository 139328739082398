import React from 'react'
import { useEffect } from 'react'
import SetPage from '../../data/SetPage'
import { Helmet } from 'react-helmet'

const RefundPolicyPage = () => {

    
  useEffect(() => {
    window.scrollTo(0, 0)
    SetPage("Refund Policy")
  }, [])


    return (
       <>
       <Helmet>‍
        <title>Refund Policy - Skills Uprise</title>‍
        <meta name="description" content="Skills Uprise" />       
        <meta name="twitter:card" content="" />   
        <meta name="twitter:site" content="@skillsuprise" />    
        <meta name="twitter:creator" content="@skillsuprise" />                
        <meta name="twitter:title" content="" />   
        <meta name="twitter:description" content="" />  
        <meta name="twitter:image" content=""/>     
        <meta property="og:title" content="" />  
        <meta property="og:description" content="" />        
        <meta property="og:image" content=""/>
        <meta property="og:url" content=""/>
        <meta property="og:site_name" content="Skills Uprise" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta property="fb:app_id" content="ID_APP_FACEBOOK" />
      </Helmet>
<div className="w-100">


<div className="bg-black p-5 text-white sm-p-15">
<div className="flex-wrap container-xl">


<div className="col-lg-7">

<h2 className="sm-25 mt-5">Refund & Cancellation Policy</h2>


<h6 className="font-weight-normal">Thanks for using Skills Uprise. Please read our refund policy carefully to understand how and when you can get your money back and know more about the circumstances where you can request for a refund.</h6>

</div>

<div className="col-lg-4">

</div>


</div>
</div>


               
               <div className="pb-5 pt-5 container-xl">




<div id="intro" className="account">

<p>
For training programs offered on Skills Uprise, except for Offline Training's and Job Oriented Specializations, a user may request a refund anytime before their chosen batch commencement date. A transaction processing charge of 20% of the training fee paid by the user will be deducted and the remaining will be refunded within 15 working days (Monday to Friday, excluding national holidays) of the refund request being raised by the user.
No refund will be provided for the Offline Training's or Job Oriented Specializations offered on Skills Uprise once the payment has been made.

If a user is enrolled in a training that has been withdrawn by Skills Uprise, he/she will be entitled to 100% refund within 15 working days (Monday to Friday, excluding national holidays) of the training program being withdrawn and the refund would be initiated by Skills Uprise.


<h5 className="mt-4">7 Day's money back policy </h5>
A user can also request for a refund after joining the training program within 7 days of registering for the course. A transaction processing charge of 20% of the training fee paid by the user will be deducted and the remaining will be refunded within 15 working days (Monday to Friday, excluding national holidays) of the refund request being raised by the user. However,  once the user has made the payment and has passed more than 7 days , no refund would be permitted. 
</p>

</div>



               </div>
               
               
               
          

  </div>
       </>
    )
}

export default RefundPolicyPage
