import React, { useState } from 'react'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import ModalStyle from '../../data/ModalStyle';
const PremiumSale = () => {

    const [open, setOpen] = useState(false);
    const handleOpen = () => {
      setOpen(true);
    };
    const handleClose = () => {
      setOpen(false);
    };
  
    const [qrCode,setQrCode] = useState()
  
  
    const Plan = (payload)=>{
      setQrCode(payload)
    }
  return (
    <div>

<Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box className=' rounded p-3 col-5' sx={ModalStyle}>
       <div className='col-12'>
       <h2 className='text-center'>Scan QR </h2>
       </div>

          <div className='mt-3 col-12'>
            <h1 className='text-center'>{qrCode}</h1>

          </div>
       
        </Box>
      </Modal>
    <div style={{ backgroundColor: "#e2e2eb" }} className='mx-auto py-5'>
          <div className='text-center'>
            <h1>Premium plans</h1>
          </div>
          <div className='d-flex justify-content-between container mx-auto flex-wrap'>
            <div className='col-lg-3 col-md-8 col-sm-12 col-12 bg-white rounded-2 mt-5 p-3'>
              <div>
               <div className='text-center'>
                <h4>Basic</h4>
                <h6 style={{color:"#a038f2"}}>₹ 2,000</h6>
               </div>
               
               <div className='col-lg-9 col-md-8 col-sm-12 col-12 mx-auto h-50'>
               <ul>
                 <li> Course Acess</li>
                 <li> Month training + Internship</li>
                 <li> Project</li>
                 <li> Training certificate</li>
                 <li> Internship Certificate</li>
                 <li> Lifetime course access</li>
                 <li> Placement training</li>
                 <li> Mock Interview</li>
                </ul>
               </div>
                 <div className='text-center '>
                 <button onClick={()=>{handleOpen();Plan("Om Nama Sivaya")}} className='btn text-white' style={{backgroundColor:"#a038f2"}}>READ MORE</button> 
                  </div> 
              </div>
            </div>

            <div className='col-lg-3 col-md-8 col-sm-12 col-12 bg-white rounded-2 mt-5 p-3'>
              <div>
               <div className='text-center'>
               <h6 style={{color:"#a038f2"}}># 2</h6>
                
                <h4>Gold</h4>
               </div>
               
               <div className='col-lg-9 col-md-8 col-sm-6 col-6 mx-auto h-50'>
               <ul>
                 <li>20 Courses</li>
                 <li>2 Internship​s</li>
                 <li> 3 Project​s</li>
                 <li>Training certificate</li>
                 <li>Internship Certificate</li>
                 <li>Lifetime course access</li>
                 <li>Placement training</li>
                 <li>Mock Interview</li>
                </ul>
               </div>
                 <div className='text-center '>
                 <button  onClick={()=>{handleOpen();Plan("Om Sai Ram")}} className='btn  text-white' style={{backgroundColor:"#a038f2"}}>READ MORE</button> 
                  </div> 
              </div>
            </div>

            <div className='col-lg-3 col-md-8 col-sm-12 col-12 bg-white rounded-2 mt-5 p-3'>
              <div>
               <div className='text-center'>
               <h6 style={{color:"#a038f2"}}>#3</h6>
                <h4>Premium</h4>
               </div>
               
               <div className='col-lg-9 col-md-8 col-sm-6 col-6 mx-auto h-50'>
               Identify priority areas and determine how to measure them
               </div>
                 <div className='text-center text-white'>
                 <button  onClick={()=>{handleOpen();Plan("Govinda Govindaa")}} className='btn text-white mt-5' style={{backgroundColor:"#a038f2"}}>READ MORE</button> 
                  </div> 
              </div>
            </div>
          </div>
        </div>

    </div>
  )
}

export default PremiumSale