import React, { useEffect, useRef, useState } from 'react';
import ShortItem from './ShortItem';
import { ArrowUpward, ArrowDownward } from '@mui/icons-material';

const ShortsList = ({ videos, account }) => {
  const containerRef = useRef(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const container = containerRef.current;
    const videoElements = container.querySelectorAll('video');

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const video = entry.target;
          if (entry.isIntersecting) {
            video.play();
            setCurrentIndex(Array.from(videoElements).indexOf(video));
          } else {
            video.pause();
          }
        });
      },
      { threshold: 1.0 }
    );

    videoElements.forEach((video) => observer.observe(video));

    return () => {
      videoElements.forEach((video) => observer.unobserve(video));
    };
  }, [videos]);

  useEffect(() => {
    const container = containerRef.current;
    const videoElements = container.querySelectorAll('video');
    if (videoElements[currentIndex]) {
      container.scrollTo({
        top: videoElements[currentIndex].offsetTop,
        behavior: 'smooth',
      });
      videoElements.forEach((video, index) => {
        if (index === currentIndex) {
          video.play();
        } else {
          video.pause();
        }
      });
    }
  }, [currentIndex]);

  const handleUpClick = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const handleDownClick = () => {
    if (currentIndex < videos.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  return (
    <div className="reels-list position-relative">
      <div className="reels-desktop-actions d-none d-md-block">
        <div>
          <ArrowUpward className='click' onClick={handleUpClick} />
        </div>
        <div className="mt-3">
          <ArrowDownward className='click' onClick={handleDownClick} />
        </div>
      </div>
      <div className="video-container" ref={containerRef}>
        {videos.map((video, index) => (
          <ShortItem key={index} account={account} video={video} />
        ))}
      </div>
    </div>
  );
};

export default ShortsList;
