import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import DetectDevice from '../../data/DetectDevice'
import SetPage from '../../data/SetPage'
import UserAccount from '../account/UserAccount'
import PersonalBadges from '../badges/PersonalBadges'
import QuizSection from '../quiz/QuizSection'
import DashboardCourseProgress from './DashboardCourseProgress'
import refer from '../../assets/images/REFER.svg'
import WorkshopsSuggestions from './WorkshopsSuggestions'
import referbanner from '../../assets/images/referral-banner.svg'
import WhatsappBotActivate from '../whatsapp/WhatsappBotActivate'
import VideosSlider from '../tutorials/VideosSlider'
import HackingInternshipBanner from './HackingInternshipBanner'
import CommunitySuggestions from '../community/CommunitySuggestions'
import Connections from '../connections/Connections'
import { Helmet } from 'react-helmet'
import GettingStartedDashboard from '../intros/GettingStartedDashboard'
import GetWalkthroughs from '../../actions/GetWalkthroughs'
import CourseSuggestions from '../community/CourseSuggestions'
import VideoRecommendations from './VideoRecommendations'
import DashboardCarousel from './DashboardCarousel'
import CollegesBusinessCta from '../home/CollegesBusinessCta'
import DashboardPlacements from './DashboardPlacements'
import DashboardPopularCourses from './DashboardPopularCourses'
import ReviewVideosSlider from './ReviewVideosSlider'
import ContactSection from '../../components/ContactSection'
import DashboardCta from './DashboardCta'
import Gallery from '../gallery/Gallery'
import DashboardBanner from './DashboardBanner'
const Dashboard = ({account,page}) => {

  const device = DetectDevice()

  const [access,setaccess] = useState(false)
  useEffect(()=>{
    window.scrollTo(0,0)
 SetPage("Dashboard")

 if(account&&account.purchases&&account.purchases.length>1){

  setaccess(true)
}

  },[])
  return (
  <>
  <Helmet>‍
        <title>Dashboard</title>‍
        <meta name="description" content="Login into your account and learn the best skills from our curated courses" />       
        <meta name="twitter:card" content="" />   
        <meta name="twitter:site" content="@skillsuprise" />    
        <meta name="twitter:creator" content="@skillsuprise" />                
        <meta name="twitter:title" content="" />   
        <meta name="twitter:description" content="" />  
        <meta name="twitter:image" content=""/>     
        <meta property="og:title" content="" />  
        <meta property="og:description" content="" />        
        <meta property="og:image" content=""/>
        <meta property="og:url" content=""/>
        <meta property="og:site_name" content="Skills Uprise" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta property="fb:app_id" content="ID_APP_FACEBOOK" />
      </Helmet>
  {account?
  <>
  <div className="grey-body ">

 <div className="d-flex justify-content-between  container sm-p-0">


  <div className="col-lg-7 col-12 sm-p-0">

{device==="mobile"?
<>
<DashboardCarousel/><div className="sm-p-15 pt-1">

{/* <video autoplay muted src="https://skillsuprise.com/data/videos/engagement.mp4" className='w-100'></video> */}

<div className="mt-2">

</div>
<DashboardPlacements/>
<DashboardPopularCourses/>


</div>

<div className="sm-p-15">
<DashboardBanner/>
</div>


<div className="sm-p-15 mt-5">
<ReviewVideosSlider header={true}/>


</div>


<div className="mb-5">
<DashboardCta/>
</div>

<Gallery limit={device==="mobile"?8:9}/>


  <div className="sm-p-15 my-5">
  <Link to="/referral-program" className="d-flex align-items-center">
    <img className="w-100 br-5  " src={refer}/>
  
    </Link>
  </div>




</>:
<>

<UserAccount account={account} />


<div className="bg-white mt-3 rounded">

<DashboardCourseProgress/>
</div>


<div className="mt-3 ">
<div className="bg-white p-3">

  <h4>Latest Student Reviews</h4>
<ReviewVideosSlider/>
</div>
</div>





<div className=" mb-0 mb-md-5 mt-4 bg-white rounded">
<GettingStartedDashboard/>
</div>
</>}
  </div>

{device==="mobile"?
<>


</>:
<>

<div className="col-lg-4 p-0">

{access?<> <div className="bg-white p-3">


<CourseSuggestions limit={3}/>




</div> </>:
<>
<Link to="/referral-program" className="d-flex mt-3 align-items-center">
<img className="w-100 br-5  " src={refer}/>

</Link>


</>}

{account?
<div className="p-3 bg-white mt-3">
<CommunitySuggestions account={account}/>
</div>
:null}

{access?
<>

<Link to="/referral-program" className="d-flex mt-3 align-items-center">
<img className="w-100 br-5  " src={refer}/>

</Link>

</>:
<></>}

<div className="mt-3   d-flex">
  <Link to="/ethical-hacking-internship" className="d-flex align-items-center">
  <img className="w-100 br-5  " src="https://skillsuprise.com/resources/images/hacking-internship.png"/>

  </Link>

 
</div>


</div>
</>}
 </div>
  </div>
  
  
  
  </>
  :null}

  </>
  )
}

export default Dashboard