import React from 'react'
import Localbase from 'localbase'
import ENDPOINT from '../../data/ENDPOINT'
import axios from 'axios'
import SecurityHeaders from '../../data/SecurityHeaders'
const Logout = async ({payload}) => {
 
  const db = new Localbase("db")

const data = new FormData()
data.append("session",payload.user)
      const res = await axios.post(ENDPOINT+"logout.php",data,SecurityHeaders)


      if(res){

          if(res.data[0].status==="loggedout"){
            db.collection("paths").doc("account").delete().then(status=>{

            }).catch(error=>{

            })
            db.collection("paths").doc("enrolled_courses").delete().then(status=>{

            }).catch(error=>{
              
            })
            db.collection("paths").doc("my_course").delete().then(status=>{

            }).catch(error=>{
              
            })
            db.collection("paths").doc("my_courses").delete().then(status=>{

            }).catch(error=>{
              
            })
            db.collection("paths").doc("my_workshops").delete().then(status=>{

            }).catch(error=>{
              
            })
            db.collection("paths").doc("notifications").delete().then(status=>{

            }).catch(error=>{
              
            })
            
               localStorage.setItem("user", null);

                 localStorage.setItem("msg", null);
                 window.location.replace("/");
          }else{
            window.location.replace("/")
               return(res.data)
          }
          
      }
          
            
           
           
                
      
          
                      
     
      
      
    
    
  
  
  
  
  
  
  
  
    
}

export default Logout