import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import defaultdp from "../../assets/user.png";
import CaiLeaderboard from "./CaiLeaderboard";
import CaiReferrals from "./CaiReferrals";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import ApplyCai from "./ApplyCai";
import { useRef } from "react";
import DownloadIcon from "@mui/icons-material/Download";
import dateFormat from "dateformat";
import coinsicon from "../../assets/money.svg";
import sessionicon from "../../assets/sessions.svg";
import influencedicon from "../../assets/influenced.svg";
import cairefericon from "../../assets/caireferral.svg";
import workshopicon from "../../assets/workshopicon.svg";
import tutorialicon from "../../assets/tutorialsicon.svg";
import Lock from "@mui/icons-material/Lock";

import Tooltip from "@mui/material/Tooltip";
import { Avatar } from "@mui/material";
import axios from "axios";
import ENDPOINT from "../../data/ENDPOINT";
import SecurityHeaders from "../../data/SecurityHeaders";
import styled from "styled-components";
const CampusAmbassador = ({
  cai,
  page,
  referrals,
  leaderboard,
  milestones,
}) => {
  const [showApplication, setshowApplication] = useState(false);

  const [show, setshow] = useState(false);
  const [modal, setModal] = useState(false);

  const [milestonestatus, setmilestonestatus] = useState(false);
  const [target, setTarget] = useState(null);
  const ref = useRef(null);

  const handleClick = (event) => {
    setmilestonestatus(!milestonestatus);
    setTarget(event.target);
  };

  const handleShow = () => {
    setshow(true);
  };
  const handleClose = () => {
    setshow(false);
  };
  const [report, setReport] = useState("");
  const [batchId, setBatchId] = useState(666);
  const [reports, setReports] = useState("");

  const submitAmbassador = async (e) => {
    e.preventDefault();
    setuploading(true)
    const data = new FormData();
    const user = JSON.parse(localStorage.getItem("user"));
    data.append("resume", report);
    data.append("batch_id", batchId);
    data.append("session", user.SessionId);

    const res = await axios.post(
      ENDPOINT + "ambassador-upload-report.php",
      data,
      SecurityHeaders
    );
if(res){
setuploading(false)
}
    if (res && res.data.status === "success") {
      getReports()
      setshow(false)
    }
  };

  const getReports = async () => {
    const data = new FormData();
    const user = JSON.parse(localStorage.getItem("user"));
    data.append("session", user.SessionId);

    const res = await axios.post(
      ENDPOINT + "get-ambassador-reports.php",
      data,
      SecurityHeaders
    );
    if (res && res.data && res.data.status == "success") {
      setReports(res.data.data);
    
    }
  };
  useEffect(() => {
    getReports();
  }, []);

  const [uploading,setuploading] = useState(false)

  return (
    <>
      {/* <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Internship Application</Modal.Title>
        </Modal.Header>

        <div className="p-3">
          <ApplyCai />
        </div>
      </Modal> */}

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Submit Report</Modal.Title>
        </Modal.Header>
        <div className="p-3">
          <ApplyInternshipStyled>
            <form onSubmit={submitAmbassador} className="position-relative">
             
              <input
                type="file"
                className=""
                placeholder="Photo/Video"
                accept="application/pdf"
                onChange={(e) => setReport(e.target.files[0])}
              />
              <br />

              <button
                className="btn btn-primary w-75 mx-auto mt-3"
                type="submit"
              >
                submit
              </button>
            </form>
          </ApplyInternshipStyled>
        </div>
      </Modal>

      <div className=" bg-dark py-5">
        <div className="wallet-crumb container flex-wrap d-flex   justify-content-between">
          <div className="col-lg-6 text-light">
            <h2 className="ambassador-title d-none">
              Welcome Campus Ambassador !
            </h2>
            <div className="flex mt-3 mb-5 align-items-center">
              <Avatar
                src={cai.profile_pic ? cai.profile_pic : defaultdp}
                className="userdp"
              />

              <div className="ms-2">
                <h4>{cai.first_name + " " + cai.last_name}</h4>
                <h6>CAI-ID : {cai.id}</h6>
              </div>
            </div>

            <hr />
            <div className="mb-50">
              <h4>Total Earnings</h4>
              <h2 className="mb-1">₹ {cai.earnings ? cai.earnings : "0"}</h2>

              <div className="flex mt-3">
                <span className="border-left-red border-right-red p-2 bg-danger mr-3 mt-0">
                  ₹ {cai.balance ? cai.balance : "0"} Balance
                </span>
                <span className="border-left-red border-right-red p-2 bg-dark mt-0">
                  SkillsUprise{cai.id}
                </span>
              </div>
            </div>
          </div>

          <div className="col-lg-5 col-sm-10   flex-wrap justify-content-evenly  p-0 fit-content mt-5 mt-md-4 mt-lg-0 mt-sm-4">
            <div className="shadow bg-light p-3 rounded fit-content col-lg-5 p-10 col-sm-5 col-5 mb-20 m-2">
              <h6 className="sm-14 text-truncate">Estimated Earnings</h6>

              <div className="flex space-between">
                <h3 className="sm-16">
                  ₹ {cai.earnings ? cai.est_earnings : "0"}
                </h3>

                <img className="stat-icon" src={coinsicon} alt="" />
              </div>
            </div>

            <div className="shadow bg-light p-3 rounded fit-content col-lg-5 p-10 col-sm-5 col-5 mb-20 m-2">
              <h6 className="sm-14 text-truncate">Sessions Taken</h6>

              <div className="flex space-between">
                <h3 className="sm-16">{cai.sessions ? cai.sessions : "0"}</h3>

                <img className="stat-icon" src={sessionicon} alt="" />
              </div>
            </div>

            <div className="shadow bg-light rounded p-3 fit-content col-lg-5 col-sm-5 col-5  p-10 mb-20 m-2">
              <h6 className="sm-14 text-truncate">Influenced</h6>

              <div className="flex space-between">
                <h3 className="sm-16">
                  {cai.influenced ? cai.influenced : "0"}
                </h3>

                <img className="stat-icon" src={influencedicon} alt="" />
              </div>
            </div>

            <div className="shadow bg-light rounded p-3 fit-content col-lg-5 col-sm-5 col-5 p-10 mb-20 m-2">
              <h6 className="text-truncate sm-14">Ambassador Referrals</h6>

              <div className="flex space-between">
                <h3 className="sm-16">{cai ? cai.num_cai_referrals : "0"}</h3>

                <img className="stat-icon" src={cairefericon} alt="" />
              </div>
            </div>

            <div className="shadow bg-light rounded p-3 fit-content col-lg-5 col-sm-5 col-5 p-10 mb-20 m-2">
              <h6 className="sm-14 text-truncate">Workshop Referrals</h6>

              <div className="flex space-between">
                <h3 className="sm-16">
                  {cai ? cai.workshop_referrals_count : "0"}
                </h3>

                <img className="stat-icon" src={workshopicon} alt="" />
              </div>
            </div>

            <div className="shadow bg-light rounded p-3 fit-content col-lg-5 col-sm-5 col-5 p-10 mb-20 m-2">
              <h6 className="sm-14 text-truncate">Course Referrals</h6>

              <div className=" d-flex space-between">
                <h3 className="sm-16">
                  {cai ? cai.course_referrals_count : "0"}
                </h3>

                <img className="stat-icon" src={tutorialicon} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="">
        <div className=" d-flex flex-wrap justify-content-center justify-content-lg-between justify-content-md-between justify-content-sm-center mt-5 container ">
          <div className=" col-lg-5 col-md-6 col-sm-10 col-12">
            <div className="grid d-none lg-grid-2  md-grid-2 grid-2">
              <Link to="/cai-referrals " className="btn btn-secondary">
                My Referrals
              </Link>

              <Link to="/wallet" className="btn btn-secondary">
                My Wallet
              </Link>
            </div>

            <div className="col-12 mt-0">
              <div className="border rounded mb-5  p-3">
                <div className="d-flex justify-content-between">
                  <h4>My Reports</h4>
                  <div>
                    <button
                      onClick={handleShow}
                      type="file"
                      className="btn btn-primary btn-sm"
                    >
                      submit Report
                    </button>
                  </div>
                </div>

                <hr />
                {reports ? <>{
                  
                    reports.map((item)=>(
                      <div className="flex space-between align-items-center mt-2">
                      <h6 className="mb-0">{dateFormat(item.submission_time,"dS mmm yyyy")}</h6>
                      <p
                      
                        className={item.status=="approved"?"text-success ":"text-danger " }
                      >{item.status}</p>
                      <a href={item.report_url} download={"report"+Date.now()} target='_blank' className="mb-0 ">
                        <DownloadIcon />
                      </a>
                    </div>

                    ))
                  
                }</> : <></>}

              
              </div>
            </div>
          </div>

          <div className="col-lg-5 border rounded col-md-6 col-sm-10">
            <CaiLeaderboard />
          </div>
        </div>

        <div className="flex-wrap container d-flex mt-4 mb-5">
          <div className="col-lg-12">
            <h3 className="sm-18">Milestones</h3>

            <div className=" grid lg-grid-6 gap-2  md-grid-4 grid-3 mt-3">
              {milestones ? (
                milestones.map((milestone) => (
                  <>
                    <>
                      {milestone.badge_status === "earned" ? (
                        <>
                          <Link to={"/milestone-earned/" + milestone.earned_id}>
                            <Tooltip
                              title={
                                <>
                                  <div className="p-3 text-white bg-dark">
                                    <p className="text-white">
                                      {milestone.description}
                                    </p>
                                  </div>
                                </>
                              }
                              arrow
                            >
                              <div className=" position-relative text-center shadow  br-5">
                                <img
                                  src={milestone.image}
                                  className="w-100 br-5"
                                />
                              </div>
                            </Tooltip>
                          </Link>
                        </>
                      ) : (
                        <>
                          <Link to={"/milestone/" + milestone.id}>
                            <Tooltip
                              title={
                                <>
                                  <div className="p-3 text-white bg-dark">
                                    <p className="text-white">
                                      {milestone.description}
                                    </p>
                                  </div>
                                </>
                              }
                              arrow
                            >
                              <div className=" position-relative text-center shadow   br-5">
                                <div className="milestone-cover">
                                  <Lock />
                                </div>
                                <img
                                  src={milestone.image}
                                  className="w-100 br-5"
                                />
                              </div>
                            </Tooltip>
                          </Link>
                        </>
                      )}
                    </>
                  </>
                ))
              ) : (
                <div className="">
                  <p className="sm-14">
                    Your targets and milestones will be displayed here once your
                    account is active.
                  </p>

                  <img className="w-100 mt-5" src="https://skillsuprise.com/resources/images/milestone.svg" alt=""></img>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/*leaderboard section*/}

      {/*leaderboard section*/}
    </>
  );
};

export default CampusAmbassador;
const ApplyInternshipStyled = styled.div`
  input,
  textarea {
    border: 1px solid lightgrey;
    border-radius: 5px;
    margin-bottom: 10px;
  }
`;
