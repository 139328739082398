import React from 'react'
import dateFormat from "dateformat";
import { Link } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import AvatarGroup from "@mui/material/AvatarGroup";
const PreviousScheduleItem = ({item,index,course_url,batch_id}) => {
  return (
 <>
 
 <div
                                className={
                                  " class-item mt-2 mb-3 p-2 c" + index
                                }
                              >
                                <h5 className="sm-16">{item.class_title}</h5>
                                <div className="d-flex sm-12 justify-content-between">
                                  <p>
                                    <b>Date : </b>
                                    {dateFormat(item.class_starts, "dS mmmm")}
                                  </p>
                                  <p>
                                    <b>Time : </b>
                                    {dateFormat(item.class_starts, "hh:mm") +
                                      " - " +
                                      dateFormat(item.class_ends, "hh:mm tt")}
                                  </p>
                                </div>

                                <div className="d-flex mt-2">
                                <b className='sm-13'>Topics : </b>
                                <p className=" truncate truncate2 sm-13">
                                
                                {item.class_description!="null"&&item.class_description!=null?
                                item.class_description:"NA"}
                              </p> 
                                </div>
                               

                                <hr />
                                <div className="d-flex justify-content-between align-items-center">
                                  <div>
                                    <div className="d-flex align-items-center">
                                    {item.attendees?
<>
<AvatarGroup   className="d-flex align-items-center" >
  {item.attendees.map((attendee)=>(
    <>
    
    <Avatar  sx={{ width: 30, height: 30 }} alt="Remy Sharp" src={attendee.profile_pic||null} />
 
    </>))}
 <p className="sm-12 mb-0 pb-0">+{item.attendees.length} attending</p>
</AvatarGroup>
</>:
<>
<span></span>
</>}
                                    </div>
                                  </div>
                                  <Link
                                    to={
                                      "/recordings/" +
                                      course_url +
                                      "/" +
                                      batch_id +
                                      "/" +
                                      item.id
                                    }
                                    className="btn btn-sm btn-primary rounded-pill sm-13"
                                  >
                                    Watch Recording
                                  </Link>
                                </div>
                              </div>
 </>
  )
}

export default PreviousScheduleItem