import axios from 'axios'
import ENDPOINT from '../../data/ENDPOINT'
import SecurityHeaders from '../../data/SecurityHeaders'

const  AcceptRequest = async ({payload})=>{

const data = new FormData()


data.append("session",payload.user)
data.append('follower_id',payload.follower_id);
data.append('status',"accept");        
            
const res = await 
axios.post(ENDPOINT+`accept-request.php`,data,SecurityHeaders)
if(res){
    
     return res.data
}

}




export default AcceptRequest