import React from 'react'

const PlacementTerms = () => {
  return (
<>


<div>
  <div className=" bg-black p-5">
    <div className="flex-wrap container-xl">
      <div className="col-lg-7 text-light">
   
        <h2 className="sm-22">Placement Terms &amp; Conditions</h2>
        <p className="text-secondary">Last Updated 29th jan, 2024 </p>
      </div>
    </div>
  </div>
  <div className="container pt-3">
    <ol>
      <li className="mt-5">
        Eligibility Criteria - Graduates not more than 5 years of gap i.e.,
        Students who have passed in 5 years from the date of joining are
        eligible. If not we shall provide a test and determine course duration
        based on exam results.
      </li>
      <li>
        Students should maintain 90% attendence in through out their training.
      </li>
      <li> Have to complete projects with 85% efficiency.</li>
      <li>
        Have to submit projects &amp; assignments without delay before the
        deadlines.
      </li>
      <li>
        Should mould himself/herself in the process of candidature - grooming to
        fit in Mnc's.
      </li>
      <li>
        Rude behaviour or not meeting ethical standard during training will
        result in disqualification.
      </li>
      <li>
        Clearing fee dues on time: Getting course paused more than 2 times will
        lead to disqualification from placement training.
      </li>
      <li>
        Have to gradually reach targets set in placements training i.e.,
        Linkedln profiles(connections &amp; posts), resumes, tasks.
      </li>
      <li>
        Is eligible if only acquires technical skills of the respective domains.
      </li>
      <li>
        Should be 85-90% efficient in communication presentation, GD. Soft
        skills to be able to place.
      </li>
      <li>
        Not more than a month of delay in learning is encourged to be eligible
        for placement (5 months is maximum duration).
      </li>
    </ol>
    <h5>Targets:</h5>
    <ol>
      <li> Linkedln connections as per targets given.</li>
      <li>Linkedln posts as suggested to upload.</li>
      <li>Updating resumes as per suggestions</li>
      <li>Active participation in GD rounds</li>
      <li>Involving in presentation works,collaboratively with team mates.</li>
      <li>Applying for no.of job posts as recommended.</li>
      <li>Attending interviews following all our instructions without fail.</li>
    </ol>
  </div>
</div>

</>
  )
}

export default PlacementTerms