import React from 'react'
import { Link } from 'react-router-dom'
import cups from '../../assets/cups.svg'
import defaultdp from '../../assets/user.png'
import DefaultTemplate from '../temaplates/DefaultTemplate'
const CaiLeaderboard = ({leaderboard}) => {
    return (


        <>
        
       


        <div className="container-fluid sm-p-15 pt-4">
<h3 className="sm-18">Leaderboard</h3>


{ leaderboard?leaderboard.map((leader,index)=>(


<div className="flex rank-item align-center fadein">
<div className="col-lg-6 col-md-6 sm-p-0 col-sm-5 col-5 align-center rank-username">
<div className="align-center">
<img className="userdp rounded-circle" src={leader.profile_pic?leader.profile_pic:defaultdp} alt="dp"></img>

<div>
<h5>{leader.first_name+" "+leader.last_name}</h5>
<p>@{leader.username}</p>
    </div>
</div>
</div>
<div className="col-lg-6 col-md-6 col-sm-7 col-7 space-between align-center mobile-space-around">
    
<p><b>{leader.points}</b> Points</p>
<Link to="/">#{index+1}</Link>
</div>
</div>
)):
<div>
<p>Your batch leaderboard has not been activiated yet</p>    
<div className="justify-content-center mt-5 d-flex  ">
<img className="w-75 mb-5 " src={cups} alt=""></img>

</div>
    
    </div>}







</div>

      
        
        </>
    )
}

export default CaiLeaderboard
