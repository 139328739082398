import { Avatar } from '@mui/material';
import React, { useState, useEffect, useContext } from 'react';
import { Link, useParams } from 'react-router-dom';
import GetCourseBatch from '../../../actions/GetCourseBatch';
import { AuthContext } from '../../../context/AuthContext';
import SearchIcon from '@mui/icons-material/Search';
import Localbase from 'localbase';

const CourseLeaderboard = ({ more }) => {
    const params = useParams();
    const course_url = params.course_url;
    const batch_id = params.batch_id;

    let db = new Localbase('db');
    const { user } = useContext(AuthContext);
    const [batch, setBatch] = useState([]);
    const [initialData, setInitialData] = useState([]);
    const [response, setResponse] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");

    const GetBatch = async () => {
        const res = await GetCourseBatch({
            payload: {
                course_url: course_url,
                limit: 100,
                batch_id: batch_id,
                user: user.SessionId
            }
        });
        if (res) {
            if (res.status === "success" && res.team !== null) {
                setBatch(res.team);
                setInitialData(res.team);
                setResponse(res.team);

                db.collection("paths").add({
                    batch: res.team
                }, 'batch');
            }
        }
    };

    useEffect(() => {
        db.collection("paths").doc("batch").get().then(batchDoc => {
            if (batchDoc.batch) {
                setBatch(batchDoc.batch);
                setInitialData(batchDoc.batch);
                setResponse(batchDoc.batch);
            }
        }).catch(error => {
            console.error("Error fetching data from Localbase", error);
        });

        GetBatch();
    }, []);

    const handleSearch = (e) => {
        const term = e.target.value.toLowerCase();
        setSearchTerm(term);

        if (initialData) {
            const filteredData = initialData.filter((item) => {
                return (
                    item.first_name.toLowerCase().includes(term) ||
                    item.username.toLowerCase().includes(term)
                );
            });

            setResponse(filteredData);
        }
    };

    return (
        <div className="w-100 p-3 rounded bg-white section sm-mt-50">
            {batch.length > 0 ?
                <>
                    <div className="d-flex justify-content-between">
                        {more ?
                            <Link to={`/leaderboard/${course_url}`}>View All</Link> :
                            null}
                    </div>

                    <div className='d-flex'>
                        <div className='position-relative mt-4 mb-4 col-12'>
                            <input
                                onChange={handleSearch}
                                value={searchTerm}
                                placeholder='Search'
                                className='w-100 form-control leaderboard-search col-lg-12'>
                            </input>
                            <SearchIcon className='text-black leaderboard-search-icon mt-2' />
                        </div>
                    </div>

                    {response.slice(3).map((item, index) => (
                        <div key={index + 3} className='bg-white'>
                            <div className='container p-0'>
                                <div>
                                    <div className='shape mt-2 p-3 hover'>
                                        <div className='d-flex flex-wrap justify-content-between align-items-center'>
                                            <div className='d-flex col-lg-3 col-md-3 col-sm-4 col-6'>
                                                <div className='text-center me-2'>
                                                    <h5 className='text-black m-2 w-25'>#{index + 4}</h5>
                                                </div>
                                          
                                                    <Avatar src={item.profile_pic}  />
                                              
                                                <div className='ms-1'>
                                                    <h6 className='text-black m-0 p-0'>{item.first_name}</h6>
                                                    <p className='m-0 p-0 text-black truncate truncate1'>@{item.username}</p>
                                                </div>
                                            </div>
                                            <div>
                                                <h6 className='text-black'>{item.points ? item.points : 0}</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </> :
                <>
                    <h4>No Students Found!</h4>
                    <p>It seems like no student has joined the community yet.</p>
                </>
            }
        </div>
    );
};

export default CourseLeaderboard;
