import React from 'react'
import { useContext } from 'react'
import { AuthContext } from '../../context/AuthContext'
import Box from '@mui/material/Box';
import defaultdp from '../../assets/user.png'
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { useState } from 'react';
import ModalStyle from '../../data/ModalStyle';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { Close } from '@mui/icons-material';
const StudentAccessModal = ({visibility,HandleClose,access}) => {
    const {user} = useContext(AuthContext)
  const [student,setstudent] = useState(access)
  return (
    <>
    
    
<Modal
  open={visibility}
  onClose={HandleClose}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
  <Box sx={ModalStyle} className="p-3 br-5 col-11 col-sm-8 col-md-4 col-lg-4">
   <div className="d-flex justify-content-between">
    <h4>Student only access !</h4>
    <Close className="click"  onClick={HandleClose}/>
   </div>
   <hr/>
   <div>
    <p>Register a course to connect with friends and learn together.</p>
   </div>
   <div className="d-flex justify-content-between mt-4">
    <button onClick={HandleClose} className="btn btn-outline-danger rounded-pill">Cancel</button>
    <Link to="/courses" className="btn btn-primary rounded-pill">Browse Courses</Link>
   </div>
  </Box>
</Modal>

    </>
  )
}

export default StudentAccessModal