import React from 'react'
import Logout from '../../pages/authentication/Logout'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { useState } from 'react';
import { useContext } from 'react';
import { AuthContext } from '../../context/AuthContext';
import { Close } from '@mui/icons-material';
import ModalStyle from '../../data/ModalStyle';
const LogoutModal = ({visibility,HandleClose}) => {
    const {user} = useContext(AuthContext)
    const InstanceLogout = async ()=>{
setloading(true)
        const res = await Logout({
          payload:{
            user:user.SessionId
          }
        })

        if(res){
        setloading(false)}
      }

      const [loading,setloading] = useState(false)
  return (
  <>
  
  
  
  <Modal
  open={visibility}
  onClose={HandleClose}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
  <Box sx={ModalStyle} className="p-3 rounded col-lg-4 col-md-5 col-sm-6 col-11">
<div className="modal-header">
     <h5>Close Application !</h5>
     <Close className="click" onClick={HandleClose}/>
</div>
<hr/>
<p>Are you sure to logout your account ?</p>


<div className="mt-3 d-flex justify-content-between">
<button className="btn btn-sm btn-danger rounded-pill"
onClick={HandleClose}
>Cancel</button>


<button className="btn btn-sm btn-primary rounded-pill" 
disabled={loading}
onClick={()=>InstanceLogout()}>{loading?"Logging Out...":"Logout"}</button>
</div>
  </Box>
</Modal>


  </>
  )
}

export default LogoutModal