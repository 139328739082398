import React, { useContext, useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import DropdownButton from 'react-bootstrap/DropdownButton'
import defaultcover from '../../assets/icons/default-cover.png'
import Dropdown from 'react-bootstrap/Dropdown';
import GetAccount from '../../actions/GetAccount';
import { AuthContext } from '../../context/AuthContext';
import Localbase from 'localbase';
import CoverPictureEdit from './CoverPictureEdit';
import DpChange from './DpChange';

import Box from '@mui/material/Box';

import Logout from '../authentication/Logout';


import GetPublicAccount from '../../actions/GetPublicAccount';
import PublicDpChange from './PublicDpChange';
const PublicUserAccount = () => {
let db = new Localbase("db")


const params = useParams()
const username = params.username;
const [account,setaccount] = useState(null)
const {user} = useContext(AuthContext)
     const NewGetAccount = async()=>{
          const res = await GetPublicAccount({payload:{user:user,username:username}})
          if(res){
if(res.status==="success"&&res.data!==null){
     setaccount(res.data)
}
               
          }
     }


   
     useEffect(()=>{
          
NewGetAccount()
     },[user])




const [dp,setdp] = useState(null)


const [stats,setstats] = useState(null)
const [statsdisplay,setstatsdisplay] = useState(false)
     const [croppedimage,setcroppedimage] = useState(null)


const [logoutmodal,setlogoutmodal] = useState(false)

const InstanceLogout = async({payload})=>{

const res = await Logout({payload});
if(res){
      //  //console.log("log instance"+JSON.stringify(res))
}

}


  return (
  <>
  
  
  {account?
  <>
  
  <div className="w-100 profile sm-shadow-none border br-5">







<div className="user-cover-container">
<div className="position-relative w-100 h-100">
{account?
<>
<img src={account.cover_pic?account.cover_pic:defaultcover} className="w-100 h-100 user-cover-image"/>
<CoverPictureEdit self={false} />
<PublicDpChange account={account}/>
</>:null}
</div>


</div>


<div className="p-3">

<div className=" ">
     {account?
     <>
     
     </>:null}

<div className="user-info  w-100 mt-4">

<Link  to={"/u/"+account.username} className="text-dark">

<h4 className="pb-0 d-sm-block">{account.first_name+" "+account.last_name}</h4>

</Link>
 <Link to={"/u/"+account.username} className="d-sm-block">@{account.username}</Link>
<div className="d-flex mt-10  col-lg-12 col-12 col-md-11">
     <Link to={"/u/"+username+"/posts"} className="col ">
<h5  className="pb-0 sm-mb-0">{account.posts}</h5>
<p>Posts</p>
     </Link>
     <Link to={"/u/"+username+"/following"} className="col ">
<h5  className="pb-0 sm-mb-0">{account.following}</h5>
<p>Following</p>
     </Link>
     <Link to={"/u/"+username+"/followers"} className="col ">
<h5  className="pb-0 sm-mb-0">{account.followers}</h5>
<p>Followers</p>
     </Link>

     <div className="col text-center">
        

     </div>
</div>






</div>
</div>







<hr className="d-block d-sm-none"/>
</div>
</div>
  </>:
  <>
  
  </>}
  </>
  )
}

export default PublicUserAccount