import React from 'react'
import VideosSlider from '../tutorials/VideosSlider'
import { Link } from 'react-router-dom'
const HackingInternshipBanner = () => {
  return (
    <div className=" d-flex">
    <Link to="/ethical-hacking-internship" className="d-flex align-items-center">
    <img className="w-100 br-5  " src="https://skillsuprise.com/resources/images/hacking-internship.png"/>
  
    </Link>
  
   
  </div>
  )
}

export default HackingInternshipBanner