import axios from 'axios'
import { useContext } from 'react'
import { AuthContext } from '../context/AuthContext'
import ENDPOINT from '../data/ENDPOINT'
import SecurityHeaders from '../data/SecurityHeaders'

const GetEnrolledInternships = async ({user,limit})=>{

const data = new FormData()
data.append("session",user.SessionId)
if(limit){
     data.append("limit",limit)

}

const res = await 
axios.post(ENDPOINT+`get-my-internships.php`,data,SecurityHeaders)
if(res){
    
     return res.data
}

}

export default GetEnrolledInternships