import React from 'react'
import Skeleton from '@mui/material/Skeleton';
const CourseDetailsLoadingTemplate = () => {
  return (
    <div className="pb-5 mb-5">
     <div className="bg-dark min-vh-80 mb-5">


<div className="container pt-5 justify-content-between d-flex flex-wrap">

<div className="col-lg-6 pt-5">
<Skeleton height={50} className="w-100" variant="text" />

<Skeleton height={20} className="w-100 mt-2" variant="text" />

<Skeleton height={20} className="w-100 mt-2" variant="text" />

<div className="d-flex mt-3 mb-3">
<Skeleton  height={30} width={30} className="mr-5" variant="text" />

<Skeleton  height={30} width={30} className="mr-5" variant="text" />

<Skeleton  height={30} width={30} className="mr-5" variant="text" />

<Skeleton  height={30} width={30} className="mr-5" variant="text" />

<Skeleton  height={30} width={30} className="mr-5" variant="text" />

</div>


<div className="d-flex">
<Skeleton height={30}  className=" w-25 mr-5" variant="text" />
  
<Skeleton height={30}  className=" w-25 mr-5" variant="text" />
  
<Skeleton height={30}  className=" w-25" variant="text" />
  
</div>



<div className="d-flex">

<Skeleton height={60} className="w-25 mr-15" variant="text" />

<Skeleton height={60} className="w-25" variant="text" />

</div>

</div>

<div className="col-lg-4">

<Skeleton className="col-11 h-100" variant="rectangular" />
<div className=" mt-2">

<Skeleton height={60} className="w-50" variant="text" />

<Skeleton height={60} className="w-50 mt-2" variant="text" />

<Skeleton height={60} className="w-100 mt-2" variant="text" />

</div>
</div>

</div>
     </div>
     
     
     
     
     
     
     </div>
  )
}

export default CourseDetailsLoadingTemplate