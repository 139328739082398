import React from 'react'
import { useEffect } from 'react'


const FitHeight = ({children}) => {

useEffect(()=>{
const component = document.getElementById("fitheight")
if(component){
    component.style.height = window.innerHeight - 60+"px"
}

},[])

  return (
    <div id="fitheight" className="position-relative">
        
    {children}    
    </div>
  )
}

export default FitHeight