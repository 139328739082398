const Defaults = ()=>{


     const height = window.innerHeight;
     const headerheight = 60
const ViewHeight = (height-headerheight)

const defaults = {"ViewHeight":ViewHeight}
return defaults

}
export default Defaults