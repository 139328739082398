import { Button,Modal } from "react-bootstrap";
import React from "react";
import ENDPOINT from '../../data/ENDPOINT'
import { useState } from "react";

function LogoutAllNotification({usercredentials,props}) {
    const [logoutShow, setlogoutShow] = useState(true);
    const [reset, setreset] = useState(false);
    const redirect = sessionStorage.getItem("next") || "/dashboard";
    const handleLogoutClose = () => {

        setlogoutShow(false);
        window.location.reload();

    }
    const handlelogoutShow = () => 
    {setlogoutShow(true)};
  


const email = usercredentials.email;
const password = usercredentials.password;

    const logoutall = ()=>{

       
setreset(true);
    fetch(`${ENDPOINT}logoutall.php?email=${email}&password=${password}`)
    .then(r=>r.json())
    .then(result=>{
      setreset(false);
   
        if(result[0].status==="success"){
 
          localStorage.setItem("user", JSON.stringify((result[0].data[0])));
                     
          if((sessionStorage.getItem("next")==="null")||(sessionStorage.getItem("next")==="")||(sessionStorage.getItem("next")===null)){
            window.location.replace("/dashboard");
        }else{
            window.location.replace(redirect);
        }
           
            sessionStorage.setItem("next","");
    

        }
    else{
     
    }

    
        
    })
    }





    return (
      <>
   
        <Modal
        {...props}
          show={logoutShow}
          onHide={handleLogoutClose}
          backdrop="static"
          keyboard={false}
          centered
          
          className="p-3"
        >
         <div className="p-2">
         <Modal.Title>
              <div className="flex align-items-center">
              <svg className="mr-2" xmlns="http://www.w3.org/2000/svg" height="40" viewBox="0 0 128 128" width="40"><g><path d="m57.362 26.54-37.262 64.535a7.666 7.666 0 0 0 6.639 11.5h74.518a7.666 7.666 0 0 0 6.639-11.5l-37.258-64.535a7.665 7.665 0 0 0 -13.276 0z" fill="#ee404c"/><g fill="#fff7ed"><rect height="29.377" rx="4.333" width="9.638" x="59.181" y="46.444"/><circle cx="64" cy="87.428" r="4.819"/></g></g></svg>

              <h5 className="mt-1">Device Limit Exceeded !</h5>
              </div>
              </Modal.Title>
         
          <Modal.Body>
              
       <p>Do you wish to logout of other devices and login here ?</p> 
          </Modal.Body>
          <Modal.Footer className="space-between">
            <Button variant="warning" onClick={handleLogoutClose}>
              Cancel
            </Button>
            <Button onClick={logoutall} disabled={reset} variant="primary">{reset===true?"Logging in...":"Login Here"}</Button>
          </Modal.Footer>
         </div>
        
        </Modal>
      </>
    );
  }
  
export default LogoutAllNotification