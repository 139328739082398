import axios from 'axios'
import React from 'react'
import CommunitySidebar from '../community/CommunitySidebar'
import JobsList from './JobsList'
import { Helmet } from 'react-helmet'

const JobAlerts = () => {


  const Send = async ()=>{
    const data = new FormData()
data.append("otp","otp")
    const res = await axios.post("https://internal.skillsuprise.com/send-otp.php")
    if(res){
      //console.log("Res is "+JSON.stringify(res))
    }
  }
  return (
    <div>
<Helmet>‍
        <title>Job Alerts - Skills Uprise</title>‍
        <meta name="description" content="Skills Uprise" />       
        <meta name="twitter:card" content="" />   
        <meta name="twitter:site" content="@skillsuprise" />    
        <meta name="twitter:creator" content="@skillsuprise" />                
        <meta name="twitter:title" content="" />   
        <meta name="twitter:description" content="" />  
        <meta name="twitter:image" content=""/>     
        <meta property="og:title" content="" />  
        <meta property="og:description" content="" />        
        <meta property="og:image" content=""/>
        <meta property="og:url" content=""/>
        <meta property="og:site_name" content="Skills Uprise" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta property="fb:app_id" content="ID_APP_FACEBOOK" />
      </Helmet>
        <div className="container-fluid ">

<div className="container min-vh-100 pt-3 pb-5">
    <div className="d-flex flex flex-wrap justify-content-between">
<div className="col-lg-3 p-2">

<CommunitySidebar/>
</div>
    
    
    <div className="col-lg-6 p-2 overflow-y">

 <JobsList/>
  
    </div>
    

    <div className="col-lg-3 p-2">


   </div>

    </div>

</div>


        </div>






    </div>
  )
}

export default JobAlerts