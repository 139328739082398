import React, { useEffect, useState } from 'react'
import SinglePage from './SinglePage'
import axios from 'axios';
import ENDPOINT from './data/ENDPOINT';
import SecurityHeaders from './data/SecurityHeaders';
import { Link, useParams } from 'react-router-dom/cjs/react-router-dom.min';

const SingleBook = () => {
    const params = useParams()
 const[response,setResponse]=useState();

    const GetBooks = async()=>{
        const data = new FormData();
      
        const res = await axios.post(ENDPOINT+"get-books.php",data,SecurityHeaders)
         if (res){
           if(res.data.status === "success"){
            setResponse(res.data.data);
            // console.log(res.data.data)
           }
         }

    }
    useEffect(()=>{
   GetBooks()
    },[])
 return (
    <div className='d-flex flex-wrap justify-content-between p-2'>

    {
        response ? <>{response.map((item, index) => (


            <div key={index} className='  col-lg-4 p-2 d-flex  ' >
                <div className=' col-12  p-2 border border-2 '>
                    <div className='text-center  rounded-2'>
                        <img className='col-3 w-100 p-2 ' src={item.cover_url?item.cover_url:"https://skillsuprise.com/assets/img/hacking.jpg"} />

                    </div>
                    <div>
                        <h6>Book_Name:{item.book_name}</h6>
                        <h6>Batch_id:{item.batch_id}</h6>
                        <h6>Course_url:{item.course_url}</h6>
                        <h6>
                            Description:
                        </h6>
                        <p>{item.description}</p>
                    </div>

                    <div className='text-center p-3'>
                        <Link to={"/book/"+item.book_name} className="btn btn-primary w-50 ">View Details</Link>

                    </div>

                </div>
               
            </div>







        ))}</> : null

    }

</div>
    )
}

export default SingleBook