
import React, { useEffect } from 'react'
import PersonalBadges from './PersonalBadges'
import SetPage from '../../data/SetPage'
const PersonalBadgesPage = () => {
  useEffect(()=>{
    SetPage("Badges")
  })
  return (
    <div className="pb-5">

<div className="blue-bg">

     <div className="container pt-5 d-flex flex-wrap">

<div className="col-lg-3">
  <img src="https://skillsuprise.com/resources/images/hand.png" className="w-100"/>
</div>

<div className="col-lg-6  text-white pb-3 ">
<h1 className="mt-5">Learn Better & Earn More Rewards</h1>
<p className="light-grey">Everytime you learn something new or do a heroic job, you will earn rewards and certificates.</p>
</div>


     </div>


</div>


<div className="position-relative container mt-5">

<div className="lg-grid-6 md-grid-5 grid-2 grid">

<PersonalBadges/>

</div>
</div>

    </div>
  )
}

export default PersonalBadgesPage