import React from 'react'
import defaultdp from '../../assets/user.png'
import { Dropdown } from 'react-bootstrap'
import { useContext } from 'react'
import axios from 'axios'
import accounticon from '../../assets/icons/account.svg'
import interviewsicon from '../../assets/icons/interviews.svg'
import CloseIcon from '@mui/icons-material/Close';
import { useState } from 'react'
import workhistoryicon from '../../assets/icons/work-history.svg'
import certificateicon from '../../assets/icons/certificate-icon.svg'
import Box from '@mui/material/Box';
import {Link} from 'react-router-dom'
import friendsicon from '../../assets/icons/friends.svg'
import { useRef } from 'react'
import Modal from '@mui/material/Modal';
import { AuthContext } from '../../context/AuthContext'
import Snackbar from '@mui/material/Snackbar';
import { useEffect } from 'react'
import internshipsicon from '../../assets/user.png'
import ProfileEditForm from './ProfileEditForm'
import ENDPOINT from '../../context/ENDPOINT'
import DpChange from './DpChange'
import workshopicon from '../../assets/icons/workshop.svg';
import Localbase from 'localbase'
import { Chip } from '@mui/material'
import Settings from '@mui/icons-material/Settings';
import { Edit } from '@material-ui/icons'
const UserProfileBasic = ({edit}) => {


const {user} = useContext(AuthContext);



let db = new Localbase('db')


const [account,setaccount] = useState(null);

useEffect(()=>{

  db.collection('paths').doc('overview').get().then(overview=>{
    setoverview(overview.overview)
  }).catch(error=>{
  
  })
  
  db.collection('paths').doc('user').get().then(user=>{
    setaccount(user.user)
  }).catch(error=>{
    
  })

getaccount();
   
}

,[])


const  getaccount = async ()=>{

const data = new FormData();
data.append('session',user.SessionId)
const res = axios.post(ENDPOINT+"get-account.php",data,{headers:{'content-type':'multipart/form-data'}})



if(res){
}

  
  }    ;





  const ExitAccount = ()=>{


    fetch(`${ENDPOINT}logout.php?session=${user.SessionId} `)
    .then(r=>r.json())
    .then(result=>{
        
      if(result[0].status==="loggedout"){
localStorage.setItem("user", null);
db.collection("paths").doc("user").delete()
db.collection("paths").doc("notifications").delete()

localStorage.setItem("msg", null);
window.location.replace("/");
      }
      else{
       localStorage.setItem("user", null);
db.collection("paths").doc("user").delete()
db.collection("paths").doc("notifications").delete()

localStorage.setItem("msg", null);
window.location.replace("/");
      }
          

    
                
    
        
    })
    



}



















  const userdata = user;
const dp = useRef();


const selectCover = ()=>{
document.getElementById("cover").click();
}



  useEffect(() => {



    getOverview()
    window.scrollTo(0, 0)
  }, [])


const [userdp,setuserdp] = useState(null)



  const [file,setfile] = useState(null)
  const [status,setstatus]= useState(false)
   const   UPLOAD_ENDPOINT = `${ENDPOINT}upload-cover.php`;
     
     const onSubmit = async (e)=> {
       e.preventDefault();
         setstatus(true)

          let res = await uploadFile(file);
        //  //  //  //console.log("internship application"+res.data.status);
  
  
          if(res.data.status==="success"){
  setstatus(false);
  setsnackbarMessage("Cover photo changed")
       setfile(null)
              setsnackbar(true)
              setuserdp(null)
               }
          else{
            setfile(null)
            setstatus(false)
            setsnackbarMessage("Error changing cover photo. Please try again")
       setuserdp(null)
            setsnackbar(true)
         

          }
      }
      const reader = new FileReader();
 
    const  onDpChange = (e)=> {
          setfile(e.target.files[0])
         


    setuserdp(e.target.files[0])


  
      }
     const uploadFile = async (file)=>{
          
  
          const formData = new FormData();
          const user = JSON.parse(localStorage.getItem("user"));
        formData.append('cover',file);
        formData.append('session',user.SessionId);
       
          return  await axios.post(UPLOAD_ENDPOINT, formData,{
              headers: {
                  'content-type': 'multipart/form-data'
              }
          });
        }
      
  






       





const [snackbar,setsnackbar] = useState(false);
const [snackbarMessage,setsnackbarMessage] = useState(null)




const [overview,setoverview] = useState(0)


const getOverview = async (status)=>{
        

  const formData = new FormData();
  
  
  //formData.append('whyus',document.getElementById('whyus').value)
  
  formData.append('session',user.SessionId);
  
  
  
  const res = await axios.post(ENDPOINT+"user-overview.php", formData,{
      headers: {
          'content-type': 'multipart/form-data'
      }
  });
  if(res){

   if(res.data.status==="success"){
   setoverview(res.data.statistics)
   db.collection('paths').add({
     overview:res.data.statistics
   },'overview')
   }
   else{
  
   }
  }
  }
  


  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',

    bgcolor: 'background.paper',
  
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };
  
  


const [editmodal,seteditmodal] = useState(false)
const [sectionmodal,setsectionmodal] = useState(false)
const [addeducationmodal,setaddeducationmodal] = useState(false)
const [addexperience,setaddexperience] = useState(false)
const [addcertifications,setaddcertifications] = useState(false)

const [addhonours,setaddhonours] = useState(false)
const [share,setshare] = useState(false)
return (
       <>

{account?
<>





<Modal
  open={editmodal}
  onClose={()=>seteditmodal(false)}
  aria-labelledby="modal-modal-title"

  aria-describedby="modal-modal-description"
>
  <Box   className="col-lg-4 col-md-4 col-sm-6 col-11" sx={style}>
  

  
      <div className="flex space-between">
      <h4 className="sm-18">Edit Basic Information</h4>
  <CloseIcon type="button" className="text-danger" onClick={()=>seteditmodal(false)}/>
      </div>
      <hr/>
      <ProfileEditForm/>
   
  </Box>
</Modal>













{/*Share modal*/}
<Modal
  open={share}
  onClose={()=>setshare(false)}
  aria-labelledby="modal-modal-title"

  aria-describedby="modal-modal-description"
>
  <Box   className="col-lg-4 col-md-4 col-sm-6 col-11" sx={style}>
  

  
      <div className="flex space-between">
      <h4 className="sm-18">Share Profile</h4>
  <CloseIcon type="button" className="text-danger" onClick={()=>setshare(false)}/>
      </div>
      <hr/>

   
  </Box>
</Modal>






<Modal
  open={sectionmodal}
  onClose={()=>setsectionmodal(false)}
  aria-labelledby="modal-modal-title"

  aria-describedby="modal-modal-description"
>
  <Box   className="col-lg-4 col-md-4 col-sm-6 col-11" sx={style}>
  

  
      <div className="flex  space-between">
      <h4 className="sm-18">Add profile information</h4>
  <CloseIcon type="button" className="text-danger" onClick={()=>setsectionmodal(false)}/>
      </div>
      <hr/>
  <div type="button" onClick={()=>setaddexperience(true)} className="flex mt-3 mb-3">
  <h6>Add Experience </h6>
  </div>


  <div onClick={()=>setaddeducationmodal(true)}  className="flex mt-3 mb-3">

  <h6>Add Education </h6>
</div>



<div type="button" onClick={()=>setaddcertifications(true)} className="flex mt-3 mb-3">


<h6>Add Licences & Certifications </h6>
  </div>




<div type="button" onClick={()=>setaddhonours(true)} className="flex mt-3 mb-3">

<h6>Add Honours & rewards</h6>
</div>


  </Box>
</Modal>








<div className="shadow user-profile-box">




<Snackbar
        open={snackbar}
        autoHideDuration={6000}
        onClose={()=>setsnackbar(false)}
        message={snackbarMessage}
     
      />






<div className="w-100 ">
<div className="flex col-lg-12 pt-3 position-relative  align-items-center">
<DpChange defaultdp={defaultdp} account={account}/>
            
           
<div className="w-100 ml-3">
<h4 className="mb-2">{account.first_name+" "+account.last_name}</h4>
<p className="text-primary">@{account.username}</p>
<div className="col-lg-10 col-xl-7 p-0 position-relative col-md-8 col-sm-12 space-between sm-14 flex align-items-center">

{overview?

<>
<Link to="/posts" className="text-center text-dark sm-14">
  <b>{overview.total_posts?overview.total_posts:0}</b>
  <p className="mb-0">Posts</p>
</Link>


<Link to={"/connections/followers"}  className="text-center text-dark sm-14">
  <b>{overview.followers?overview.followers:0}</b>
  <p className="mb-0">Followers</p>
</Link>


<Link  to={"/connections/following"}  className="text-center text-dark sm-14">
  <b>{overview.following?overview.following:0}</b>
  <p className="mb-0">Following</p>
</Link>

</>
:null}
</div>
</div>
</div>



</div>














<hr/>
<div className="flex  sm-p-15 space-evenly account-buttons w-100 pb-2">

<Link to={"/my-courses"} className="btn btn-white  mr-2 d-sm-block w-fit">My Courses</Link>

<Link to={"/my-internships"} className="btn btn-white d-none d-sm-block lg-none mr-2 w-fit">My Internships</Link>
<Link to={"/u/"+account.username} className="btn btn-white d-sm-block mr-2 w-fit">My Account</Link>
<Dropdown className="text-dark">
  <Dropdown.Toggle className="bg-transparent text-dark border-0">
    More
  </Dropdown.Toggle>

  <Dropdown.Menu>
  <Dropdown.Item>
  <Link className="text-dark" to={"/u/"+account.username}>
  <img src={accounticon} className="more-icon"/>  My Account
    </Link>
    
      </Dropdown.Item>

      <Dropdown.Item>
  <Link className="text-dark" to={"/connections"}>
  <img src={friendsicon} className="more-icon"/> Connections
    </Link>
    
      </Dropdown.Item>
      <Dropdown.Item>
      <Link className="text-dark" to={"/my-internships"}>
      <img src={internshipsicon} className="more-icon"/>
    My Internships
    </Link>
      </Dropdown.Item>


      <Dropdown.Item>
      <Link className="text-dark" to={"/interviews"}>
      <img src={interviewsicon} className="more-icon"/>
   My Interviews
    </Link>
      </Dropdown.Item>


      <Dropdown.Item>
      <Link className="text-dark" to={"/my-workshops"}>
  
      <img src={workshopicon} className="more-icon"/>
    My Workshops
    </Link>
      </Dropdown.Item>
      <Dropdown.Item>
      <Link className="text-dark" to={"/certificates"}>
      <img src={certificateicon} className="more-icon"/>
    My Certificates
    </Link>
      </Dropdown.Item>
      <Dropdown.Item>
      <Link className="text-dark" to={"/watch-history"}>
      <img src={workhistoryicon} className="more-icon"/>  Watch History
    </Link>
      </Dropdown.Item>
    


  
  

  


  </Dropdown.Menu>
</Dropdown>

</div>



{edit?
<>
<div className="container-fluid sm-p-15  pb-3">
<hr/>


<div className="flex space-between">
<div className="flex">
<Chip type="button" className="mr-3" onClick={()=>seteditmodal(true)} label="Edit Profile"/>


<Chip type="button" onClick={()=>setshare(true)}  label="Share Profile"/>


</div>

<div>

<Link to="/settings">
<Settings className="" type="button"/>

</Link>

</div>

</div>

 </div>
</>:
<>

</>}
          
    
            
         
                      </div>

</>
:

<>

<div className="shadow">










<div className="w-100 ">
<div className="flex col-lg-12 position-relative  align-items-center">


           <img src={defaultdp} className="userdp rounded-circle shadow mb-3"/>
<div className="w-100 ml-2">
<h4 className="mb-2">Name</h4>
<p className="text-primary">Username</p>
<div className="col-lg-9 col-xl-7 p-0 position-relative col-md-5 col-sm-12 space-between sm-14 flex align-items-center">

{overview?

<>
<div className="text-center sm-14">
  <b>0</b>
  <p className="mb-0">Posts</p>
</div>


<Link to={"/connections/followers"}  className="text-center text-dark sm-14">
  <b>0</b>
  <p className="mb-0">Followers</p>
</Link>


<Link  to={"/connections/following"}  className="text-center text-dark sm-14">
  <b>0</b>
  <p className="mb-0">Following</p>
</Link>

</>
:null}
</div>
</div>
</div>



</div>
<hr/>
<div className="flex  sm-p-15 space-evenly account-buttons w-100 pb-2">

<Link to={"my-courses"} className="btn btn-white  mr-2 d-sm-block w-fit">My Courses</Link>

<Link to={"/my-internships"} className="btn btn-white d-none d-sm-block lg-none mr-2 w-fit">My Internships</Link>
<Link to={"/account"} className="btn btn-white d-sm-block mr-2 w-fit">My Account</Link>
<Dropdown>
  <Dropdown.Toggle className="bg-transparent text-dark border-0">
    More Options
  </Dropdown.Toggle>

  <Dropdown.Menu >
  <Dropdown.Item  className="pt-1 pb-1">
  <Link to={"/account"}>
    My Account
    </Link>
      </Dropdown.Item>
      <Dropdown.Item  className="pt-1 pb-1">
      <Link to={"/my-internships"}>
    My Internships
    </Link>
      </Dropdown.Item>
      <Dropdown.Item  className="pt-1 pb-1">
      <Link to={"/my-workshops"}>
    My Workshops
    </Link>
      </Dropdown.Item>
      <Dropdown.Item  className="pt-1 pb-1">
      <Link to={"/certificates"}>
    My Certificates
    </Link>
      </Dropdown.Item>
      <Dropdown.Item  className="pt-1 pb-1">
      <Link to={"/watch-history"}>
   Watch History
    </Link>
      </Dropdown.Item>
      <Dropdown.Item  className="pt-1 pb-1">
      
    <Link to={"/my-workshops"}>
    My Workshops
    </Link>

      </Dropdown.Item>


      <Dropdown.Item className="pt-1 pb-1">
      
      <Link to={"/settings"}>
      Settings
      </Link>
  
        </Dropdown.Item>
  
        <Dropdown.Item>
      
      <Link to={"/my-workshops"}>
      Logout
      </Link>
  
        </Dropdown.Item>
  


  </Dropdown.Menu>
</Dropdown>

</div>


          
    
            
         
                      </div>





                      <div className="container-fluid sm-p-15 shadow sm-shadow-none p-3 mt-3">
 
 <div className="flex space-between">
 <h5>About</h5>


 </div>
<div>


 <p>{account?account.about:null}</p>
</div>

</div>
</>
}






<>





</>
 
        </>
    )
}

export default UserProfileBasic
