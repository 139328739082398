import React, { useState } from 'react'
import { Checkbox, TextField } from '@mui/material'
import RegisterWorkshop from '../../actions/workshops/RegisterWorkshop'
import { WhatsappIcon } from 'react-share'
const WorkshopRegistrationForm = ({workshop_id}) => {

     const [name,setname] = useState(null)
const [email,setemail] = useState(null)
const [phone,setphone] = useState(null)
const [college,setcollege] = useState(null)
const [city,setcity] = useState(null)
const [branch,setbranch] = useState(null)
const [year,setyear] = useState(null)
const [whatsapp,setwhatsapp] = useState(false)
const [referralcode,setreferralcode] = useState(null)
const [workshop,setworkshop] = useState(null)

const CompleteRegistration = async()=>{

     const res = await RegisterWorkshop({payload:{
       name:name,
       email:email,
       phone:phone,
       college:college,
       city:city,
       branch:branch,
       year:year,
       referral_code:referralcode,
   workshop_id:workshop_id,
   whatsapp:whatsapp
     }})
     if(res){
           //  //console.log("registered "+JSON.stringify(res))
     }
   }

   const CheckChanged = (e)=>{

     setwhatsapp(e.target.checked)
    
   }
   
  return (
    <div className=" sm-p-15">
     
     <form onSubmit={(e)=>{e.preventDefault();CompleteRegistration()}} className="w-100">
<TextField required value={name} onChange={(e)=>{setname(e.target.value)}} label="Name" className="w-100" variant="outlined" />

<TextField  value={email} onChange={(e)=>{setemail(e.target.value)}}  required className="mt-2 w-100" label="Email" variant="outlined" />

<TextField  value={phone} onChange={(e)=>{setphone(e.target.value)}}  required className="mt-2 w-100" label="Phone" variant="outlined" />

<TextField  value={college} onChange={(e)=>{setcollege(e.target.value)}}  required className="mt-2 w-100" label="College" variant="outlined" />
<TextField  value={city} onChange={(e)=>{setcity(e.target.value)}}  required className="mt-2 w-100" label="City" variant="outlined" />
<TextField  value={branch} onChange={(e)=>{setbranch(e.target.value)}}  required className="mt-2 w-100" label="Branch" variant="outlined" />
<TextField  value={year} onChange={(e)=>{setyear(e.target.value)}}  required className="mt-2 w-100" label="Year" variant="outlined" />
<TextField  value={referralcode} onChange={(e)=>{setreferralcode(e.target.value)}}  className="mt-2 w-100" label="Referral Code (optional)" variant="outlined" />
<div className="d-flex mt-3 align-items-center">
<Checkbox checked={whatsapp} onChange={(e)=>CheckChanged(e)}/>
<p className="extra-small"><WhatsappIcon className="h-25px extra-small"/> Receive details & updates view WhatsApp</p>
</div>

<button className="btn btn-primary w-100 mt-3">Complete Registration</button>


</form>


    </div>
  )
}

export default WorkshopRegistrationForm