import React from 'react'
import Skeleton from '@mui/material/Skeleton';
const WorkshopLoadingTemplate = ()=>{


return(
    <>
    
    <div className="container-fluid">

<div className="workshop-crumb">




</div>


<div className="container justify-content-between p-3 d-flex flex-wrap shadow br-5 workshop-info ">


<div className="col-lg-6">
<Skeleton height={40} className="w-100 mb-2" variant="text" />

  <Skeleton height={40} className="w-25 mb-2" variant="text" />

  <Skeleton height={40} className="w-25 mb-2" variant="text" />

  <Skeleton height={40} className="w-25 mb-2" variant="text" />
    


</div>

<div className="col-lg-5">

<Skeleton variant="rectangular" className="w-100 mb-3" height={118} />




<Skeleton variant="rectangular" className="w-100" height={70} />
</div>
</div>





<div className="container mt-5 d-flex justify-content-between flex-wrap">
<div className="col-lg-5">

<Skeleton variant="rectangular" className="w-100" height={118} />


</div>

<div className="col-lg-6">
<Skeleton height={40} className="w-75 mb-2" variant="text" />
    
<Skeleton height={40} className="w-75 mb-2" variant="text" />
    
<Skeleton height={40} className="w-75 mb-2" variant="text" />
    
<Skeleton height={40} className="w-75 mb-2" variant="text" />
    
</div>

</div>



    </div>
   
    
    
    </>
)

}
export default WorkshopLoadingTemplate