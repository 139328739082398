import React, { useRef, useEffect, useState } from 'react';

const HomeEngagementVideo = () => {
  const videoRef = useRef(null);
  const [isMuted, setIsMuted] = useState(true);

  useEffect(() => {
    const handlePlayPause = (entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          videoRef.current.play();
        } else {
          videoRef.current.pause();
        }
      });
    };

    const observer = new IntersectionObserver(handlePlayPause, {
      threshold: 0.5, // Adjust this value as needed
    });

    if (videoRef.current) {
      observer.observe(videoRef.current);
    }

    return () => {
      if (videoRef.current) {
        observer.unobserve(videoRef.current);
      }
    };
  }, []);

  return (
    <>
      <video
        preload="auto"
        id="mainvideo2"
        ref={videoRef}
        className="rounded w-100 position-relative"
        controlsList="play pause volume seeking nodownload foobar"
        loop
       // Set initial muted state based on isMuted
        onClick={() => setIsMuted(false)}
        controls
        poster="https://skillsuprise.com/data/images/poster1.png"
        src="https://skillsuprise.com/data/videos/engagement.mp4"
      ></video>
    </>
  );
}

export default HomeEngagementVideo;
