import React from 'react'
import { useEffect } from 'react';
import {
     EmailIcon,
     EmailShareButton,
   
     LinkedinIcon,
     LinkedinShareButton,
   
    
     TelegramIcon,
   
    
     TelegramShareButton,
     
     TwitterIcon,
     
     TwitterShareButton,
   
     WhatsappIcon,
   
     WhatsappShareButton,
   
   } from "react-share";
const SocialShare = ({path}) => {

 let location = "https://skillsuprise.com"+window.location.pathname

 if(path==="hacking-workshop"){
  location = "Hey i've found a great opportunity to become an Ethical Hacker. Join the National Level Workshop by registering here and get free training & internship opportunity. Registration Link: https://skillsuprise.com/hacking-workshop"

}
else{
  if(path!==null){
    location = path

  }
  else{
    location = "https://skillsuprise.com"+window.location.pathname

  }

}
      return (
    <>



<div className="flex  w-fit">
<WhatsappShareButton size={30}   round={true} url={location}><WhatsappIcon size={30}  type="button"  round={true} url={location} className="mr-2"/></WhatsappShareButton>
<LinkedinShareButton size={30}   round={true} url={location}><LinkedinIcon size={30}  type="button"  round={true} url={location} className="mr-2"></LinkedinIcon></LinkedinShareButton>
<TelegramShareButton size={30}   round={true} url={location}><TelegramIcon size={30}  type="button"  round={true} url={location} className="mr-2"/></TelegramShareButton>
<TwitterShareButton size={30}   round={true} url={location}><TwitterIcon size={30}  type="button"  round={true} url={location} className="mr-2"/></TwitterShareButton>
<EmailShareButton size={30}   round={true} url={location}><EmailIcon size={30}  type="button"  round={true} url={location} className="mr-2"/></EmailShareButton>


</div>



    </>
  )
}

export default SocialShare