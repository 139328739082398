import { useEffect } from "react";

const AnimatePage = () => {

  function Animate() {
    const items = document.getElementsByClassName("animate");
    const screenheight = window.innerHeight;

    for (var i = 0; i < items.length; i++) {
      const currentelement = items[i];

      if (currentelement) {
        const elementposition = parseInt(currentelement.getBoundingClientRect().top.toFixed());

        if (elementposition < screenheight) {
          if (items[i].classList.contains("animation-active")) {

          } else {
            items[i].classList.add("animation-active");
          }
        }
      }
    }
  }


    // Cleanup the event listener when the component unmounts
   
}

export default AnimatePage;
