import { Avatar } from '@mui/material'
import React, { useState } from 'react'
import { useEffect } from 'react'
import { useContext } from 'react'
import { Link, useParams } from 'react-router-dom'
import GetCourseBatch from '../../../actions/GetCourseBatch'
import { AuthContext } from '../../../context/AuthContext'
import Localbase from 'localbase'
const CourseBatch = ({account,more}) => {

const params = useParams()
     const course_url = params.course_url

let db = new Localbase('db')
const {user} = useContext(AuthContext)
const [batch,setbatch] = useState(null)
const GetBatch = async()=>{

     const res = await GetCourseBatch({payload:{
          course_url:course_url,
          limit:4,
          user:user.SessionId
     }})
     if(res){
          if(res.status==="success"&&res.team!==null){

setbatch(res.team)

db.collection("paths").add({
     batch:res.team
},'batch')
          }
           //  //console.log("batch "+JSON.stringify(res))
     }
}


useEffect(()=>{

db.collection("paths").doc("batch").get().then(batch=>{
setbatch(batch.batch)
}).catch(error=>{

})

GetBatch()
},[])
  return (

    <>
      
   {batch?
   <>
     <div className="w-100 section sm-mt-50">
   
     <div className="d-flex  justify-content-between">
         <h4>Leaderboard</h4>
         {more?
         <>
               <Link className="" to={"/leaderboard/"+course_url}>View All</Link>
  
         </>:
         <>
         
         
         </>}
     </div>
    <hr className="mt-1"/>

    <div className="d-flex">

    </div>
   {batch.map((user,index)=>(

    <>
<div className="d-flex w-100 justify-content-between align-items-center mt-3 mb-3">
   <Link to={"/u/"+user.username} className="d-flex align-items-center text-dark col">
   <Avatar src={user.profile_pic} className="mr-5"/>
    <div>
         <h6 className="pb-0 truncate truncate1 sm-16">{user.first_name+" "+user.last_name}</h6>
         <p className="truncate truncate1 text-grey extra-small">@{user.username}</p>
    </div>
   </Link>
   <div className="mr-15 text-grey">
 
   <p className="extra-small">{user.points?user.points:0} points</p>
   </div>
   <div>
    #{index+1}
   </div>
   
     </div>
    
    </>
   ))}
      </div>
   </>:
   
   
   <>

   </>

   }


   
    


    
    </>
  )
}

export default CourseBatch