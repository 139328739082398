import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import SearchIcon from '@mui/icons-material/Search';
import EastIcon from '@mui/icons-material/East';
import { Avatar, AvatarGroup, Badge } from '@mui/material';
import LocalMallIcon from '@mui/icons-material/LocalMall';
import BusinessIcon from '@mui/icons-material/Business';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import MarkEmailUnreadIcon from '@mui/icons-material/MarkEmailUnread';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import DirectionsIcon from '@mui/icons-material/Directions';
import { FilterList, ForkRight } from '@mui/icons-material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import axios from 'axios';
import SecurityHeaders from '../../data/SecurityHeaders';
import ENDPOINT from '../../data/ENDPOINT';
import { Dropdown } from 'react-bootstrap';
import JobSearchComponent from './JobSearchComponent';
import Close from '@mui/icons-material/Close'
import ModalStyle from '../../data/ModalStyle';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

const JobHuntOne = () => {



    const [jobs, setJobs] = useState([])
    const [companyname, setCompanyname] = useState([])
    const [searchtype, setSearchType] = useState([])

    const GetJobs = async (payload) => {

        const data = new FormData()

        if (payload === "company_name" && "popular_companies") {
            data.append('key', searchtype)
            data.append('search_name', companyname)
        }


        const response = await axios.post(ENDPOINT + "get-job-hunt.php", data, { 'content-type': 'multipart/form-data' })

        if (response.data.status === 'success') {
            setJobs(response.data);
        } else {
            console.error('Error fetching jobs:', response.data.message);
        }
    };
    useEffect(() => {
        GetJobs()
    }, [])



    const [search, setSearch] = useState(false)



    const openModal = () => {
        setSearch(true)
    }
    const closeModal = () => {
        setSearch(false)
    }

    return (
        <Style>


            <div className=' bg-img min-vh-90'>

                <div className='container'>


                    <div className='d-flex flex-wrap justify-content-between  '>
                        <div className='col-xl-7 col-lg-7 col-md-12 col-sm-12 col-12 font-effect-splintered mt-5 mb-5'>

                            <p className='fs-1 fw-bold text-start'>Find the right job<br></br>With Our AI Powered Job Hunt</p>



                            <div className=' d-flex mt-3 col-9 '>

                                <Paper
                                    className='w-100'
                                    component="form"
                                    sx={{ p: '2px 4px', display: 'flex', alignItems: 'center' }}
                                >
                                    <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                                        <SearchIcon />
                                    </IconButton>
                                    <InputBase onClick={openModal}
                                        sx={{ ml: 1, flex: 1 }}
                                        placeholder="Search Jobs"
                                        inputProps={{ 'aria-label': 'search google maps' }}
                                    />
                                    <IconButton type="button" className='bgn btn-success rounded rounded-circle' sx={{ p: '10px' }} aria-label="search">
                                        <ArrowForwardIcon />
                                    </IconButton>

                                </Paper>

                            </div>


                            <div className='mt-4'>
                                <p className='text-dark fs-5 col-9'>Our AI powered Job Hunt  will find all the jobs across the world for you. Checkout the Job, Apply & Get Placed</p>                           </div>

                            <div className='d-flex justify-content-between mt-3'>


                                <div className='bg-black m-0 p-3 rounded-3 col-3 d-flex justify-content-center'>
                                    <p className='text-light p-3 fs-3 d-flex justify-content-center my-auto fw-bold m-0'>320K</p>
                                </div>

                                <div className='my-auto ms-xl-4 ms-lg-1 ms-md-2 ms-2 ms-sm-2 col-9 '>

                                    <div className='col-xl-5 col-lg-5 col-md-5 col-sm-5 col-5 d-flex '>
                                        <AvatarGroup max={5}>

                                            <Avatar alt="Travis Howard" src="https://skillsuprise.com/data/images/Bhavitha-Shetty.png" />
                                            <Avatar alt="Cindy Baker" src="https://skillsuprise.com/data/images/yahwanth.jpg" />
                                            <Avatar alt="Agnes Walker" src="https://skillsuprise.com/data/images/hemasri.png" />
                                            <Avatar alt="Trevor Henderson" src="https://skillsuprise.com/data/images/saif.png" />
                                            <Avatar alt="Trevor Henderson" src="https://skillsuprise.com/data/images/sravani.jpg" />
                                        </AvatarGroup>
                                    </div>

                                    <div className='col-6'>
                                        <b className='text-black  '>Avatar Users</b>
                                        <small className='d-block'>Discover thousands of exciting job opptunities from various industries</small>
                                    </div>
                                </div>


                            </div>


                        </div>



                        <div className="col-12 col-xl-5 col-lg-5 d-xl-block d-lg-block d-md-none d-none  text-center mt-5 ">
                            <img className='w-75 h-75 my-auto' src="https://www.freepngimg.com/thumb/girl/147846-professional-woman-business-png-file-hd.png" />
                        </div>
                    </div>

                </div>


            </div>


            <Modal
                open={search}
                onClose={closeModal}

            >
                <Box sx={{
                    position: 'absolute',
                    maxWidth: '100%',
                    minWidth: '100%',
                    height: '100%',
                    margin: 'auto',
                    overflowY: 'auto',
                    bgcolor: 'background.paper',
                    pt: 2,
                    px: 4,
                    pb: 3,
                }} className="col-lg-4 rounded col-md-5 br-5 col-sm-6 col-11 p-2">
                    <div className="d-flex justify-content-between">
                        <Close className="text-danger" type="button" onClick={closeModal} />
                    </div>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        <JobSearchComponent />
                    </Typography>
                </Box>
            </Modal>






            {/* Second Section */}

            <div className='second-section-bg p-4      '>

                <div className="d-flex flex-wrap container">

                    <div className='col-xl-3 col-lg-3 col-md-3 col-sm-3 col-6'>
                        <div className='d-flex  justify-content-center'>
                            <LocalMallIcon className='my-auto text-center' />
                            <p className='fw-bold fs-3 p-0 m-0'>{"+" + jobs.totaljobs ? jobs.totaljobs : 2000}</p>
                        </div>

                        <div className='d-flex justify-content-center'>
                            <small className=' ms-4 p-0'>Open Jobs</small>
                        </div>

                    </div>

                    <div className='col-xl-3 col-lg-3 col-md-3 col-sm-3 col-6'>
                        <div className='d-flex  justify-content-center'>
                            <BusinessIcon className='my-auto text-center' />
                            <p className='fw-bold fs-3 p-0 m-0'>{"+" + jobs.totalinternships ? jobs.totalinternships : 1500}</p>
                        </div>

                        <div className='d-flex justify-content-center'>
                            <small className=' ms-4 p-0'>Internships</small>
                        </div>

                    </div>


                    <div className='col-xl-3 col-lg-3 col-md-3 col-sm-3 col-6'>
                        <div className='d-flex  justify-content-center'>
                            <PeopleAltIcon className='my-auto text-center' />
                            <p className='fw-bold fs-3 p-0 m-0'>{"+" + jobs.companies ? jobs.companies : 1000}</p>
                        </div>

                        <div className='d-flex justify-content-center'>
                            <small className=' ms-4 p-0'>Companies</small>
                        </div>

                    </div>


                    <div className='col-xl-3 col-lg-3 col-md-3 col-sm-3 col-6'>
                        <div className='d-flex  justify-content-center'>
                            <MarkEmailUnreadIcon className='my-auto text-center' />
                            <p className='fw-bold fs-3 p-0 m-0'>6,000+</p>
                        </div>

                        <div className='d-flex justify-content-center'>
                            <small className=' ms-4 p-0'>Subscribed Job Hunt</small>
                        </div>

                    </div>

                </div>

            </div>




            {/* Fouth div */}

            <div className='bg-fourth p-3 pt-5 rounded-4 ms-2 me-2'>

                <div className='text-center'>
                    <h1 className='mx-auto'>Recomended Jobs For You</h1>

                    <small className='mx-auto'>Explore Position curated  specifically for your skills and preference</small>

                    {/* <div className='bg-white mt-3 d-flex p-2 col-xl-5 col-lg-5 col-md-10 col-sm-12 col-12 mx-auto rounded-5 shadow'>
                        <div className='col-4' role="button" tabindex="0" onClick={() => GetJobs()}>
                            <p className='fw-semibold text-light bg-dark rounded-4 m-0 fs-xl-5 fs-lg-5 fs-md-5 fs-sm-6 fs-6'>Latest Jobs</p>
                        </div>

                        <div className='col-4' role="button" tabindex="0" onClick={() => GetJobs("popular_companies")}>
                            <p className='fw-semibold text-secondary rounded-4 m-0 fs-xl-5 fs-lg-5 fs-md-5 fs-sm-6 fs-6'>popular company</p>
                        </div>

                        <div className='col-4' role="button" tabindex="0" onClick={() => GetJobs("trending_jobs")}>
                            <p className='fw-semibold text-secondary rounded-4 m-0 fs-xl-5 fs-lg-5 fs-md-5 fs-sm-6 fs-6'>Trending Jobs</p>
                        </div>
                    </div> */}

                </div>

                <form onSubmit={(event) => { event.preventDefault(); GetJobs() }}>

                    <div className='col-lg-4 col-md-6 col-sm-6 mx-auto d-flex mt-5'>

                        <Paper
                            className='w-100'
                            component="form"
                            sx={{ p: '2px 4px', display: 'flex', alignItems: 'center' }}
                        >
                            <select onChange={(e) => setSearchType(e.target.value)}>
                                <option value={"all"}>All</option>

                                <option value={"popular_companies"}>Popular Companies</option>
                                <option value={"trending_jobs"}>Trending Jobs</option>
                                <option value={"latest_jobs"}>Latest Jobs</option>
                            </select>
                            <div className='border-start'>
                                <InputBase onChange={(e) => { setCompanyname(e.target.value) }}
                                    sx={{ ml: 1, flex: 1 }}
                                    placeholder="Search Jobs"
                                    inputProps={{ 'aria-label': 'search google maps' }}
                                />
                            </div>
                            <IconButton onClick={() => GetJobs('company_name')} type="button" sx={{ p: '10px', ml: 3 }} aria-label="search">
                                <SearchIcon />
                            </IconButton>


                        </Paper>

                    </div>
                </form>


                <div className='d-flex flex-wrap container mt-3'>

                    {jobs.jobs ? <>
                        {jobs.jobs.map((item) => (
                            <div className='col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 p-xl-4 p-lg-2 p-md-2 p-sm-3 p-3'>
                                <div className='bg-white rounded-4 shadow p-3 d-xl-block d-lg-block d-md-flex d-sm-flex d-flex flex-wrap'>


                                    <div className='col-xl-8 col-lg-8 col-md-4 col-sm-6 col-6  logo-section ps-3'>

                                        <div>
                                            <b>{item.company_name}</b>
                                            <small className='d-block'>2 months ago</small>
                                        </div>
                                    </div>

                                    <div className='d-flex col-xl-7 col-lg-7 col-md-8 col-sm-12 col-12 justify-content-between'>

                                        <div className='col-xl-12 col-lg-12 col-md-5 col-sm-5 col-8 p-xl-3 p-lg-3 p-md-3 p-sm-2 '>
                                            <Badge className='bg-fourth p-2 rounded-3 '>
                                                <small className='p-0 m-0 fw-semibold '>{item.location}</small>
                                            </Badge>
                                        </div>

                                        <div className='col-xl-12 col-lg-12 col-md-5 col-sm-5 col-5 p-xl-3 p-lg-3 p-md-3 p-sm-2 '>
                                            <Badge className='bg-fourth p-2 rounded-3 '>
                                                <small className='p-0 m-0 fw-semibold '>{item.mode}</small>
                                            </Badge>
                                        </div>

                                        <div className='col-xl-2 col-lg-2 col-md-1 col-sm-1 col-1 '>

                                        </div>




                                    </div>

                                    <div className='ps-3 d-xl-block d-lg-block d-md-flex d-sm-flex d-block '>
                                        <div>
                                            <h4 className='p-0 m-0'>{item.title}</h4>

                                            <small>{item.responsibilities}</small>
                                        </div>

                                        <div className='col-xl-6 col-lg-6 col-md-4 col-sm-4 col-12 mt-3'>
                                            <div className="btn btn-transparant border border-3 border-success d-block rounded-3">
                                                <Link to={"/job-details/"+item.id} className='nav-link fw-semibold text-success w-100'>Apply Now</Link>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        ))}
                    </> :
                        <>

                        </>}

                </div>

                <div className='d-flex justify-content-center mt-4 mb-3'>
                    <div className="btn btn-success rounded-3 d-xl-block d-lg-block d-md-flex d-sm-flex d-flex flex-wrap ">
                        <p className='m-0'>Browse More Jobs</p>
                    </div>
                </div>

            </div>

            {/* fifth div */}




























            <div className='bg-black p-3 pt-5' >
                <div className='d-flex container flex-wrap '>

                    <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 p-3'>
                        <p className='text-white fs-2 fw-bold'>Stride Towards Your ideal Career</p>
                    </div>

                    <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 p-3'>
                        <p className='text-light'>Responsible for developing intuitive and engaing user interface in digital focusing/Responsible for developing intuitive and engaing user interface in digital focusing</p>

                        <div className='d-flex col-6 justify-content-between mt-2'>

                            <div className="btn btn-success rounded rounded-3"><p className='text-light ps-4 pe-4 m-0'>Login</p></div>

                            <div className="btn btn-transprant border border-success rounded rounded-3"><p className='text-success  ps-4 pe-4 m-0'>Login</p></div>

                        </div>
                    </div>

                </div>

                <hr className='mb-5 text-light' />

                <div className=' d-xl-flex d-lg-flex d-md-flex d-none justify-content-between col-xl-12 col-lg-12 col-md-12 ps-xl-4 ps-lg-4 ps-md-4 ps-1  pe-xl-4 pe-lg-4 pe-md-4 pe-1'>

                    <div className='col-2'>
                        <Link to="" className='text-decoration-none' ><p className='text-light p-2 m-0' >Home</p></Link>
                    </div>

                    <div className="col-2">
                        <Link to="" className='text-decoration-none' ><p className='text-light p-2 m-0' >Company</p></Link>
                    </div>
                    <div className="col-2">
                        <Link to="" className='text-decoration-none' ><b className='text-light p-2 m-0 fs-3' >JobSync</b></Link>
                    </div>
                    <div className="col-2">
                        <Link to="" className='text-decoration-none' ><p className='text-light p-2 m-0' >Jobs</p></Link>
                    </div>
                    <div className="col-2">
                        <Link to="" className='text-decoration-none' ><p className='text-light p-2 m-0 ' >Community</p></Link>
                    </div>


                </div>

                <div className='d-flex flex-wrap mt-5 container'>

                    <div className='col-xl-6 col-lg-6 d-xl-block d-lg-block d-md-none d-sm-none d-none p-5'>
                        <img className='w-100 rounded-5' src="https://th.bing.com/th/id/OIP.v2aCkFJ4519etpbfDFqHNAHaE7?rs=1&pid=ImgDetMain" />
                    </div>

                    <div className='col-xl-5 col-lg-5 col-md-8 col-sm-10 col-12 my-auto'>

                        <div className='d-flex p-5'>
                            <div className='col-2'>
                                <div className='bg-white w-100  d-flex justify-content-center align-items-center  rounded-circle p-3'>
                                    <p className='m-0 fs-3'>1</p>
                                </div>
                            </div>

                            <div className='ms-4'>
                                <p className='fs-3 fw-semibold text-light m-0'>Register as a user</p>
                                <p className='text-light m-0'>The first step is to Create a user account on jobsync and make the application process forword</p>
                            </div>
                        </div>

                        <div className='d-flex p-3 '>
                            <div className='col-2'>
                                <div className='bg-white w-75  d-flex justify-content-center align-items-center  rounded-circle p-3  '>
                                    <p className='m-0   fs-3 '>2</p>
                                </div>
                            </div>

                            <div className='ms-4'>
                                <p className='fs-3 fw-semibold text-light m-0'>Register as a user</p>
                                <p className='text-light m-0'>The first step is to Create a user account on jobsync and make the application process forword</p>
                            </div>
                        </div>

                        <div className='d-flex p-3 '>
                            <div className='col-2'>
                                <div className='bg-white w-75  d-flex justify-content-center align-items-center  rounded-circle p-3  '>
                                    <p className='m-0   fs-3 '>3</p>
                                </div>
                            </div>

                            <div className='ms-4'>
                                <p className='fs-3 fw-semibold text-light m-0'>Register as a user</p>
                                <p className='text-light m-0'>The first step is to Create a user account on jobsync and make the application process forword</p>
                            </div>
                        </div>


                    </div>

                </div>
            </div>










            {/* Third section */}

            <div className='bg-black p-3 mt-4 d-flex flex-wrap rounded-4 ms-2 me-2'>

                <div className='col-xl-5 col-lg-5 col-md-5 col-sm-12 col-12 container'>

                    <p className='text-white fw-bold fs-1'>
                        Get employed by renowned startups
                    </p>

                    <p className='text-light'>
                        Explore exciting career opening at industry-leading satrtups known for inovation and growth. join a dynamic team and be a part of ground breaking
                    </p>

                    <div className='d-flex flex-wrap'>
                        <div className='col-6 p-3'>
                            <div className='bg-white rounded-3  d-flex p-2'>

                                <div>

                                </div>

                                <div>
                                    <p className='fw-bold fs-5 p-0 m-0'>Vista</p>
                                    <small>128+ jobs avalible</small>
                                </div>
                            </div>
                        </div>

                        <div className='col-6 p-3'>
                            <div className='bg-white rounded-3  d-flex p-2'>

                                <div>

                                </div>

                                <div>
                                    <p className='fw-bold fs-5 p-0 m-0'>Rounded</p>
                                    <small>92+ jobs avalible</small>
                                </div>
                            </div>
                        </div>


                        <div className='col-6 p-3'>
                            <div className='bg-white rounded-3  d-flex p-2'>

                                <div>

                                </div>

                                <div>
                                    <p className='fw-bold fs-5 p-0 m-0'>Davance</p>
                                    <small>62+ jobs avalible</small>
                                </div>
                            </div>
                        </div>


                        <div className='col-6 p-3'>
                            <div className='bg-white rounded-3  d-flex p-2'>

                                <div className='my-auto'>

                                </div>

                                <div>
                                    <p className='fw-bold fs-5 p-0 m-0'>BMW</p>
                                    <small>70+ jobs avalible</small>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>

                <div className='col-xl-6 col-lg-7 col-md-7 col-sm-12 col-12 container'>
                    <div className='p-4 d-flex justify-content-center rounded-4'>
                        <img className='w-100 rounded-4' src="https://canopy.sfo2.cdn.digitaloceanspaces.com/public/Uploads/lycs-architecture-U2BI3GMnSSE-unsplash__FocusFillWzEwMDAsODAwLCJ4Iiw5OV0.jpg" />
                    </div>
                </div>

            </div>



            <div className='bg-five p-3 mt-4 rounded-4 ms-2 me-2'>


                <div className='text-center'>
                    <h1 className='mx-auto'>Recomended Position For You</h1>

                    <small className='mx-auto'>Explore Position curated  specifically for your skills and preference</small>
                </div>

                <div className='d-flex flex-wrap container'>

                    <div className='col-xl-4 col-lg-4 col-md-12 col-12 p-xl-4 p-lg-4 p-md-2 p-sm-2 p-2'>
                        <div className='bg-white rounded-4 shadow p-3  '>

                            <div className='d-flex logo-section ps-3'>
                                <div>
                                    <Avatar />
                                </div>

                                <div className='ms-3'>
                                    <b>Vista</b>
                                    <small className='d-block'>2 Months ago</small>
                                </div>
                            </div>

                            <div className='ps-3 mt-2'>

                                <small>Responsible for developing intuitive and engaing user interface  in digital focusing/Responsible for developing intuitive and engaing user interface  in digital focusing</small>
                            </div>

                        </div>
                    </div>


                    <div className='col-xl-4 col-lg-4 col-md-12 col-12 p-xl-4 p-lg-4 p-md-2 p-sm-2 p-2'>
                        <div className='bg-white rounded-4 shadow p-3  '>

                            <div className='d-flex logo-section ps-3'>
                                <div>
                                    <Avatar />
                                </div>

                                <div className='ms-3'>
                                    <b>Vista</b>
                                    <small className='d-block'>2 Months ago</small>
                                </div>
                            </div>

                            <div className='ps-3 mt-2'>

                                <small>Responsible for developing intuitive and engaing user interface  in digital focusing/Responsible for developing intuitive and engaing user interface  in digital focusing</small>
                            </div>

                        </div>
                    </div>


                    <div className='col-xl-4 col-lg-4 col-md-12 col-12 p-xl-4 p-lg-4 p-md-2 p-sm-2 p-2'>
                        <div className='bg-white rounded-4 shadow p-3  '>

                            <div className='d-flex logo-section ps-3'>
                                <div>
                                    <Avatar />
                                </div>

                                <div className='ms-3'>
                                    <b>Vista</b>
                                    <small className='d-block'>2 Months ago</small>
                                </div>
                            </div>

                            <div className='ps-3 mt-2'>

                                <small>Responsible for developing intuitive and engaing user interface  in digital focusing/Responsible for developing intuitive and engaing user interface  in digital focusing</small>
                            </div>

                        </div>
                    </div>


                    <div className='col-xl-4 col-lg-4 col-md-12 col-12 p-xl-4 p-lg-4 p-md-2 p-sm-2 p-2'>
                        <div className='bg-white rounded-4 shadow p-3  '>

                            <div className='d-flex logo-section ps-3'>
                                <div>
                                    <Avatar />
                                </div>

                                <div className='ms-3'>
                                    <b>Vista</b>
                                    <small className='d-block'>2 Months ago</small>
                                </div>
                            </div>

                            <div className='ps-3 mt-2'>

                                <small>Responsible for developing intuitive and engaing user interface  in digital focusing/Responsible for developing intuitive and engaing user interface  in digital focusing</small>
                            </div>

                        </div>
                    </div>


                    <div className='col-xl-4 col-lg-4 col-md-12 col-12 p-xl-4 p-lg-4 p-md-2 p-sm-2 p-2'>
                        <div className='bg-white rounded-4 shadow p-3  '>

                            <div className='d-flex logo-section ps-3'>
                                <div>
                                    <Avatar />
                                </div>

                                <div className='ms-3'>
                                    <b>Vista</b>
                                    <small className='d-block'>2 Months ago</small>
                                </div>
                            </div>

                            <div className='ps-3 mt-2'>

                                <small>Responsible for developing intuitive and engaing user interface  in digital focusing/Responsible for developing intuitive and engaing user interface  in digital focusing</small>
                            </div>

                        </div>
                    </div>


                    <div className='col-xl-4 col-lg-4 col-md-12 col-12 p-xl-4 p-lg-4 p-md-2 p-sm-2 p-2'>
                        <div className='bg-white rounded-4 shadow p-3  '>

                            <div className='d-flex logo-section ps-3'>
                                <div>
                                    <Avatar />
                                </div>

                                <div className='ms-3'>
                                    <b>Vista</b>
                                    <small className='d-block'>2 Months ago</small>
                                </div>
                            </div>

                            <div className='ps-3 mt-2'>

                                <small>Responsible for developing intuitive and engaing user interface  in digital focusing/Responsible for developing intuitive and engaing user interface  in digital focusing</small>
                            </div>

                        </div>
                    </div>
                </div>


            </div>

            {/* six div */}


        </Style>
    )
}

export default JobHuntOne

const Style = styled.div`
.bg-img{
background-color:#D0BFFF;
}

.splintered {
    font-family: "Chrome, Safari";
    font-weight: 400;
    font-style: normal;
  }

  .btn-radius{
    border-radius: 40px 0px 0px 40px;
  }
  .radius{
    border-radius: 0px 40px 40px 0px;
  }
  .second-section-bg{
    background-color:#C1F2B0;
  }
.bg-fourth{
    background-color:#F1EFEF;
}
.bg-five{
    background-color:#F78CA2;

}


`