import React from 'react'
import Skeleton from "@mui/material/Skeleton";

const MockTests = () => {
  return (
    <div className="d-flex flex-wrap container justify-content-between">
    <div className="col-lg-4 p-3">
    <div className="col-12  mt-5 rounded border ">
    <>
          <Skeleton
            sx={{ height: 160 }}
            animation="wave"
            variant="rectangular"
          />

         <div className="p-2">
         <Skeleton variant="text" className="col-lg-12 mt-1" height={40} />
          
          <div className="d-flex justify-content-between mt-1">
            <Skeleton variant="caption" className="col-lg-5 mt-2" height={20} />
            <Skeleton variant="caption" className="col-lg-5 mt-2" height={20} />
          </div>
          <Skeleton variant="caption" className=" mt-3" height={5} />
          <div className="d-flex justify-content-end">
          <Skeleton variant="caption" className="col-lg-3 mt-2" height={20} />

          </div>

         </div>
        </>
      </div>
    </div>
    <div className="col-lg-4 p-3">
    <div className="col-12  mt-5 rounded border ">
    <>
          <Skeleton
            sx={{ height: 160 }}
            animation="wave"
            variant="rectangular"
          />

         <div className="p-2">
         <Skeleton variant="text" className="col-lg-12 mt-1" height={40} />
          
          <div className="d-flex justify-content-between mt-1">
            <Skeleton variant="caption" className="col-lg-5 mt-2" height={20} />
            <Skeleton variant="caption" className="col-lg-5 mt-2" height={20} />
          </div>
          <Skeleton variant="caption" className=" mt-3" height={5} />
          <div className="d-flex justify-content-end">
          <Skeleton variant="caption" className="col-lg-3 mt-2" height={20} />

          </div>

         </div>
        </>
      </div>
    </div>
    <div className="col-lg-4 p-3">
    <div className="col-12  mt-5 rounded border ">
    <>
          <Skeleton
            sx={{ height: 160 }}
            animation="wave"
            variant="rectangular"
          />

         <div className="p-2">
         <Skeleton variant="text" className="col-lg-12 mt-1" height={40} />
          
          <div className="d-flex justify-content-between mt-1">
            <Skeleton variant="caption" className="col-lg-5 mt-2" height={20} />
            <Skeleton variant="caption" className="col-lg-5 mt-2" height={20} />
          </div>
          <Skeleton variant="caption" className=" mt-3" height={5} />
          <div className="d-flex justify-content-end">
          <Skeleton variant="caption" className="col-lg-3 mt-2" height={20} />

          </div>

         </div>
        </>
      </div>
    </div>
   
   
    
      
     </div>
  )
}

export default MockTests