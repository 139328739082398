import axios from 'axios'
import ENDPOINT from '../data/ENDPOINT'
import SecurityHeaders from '../data/SecurityHeaders'

const GetWorkshopDetails = async ({workshop_url})=>{

const data = new FormData()
data.append("workshop_url",workshop_url)

const res = await 
axios.post(ENDPOINT+`workshop-details.php`,data,SecurityHeaders)
if(res){
    
     return res.data
}

}
export default GetWorkshopDetails