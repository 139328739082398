import React, { useEffect, useState } from 'react'
import ReactApexChart from 'react-apexcharts'

const AssignmentsChart = ({report}) => {

const [state,setstate]= useState({
            
     series: [1, 0,3],
     options: {
       chart: {
        
         type: 'pie',
       },
       labels: ['Marks Lost','Marks Earned','Under Evaluation' ],
       responsive: [{
         breakpoint: 480,
         options: {
           chart: {
          
           },
           legend: {
             position: 'bottom'
           }
         }
       }]
     },
   
   
   })

  
  
     //set new data
     
    
     useEffect(() => {
       
if(report){

     setstate({
            
          series: [(100-((report.marks_earned/report.total_marks)*100)),((report.marks_earned/report.total_marks)*100)],
          options: {
            chart: {
             
              type: 'pie',
            },
            labels: ['Marks Lost','Marks Earned' ],
            responsive: [{
              breakpoint: 480,
              options: {
                chart: {
               
                },
                legend: {
                  position: 'bottom'
                }
              }
            }]
          },
        
        
        })
}

  
     }, [])

        
  
       
  
     
     return (
          <div className="w-100">
              
        <div id="chart">
             
    <ReactApexChart options={state.options} series={state.series} type="pie" width={380} />
  </div>
       
          </div>
     )
}

export default AssignmentsChart
