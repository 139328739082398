import React from "react";

import { CheckBoxRounded, Close, WhatsApp } from "@mui/icons-material";

import cssIcon from '../../../assets/images/workshops/icons/css-3.png'

import amazonIcon from'../../../assets/images/workshops/icons/social.png'

import divider from '../../../assets/images/divider.png'
import ContactSection from "../../../components/ContactSection";
import internship from '../../../assets/icons/internships.svg'
import placement from '../../../assets/icons/placement.svg'
import certificate from '../../../assets/icons/certificate.svg'
import premium from '../../../assets/icons/diamond.png'
import DefaultTemplate from "../../temaplates/DefaultTemplate";
import Modal from '@mui/material/Modal';

import { useState } from "react";
import { Rating,  Typography } from "@mui/material";
import Box from '@mui/material/Box';
import ModalStyle from "../../../data/ModalStyle";
import WorkshopRegistrationForm from "../WorkshopRegistrationForm";


const AmazonWorkshop = () => {

  const [register,setRegister] = useState(false)
  return (
    <>


<Modal
        open={register}
        onClose={()=>{setRegister(false)}}
      >
        <Box sx={ModalStyle} className="col-lg-4 rounded col-md-5 col-sm-6 col-11 p-3">
          <Typography id="modal-modal-title" className="d-flex justify-content-between" variant="h6" component="h2">
         Apply for Internship
         <Close className="click" onClick={()=>setRegister(false)}/>
          </Typography>

          <WorkshopRegistrationForm workshop_id={1111}/>
       
   </Box>
      </Modal>
     <DefaultTemplate>
     <div className="container-fluid pt-5 hacking-internship-bg bg-dark text-light ">
        <div className="container flex-wrap justify-content-between d-flex  ">
          <div className="col-lg-6 mb-5 col-md-7 mt-5 col-sm-12 p-3  br-5 text-white">
            <h4>National Workshop on</h4>
            <h1>AMAZON WEBSITE DEVELOPMENT USING REACT </h1>
            <div className="d-flex text-white align-0items-center">
            <Rating name="read-only" value={4.5} precision={0.5} readOnly />
              <h6>4.8/5 (5,000+ Ratings)</h6>
            </div>
            <p className="text-light">
              Learn Hacking from the best trainer who Trained DSP Rank Officers
              , College Students and Corporate Teams
            </p>
            <hr />
            <ul className="text-secondary">
              <li>Free Training & Internship (for eligible candidates)</li>
              <li>Certificate of participation</li>
              <li>Access to Premium hacking tools</li>
              <li>Access to Premium E-Books</li>
            </ul>
            <div className="d-flex">
            <a href="https://wa.me/?number=919490174876&text=Ethical%20Hacking%Internship%20Details%Needed&type=custom_url&app_absent=0" target="_blank" className="btn me-4 btn-success"><WhatsApp/> Contact Us</a>

              <button onClick={()=>setRegister(true)} className="btn btn-primary">Register Now for Free</button>
            </div>
          </div>

          <div className="col-lg-5 p-4 rounded ">
            <img className="w-100 rounded" src="https://skillsuprise.com/resources/images/image1.png" />
          </div>
        </div>
      </div>

      <div className="container mt-5">
      <h2 className="text-center ">Topics to be covered</h2>
        <div className="row align-items-center">
          <div className="col-lg-5">
            <img src={"https://skillsuprise.com/resources/images/amazon.png"} className="w-100" />
          </div>

          <div className="col-lg-7 ps-5 sm-p-0 sm-mt-20">
            <h2 className="uppercase">From Scratch To Advanced Training</h2>
            <p>Work with 100+ hacking tools, techniques and practical labs</p>

            <div className="d-flex align-items-center mt-3">
              <CheckBoxRounded color="success" />
              <h6 className="pb-0 mb-0 text-secondary">
                Roadmap to Become a Full stack Website Developer
              </h6>
            </div>

            <div className="d-flex align-items-center mt-3">
              <CheckBoxRounded color="success" />
              <h6 className="pb-0 mb-0 text-secondary">Basics of HTML</h6>
            </div>

            <div className="d-flex align-items-center mt-3">
              <CheckBoxRounded color="success" />
              <h6 className="pb-0 mb-0 text-secondary">Fundamentals of CSS</h6>
            </div>

            <div className="d-flex align-items-center mt-3">
              <CheckBoxRounded color="success" />
              <h6 className="pb-0 mb-0 text-secondary">
                Fundamentals of React
              </h6>
            </div>

            <div className="d-flex align-items-center mt-3">
              <CheckBoxRounded color="success" />
              <h6 className="pb-0 mb-0 text-secondary">
                Amazon website development using React
              </h6>
            </div>
          </div>
        </div>
      </div>

      <div className="container mt-5">
      <div>
        <h2 className="text-center">Workshop Benefits</h2>
       <div className="col-2 mx-auto">
       <img src={divider} className="w-100   "/>
       </div>
      </div>
        <div className="row mt-3 ">
          <div className="col-lg-4 p-3">
            <div className="col-12 rounded shadow p-4 d-flex align-items-center">
              <div className="col-2 rounded-circle">
                <img src={internship} className="w-75"/>
              </div>
              <div>
              <h5 className="mt-3">Internship for eligible candidates</h5>
              </div>


            </div>

          </div>

          <div className="col-lg-4 p-3">
            <div className="col-12 rounded shadow p-4 d-flex align-items-center">
              <div className="col-2 rounded-circle">
                <img src={placement} className="w-75"/>
              </div>
              <h5 className="mt-3">Pre-Placement Opportunity</h5>


            </div>

          </div>

          <div className="col-lg-4 p-3">
            <div className="col-12 rounded shadow p-4 d-flex align-items-center">
              <div className="col-2 rounded-circle">
                <img src={certificate} className="w-75"/>
              </div>
              <h5 className="mt-3">Certificate of Participation</h5>


            </div>

          </div>



        </div>

        <div className="row mt-3 ">
          <div className="col-lg-4 p-3">
            <div className="col-12 rounded shadow p-4 d-flex align-items-center">
              <div className="col-2 rounded-circle">
                <img src={premium} className="w-75"/>
              </div>
              <div>
              <h5 className="mt-3">Access to premium software</h5>
              </div>


            </div>

          </div>

          <div className="col-lg-4 p-3">
            <div className="col-12 rounded shadow p-4 d-flex align-items-center">
              <div className="col-2 rounded-circle">
                <img src={amazonIcon} className="w-75"/>
              </div>
              <h5 className="mt-3">Amazon website development using React</h5>


            </div>

          </div>

          <div className="col-lg-4 p-3">
            <div className="col-12 rounded shadow p-4 d-flex align-items-center">
              <div className="col-2 rounded-circle">
                <img src={cssIcon} className="w-75"/>
              </div>
              <h5 className="mt-3">Fundamentals of CSS</h5>


            </div>

          </div>



        </div>

      </div>

      <div className="mt-5 mb-5">


<div className="container d-flex flex-wrap justify-content-between">

<div className="col-lg-6   br-5 p-4 sm-p-0">
<img src="https://skillsuprise.com/static/media/workshop-certificate.c3dfaa4a.png" className="w-100"/>
       
</div>

<div className="col-lg-6 sm-mt-20  br-5 p-4 sm-p-0">
<h2>Training & Internship Certificate</h2>
<p>An X-Factor to your resume and the best way to prove your skills to land your dream job in cyber security</p>


<div className="d-flex align-items-center mt-3">
    <CheckBoxRounded  className="text-success mr-2"/>
    <h6  className="pb-0 mb-0">Certificate of training</h6>
</div>

<div className="d-flex align-items-center mt-3">
    <CheckBoxRounded  className="text-success mr-2"/>
    <h6  className="pb-0 mb-0">Certificate of Internship</h6>
</div>

<div className="d-flex align-items-center mt-3">
    <CheckBoxRounded  className="text-success mr-2"/>
    <h6  className="pb-0 mb-0">Best Intern Certificate (top performers)</h6>
</div>

<div className="d-flex align-items-center mt-3">
    <CheckBoxRounded  className="text-success mr-2"/>
    <h6  className="pb-0 mb-0">Recommendation Letter (top performers)</h6>
</div>
</div>
</div>
    
    </div>

    <div className="pb-5">
        <ContactSection/>
        </div>
     </DefaultTemplate>
    </>
  );
};

export default AmazonWorkshop;
