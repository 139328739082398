import React from 'react';

const  MobileGallery = ()=>{

        return (
         <>
         
         </>
        );
    }
export default MobileGallery