import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import SetPage from '../../data/SetPage'

const CommunitySidebar = () => {

    useEffect(()=>{

    },[])

    const [page,setpage] = useState(null)
  return (
    <div>
        
        <div className="border sidebar-options w-100 br-5 p-3">
    <Link to="/newsfeed">
    <div className={page==="recommendations"?"active-side-option":"side-option"}>
    <h6>Newsfeed</h6>
    </div>
    </Link>

    <Link to="/my-posts">
    <div className={page==="followers"?"active-side-option":"side-option"}>
    <h6>My Posts</h6>
    </div>
    </Link>


    <Link to="/saved-posts">
    <div className={page==="followers"?"active-side-option":"side-option"}>
    <h6>Saved Posts</h6>
    </div>
    </Link>


    <Link to="/job-alerts">
    <div className={page==="recommendations"?"active-side-option":"side-option"}>
    <h6>Latest Jobs</h6>
    </div>
    </Link>

   



</div>




        </div>
  )
}

export default CommunitySidebar