
import defaultboy from '../../assets/user.png'
import React, { useState } from 'react'
import axios from 'axios'
import ENDPOINT from '../../data/ENDPOINT'
import { useContext } from 'react';
import { AuthContext } from '../../context/AuthContext';
import { Link, useParams } from 'react-router-dom';
import {useEffect} from 'react'
import Localbase from 'localbase';
import Alert from '@mui/material/Alert';
import ConnectionItem from './ConnectionItem';
import ConnectionsTemplate from './ConnectionsTemplate';
import SecurityHeaders from '../../data/SecurityHeaders';
import SetPage from '../../data/SetPage';
import DetectDevice from '../../data/DetectDevice';
import { CircularProgress } from '@mui/material';
import AnimatePage from '../../actions/animations/AnimatePage';
import '../../assets/css/style.css'
const AllConnections = ({post,account,access,limit}) => {
const params = useParams()

const {user}= useContext(AuthContext)


const [connections,setconnections] = useState(null)
let db = new Localbase("db")




const [newlimit,setnewlimit] = useState(limit||40)
    const getConnections = async ()=>{
        
setloading(true)
        const formData = new FormData();
        formData.append('session',user.SessionId);
        
   
            formData.append("limit",newlimit)
      
        
        const res = await axios.post(ENDPOINT+"connections.php", formData,SecurityHeaders);
        if(res){
            setloading(false)
         ////console.log("connections "+JSON.stringify(res))
         if(res.data.status==="success" && res.connections!==null){
setconnections(res.data.connections)
AnimatePage()
         }
       
        }
        }



useEffect(()=>{
    SetPage("Recommendations")
    getConnections()

},[])




const [nomore,setnomore] = useState(false)

const device = DetectDevice()



const [loading,setloading] = useState(false)

const LoadConnections = ()=>{

    const container = document.getElementById("container")
    if(container){
  
      const containerheight = container.clientHeight
      const scrolledheight = window.scrollY
     
      if(scrolledheight>(containerheight-300)){
        setnewlimit(newlimit+40)
       
      if(nomore){
      
      }
      else{
      
      }
      }
        
      
   
  
    }
  
  }
  
  useEffect(()=>{
  
    window.addEventListener("scroll", LoadConnections);
    
  
  },[])

useEffect(()=>{
getConnections()
},[newlimit,limit])


    const [alert,setalert] = useState(false)       

    return (
        <>
<ConnectionsTemplate>



<div className="col-lg-8  col-md-8 col-sm-12 col-12 overflow-y flow">


{connections?
<>

{access?
<>


</>:
<>
<Alert className="mb-3 w-100" severity="info">Latest 70 accounts are only displayed. Please register a course to get access to all connections.</Alert>

</>}
<div id="container" className="grid lg-grid-5 animate grid-2 md-grid-4 sm-grid-3">


{connections.map((connection,index)=>(
    <>
    
   <ConnectionItem className={"fade-in-bottom delay"+(index+1)+"s"} key={connection.user_id} access={access} account={account} connection={connection}/> 

    
    </>
))}





</div>

{loading?
<>
<div className=" text-center justify-content-center mt-5 mb-5">

<CircularProgress/>

</div>



</>:<>
{newlimit>=70?
<>
<Alert className="mb-5 mt-5  w-100" severity="error">Earn more points to connect with more friends.</Alert>

</>:
<>


</>}

</>}





</>:<>

{loading?
<>
<div className="grid text-center justify-content-center mt-5 mb-5">

<CircularProgress/>

</div>



</>:null}


</>}


</div>



 
</ConnectionsTemplate>
        
        </>
    )
}

export default AllConnections
