import axios from 'axios'
import React, { useEffect, useState } from 'react'
import ENDPOINT from '../../data/ENDPOINT'
import SecurityHeaders from '../../data/SecurityHeaders'
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';

import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { Link } from 'react-router-dom';
import ModalStyle from '../../data/ModalStyle';
import SetPage from '../../data/SetPage';
import { CircularProgress } from '@material-ui/core';
import Localbase from 'localbase';
import SearchIcon from "@mui/icons-material/Search";
import Rating from '@mui/material/Rating';

const TechnicalQuizzes = ({ account }) => {


     useEffect(() => {
          window.scrollTo(0, 0)
          db.collection("paths").doc("technical_quiz_categories").get().then(data => {
               setcategories(data.mock_test_categories)
               setloading(false)
          }).catch(error => {

          })
     }, [])


     const [query, setquery] = useState(null)

     const SearchTechnicalQuizzes = (e) => {

          e.preventDefault()

     }

     const [categories, setcategories] = useState(null)
     let db = new Localbase('db')
     const GetQuizCategories = async () => {
          setloading(true)
          const data = new FormData()
          const res = await axios.post(ENDPOINT + "get-quiz-categories.php", data, SecurityHeaders)

          if (res) {
               setloading(false)
               if (res.data.status === "success") {
                    setResponse(res.data.data)
                    setInititalData(res.data.data)
                    setcategories(res.data.data)
                    db.collection("paths").add({
                         technical_quiz_categories: res.data.data
                    }, ['technical_quiz_categories'])
               }

          }
     }

     useEffect(() => {
          GetQuizCategories()
          SetPage("Quizzes")
     }, [])

     useEffect(() => {
          if (account) {
               if (account.purchases && account.purchases.length > 0) {
                    setaccess(true)
               } else {
                    setaccess(false)
               }
          }
     }, [account])

     const [access, setaccess] = useState(false)
     const [modal, setmodal] = useState(false)


     const ViewQuiz = () => {
          if (access) {

          }
          else {
               setmodal(true)
          }
     }
     const [loading, setloading] = useState(false)
     const [initialData, setInititalData] = useState(null);

     const [response, setResponse] = useState(initialData);
     const [searchTerm, setSearchTerm] = useState("");

     const handleSearch = (e) => {
          const term = e.target.value.toLowerCase();

          const filteredData = initialData.filter((item) => {
               return item.title.toLowerCase().includes(term);
          });

          setResponse(filteredData);
          setSearchTerm(term);
     };
     return (


          <>






               <Modal
                    open={modal}
                    onClose={() => { setmodal(false) }}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
               >
                    <Box className="col-lg-4 col-md-5 col-sm-6 col-11 p-2" sx={ModalStyle}>
                         <Typography id="modal-modal-title" variant="h6" component="h2">
                              Student Access Only !
                         </Typography>
                         <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                              Login into your account to access this section


                              <div className="d-flex justify-content-between mt-4">
                                   <button onClick={() => setmodal(false)} className="btn btn-sm  btn-outline-danger rounded-pill">Cancel</button>
                                   <Link to="/login" className="btn btn-sm  btn-primary rounded-pill">Login</Link>
                              </div>
                         </Typography>
                    </Box>
               </Modal>


               <div className="min-vh-100 interview-praparation pb-5">













                    {/* <div className="container text-light">
                              <h1>Technical Quizzes to Test and Improve Your Skills</h1>

                         </div> */}

                    <div className="d-flex align-items-center tech-cover text-light w-100 min-vh-30">
                         <div className="container justify-content-center  d-flex flex-wrap">
                              <div className="col-lg-8 text-center pt-2 pt-md-0 ps-md-3 ps-0 col-sm-12 col-12">
                                   <h2>Technical Quizzes to Test and Improve Your Skills</h2>

                                   <Paper
                                        component="form"
                                        onSubmit={(e) => SearchTechnicalQuizzes(e)}
                                        className="mt-3 col-lg-8 col-md-9 col-12 mb-4 mx-auto"
                                        sx={{ p: '2px 4px', display: 'flex', alignItems: 'center' }}
                                   >

                                        <InputBase
                                             onChange={handleSearch}
                                             value={query}
                                             sx={{ ml: 1, flex: 1 }}
                                             placeholder="Search Mocktests"
                                             inputProps={{ 'aria-label': 'search Mocktets' }}
                                        />
                                        <IconButton type="submit" sx={{ p: '10px' }} aria-label="search">
                                             {query ?
                                                  <>
                                                       {/* <CloseIcon onClick={() => setquery(null)} /> */}
                                                  </> : <SearchIcon />}
                                        </IconButton>

                                   </Paper>





                                   {/* <div className="d-flex justify-content-end mt-5 mb-3 container">
              <div className="d-flex p-1 border border-1 align-items-center  col-12 col-sm-10 col-md-4 col-lg- ">
                <SearchIcon className="ri-search-line  text-secondary" />
                <input
                  onChange={handleSearch}
                  type="text"
                  className="border border-0"
                  placeholder="Search"
                />
              </div>
            </div> */}
                              </div>
                         </div>

                         {/* <div className="col-lg-5 ">
          <img
            src="https://skillsuprise.com/resources/images/prepare-mock.png"
            className="w-100"
          />
        </div> */}
                    </div>




                    <div className="container d-none d-flex flex-wrap justify-content-between pt-5 ">

                         <h2>Technical Quizzes</h2>


                         <Box >
                              <FormControl onClick={() => setmodal(true)} >
                                   <InputLabel id="demo-simple-select-label">Difficulty</InputLabel>
                                   <Select

                                        value={"Python"}
                                   >
                                        <MenuItem value={"Python"}>Beginner</MenuItem>
                                        <MenuItem value={"ai"}>Intermediate</MenuItem>
                                        <MenuItem value={"dsPython"}>Advanced</MenuItem>



                                   </Select>
                              </FormControl>
                         </Box>


                    </div>







                    {/* <div className="d-flex justify-content-end mt-5 mb-3 container">
                         <div className="d-flex p-1 border border-1 align-items-center  col-12 col-sm-10 col-md-4 col-lg-4 ">
                              <SearchIcon className="ri-search-line  text-secondary" />
                              <input
                                   onChange={handleSearch}
                                   type="text"
                                   className="border border-0"
                                   placeholder="Search"
                              />

                         </div>
                    </div> */}


                    <div className="container mt-4 d-flex flex-wrap">


                         {response ?

                              <>
                                   {response.map((item) => (
                                        <>
                                             <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 p-0 p-md-3 p-sm-2">


                                                  <div onClick={() => ViewQuiz()} className=" quiz-category  p-0">
                                                       <img className="w-100 mb-3 br-4 prep-image-category" src={item.image}></img>
                                                       <div className='p-2'>
                                                            <h5>{item.title}</h5>

                                                            <div className="text-secondary">
                                                                 <p><b>Total quizzes : </b>{item.total_quizzes}</p>
                                                                 <p className='d-flex align-items-center'>
                                                                      <b>Level : </b>
                                                                      <Rating className="" name="read-only" value={item.level} precision={0.5} readOnly />
                                                                      <span>(level)</span>
                                                                 </p>

                                                            </div>

                                                            <div className="d-flex text-primary  mt-3">
                                                                 <span></span>
                                                                 {access ?
                                                                      <>

                                                                           <Link to={"/test-topics/technical-quiz/" + item.course_url} className="btn   ">View Levels</Link>

                                                                      </> :
                                                                      <>

                                                                           <Link to={"/test-topics/technical-quiz/" + item.course_url} className="btn  btn-primary w-100">View Levels</Link>

                                                                      </>}
                                                            </div>
                                                       </div>

                                                  </div>





                                             </div>

                                        </>
                                   ))}
                              </> :
                              <>
                                   {loading ?
                                        <>

                                             <div className="mt-5 w-100 text-center">

                                                  <CircularProgress />
                                             </div>
                                        </> : null}

                              </>}








                    </div>

               </div>

          </>

     )
}

export default TechnicalQuizzes