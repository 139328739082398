import React from 'react'
import ConnectionItem from './ConnectionItem'
import SearchConnections from './SearchConnections'

const ConnectionsList = ({connections,account,access}) => {
  return (

     <>
  {connections?
     
     <>
     {connections.map((connection,index)=>(
     
     
     <>
     <ConnectionItem account={account} access={access} key={connection.user_id} connection={connection}/>
     
     </>
     
     ))}
     </>
     :
     
     <>
     <div className="container-fluid">
     
     
     <div className="justify-center">
         <h4>No Followers</h4>
     </div>
     
     
     
     </div>
     
     
     </>
     }
     </>

  )
}

export default ConnectionsList