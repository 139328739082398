import { Avatar, Skeleton } from '@mui/material'
import React, { useContext } from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import GetComments from '../../actions/post/GetComments'
import GetPost from '../../actions/post/GetPost'
import { AuthContext } from '../../context/AuthContext'
import PostItem from './PostItem'
import * as timeago from 'timeago.js'
import SetPage from '../../data/SetPage'
import PostComment from '../../actions/post/PostComment'
import { useRef } from 'react'
import DetectDevice from '../../data/DetectDevice'
import ModalStyle from '../../data/ModalStyle'
import { Close } from '@mui/icons-material'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
const CommentsPage = ({account}) => {
     useEffect(() => {
          window.scrollTo(0, 0)
      
       
        }, [])
const params = useParams()
const post_id = params.post_id
const [comment,setcomment] = useState(null)
const ref = useRef()
const current_comment = ref.current_comment

     const {user} = useContext(AuthContext)
     const [post,setpost] = useState(null)
     const device = DetectDevice()
useEffect(()=>{

const commentscontainer = document.getElementById("comments-container")
if(device==="mobile"){

}
else{
     commentscontainer.style.height = (window.innerHeight-120)+"px"
     commentscontainer.style.overflowY = "scroll"
}

})

     const NewPostComment = async()=>{

          const res = await PostComment({payload:{
               user:user.SessionId,
               post_id:post.post_id,
               comment:comment
          }})
          if(res){
               if(res.status==="success"){
                    SinglePost()
                    FetchComments()
                    const scrollTo = (ref) => {
                         if (ref && ref.current_comment/* + other conditions */) {
                           ref.current_comment.scrollIntoView({ behavior: 'smooth', block: 'start' })
                         }
                       }
                       
               }
             
          }
         
     }
    
     const SinglePost = async ()=>{


          const res = await GetPost({
               payload:{
                    user:user.SessionId,
                    post_id:post_id

               }
          })
          if(res){
               setloading(false)
               if(res.status==="success"){
                    setpost(res.posts[0])
               }
              
          }
     }


     const FetchComments = async ()=>{


          const res = await GetComments({
               payload:{
                    user:user.SessionId,
                    post_id:post_id

               }
          })
          if(res){
                if(res.status==="success"){
                    setcomments(res.comments)
                    setcomment("")
               }
              
          }
     }


     const [comments,setcomments] = useState(null)
     useEffect(()=>{
          SetPage("Comments")
          FetchComments()
          SinglePost()
     },[])
const [editcomment,seteditcomment] = useState(false)
     const [loading,setloading] = useState(true)
const [currentcommentid,setcurrentcommentid] = useState(null)
const [currentcomment,setcurrentcomment] = useState(null)
     const OpenCommentEditor = ({comment_id,comment})=>{

setcurrentcomment(comment)
setcurrentcommentid(comment_id)
seteditcomment(true)
     }
  return (
    <div className="container sm-p-0 sm-mb-50 pb-50 comments-page">


<Modal
  open={editcomment}
  onClose={()=>seteditcomment(false)}
>
  <Box sx={ModalStyle} className="col-12 col-lg-6 col-md-7 col-sm-10 p-3">
     <div className="modal-header">
<h4>Edit Comment</h4>
<Close className="click" onClick={()=>seteditcomment(false)}/>
     </div>
 <hr className="pb-0"/>
 <div>
     

 <input value={currentcomment} onChange={(e)=>setcurrentcomment((e.target.value))} className="border-0 w-100"></input>
   
<button className="w-100 btn btn-primary btn-sm mt-3">Update Comment</button>
     </div> 
 
  </Box>
</Modal>



<div className=" d-flex justify-content-between pt-3 flex-wrap">

<div className="col-lg-6 col-xl-5 col-md-6 col-sm-12 col-12">

{post?
     <>
     <PostItem commentpage={true} account={account} post={post}/>
     </>:null}

</div>

<div className="col-lg-6 col-xl-6 col-md-6 col-sm-12 col-12">
<div id="comments-container" className="container">
   


   {comments?
   <>
   
   
   {comments.map((comment,index)=>(
   
        <>
   
        <>
        {index===0?
        <>
        <span ref={current_comment}></span>
        </>:null}
        
        </>
   <div  class="media d-flex is-comment mt-3 mb-3">
           {/* User image */}
      
           
                 <Avatar className="mr-5" src={comment.profile_pic}/>
           
         
           {/* Content */}
           <div class="media-content w-100">
               <a className="text-dark" href="#">{comment.first_name+" "+comment.last_name}</a>
               <span>{comment.comment}</span>
            
               {/* Actions */}
               <div class="w-100  extra-small d-flex">
                   <div class="like-count mr-15">
                       <i data-feather="thumbs-up"></i>
                       <span className="extra-small">{timeago.format(comment.date_time)}</span>
                   </div>

                   {comment.user_id===account.user_id?
                   <>                   
                   </>:
                   <>   <div class="reply mr-15">
                   <a href="#">Reply</a>
               </div>
                   
                   </>}
                
                   {comment.user_id===account.user_id?
                   <>
                   <div class="edit">
                       <a onClick={()=>OpenCommentEditor({comment_id:comment.id,comment:comment.comment})}>Edit</a>
                   </div>
                   </>:null}
                   
               </div>
   
          
   
           </div>
           {/* Right side dropdown */}
           <div class="media-right">
               {/* /partials/pages/feed/dropdowns/comment-dropdown.html */}
             
           </div>
       </div>
   
        </>
   ))}
   
   </>:
   
   <>
   {loading?
   <>
     <Skeleton height={50} variant="rectangular" className=" mb-2 mt-2 w-100"/>

     <Skeleton height={50} variant="rectangular"  className=" mb-2 mt-2 w-100"/>

     <Skeleton height={50} variant="rectangular"  className=" mb-2 mt-2 w-100"/>

     <Skeleton height={50} variant="rectangular"  className=" mb-2 mt-2 w-100"/>

     <Skeleton height={50}  variant="rectangular" className=" mb-2 mt-2 w-100"/>

     <Skeleton height={50} variant="rectangular"  className=" mb-2 mt-2 w-100"/>

     <Skeleton height={50} variant="rectangular"  className=" mb-2 mt-2 w-100"/>

<Skeleton height={50}  variant="rectangular" className=" mb-2 mt-2 w-100"/>

<Skeleton height={50} variant="rectangular"  className=" mb-2 mt-2 w-100"/>

   
   </>:
   <>
   <div className="container-fluid pt-4 text-center">
     <h4>No Comments</h4>

     <p>Be the first one to comment</p>
   </div>
   
   </>}
   
   
   </>
   
   
   }
   
   
   </div>
   <div  className="post-comment shadow  d-flex">
       
        <input value={comment} placeholder="Add your comment" onChange={(e)=>setcomment((e.target.value))} className="border-0 "></input>
        <button onClick={()=>NewPostComment()} className="btn btn-link">Post</button>
     </div>
</div>

</div>



    </div>
  )
}

export default CommentsPage