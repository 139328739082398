import axios from 'axios'
import ENDPOINT from '../../data/ENDPOINT'
import SecurityHeaders from '../../data/SecurityHeaders'

const AddClassRemainder = async ({payload})=>{
    
const data = new FormData()
data.append("class_id",payload.class_id)
data.append("course_url",payload.course_url)
data.append("batch_id",payload.batch_id)
data.append("session",payload.session)
data.append("date_time",payload.date_time)
data.append("class_time",payload.class_time)
const res = await 
axios.post(ENDPOINT+`add-class-remainder.php`,data,SecurityHeaders)
if(res){
    
     return res.data
}

}



export default AddClassRemainder