import axios from 'axios'
import ENDPOINT from '../../data/ENDPOINT'
import SecurityHeaders from '../../data/SecurityHeaders'

const  GetIntro = async ({payload})=>{

const data = new FormData()


 
     
     
          data.append("session",payload.user)
       data.append("name",payload.name)
       if(payload.value){
          data.append("value",payload.value)
       }

const res = await 
axios.post(ENDPOINT+`get-intro.php`,data,SecurityHeaders)
if(res){
    
     return res.data
}

}



export default GetIntro