const AuthReducer = (state, action) => {
  switch (action.type) {
    case "LOGIN_START":
      return {
        user: null,
        isFetching: true,
        error: false,
      };
    case "LOGIN_SUCCESS":
      return {
        user: action.payload,
        isFetching: false,
        error: false,
      };
    case "LOGIN_FAILURE":
      return {
        user: null,
        isFetching: false,
        error: true,
      };
    case "FOLLOW":
      return {
        ...state,
        user: {
          ...state.user,
          followings: [...state.user.followings, action.payload],
        },
      };
    case "UNFOLLOW":
      return {
        ...state,
        user: {
          ...state.user,
          followings: state.user.followings.filter(
            (following) => following !== action.payload
          ),
        },
      }; 
      case "UPLOAD_POST_START":
        return{
          isLoading:true,
          status:"uploading"
        }
        case "UPLOAD_POST_SUCCESS":
          return{
            isLoading:false,
            status:"success"
          }
          case "UPLOAD_POST_FAILED":
            return{
              isLoading:false,
              status:"error"
            }
    default:
      return state;
  }
};

export default AuthReducer;
