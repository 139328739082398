import { createContext, useContext, useEffect, useState } from "react";
import { AuthContext } from "./AuthContext";
import React from "react";
const ActionsContext = createContext()

export const useActionsContext = ()=>useContext(ActionsContext)

export const ActionsProvider = ({children})=>{

const [search,setsearch] = useState(false)
const [sidebar,setsidebar] = useState(false)
const HideSidebar = ()=>{
    setsidebar(false)
}
const ShowSidebar = ()=>{
    setsidebar(true)
}
const HideSearch = ()=>{
    setsearch(false)
}


const ShowSearch  = ()=>{
setsearch(true)

}
const ToggleSidebar = () => {
    const sidebar = document.getElementById("sidebar");
    if (sidebar.classList.contains("display-sidebar")) {
  
      setsidebar(false)
      sidebar.classList.replace("display-sidebar", "hide-sidebar");
    } else {
      
      setsidebar(true)
      sidebar.classList.replace("hide-sidebar", "display-sidebar");
    }
  };
return (<>


<ActionsContext.Provider value={{
 
search,HideSearch,ShowSearch,sidebar,ShowSidebar,HideSidebar,ToggleSidebar
}}>
    {children}
</ActionsContext.Provider>
</>)

}




