import { SpeakerPhone } from '@material-ui/icons'
import { Speaker, VolumeUp } from '@mui/icons-material'
import React, { useEffect } from 'react'
import { useState } from 'react'

const CustomVideoPlayer = ({video,nosound,poster}) => {

    const [muted,setmuted] = useState(true)

    
  const animateFunction = (entries, observer) => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        // If the element is intersecting with the viewport, execute your animation function here
        executeAnimation();
        observer.unobserve(entry.target); // Stop observing once the animation is executed
      }
    });
  };

  const executeAnimation = () => {
    // Your animation logic goes here
    console.log("Element is in viewport! Execute your animation function.");
  };

  useEffect(() => {
    const items = document.querySelectorAll(".animate");
    const observerOptions = {
      root: null, // Use the viewport as the root
      rootMargin: '0px', // No margin
      threshold: 0.5, // Trigger when 50% of the element is visible
    };

    const observer = new IntersectionObserver(animateFunction, observerOptions);

    items.forEach(item => {
      observer.observe(item);
    });

    // Cleanup the observer when the component unmounts
    return () => {
      observer.disconnect();
    };
  }, []); // Empty dependency array ensures the effect runs once on mount


  return (
    <>
    
    <div className="position-relative h-100 w-100">
        {muted?
        <>
          
          <div onClick={()=>setmuted(false)} className="d-flex click justify-content-center muted-block h-100 w-100 ">
<div className='muted-speaker'>
<VolumeUp />
<h5>Tap Here to Unmute</h5>
</div>
        </div>
        </>:
        <>
      
        </>}
    <video
    preload={true}
    className="rounded w-100 position-relative"
     controlsList="play pause volume seeking nodownload foobar" loop
    onClick={()=>setmuted(false)}  controls poster={poster} src={video}  muted={muted} ></video>
    </div>
    
    
    </>
  )
}

export default CustomVideoPlayer