import React from 'react'


import { useContext } from 'react'
import { useState } from 'react'
import ENDPOINT from '../../context/ENDPOINT'
import divider from '../../assets/images/bg/divider.png'
import CloseIcon from '@mui/icons-material/Close';
import { Link } from 'react-router-dom'
import { AuthContext } from '../../context/AuthContext'

import { useEffect } from 'react'
import ApplyCai from './ApplyCai'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

import { Helmet } from 'react-helmet'
import BenifitsList from './BenifitsList'
import ContactSection from '../../components/ContactSection'
import RecentEarners from './RecentEarners'
import CustomVideoPlayer from '../../components/elements/CustomVideoPlayer'
const AmbassadorUnregistered = ({ account, page }) => {

  const { user } = useContext(AuthContext);
  const userdata = user;

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])



  const loginRedirect = () => {

    sessionStorage.setItem("next", "/campus-ambassador-internship")
    sessionStorage.setItem("msg", "Login into your account to apply for campus ambassador internship");

  }


  const [loginprompt, setloginprompt] = useState(false)


  const apply = async () => {

    fetch(`${ENDPOINT}cai-register.php?session=${userdata.SessionId}`)
      .then(r => r.json())
      .then(result => {



        if (result.status === "success") {

          window.location.reload();
        }



      })

  };



  const [ambassador, setambassador] = useState(null);

  const [caistatus, setcaistatus] = useState(null);
  const [referrals, setreferrals] = useState(null);
  const [caiaccount, setcaiaccount] = useState(null)
  useEffect(() => {

    const checkAmbassador = async () => {

      fetch(`${ENDPOINT}check-ambassador.php?session=${userdata.SessionId}`)
        .then(r => r.json())
        .then(result => {
          if (result) {

            if (result.status === "success") {


              setcaistatus(result.data.status);
              if (result.data.status === "active") {

                if (result.data.code !== null) {

                  getReferrals();
                  getLeaderboard();
                  getMilestones();
                }

              }

              setambassador(true);

            }



          }



        })

    };
    if (userdata) {
      checkAmbassador();
      // //  //  //console.log("chacking ambassador")
    }


  }

    , [])



  useEffect(() => {

  }, [])




  const getReferrals = async () => {

    fetch(`${ENDPOINT}get-referrals.php?session=${userdata.SessionId}`)
      .then(r => r.json())
      .then(result => {

        if (result.status === "success") {


          setcaiaccount(result.data[0])
          //setreferrals(result.data[0].referrals)
        }




      })

  }


  const [leaderboard, setleaderboard] = useState(null)

  const getLeaderboard = async () => {

    fetch(`${ENDPOINT}ambassadors-leaderboard.php?session=${userdata.SessionId}`)
      .then(r => r.json())
      .then(result => {

        if (result.status === "success") {


          setleaderboard(result.data)
        }




      })

  }

  function titleCase(str) {
    return str.toLowerCase().replace(/\b(\w)/g, s => s.toUpperCase());
  }



  const [milestones, setmilestones] = useState(null)
  const getMilestones = async () => {

    fetch(`${ENDPOINT}ambassador-milestones.php?session=${userdata.SessionId}`)
      .then(r => r.json())
      .then(result => {

        if (result.status === "success") {


          setmilestones(result.data)
        }




      })

  }




  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',

    boxShadow: 24,
    p: 4,
  };

  return (

    <>
      <Helmet>‍
        <title>Campus Ambassador Internship - Skills Uprise</title>‍
        <meta name="description" content="Skills Uprise" />
        <meta name="twitter:card" content="" />
        <meta name="twitter:site" content="@skillsuprise" />
        <meta name="twitter:creator" content="@skillsuprise" />
        <meta name="twitter:title" content="" />
        <meta name="twitter:description" content="" />
        <meta name="twitter:image" content="" />
        <meta property="og:title" content="" />
        <meta property="og:description" content="" />
        <meta property="og:image" content="" />
        <meta property="og:url" content="" />
        <meta property="og:site_name" content="Skills Uprise" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta property="fb:app_id" content="ID_APP_FACEBOOK" />
      </Helmet>
      <Modal
        open={loginprompt}
        onClose={() => setloginprompt(false)}
        aria-labelledby="modal-modal-title"

        aria-describedby="modal-modal-description"
      >
        <Box className="col-lg-4 col-md-5 col-sm-6 col-11 rounded p-3 rounded" sx={style}>


          <div className="text-center">

            <div className="flex space-between">
              <h4 className="sm-18">Login into your account to apply</h4>
              <CloseIcon type="button" className="text-danger" onClick={() => setloginprompt(false)} />
            </div>
            <hr />
            <Link to="/login" onClick={() => { loginRedirect({ redirect: "login" }) }} className="btn btn-primary  mt-3 mb-4">Login & Continue</Link>

            <div className="text-center">
              <p>Dont have an account ?<Link to="/signup" onClick={() => { loginRedirect({ redirect: "signup" }) }} >Signup & Continue</Link> </p>
            </div>
          </div>

        </Box>
      </Modal>





      <Modal
        open={show}
        onClose={() => setShow(false)}
        aria-labelledby="modal-modal-title"

        aria-describedby="modal-modal-description"
      >
        <Box className="col-lg-4 col-md-6 col-sm-12" sx={style}>

          <div className="flex justify-content-between">
            <h4>Internship Application</h4>
            <CloseIcon type="button" onClick={() => setShow(false)} />
          </div>
          <hr />

          <ApplyCai />



        </Box>
      </Modal>









      <>




        <div className=" text-white cai-bg bg-dark ">
          <div className="container-fluid">

            <div className="col-lg-7 mx-auto text-center pt-5">

              <h1 className=" sm-22">Be the Leader of Your College</h1>

              <h5 className="page-title sm-22"> Get trained with us for free, start working with us & earn with us</h5>
              {userdata ?

                <>
                  {ambassador ?
                    <>

                      <Link to="/campus-ambassador" className="btn btn-warning px-5 mt-3">Go to Dashboard</Link>


                    </> :
                    <>

                      <button onClick={handleShow} className="btn btn-warning px-5 mt-3">Apply Now</button>
                    </>}
                </>
                :
                <button onClick={() => setloginprompt(true)} className="btn px-5 btn-warning mt-3">Apply Now</button>

              }


              <img src="https://skillsuprise.com/resources/images/caigroup.png" className="w-100" />
            </div>
          </div>

        </div>


        <div className="container pt-5 mt-5 mb-5">
          <div className="text-center mx-auto col-lg-8 col-md-8 col-sm-12 col-12">
            <h1 className="primary-text">About Campus Ambassador Internship</h1>
            <p>A 90 day's comprehensive internship program aimed to help students learn skills and achieve their dreams by building professional skills and leadership qualities along with building professional contacts with working industry experts.</p>


            <div className="mt-3 d-none">

              <CustomVideoPlayer />
            </div>
          </div>


        </div>

        <div className="mt-5 pt-5 mb-5">

          <BenifitsList />
        </div>














        <div className="text-center pt-5 pb-5">
          <h1 className="page-title secondary-text">Responsibilities</h1>

          <div class="divider">
            <img src={divider} alt="divider"></img>
          </div>


          <div className="d-flex container">

            <div className="col-lg-3">

            </div>

            <div className="col-lg-9 d-flex flex-wrap space-evenly">

            </div>

          </div>

          <div className="flex-wrap d-flex container justify-content-evenly mt-5">


            <div className="col-lg-3 p-2 border rounded border-2 col-md-3 col-sm-5 col-12 mb-5">

              <img src="https://skillsuprise.com/resources/images/Learn-Skills.png" className="w-100" />

              <h5 className="mt-3 ">Learn Skills from us</h5>
              <p>Attend weekly training programs, understand our services and learn better skills from us</p>
            </div>


            <div className="col-lg-3 col-md-3 col-sm-5 col-12 mb-5 m-1 border border-2 rounded p-2">
              <img src="https://skillsuprise.com/resources/images/Promote-our-Events.png" className="w-100" />
              <h5 className="mt-3 ">Promote our Events & Activities</h5>
              <p>Share the benifits of SkillsUprise and its impact on students career growth through our workshops, webinars etc</p>
            </div>



            <div className="col-lg-3 col-md-3 col-sm-5 col-12 mb-5 m-1 border border-2 rounded p-2">
              <img src="https://skillsuprise.com/resources/images/Help-your-friends.png" className="w-100" />
              <h5 className="mt-2 ">Help your friends get internships</h5>
              <p>Discuss the importance of interships in career growth and help your friend find one.</p>
            </div>

          </div>


        </div>





        <div className="mb-5 pb-5">
          <RecentEarners />

        </div>
        <div className="container-fluid mb-5 br-4 col-lg-10 p-3 cta2 mx-auto justify-content-between bg-primary text-white pt-4 pb-4 flex-wrap">

          <div className="col-lg-7 text-light sm-text-center p-2">
            <h2 className="sm-25 ">Start Earning with us Today !</h2>
            <p className="text-light">Register for Campus Ambassador Internship</p>

          </div>

          {userdata ?

            <button onClick={handleShow} className="btn h-fit btn-warning px-5 mt-4 col-lg-2 col-md-4 col-sm-6 col-8">Apply Now</button>
            :
            <button onClick={() => setloginprompt(true)} className="btn h-fit btn-warning px-5 mt-4 col-lg-2 col-md-4 col-sm-6 col-8">Apply Now</button>

          }


        </div>









        <div className="my-5">

          <ContactSection />
        </div>















      </>


    </>

  )
}

export default AmbassadorUnregistered
