import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { useContext } from 'react'
import { Link, useParams } from 'react-router-dom'
import GetCourseRewards from '../../../actions/GetCourseRewards'
import { AuthContext } from '../../../context/AuthContext'
import Localbase from 'localbase'
import BadgeItem from '../../badges/BadgeItem'
import SetPage from '../../../data/SetPage'
import { Lock } from '@material-ui/icons'
import { Tooltip } from '@material-ui/core'
import CourseBadge from '../../badges/CourseBadge'
import { Skeleton } from '@mui/material'
const CourseRewards = () => {
let db = new Localbase('db')
const params = useParams()
const course_url = params.course_url
const {user} = useContext(AuthContext)
const [badges,setbadges] = useState(null)
const GetBadges = async ()=>{

     const res = await GetCourseRewards({payload:{
          course_url:course_url,
          user:user.SessionId,
          limit:20
     }})
     if(res){
          setloading(false)
          if(res.status==="success"&&res.badges!==null){
setbadges(res.badges)

          }
           //  //console.log("badges "+JSON.stringify(res))
     }
}
useEffect(()=>{
     window.scrollTo(0,0)
   
     GetBadges()
},[])

const [loading,setloading] = useState(true)
     
  return (

     <>
     
     {badges?
     <>
    {badges.map((badge)=>(
     <>
  
<CourseBadge badge={badge}/>
     </>
))}

     
     </>:
     <>
   {loading?
   <>
   
   <Skeleton height={300}/>

<Skeleton height={300}/>

<Skeleton height={300}/>

<Skeleton height={300}/>

<Skeleton height={300}/>

<Skeleton height={300}/>

<Skeleton height={300}/>

<Skeleton height={300}/>

<Skeleton height={300}/>

<Skeleton height={300}/>

<Skeleton height={300}/>

<Skeleton height={300}/>
   </>:
   <>
   
   
   </>}

   
     </>}
  
     
     </>
  )
}

export default CourseRewards