import axios from 'axios'
import ENDPOINT from '../data/ENDPOINT'
import SecurityHeaders from '../data/SecurityHeaders'

const SearchCourses = async ({payload})=>{

const data = new FormData()
if(payload.category){
     data.append("category",payload.category)
}
if(payload.limit){
     data.append("limit",payload.limit)
}
if(payload.search){
     data.append("key",payload.search)
}


const res = await 
axios.post(ENDPOINT+`basic-search.php`,data,SecurityHeaders)
if(res){
    
     return res.data
}

}


export default SearchCourses