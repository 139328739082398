
import NotificationsIcon from '@mui/icons-material/Notifications';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import React, { useContext, useEffect, useState } from 'react'
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

import {  Switch } from '@material-ui/core'
import SecurityIcon from '@mui/icons-material/Security';
import PaymentIcon from '@mui/icons-material/Payment';
import HistoryIcon from '@mui/icons-material/History';

import FlagIcon from '@mui/icons-material/Flag';

import { AuthContext } from '../../context/AuthContext'

import { Link } from 'react-router-dom'
import axios from 'axios';
import styled from 'styled-components'

import PrivacyIcon from '@mui/icons-material/AdminPanelSettings';
import ENDPOINT from '../../data/ENDPOINT'
import LogoutIcon from '@mui/icons-material/Logout';

import preferences from '../../assets/preferences.svg'
import { useRef } from 'react';

import { Toast } from 'react-bootstrap';
import SetPage from '../../data/SetPage';
import { Helmet } from 'react-helmet';
const Settings = ({account}) => {

const loginnotif = useRef()
const {user} = useContext(AuthContext)

const [key,setkey] = useState(null);
const [value,setvalue] = useState(false)

const [status,setstatus]= useState(false);
const [toast,settoast] = useState(false)

const [loginnotification,setloginnotification] = useState(true);
const [classes,setclasses] = useState(true);
const [recordings,setrecordings] = useState(true);
const [rewards,setrewards] = useState(true);
const [assignments,setassignments] = useState(true);
const [projects,setprojects] = useState(true);
const [workshops,setworkshops] = useState(true);
const [internships,setinternships] = useState(true);
const [certificates,setcertificates] = useState(true);

useEffect(()=>{
window.scrollTo(0,0)
},[])

const updatePreferences = async ({key2,val})=>{
        

  const formData = new FormData();
  
  
  //formData.append('whyus',document.getElementById('whyus').value)
  
  formData.append('session',user.SessionId);
  
    formData.append('key',key2);
    formData.append('value',val);
////  //  //console.log("key "+key+" val"+val)
  const res = await axios.post(ENDPOINT+"update-preferences.php", formData,{
      headers: {
          'content-type': 'multipart/form-data'
      }
  });
  if(res){

   if(res.data.status==="success"){
     setstatus(true)
    settoast(true)
    getPreferences()
   }
   else{
     settoast(false)
  settoast(true)
   }
  }
  }
  




const [pref,setpref] = useState(null)




//GET PREFERENCES
const getPreferences = async ()=>{
        

  const formData = new FormData();
  
  
 
  formData.append('session',user.SessionId);
  


  const res = await axios.post(ENDPOINT+"get-preferences.php", formData,{
      headers: {
          'content-type': 'multipart/form-data'
      }
  });
  if(res){

 
   if(res.data.status==="success"&&res.data.data!==null){
     const data = res.data.data;
     setpref(res.data.data)
    // //  //  //console.log("data is "+JSON.stringify(data.login_notification))
     setloginnotification(res.data.data.login_notification)
     setclasses(res.data.data.upcoming_classes)
     setrecordings(data.recording_uploaded)
     setrewards(data.reward_earned)
     setassignments(data.assignment_added)
     setprojects(data.project_added)
     setworkshops(data.workshops)
     setinternships(data.internships);
     setcertificates(data.certificates)
     setstatus(true)
 
    ////  //  //console.log("server stat "+res.data.data.upcoming_classes)
   }
   else if(res.data.status==="success"&&res.data.data===null){
    setloginnotification(true);
    setclasses(true);
    setrecordings(true);
    setrewards(true);
    setassignments(true);
    setprojects(true);
    setworkshops(true);
    setinternships(true);
    setcertificates(true);
    
   }
   else{
     //error
   }
  }
  }


useEffect(()=>{
getPreferences()
SetPage("Settings")
},[])


    const IOSSwitch = styled((props) => (
        <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
      ))(({ theme }) => ({
        width: 42,
        height: 26,
        padding: 0,
        '& .MuiSwitch-switchBase': {
          padding: 0,
          margin: 2,
          transitionDuration: '300ms',
          '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
              backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
              opacity: 1,
              border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
              opacity: 0.5,
            },
          },
          '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
          },
          '&.Mui-disabled .MuiSwitch-thumb': {
            color:
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[600],
          },
          '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
          },
        },
        '& .MuiSwitch-thumb': {
          boxSizing: 'border-box',
          width: 22,
          height: 22,
          marginleft:10,
          margintop:5
        },
        '& .MuiSwitch-track': {
          borderRadius: 26 / 2,
          backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
          opacity: 1,
          transition: theme.transitions.create(['background-color'], {
            duration: 500,
          }),
        },
      }));
      








    return (
     
<>

<Helmet>‍
        <title>Settings - Skills Uprise</title>‍
        <meta name="description" content="Skills Uprise" />       
        <meta name="twitter:card" content="" />   
        <meta name="twitter:site" content="@skillsuprise" />    
        <meta name="twitter:creator" content="@skillsuprise" />                
        <meta name="twitter:title" content="" />   
        <meta name="twitter:description" content="" />  
        <meta name="twitter:image" content=""/>     
        <meta property="og:title" content="" />  
        <meta property="og:description" content="" />        
        <meta property="og:image" content=""/>
        <meta property="og:url" content=""/>
        <meta property="og:site_name" content="Skills Uprise" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta property="fb:app_id" content="ID_APP_FACEBOOK" />
      </Helmet>
{account?
<>
<div className=" container mx-auto p-2">


<div className="position-fixed bottom-60 left-0 container-fluid justify-center">
<Toast  className={status?"bg-success":"bg-warning"} onClose={() => settoast(false)} show={toast} delay={3000} autohide>
<Toast.Header >

</Toast.Header>

          <Toast.Body>{status?"Changes Saved Successfully !":"Something went wrong !"}</Toast.Body>
          </Toast>
</div>


<div className="flex-wrap min-vh-100 justify-content-between d-flex mb-5 ">








<div className="col-lg-6 mt-0 sm-p-0">

<div className="mt-3  mb-5 sm-mt-0 lg-mt-3 p-0 ">



<Link to={"/u/"+account.username} className="w-100 align-items-center setting-option flex  mt-2 mb-2">
<div className="setting-icon">
<AccountCircleIcon className="w-100"/>
</div>



<div className="ml-2">
<h5 className="mb-0 sm-18 pb-1">Edit Profile</h5>
<p className="truncate truncate1 mb-0 pr-3 sm-14">Make changes to your username,full name, email, phone, profile picture and address etc</p>
</div>
<ChevronRightIcon className="position-absolute right-0"/>
</Link>








{/* 
<Link to="/watch-history" className="w-100 align-items-center setting-option flex  mt-2 mb-2">
<div className="setting-icon">
<HistoryIcon className="w-100"/>
</div>



<div className="ml-2">
<h5 className="mb-0 sm-18 pb-1">My History</h5>
<p className="truncate truncate1 mb-0 pr-3 sm-14">Check out your learning history, favourites and saved tutorials</p>
</div>
<ChevronRightIcon  className="position-absolute right-0"/>
</Link> */}







<Link to="/payments" className="w-100 align-items-center setting-option flex  mt-2 mb-2">
<div className="setting-icon">
<PaymentIcon className="w-100"/>
</div>



<div className="ml-2">
<h5 className="mb-0 sm-18 pb-1">Payments</h5>
<p className="truncate truncate1 mb-0 pr-3 sm-14">Keep a track of your registrations and payments</p>
</div>
<ChevronRightIcon  className="position-absolute right-0"/>
</Link>




<Link to="/notification-settings" className="w-100 align-items-center setting-option flex  mt-2 mb-2">
<div className="setting-icon">
<NotificationsIcon className="w-100"/>
</div>

<div className="ml-2">
<h5 className="mb-0 sm-18 pb-1">Notification Settings</h5>
<p className="truncate truncate1 mb-0 pr-3 sm-14">Choose when and how to get notified</p>
</div>
<ChevronRightIcon  className="position-absolute right-0"/>
</Link>




<Link to="/security-settings" className="w-100 d-none align-items-center setting-option flex  mt-2 mb-2">
<div className="setting-icon">
<SecurityIcon className="w-100"/>
</div>

<div className="ml-2">
<h5 className="mb-0 sm-18 pb-1">Security Settings</h5>
<p className="truncate truncate1 mb-0 pr-3 ">Take control of how secure your account should be</p>
</div>
<ChevronRightIcon  className="position-absolute right-0"/>
</Link>



<Link to="/privacy-settings" className="w-100 d-none align-items-center setting-option flex  mt-2 mb-2">
<div className="setting-icon">
<PrivacyIcon className="w-100"/>
</div>

<div className="ml-2">
<h5 className="mb-0 sm-18 pb-1">Privacy Settings</h5>
<p className="truncate truncate1 mb-0 pr-3 sm-14">Control your visibility</p>
</div>
<ChevronRightIcon  className="position-absolute right-0"/>
</Link>

























<div type="button"  className=" w-100 align-items-center setting-option flex  mt-2 mb-2">
<div className="setting-icon d-flex align-items-center">
<LogoutIcon className="w-100"/>
</div>



<div className="ml-2">
<h5 className="mb-0 sm-18 pb-1">Logout</h5>
</div>
</div>




</div>































</div>

<div className="col-lg-5 pt-5 col-12 justify-content-center">
    <div className="col-lg-8 mx-auto text-center">
    <img src={preferences} className="w-100" alt=""></img>
    <p>If you'd like to learn more about privacy , you can read about it in the <Link to="/privacy-policy">Privacy Policy</Link></p>
    </div>
</div>



</div>




</div>


</>:
<>

</>}


</>


    
    )
}

export default Settings


