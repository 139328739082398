import dateFormat from "dateformat";
import BadgeIcon from '@mui/icons-material/Badge';
import TimelineIcon from '@mui/icons-material/Timeline';
import * as React from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import Typography from '@mui/material/Typography';
import RegIcon from '@mui/icons-material/HowToReg';
import EducationIcon from '@mui/icons-material/CastForEducation';
export default function WorkshopDates({workshop}) {
  return (
    <Timeline position="alternate">








      <TimelineItem>
        <TimelineOppositeContent
          sx={{ m: 'auto 0' }}
          align="right"
          variant="body2"
          color="text.secondary"
        >
          {dateFormat(workshop.registration_ends, "dS  mmm, yyyy")}
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineConnector />
          <TimelineDot>
            <RegIcon />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ py: '12px', px: 2 }}>
          <Typography component="span">
            <h5 className="sm-16" >Registration Ends</h5>
          </Typography>
         
        </TimelineContent>
      </TimelineItem>



      <TimelineItem>
        <TimelineOppositeContent
          sx={{ m: 'auto 0' }}
          variant="body2"
          color="text.secondary"
        >
          {dateFormat(workshop.workshop_date, "dS  mmm, yyyy")}
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineConnector />
          <TimelineDot color="primary">
            <EducationIcon />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ py: '12px', px: 2 }}>
        <Typography component="span">
            <h5 className="sm-16" >
             Workshop Begins
              </h5>
          </Typography>
          </TimelineContent>
      </TimelineItem>



       
      <TimelineItem>
        <TimelineOppositeContent
          sx={{ m: 'auto 0' }}
          align="right"
          variant="body2"
          color="text.secondary"
        >
          {(workshop.workshop_ends)}
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineConnector />
          <TimelineDot>
            <CancelPresentationIcon />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ py: '12px', px: 2 }}>
        <Typography component="span">
            <h5 className="sm-16" >
             Workshop Ends
              </h5>
          </Typography>
         
        </TimelineContent>
      </TimelineItem>







      <TimelineItem>
        <TimelineOppositeContent
          sx={{ m: 'auto 0' }}
          variant="body2"
          color="text.secondary"
        >
         {dateFormat(workshop.results_declaration,"dS mmm yyyy")}
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineConnector />
          <TimelineDot color="primary">
            <TimelineIcon />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ py: '12px', px: 2 }}>
        <Typography component="span">
            <h5 className="sm-16" >
            Results Declaration
              </h5>
          </Typography>
          </TimelineContent>
      </TimelineItem>






       
      <TimelineItem>
        <TimelineOppositeContent
          sx={{ m: 'auto 0' }}
          align="right"
          variant="body2"
          color="text.secondary"
        >
         {dateFormat(workshop.certificate_issue, "dS  mmm, yyyy")}
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineConnector />
          <TimelineDot>
            <BadgeIcon />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ py: '12px', px: 2 }}>
        <Typography component="span">
            <h5 className="sm-16" >
           Rewards & Certificates Distribution
              </h5>
          </Typography>
         
        </TimelineContent>
      </TimelineItem>





  
   
    </Timeline>
  );
}
