import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import FavoriteIcon from '@mui/icons-material/FavoriteBorderOutlined';
import WatchLaterIcon from '@mui/icons-material/WatchLaterOutlined';
import LikeVideo from '../../actions/videos/LikeVideo';
import ToggleWatchLater from '../../actions/videos/ToggleWatchLater';
import { useContext } from 'react';
import { AuthContext } from '../../context/AuthContext';
import FavoriteIconFilled from '@mui/icons-material/Favorite';
import Toast from 'react-bootstrap/Toast';

import StudentAccessModal from '../../components/modals/StudentAccessModal';
const SuggestionItem = ({suggestion,account,watchbutton}) => {
const {user} = useContext(AuthContext)
const [liked,setliked] = useState(suggestion.favourite)
const [watchlater,setwatchlater] = useState(suggestion.watch_later)

const Like =  async({video_id,account})=>{
     setliked(true)
     const res = await LikeVideo({payload:{
          user:user.SessionId,
          video_id:video_id
     }})
     if(res){
          if(res.action==="disliked"){
               setliked(false)
               settoasttitle("Removed from favourites")
             
               settoast(true)
          }
          else if(res.action==="liked"){
               setliked(true)
               settoasttitle("Added to favourites")
               settoast(true)
          }
          
     }
}

const NewToggleWatchLater =  async({video_id})=>{
     setwatchlater(true)
     
     const res = await ToggleWatchLater({payload:{
          user:user.SessionId,
          video_id:video_id
     }})
     if(res){
          if(res.action==="added"){
               setwatchlater(true)
               settoasttitle("Added to watch later")
               settoast(true)
          }
          else if(res.action==="removed"){
               setwatchlater(false)
               settoasttitle("Removed from watch later")
               settoast(true)
          }
          
     }else{
          setwatchlater(false) 
     }
}




const [toast,settoast] = useState(false)
const [toastmessage,settoastmessage] = useState(null)
const [toasttitle,settoasttitle] = useState(null)


useEffect(()=>{

},[])
const [access,setaccess] = useState(suggestion.access || false)
useEffect(()=>{

     if(account){
       if(account.purchases){
   
         if(account.purchases.length>0){
           setaccess(true)
         }else{
          setaccess(false)
         }
       }else{
          setaccess(false)
       }
     }
   
   },[account,user,suggestion])

const [alert,setalert] = useState(false)

const CloseStudentAccessModal = ()=>{

     setalert(false)
     }
  return (


     <>



<StudentAccessModal visibility={alert} HandleClose={CloseStudentAccessModal}/>

     
     <div className="toast-container">
<Toast className="bg-warning" onClose={() => settoast(false)} show={toast} delay={3000} autohide>
          <Toast.Header>
 
            <strong className="me-auto">{toasttitle}</strong>
            
          </Toast.Header>
          <Toast.Body>{toastmessage}</Toast.Body>
        </Toast>
</div>


<div className="pt-1 pb-1 text-dark w-100">













<div className="video-suggestion rounded  mt-1 mb-1 w-100 position-relative d-flex">
 <Link to={"/watch/"+suggestion.video_id}  className="suggestion-cover rounded col-lg-4 col-md-4 col-sm-4 col-5 mr-10">


 <div className="video-cover-container rounded h-100">
<img src={suggestion.cover_image} className="w-100 rounded h-100"/>
 

</div>

 </Link><div>
      <Link className="text-dark" to={"/watch/"+suggestion.video_id}>
      <h6 className="truncate truncate2 sm-16">{suggestion.title}</h6>
    
    <div className=" d-sm-block">
    <p className="extra-small truncate truncate2 ">{suggestion.description}</p>

    </div>
    
      </Link>

{watchbutton?<>
<div className="pt-2">

</div>
<Link to={"/watch/"+suggestion.video_id}  className="btn-continue w-fit mt-2 position-relative d-sm-block">Watch Video</Link>
</>:
<><div className="d-flex mt-2">
{watchlater?
<>
<WatchLaterIcon onClick={()=>NewToggleWatchLater({video_id:suggestion.video_id})} type="button" className="text-primary mr-15"/>
</>:
<>
<WatchLaterIcon onClick={()=>NewToggleWatchLater({video_id:suggestion.video_id})} type="button" className="mr-15" />
</>}
{liked?
<>
<FavoriteIconFilled type="button" onClick={(e)=>Like({video_id:suggestion.video_id})}  className="text-danger mr-15"/>
</>:
<>
<FavoriteIcon type="button" className="mr-15"  onClick={(e)=>Like({video_id:suggestion.video_id})} />
</>}

</div></>}
<p>{suggestion.upload_time}</p>
</div>

</div>
</div>
     
     </>
    

  )
}

export default SuggestionItem