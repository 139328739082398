import React, { useEffect, useState } from 'react'

import DetectDevice from '../../data/DetectDevice'


import GetVideos from '../../actions/GetVideos'
import { Link } from 'react-router-dom'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import ModalStyle from '../../data/ModalStyle'
import Close from '@mui/icons-material/Close'
import SearchCourses from '../../actions/SearchCourses'
import SetPage from '../../data/SetPage'

import ENDPOINT from '../../data/ENDPOINT'
import axios from 'axios'

import UserEngagement from './UserEngagement'

import PopularCoursesSlider from '../courses/PopularCoursesSlider'
import SuccessStories from './SuccessStories'
import VideosSlider from '../tutorials/VideosSlider'
import SaveUserEvent from '../../actions/SaveUserEvent'
import { useContext } from 'react'
import { AuthContext } from '../../context/AuthContext'
import MobileHomeReview from './MobileHomeReview';
const MobileHome = ({account}) => {
    const [results,setresults] = useState(null)
     const [mobile,setmobile] = useState(false)
     const device = DetectDevice()
     const {user} = useContext(AuthContext)
useEffect(()=>{

     SetPage(null)

 //  //console.log(device)
if(device==="mobile"){
     setmobile(true)
}
else{
     
     setmobile(false)
 
}
NewVideos()
},[])
  
const [scrollsuggestion,setscrollsuggestion] = useState(false)




var scrollAmount ;

  
useEffect(() => {
      
  //window.addEventListener("scroll",TogglePlayer)
}, []);


const TogglePlayer = ()=>{
   const video = document.getElementById("prime-video2")
    if(video){
        const screenheight = window.innerHeight
        const videoposition = parseInt(video.getBoundingClientRect().top.toFixed())
    
        if(((videoposition+200)<screenheight) && videoposition>-100){
             video.play()
           
             if(video.onplaying){
                 setmuted(false)
             }
             if((video.onplaying)&&(parseInt(video.currentTime)>5)){
                 
             }
             
           }else{
             if(video.onplaying){
                  video.pause()
             }
            
           }
 }
}



const [carousel,setcarousel] = useState(false)

const [videos,setvideos] = useState(null)
const NewVideos = async()=>{
     const res= await GetVideos({payload:{limit:2}})
     if(res){
           //  //console.log("got "+JSON.stringify(res))
     }
     if(res.status==="success"){
setvideos(res.videos)
     }
}

const [demo,setdemo] = useState(false)
const style = {
     width:500
}


const [query,setquery] = useState(null)
     const NewSearch = async (e)=>{
      e.preventDefault()
      setquery(e.target.value)
       //  //console.log("e v nt"+e.target.value)
      if(e.target.value.length>2){
        const res = await SearchCourses({
          payload:{
            category:"courses",
            limit:6,
            search:query
          }
        })
        if(res){
          if(res.status==="success"){
            setresults(res.data)
          }
           //  //console.log("res "+JSON.stringify(res))
        }
      }
    
     }



const [statistics,setstatistics] = useState(null)
     const WebsiteStatistics = async ()=>{
 
      ////
              const formData = new FormData();
             
              const res =   await axios.post(ENDPOINT+"about-page-statistics.php", formData,{
                  headers: {
                      'content-type': 'multipart/form-data'
                  }
              });

              if(res){
                 //  //console.log("apply internship "+JSON.stringify(res))
                if(res.data.status==="success"){

                  setstatistics(res.data)
                }
              }
            }
          
     
     

useEffect(()=>{
WebsiteStatistics()
},[])
const [muted,setmuted] = useState(true)

const [studentactivities,setstudentactivities] = useState(false)

const [loginprompt,setloginprompt] = useState(false)
const [showreview,setshowreview] = useState(false)
const [currentvideo,setcurrentvideo] = useState(false)

const PlayReview = ({data})=>{
setcurrentvideo(data)
setshowreview(true)
}
  return (
    <>
    
    <div className="">

<Modal
  open={demo}
  onClose={()=>setdemo(false)}

>
  <Box sx={ModalStyle}  className="col-lg-4 col-md-5 br-5 col-sm-6 col-11 p-2">
<div className="d-flex justify-content-between">
     <h4>Introduction</h4>
     <Close className="text-danger" type="button" onClick={()=>setdemo(false)}/>
</div>
    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
    <video className="w-100" autoPlay src="https://skillsuprise.com/data/videos/demo.mp4"></video>
    </Typography>
  </Box>
</Modal>

















<div className="mt-1 mb-3 sm-p-15">
  
  <div className="d-flex mb-18 align-items-center justify-content-between">
  <h4 className="left-blue-border">Flexible learning modes</h4>
  <Link onClick={()=>{SaveUserEvent(
          {payload:{session: user!=null||user!=undefined?user.SessionId:null,
           
    page:window.location.pathname,
    action:"MobileHome-opencourses"
  }});

}
}
 to="/courses" className="sm-10  rounded-pill btn btn-outline-primary">View all</Link>
  </div>

<div className="grid text-white text-center grid-2 mb-5">

<Link onClick={()=>{SaveUserEvent(
          {payload:{session: user!=null||user!=undefined?user.SessionId:null,
           
    page:window.location.pathname,
    action:"MobileHome-openofflinecourses"
  }});

}
} to="/offline-courses"><div className="shadow offline-bg p-3 ">
<h6>Offline<br/>Courses</h6>
</div></Link>


<Link onClick={()=>{SaveUserEvent(
          {payload:{session: user!=null||user!=undefined?user.SessionId:null,
           
    page:window.location.pathname,
    action:"MobileHome-openonlinecourses"
  }});

}
}

to="/online-courses">
<div className="shadow online-bg p-3">
<h6>Online<br/>Courses</h6>
</div>
</Link>


<Link onClick={()=>{SaveUserEvent(
          {payload:{session: user!=null||user!=undefined?user.SessionId:null,
           
    page:window.location.pathname,
    action:"MobileHome-openspecializationcourses"
  }});

}
} to="/specialization-courses">
<div className="shadow specialization-bg p-3">
<h6>Specialization<br/>Courses</h6>
</div>
</Link>


<Link
onClick={()=>{SaveUserEvent(
          {payload:{session: user!=null||user!=undefined?user.SessionId:null,
           
    page:window.location.pathname,
    action:"MobileHome-openselfpacedcourses"
  }});

}
}
to="/selfpaced-courses">
<div className="shadow selfpaced-bg p-3">
<h6>Pre-recorded<br/>Courses</h6>
</div>
</Link>




</div>





<PopularCoursesSlider />





<div className="mt-5" >

<UserEngagement/>
</div>

<div className="mt-5 mobile mb-3">
<SuccessStories/>
</div>


</div>











<div className="w-100 sm-mt-20 d-flex flex-wrap">
<Modal
  open={studentactivities}
  onClose={()=>setstudentactivities(false)}

>
  <Box sx={ModalStyle}  className="col-lg-4 col-md-5 br-5 col-sm-6 col-11 p-2">
<div className="d-flex justify-content-between">
     <h4>Student Activities</h4>
     <Close className="text-danger" type="button" onClick={()=>setstudentactivities(false)}/>
</div>
    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
    <video className="w-100 bg-dark"controls  poster="https://skillsuprise.com/data/images/student-activities.png"   autoPlay src="https://skillsuprise.com/data/videos/student-activities.mp4" ></video>
    </Typography>
  </Box>
</Modal>




<Modal
  open={showreview}
  onClose={()=>setshowreview(false)}

>
  <Box sx={ModalStyle}  className="col-lg-4 col-md-5 br-5 col-sm-6 col-11 p-2">
<div className="d-flex justify-content-between">
     <h4>Student Review</h4>
     <Close className="text-danger" type="button" onClick={()=>setshowreview(false)}/>
</div>
    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
    <video className="w-100 bg-dark"controls  poster={currentvideo.poster}   autoPlay src={currentvideo.video} ></video>
    </Typography>
  </Box>
</Modal>


      <div className="d-flex w-100 justify-content-between">

<div>
 
  
</div>


<div className="bg-theme-gradient p-3 w-100">
<h2 className="left-red-border text-light sm-mb-10">Students Love, Trust & Respect Us</h2>
  {/* <p className="text-light ps-10 sm-mb-20 d-none">Our trainers care and guide every student to acheive their goals and fullfill their dreams by being a better guide throughout their career journey.</p>
 */}


  <div className="container mt-2 sm-p-0 px-0  p-0 d-flex flex-wrap justify-content-evenly animate">


<div className="col-lg-3">
<MobileHomeReview/>
</div>


<div className="col-lg-7 flex-wrap d-flex p-0 mt-2 mt-md-0">
<div className="col-lg-6 col-md-6 col-6 p-2 ps-0 ps-md-2">
<video onClick={()=>PlayReview({data:{video:"https://skillsuprise.com/data/videos/home-review1.mp4",poster:"https://skillsuprise.com/data/videos/home-review1.png"}})}  className='w-100 rounded border bg-dark'  src='https://skillsuprise.com/data/videos/home-review1.mp4' poster='https://skillsuprise.com/data/videos/home-review1.png'></video>

</div>

<div className="col-lg-6 col-md-6 col-6 p-2 pe-0 pe-md-2">
<video  onClick={()=>PlayReview({data:{video:"https://skillsuprise.com/data/videos/home-review2.mp4",poster:"https://skillsuprise.com/data/videos/home-review2.png"}})}   className='w-100 rounded border bg-dark'   src='https://skillsuprise.com/data/videos/home-review2.mp4' poster='https://skillsuprise.com/data/videos/home-review2.png'></video>

</div>

<div className="col-lg-6 col-lmd-6 d-none d-md-block col-12 p-2">
<video className='w-100 rounded border bg-dark' src='https://skillsuprise.com/data/videos/home-review3.mp4' poster='https://skillsuprise.com/data/videos/home-review3.png'></video>

</div>


<div className="col-lg-6 col-lmd-6 d-none d-md-block col-12 p-2">
<video className='w-100 rounded border bg-dark' src='https://skillsuprise.com/data/videos/home-review4.mp4' poster='https://skillsuprise.com/data/videos/home-review4.png'></video>

</div>





</div>





</div>


  {/* <div className=" d-none story-main  p-0 mb-3 mt-2">
  <video id="prime-video2"   muted={muted}  poster="https://skillsuprise.com/data/images/training-features.png"  className="w-100  mb-0 br-5  p-0 bg-white" controlsList="download:false;"  preload={true}  src="https://skillsuprise.com/data/videos/placement-training.mp4"></video>

 
  </div> */}


  {/* <div className="grid grid-2 lg-grid-3 d-none w-100">
  <div className=" br-5 p-0">

  <video id="prime-video3"   onClick={()=>setstudentactivities(true)}   poster="https://skillsuprise.com/data/images/student-activities.png"  className="w-100 mb-0 p-0 br-5 bg-white" controlsList="download:false;"  preload={true}  src="https://skillsuprise.com/data/videos/student-activities.mp4"></video>

</div>
<div className=" br-5 p-0">
<video id="prime-video4"  onClick={()=>setstudentactivities(true)}   poster="https://skillsuprise.com/data/images/student-reviews.png"  className="w-100 mb-0 p-0 br-5  bg-white" controlsList="download:false;"  preload={true}  src="https://skillsuprise.com/data/videos/student-reviews.mp4"></video>


</div>
<div className=" br-5 p-0 d-none d-sm-block">

<video id="prime-video4"   onClick={()=>setstudentactivities(true)}    poster="https://skillsuprise.com/data/images/placement-training.png"  className="w-100 p-0 br-5 mb-0 bg-white" controlsList="download:false;"  preload={true}  src="https://skillsuprise.com/data/videos/placement-trainng.mp4"></video>

</div>

  </div> */}
<div>

</div>

</div>











    
      </div>
     


    </div>















   



    </div>
    
    
    
    </>
  )
}

export default MobileHome