import React, { useEffect } from 'react'
import AlumniSection from '../home/AlumniSection'
import divider from '../../assets/bg/divider.png'
import freeicon from './free.svg'
import projecticon from './project.svg'
import certificateicon from './certificate.svg'
import trainericon from './support.svg'
import placementicon from './placement.svg'
import internshipicon from './internship.svg'
import labicon from './lab.svg'
import communityicon from './community.svg'
import  videosicon from './videos.svg'
import ContactSection from '../../components/ContactSection'
import SetPage from '../../data/SetPage'
import StudentPassCta from './StudentPassCta'
const StudentPassPage = () => {


     useEffect(()=>{
window.scrollTo(0,0)
SetPage("Student Pass")
     },[])
  return (
    <div className="w-100">
     

<div className="pass-registration align-items-center  justify-content-between">

<div>
<h6 className="mb-0 pb-0">₹ 9,999</h6>
<p className="extra-small mb-0 pb-0">Ends on 3rd Sept</p>

</div>
<button className="btn btn-register-course w-60">Buy Student Pass</button>
</div>

     <div className="w-100 pass-cover pb-5 ">
     <div className="container   pt-5 text-center min-vh-80">     
     <div className="text-center">
<h3 className="text-light sm-14">Introducing Skills Uprise <b>Student Pass</b></h3>
        
        
</div>
     
     <h1 className="text-light sm-22">Get Lifetime Access to 20+ Courses</h1>

   

          <div className="mx-auto mt-3">
               <a target="_blank" href="https://rzp.io/l/skp-studentpass" className="btn btn-warning">Purchase Student Pass</a>
          </div>
     </div>

     </div>
    
<div className="container student-pass-stats mt-5 justify-content-evenly d-flex">

<div className="col-lg-3 p-3">
<div className="text-center br-5 p-3">
     <h1>20+</h1>
     <h2>Courses</h2>
</div>




</div>







<div className="col-lg-3 p-3">
<div className="text-center br-5 p-3">
     <h1>100+</h1>
     <h2> Projects</h2>
</div>



</div>




<div className="col-lg-3 p-3">
<div className=" text-center br-5 p-3">
     <h1>5k+ </h1>
     <h2>Enrolled</h2>
</div>
</div>




</div>




<div className="container pt-5 lg-mt-30 sm-mt-0">
     <div className="text-center">
          <h3 className="secondary-text">Purchase Student Pass &</h3>
          <h2 className="text-primary">Enjoy Unlimited Benifits</h2>
          <img src={divider}/>
     </div>


     <div className="container student-pass-features d-flex flex-wrap ">

     <div className="col-lg-4 p-2">

<div className="shadow p-3">
     
          <img className=" mx-auto" src={videosicon}/>
    
<h4>Pre-Recorded Videos</h4>
<p>Learn anywhere, anytime with beginner friendly practical course content to help you learn easily.</p>
</div>

</div>




<div className="col-lg-4 p-2">

     <div className="shadow p-3">
          
               <img className=" mx-auto" src={freeicon}/>
         
     <h4>Lifetime Access</h4>
<p>Learn throughout your life with unlimited access to our premium courses and resources</p>
     </div>

</div>




<div className="col-lg-4 p-2">

     <div className="shadow p-3">
     <img className="mx-auto"  src={trainericon}/>
  
     <h4>Dedicated trainer support</h4>
<p>Experts support to help you clear your doubts and make your learning a lovable experience</p>
     </div>

</div>


<div className="col-lg-4 p-2">

     <div className="shadow text-center p-3">

               <img className="mx-auto"  src={projecticon}/>
  
     <h4>Real world projects</h4>
<p>Get industry exposure by working with real world projects and gain better knowledge</p>
     </div>

</div>







<div className="col-lg-4 p-2">

     <div className="shadow p-3">
     <img className="mx-auto"  src={certificateicon}/>
  
     <h4>Certificate for each training</h4>
<p>Earn professional certificates for each training and add extra value to your resume</p>
     </div>

</div>









<div className="col-lg-4 p-2">

     <div className="shadow p-3">
     <img className="mx-auto"  src={placementicon}/>
  
     <h4>Placement Trainings</h4>
<p>Resume building, communication development, mock interviews, reasoning & aptitude training and many more</p>
     </div>

</div>


<div className="col-lg-4 p-2">

     <div className="shadow p-3">
     <img className="mx-auto"  src={internshipicon}/>
  
     <h4>Guaranteed Internship</h4>
<p>Learn the right skills with us and get an internship. Get placed in your favourite dream jobs</p>
     </div>

</div>


<div className="col-lg-4 p-2">

     <div className="shadow p-3">
     <img className="mx-auto"  src={communityicon}/>
  
     <h4>Students Community</h4>
<p>Learn with 5k+ students who have already joined us and build your professional connections</p>
     </div>

</div>




<div className="col-lg-4 p-2">

     <div className="shadow p-3">
     <img className="mx-auto"  src={labicon}/>
  
     <h4>Online Practise Lab</h4>
<p>Master every concept with our practise lab that guides you through every step of you learning journey</p>
     </div>

</div>









     </div>
</div>



<AlumniSection/>


<StudentPassCta/>

<div className="lg-mb-50  sm-mb-0">
<ContactSection/>

</div>

     </div>
  )
}

export default StudentPassPage