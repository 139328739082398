import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import GetEnrolledCourses from '../../actions/GetEnrolledCourses'
import LinearProgressWithLabel from '../../components/progress/LinearProgressWithLabel'
import { AuthContext } from '../../context/AuthContext'
import Localbase from 'localbase'
import VideoRecommendations from './VideoRecommendations'
import { Skeleton } from '@mui/material'
import Rating from '@mui/material/Rating';
import GettingStartedDashboard from '../intros/GettingStartedDashboard'
import CoursesList from '../courses/CoursesList'

import Box from '@mui/material/Box';
import { Offcanvas, Toast } from 'react-bootstrap'
import GetCourses from '../../actions/GetCourses'
import CourseItem from '../courses/CourseItem'
import SliderCourseItem from '../courses/SliderCourseItem'
import SacEvents from '../home/SacEvents'
import PreviewIcon from '@mui/icons-material/ArrowBackIosNew';
import NextIcon from '@mui/icons-material/ArrowForwardIos';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import ModalStyle from '../../data/ModalStyle'
import Close from '@mui/icons-material/Close'
import SubmitCourseFeedback from '../../actions/course/SubmitCourseFeedback'
const DashboardCourseProgress = ({ account }) => {
  const { user } = useContext(AuthContext)
  const [mycourses, setmycourses] = useState(null)
  let db = new Localbase("db")
  const NewGetMyCourses = async () => {

    const res = await GetEnrolledCourses({ user, limit: 2 })
    if (res) {
      setloading(false)
      if (res.status === "success") {

        setmycourses(res.data)
        db.collection("paths").add({
          my_courses: res.data
        }, "my_courses")
      }


    }
  }
  const [loading, setloading] = useState(true)
  useEffect(() => {
    db.collection("paths").doc("my_courses").get().then(data => {
      setmycourses(data.my_courses)
    }).catch(error => {

    })


    NewGetMyCourses({ user })
  }, [])


  const NewGetCourses = async () => {
    const res = await GetCourses({
      payload: {
        mode: "live",
        limit: 4
      }
    });
    if (res) {


      setcourses(res)

    }
  }


  const [courses, setcourses] = useState(null);
  useEffect(() => {
    NewGetCourses()
  }, []);




  var scrollAmount;


  const buttonclick = () => {
    var container = document.getElementById('dash-container');
    sideScroll(container, 'right', 15, 300, 10);
  };


  const back = () => {
    var container = document.getElementById('dash-container');
    sideScroll(container, 'left', 15, 300, 10);
  };

  function sideScroll(element, direction, speed, distance, step) {
    scrollAmount = 0;
    var slideTimer = setInterval(function () {
      if (direction === 'left') {
        element.scrollLeft -= step;
      } else {
        element.scrollLeft += step;
      }
      scrollAmount += step;
      if (scrollAmount >= distance) {
        window.clearInterval(slideTimer);
      }
    }, speed);
  }
  const [review, setreview] = useState(null)
const [modal,setmodal] = useState(false)
const [submitting,setsubmitting] = useState(false)
const [value,setValue] = useState(0)
const SubmitFeedback = async () => {


  setsubmitting(true)
  const res = await SubmitCourseFeedback({
    payload: {
      session: user.SessionId,
course_url:currentcourse.course_url,
batch_id:currentcourse.batch_id,
      review: review,
      rating: value,

    }
  })

  if (res) {
    setsubmitting(false)
    if (res.status === "success") {

      settoastmessage("Course review submitted successfully")
      setmodal(false)
      NewGetMyCourses()

      settoast(true)
    } else {
      settoastmessage("Something went wrong !")

      settoast(true)
    }
  }
}



const [currentcourse,setcurrentcourse] = useState(null)

const [toast, settoast] = useState(false)

const [toastmessage, settoastmessage] = useState(null)
  return (
    <>


      {mycourses ? <>


        <div className="container-fluid position-fixed bottom-60 left-0 justify-center">
        <Toast className="bg-warning" show={toast} delay={6000} autohide onClose={() => settoast(false)}>
          <Toast.Header className='bg-warning'>
            <img
              src="holder.js/20x20?text=%20"
              className="rounded me-2"
              alt=""
            />
            <strong className="me-auto">{toastmessage}</strong>

          </Toast.Header>
        </Toast>
      </div>



        <Modal
        open={modal}
        onClose={() => { setmodal(false) }}
      >
        <Box sx={ModalStyle} className="col-lg-4 p-3 col-md-5 col-sm-6 col-11 br-5">
          <div className="modal-header">
            <h4>Submit Course Review</h4>
            <Close className="click" onClick={() => setmodal(false)} />
          </div>
          <hr />
          <p className="mb-3"> {parseInt(value) === 1 ?
            <>
              Poor
            </> :
            <>
              {parseInt(value) === 2 ?

                <>
                  Below Average
                </> : <>

                  {parseInt(value) === 3 ?

                    <>
                      Average
                    </> : <>

                      {parseInt(value) === 4 ?

                        <>
                          Good
                        </> : <>

                          {parseInt(value) === 5 ?

                            <>
                              Excellent
                            </> : <>


                            </>
                          }
                        </>
                      }
                    </>
                  }
                </>
              }
            </>}</p>
          <form onSubmit={(e) => { e.preventDefault(); SubmitFeedback() }}>
          <Rating
              size="large"
              name="simple-controlled"
              value={value}
              onChange={(event, newValue) => {
                setValue(newValue);
              }}
            />

          <textarea value={review} onChange={(e) => setreview(e.target.value)} className="mt-3 mb-3 w-100 form-control" placeholder="Write your review"></textarea>

          

           

            <button className="btn mt-4  btn-primary btn-sm w-100" disabled={submitting}>{submitting ? "Submitting..." : "Submit Your Review"}</button>


          </form>
        </Box>
      </Modal>







        <div div className="w-100 p-2  ">
          <div className="border p-3  rounded bg-white">
            {mycourses ?
              <>





                <div className="d-flex w-100 mb-4 justify-content-between align-items-center">
                  <h4 className="mb-0 pb-0">My Courses</h4>
                  <Link className=" sm-10  rounded-pill btn btn-outline-primary" to="/my-courses">View All</Link>



                </div>




                {mycourses.map((course, index) => (
                  <>

                    <div className="d-flex text-dark ">
                      <div className="col-lg-4 col-md-4 col-sm-4 col-5 mr-10">
                        <img src={course.course_cover} className="w-100 br-4 h-100 course-progress-cover" />
                      </div>
                      <div className="col-7 position-relative ">
                        <h5 className="truncate truncate2 sm-16">{course.course_title}</h5>
                        <div className="w-100">
                          <LinearProgressWithLabel value={course.progress} />
                        </div>
                        {/* <div className="d-flex d-none text-center mb-2 align-items-center">
            <div className="mr-10">
            <h6 className="">Completed</h6>
           <p>{course.progress}%</p>
            </div>
            <div className="">
            <h6>Next Class</h6>
            <p>23rd July @ 6pm</p>
         
            </div>
         
          </div> */}
                        <div className=" d-none d-md-flex align-items-center">
                          <Rating onClick={()=>{setmodal(true);setcurrentcourse(course)}}  readOnly={course.my_rating?true:false} value={course.my_rating?course.my_rating:3} />
                          <p className="p-0 m-0 ms-1">Submit Rating</p>
                        </div>
                        <div className='mt-2 mt-md-5'>
                          <Link to={"/course-dashboard/" + course.course_url + "/" + course.batch_id} className="btn-continue d-sm-block">Continue Couse</Link>
                        </div>
                      </div>
                    </div>





                    {index === (mycourses.length - 1) ?
                      <>

                      </>
                      : <>
                        <hr className="mb-2 mt-2" />
                      </>}




                  </>
                ))}







              </> :

              <>

                {loading ?
                  <>

                    <Skeleton variant="rectangular" height={90} className="w-100 mt-2 mb-2" />


                    <Skeleton variant="rectangular" height={90} className="w-100 mt-2 mb-2" />



                  </>

                  :
                  <>

                  </>}

              </>}

          </div>

        </div>





















      </> : <>






        <div className="p-3 ">


          <div className="flex  justify-content-between align-items-center">
            <h4>Popular Courses</h4>


            <div class="slider-buttons d-none d-sm-flex">
              <PreviewIcon id="slideBack" className="mr-15 next-prev" onClick={buttonclick} alt="" type="button" />


              <NextIcon id="slide" className="mr-15 next-prev" onClick={back} type="button" />


            </div>
          </div>



          <div id="dash-container" className="d-flex overflow-x-scroll mt-1">


            {courses ?
              <>

                {courses.map((course) => (
                  <div className="col-lg-4 p-2">
                    <div className="border">
                      <SliderCourseItem className="w-100" course={course} />
                    </div>
                  </div>
                ))}
              </> :
              <></>}
          </div>
        </div>









      </>}


    </>
  )
}

export default DashboardCourseProgress