import React, { useEffect, useRef, useState } from 'react';
import GroupIcon from '@mui/icons-material/Group';
import Button from '@mui/material/Button';
import { useHistory } from "react-router-dom";
import SchoolIcon from '@mui/icons-material/School';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import styled from 'styled-components';
import mobilelogo from '../assets/images/mobile-logo.png';
import logo from '../assets/images/skillsuprise-logo.png';
import { Link, Route } from 'react-router-dom';
import WidgetsIcon from '@mui/icons-material/Widgets';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChatIcon from '@mui/icons-material/Chat';
import Badge from '@mui/material/Badge';
import ReviewsIcon from '@mui/icons-material/Reviews';
import MailIcon from '@mui/icons-material/Mail';
import Tooltip from '@mui/material/Tooltip';
import BackIcon from '@mui/icons-material/ArrowBack';
import SearchIcon from '@mui/icons-material/Search';
import DirectionsIcon from '@mui/icons-material/Directions';
import Offcanvas from 'react-bootstrap/Offcanvas';
import SearchCourses from '../actions/SearchCourses';
import Close from '@mui/icons-material/Close';
import { useActionsContext } from '../context/ActionsContext';

const NavbarCourseSearch = ({ show, props, visibility, HandleClose }) => {
  const [displaysearch, setdisplaysearch] = useState(true);
  const { HideSearch } = useActionsContext();
  const [query, setquery] = useState(null);
  const inputRef = useRef(null);

  const NewSearch = async (e) => {
    e.preventDefault();
    setquery(e.target.value);

    if (e.target.value.length > 2) {
      const res = await SearchCourses({
        payload: {
          category: "courses",
          limit: 6,
          search: query
        }
      });
      if (res) {
        if (res.status === "success") {
          setresults(res.data);
        }
      }
    }
  }

  const [results, setresults] = useState(null);

  const CloseSearchTab = () => {
    HandleClose();
  }

  useEffect(() => {
    if (visibility) {
      inputRef.current.focus();
    }
  }, [visibility]);

  useEffect(() => {
    setdisplaysearch(false);
    HandleClose();
  }, [window.location.pathname]);

  return (
    <>
      {visibility ?
        <>
          <div className="search-backdrop" onClick={HideSearch}></div>
          <Paper
            component="form"
            className="w-100 position-relative"
            sx={{ p: '2px 4px', display: 'flex', alignItems: 'center' }}
          >
            <InputBase
              ref={inputRef}
              sx={{ ml: 1, flex: 1 }}
              value={query}
              onChange={(e) => NewSearch(e)}
              placeholder="Search courses"
              inputProps={{ 'aria-label': 'search google maps' }}
            />
            <IconButton onClick={HandleClose} type="button" sx={{ p: '10px' }} aria-label="search">
              <Close />
            </IconButton>
            {results ?
              <div className="search-suggestions position-absolute left-0 bottom-0 h-fit bg-white w-100">
                <div className="p-2">
                  {results.map((result) => (
                    <div key={result.course_url} onClick={() => { setdisplaysearch(false) }} className="mt-2 mb-2 d-flex align-items-center">
                      <Link className="text-dark d-flex w-100 align-items-center" to={"/course/" + result.course_url + "/" + result.mode}>
                        <SearchIcon className="mr-5" />
                        <div>
                          <p className="truncate truncate1"> {result.course_title}</p>
                          <p className="smallest text-secondary"> {result.mode + " course"}</p>
                        </div>
                      </Link>
                    </div>
                  ))}
                </div>
              </div>
              : null}
          </Paper>
        </>
        : null}
    </>
  )
}

export default NavbarCourseSearch;
