import React from 'react'

const TrainerDetailsPage = () => {
  return (
    <div>
        
        
        
        
        
        TrainerDetailsPage</div>
  )
}

export default TrainerDetailsPage