import React, { useRef, useEffect } from 'react';

const DashboardBanner = () => {
  const videoRef = useRef(null);

  useEffect(() => {
    const handlePlayPause = (entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          videoRef.current.play();
        } else {
          videoRef.current.pause();
        }
      });
    };

    const observer = new IntersectionObserver(handlePlayPause, {
      threshold: 0.5, // Adjust this value as needed
    });

    if (videoRef.current) {
      observer.observe(videoRef.current);
    }

    return () => {
      if (videoRef.current) {
        observer.unobserve(videoRef.current);
      }
    };
  }, []);

  return (
    <div className="my-3">
      <video 
        ref={videoRef} 
        src="https://skillsuprise.com/data/videos/dashboard-banner.mp4" 
        poster="https://skillsuprise.com/data/images/dashboard-banner.png" 
        className="dashboard-review-image min-vh-80 rounded"
       // Optional: mutes the video so it can autoplay without user interaction
      ></video>
    </div>
  );
}

export default DashboardBanner;
