import React from "react";
import ReactDOM from "react-dom/client";

import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import { AuthContextProvider } from "./context/AuthContext";

import firebaseConfig from "./firebase-Config";
import { initializeApp } from "@firebase/app";
import { ActionsProvider } from "./context/ActionsContext";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
<ActionsProvider>
<AuthContextProvider>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </AuthContextProvider>
</ActionsProvider>
);

//register to make app work offline and load faster

serviceWorkerRegistration.register();



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals( //  //console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
