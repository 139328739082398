import axios from 'axios'
import React, { useEffect, useState } from 'react'
import ENDPOINT from '../../data/ENDPOINT'
import SecurityHeaders from '../../data/SecurityHeaders'
import Box from '@mui/material/Box';


import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { Link } from 'react-router-dom';
import ModalStyle from '../../data/ModalStyle';
import SetPage from '../../data/SetPage';
import { CircularProgress } from '@material-ui/core';
const QuizPage = ({account}) => {


useEffect(()=>{
window.scrollTo(0,0)
},[])

const [categories,setcategories] = useState(null)

const GetQuizCategories = async()=>{
setloading(true)
     const data = new FormData()
     const res = await axios.post(ENDPOINT+"get-quiz-categories.php",data,SecurityHeaders)

     if(res){
setloading(false)
if(res.data.status==="success"){

setcategories(res.data.data)

}

     }
}

useEffect(()=>{
GetQuizCategories()
SetPage("Quizzes")
},[])

useEffect(()=>{
if(account){
     if(account.purchases && account.purchases.length>0){
          setaccess(true)
     }else{
          setaccess(false)
     }
}
},[account])

const [access,setaccess] = useState(false)
const [modal,setmodal] = useState(false)


const ViewQuiz = ()=>{
     if(access){

     }
     else{
          setmodal(true)
     }
}
const [loading,setloading] = useState(false)
  return (


     <>



     


<Modal
  open={modal}
  onClose={()=>{setmodal(false)}}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
  <Box className="col-lg-4 col-md-5 col-sm-6 col-11 p-2"  sx={ModalStyle}>
    <Typography id="modal-modal-title" variant="h6" component="h2">
      Student Access Only !
    </Typography>
    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
     Login into your account to access this section


     <div className="d-flex justify-content-between mt-4">
<button onClick={()=>setmodal(false)} className="btn btn-sm  btn-outline-danger rounded-pill">Cancel</button>
<Link to="/login" className="btn btn-sm  btn-primary rounded-pill">Login</Link>
     </div>
    </Typography>
  </Box>
</Modal>


         <div className="tech-cover min-vh-50">
          <div className="container">
<h1>Technical Quizzes</h1>
          </div>

</div>




         <div className="min-vh-100 interview-praparation pb-5">
     









     <div className="container d-flex flex-wrap justify-content-between pt-5 ">



          <Box >
      <FormControl onClick={()=>setmodal(true)} >
        <InputLabel id="demo-simple-select-label">Difficulty</InputLabel>
        <Select
       
          value={"Python"}
        >
          <MenuItem value={"Python"}>Beginner</MenuItem>
          <MenuItem value={"ai"}>Intermediate</MenuItem>
          <MenuItem value={"dsPython"}>Advanced</MenuItem>



        </Select>
      </FormControl>
</Box>


     </div>


<hr/>

     <div className="container mt-4 d-flex flex-wrap">



{categories?

<>
{categories.map((item)=>(
     <>
<div className="col-lg-4 col-md-4 col-sm-6 col-12 p-4 sm-p-0">


<div  onClick={()=>ViewQuiz()}  className=" quiz-category  p-2">
     <img className="w-100 mb-3 br-4 prep-image" src={item.image}></img>
<h5>{item.title}</h5>

<div className="d-flex text-secondary justify-content-between align-items-center">
<p><b>Total quizzes : </b>{item.total_quizzes}</p>
<p><b>Level : </b>{item.level}</p>

</div>
<hr/>
<div className="d-flex text-primary justify-content-between mt-3">
<button className="btn btn-sm ">View Levels</button>
{access?
<>

<Link to={"/quiz/"+item.category} className="btn  btn-sm">Start Quiz</Link>

</>:
<>

<button className="btn  btn-sm">Start Quiz</button>

</>}</div>
</div>





</div>

     </>
))}
</>:
<>
{loading?
<>

<div className="mt-5 w-100 text-center">

<CircularProgress/>
</div>
</>:null}

</>}








     </div>
     
     </div>
     
     </>

  )
}

export default QuizPage