import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import GetWorkshops from '../../actions/GetWorkshops'
import zoomicon from '../../assets/icons/zoom-icon.svg'
import Localbase from 'localbase'
import Offcanvas from 'react-bootstrap/Offcanvas'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import SetPage from '../../data/SetPage'
import RegisterWorkshop from '../../actions/workshops/RegisterWorkshop'
import ModalStyle from '../../data/ModalStyle'
import { TextField } from '@mui/material'
import WorkshopRegistrationForm from './WorkshopRegistrationForm'
import DetectDevice from '../../data/DetectDevice'
import { Helmet } from 'react-helmet'
const WorkshopsPage = ({props}) => {
let db = new Localbase("db")
const [workshops,setworkshops] = useState(null)
  const NewGetWorkshops = async()=>{
    const res = await GetWorkshops({limit:9})
    if(res){

setworkshops(res)
db.collection("paths").add({
  workshops:res
},"workshops")
      
    }
  }
  useEffect(()=>{
    SetPage("Events")
    db.collection("paths").doc("workshops").get().then(data=>{
      setworkshops(data.workshops)
        }).catch(error=>{
      
        })
NewGetWorkshops()
  },[])


  useEffect(()=>{
window.scrollTo(0,0)
  },[])

const [workshop_id,setworkshop_id]=useState(null)
const [registermodal,setregistermodal] = useState(false)
const InitiateRegistration = ({workshop_id})=>{
  setworkshop_id(workshop_id)
  setregistermodal(true)
}
const device = DetectDevice()

  return (
    <div className="workshops-page min-vh-100 pb-5">
     
     <Helmet>‍
        <title>Skills Uprise - Events & Workshops</title>‍
        <meta name="description" content="Login into your account and learn the best skills from our curated courses" />       
        <meta name="twitter:card" content="" />   
        <meta name="twitter:site" content="@skillsuprise" />    
        <meta name="twitter:creator" content="@skillsuprise" />                
        <meta name="twitter:title" content="" />   
        <meta name="twitter:description" content="" />  
        <meta name="twitter:image" content=""/>     
        <meta property="og:title" content="" />  
        <meta property="og:description" content="" />        
        <meta property="og:image" content=""/>
        <meta property="og:url" content=""/>
        <meta property="og:site_name" content="Skills Uprise" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta property="fb:app_id" content="ID_APP_FACEBOOK" />
      </Helmet>


   {device==="mobile"?
   <>
   
<Offcanvas className="register-canvas" placement="bottom" show={registermodal} onHide={()=>setregistermodal(false)} {...props}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Registration Form</Offcanvas.Title>
        </Offcanvas.Header>



      <WorkshopRegistrationForm workshop_id={workshop_id}/>

   
      </Offcanvas>



   </>:
   <>
   
   <Modal
  open={registermodal}
  onClose={()=>setregistermodal(false)}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
  <Box   className="col-lg-4 col-12" sx={ModalStyle}>
    <Typography id="modal-modal-title" variant="h6" component="h2">
      Registration Form
    </Typography>
    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
    <WorkshopRegistrationForm  workshop_id={workshop_id}/>
    </Typography>
  </Box>
</Modal>

   
   
   </>
   
   }










<div className="w-100 pt-5 sm-pt-50 d-none sm-pb-30 workshop-crumb2 workshop-banner pb-5 bg-dark">
<div className="container text-white">

  <h1>Webinars & Workshops</h1>
  <h2>On Trending topics & technologies</h2>
</div>

</div>


<div className="d-flex mt-3 flex-wrap container">
{workshops?
<>
{workshops.map((workshop)=>(

  <>
<div to={"/event/"+workshop.workshop_url} className="col-lg-4 col-md-6 col-sm-6 col-12 p-3 sm-p-0 mt-3 mb-3">

<div className="shadow">
  <img src={workshop.workshop_cover_url} className="w-100"/>
 
 
 <div className="p-3 pb-0">
 <h5>{workshop.workshop_title}</h5>
  <div className="d-flex flex-wrap justify-content-between">
    <p><b className="text-primary">Date : </b>{workshop.date}</p>
    <p><b  className="text-primary">Timings : </b>{workshop.workshop_duration}</p>
  </div>
  <div className="d-flex mt-2">
    <p><b  className="text-primary">Venue : </b>Zoom meetings</p>
    
  </div>

  </div> 
  <div className="p-2">
  <hr/>
  <div className="d-flex justify-content-between">
    <Link  to={"/event/"+workshop.workshop_url} className="btn btn-outline-primary rounded-pill">View Details</Link>
    <button className="btn btn-primary rounded-pill" onClick={()=>{InitiateRegistration({workshop_id:workshop.workshop_id})}}>Register Now</button>
 
  </div>
  </div>
 
</div>

</div>

  </>
))}

</>:<>
<div className="col-12 h-100">

  <div className="col-lg-4 col-md-4 col-sm-6 col-11  mx-auto mt-5">
    <img className="w-100" src="https://skillsuprise.com/resources/images/no-events.png"/>
  </div>
 <div className=" text-center mt-3"> <h4>No Events or Workshops</h4></div>
</div>
</>}






</div>
    </div>
  )
}

export default WorkshopsPage