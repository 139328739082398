import React, { useContext, useEffect } from "react";
import { useState } from "react";
import MyTimeline from "../../actions/post/MyTimeline";
import { AuthContext } from "../../context/AuthContext";
import SetPage from "../../data/SetPage";
import PostItem from "./PostItem";
import Localbase from "localbase";
import Modal from "@mui/material/Modal";

import { Alert, Chip, Skeleton } from "@mui/material";
import StatusUpdate from "./StatusUpdate";

import GetIntro from "../../actions/intros/GetIntro";
import { Link } from "react-router-dom";

import CommunityLeaderboard from "./CommunityLeaderboard";
import OffersSlider from "./OffersSlider";
import MainSidebanner from "./MainSidebanner";
import CourseSuggestions from "./CourseSuggestions";
import DemoCommunityPosts from "./DemoCommunityPosts";
import CourseBatch from "../account/mycourses/CourseBatch";
import CommunitySuggestions from "./CommunitySuggestions";
import AllConnections from "../connections/AllConnections";
import ConnectionsList from "../connections/ConnectionsList";
import Connections from "../connections/Connections";
import DetectDevice from "../../data/DetectDevice";
import CommunitySidebar from "./CommunitySidebar";
const Community = ({ account, props, access }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { user } = useContext(AuthContext);
  const { dispatch } = useContext(AuthContext);
  let db = new Localbase("db");
  let checkpost = sessionStorage.getItem("post");
  const [limit, setlimit] = useState(10);
  useEffect(() => {
    db.collection("paths")
      .doc("posts")
      .get()
      .then((data) => {
        setposts(data.posts);
        //  //console.log("added from community")
      })
      .catch((error) => {});

    SetPage("Newsfeed");
  }, [checkpost, account]);
  const [posts, setposts] = useState(null);

  const device = DetectDevice();

  useEffect(() => {
    GetMyPosts();
  }, []);

  const GetMyPosts = async () => {
    const res = await MyTimeline({
      payload: {
        user: user.SessionId,
        limit: limit,
      },
    });
    if (res) {
      setloading(false);
       console.log("posts are "+JSON.stringify(res))
      setposts(null);
      try {
        //delete posts from browser cache
        db.collection("paths")
          .doc("posts")
          .delete();
      } catch (error) {}
      if (res.status === "success" && res.posts !== null) {
        setposts(res.posts);
        db.collection("paths").add(
          {
            posts: res.posts,
          },
          "posts"
        );
       

      }
      
      
    }
  };

  const RefreshPosts = () => {
    GetMyPosts();
  };

  useEffect(() => {
    GetMyPosts();
  }, [limit]);

  const Reload = () => {
    if (posts) {
      const container = document.getElementById("container");
      const containerheight = container.clientHeight;
      const scrollheight = window.scrollY;
      //  //console.log("scroll h "+scrollheight+" container"+containerheight)
      if (scrollheight > containerheight - 200) {
        setlimit(limit + 10);
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", Reload);
  }, []);
  const CheckBeginner = async ({ payload }) => {
    const res = await GetIntro({ payload });
    if (res) {
      //  //console.log("beginner "+JSON.stringify(res))
      if (res.status === "success") {
        setbeginner(res.beginner);
      }
    }
  };
  const [beginner, setbeginner] = useState(true);
  useEffect(() => {
    CheckBeginner({
      payload: {
        user: user.SessionId,
        name: "community_toc",
      },
    });
  }, [beginner]);
  const AcceptToc = () => {
    CheckBeginner({
      payload: {
        user: user.SessionId,
        name: "community_toc",
        value: "false",
      },
    });
  };

  const [page, setpage] = useState();
  useEffect(() => {
    setpage(JSON.parse(sessionStorage.getItem("page")));
  }, [window.location]);

  const [payload, setpayload] = useState("pay");
  const [loading, setloading] = useState(true);
  return (
    <>
      {account ? (
        <>
          <div className="sm-pb-60 pb-5">
            <div className="container sm-p-0 community-page justify-content-between d-flex flex-wrap">
              <div className="col-lg-3  d-lg-block d-none">
                {device === "mobile" ? (
                  <>
                    <div className="d-flex w-100 overflow-x p-2">
                      <Link to="/recommendations">
                        <Chip
                          size="small"
                          className={
                            page === "Newsfeed"
                              ? "bg-primary text-white mr-15"
                              : " mr-15"
                          }
                          label="Recommendations"
                        />
                      </Link>
                      <Link to="/my-posts">
                        <Chip
                          size="small"
                          className={
                            page === "My Posts"
                              ? "bg-primary text-white  mr-15"
                              : " mr-15"
                          }
                          label="My Posts"
                        />
                      </Link>

                      <Link to="/watch-history">
                        <Chip
                          size="small"
                          className={
                            page === "following"
                              ? "bg-primary text-white mr-15"
                              : " mr-15"
                          }
                          label="Watch History"
                        />
                      </Link>

                      <Link to="/requests-sent">
                        <Chip
                          size="small"
                          className={
                            page === "requests-sent"
                              ? "bg-primary text-white  mr-15"
                              : " mr-15"
                          }
                          label="Requests sent"
                        />
                      </Link>

                      <Link to="/requests-received">
                        <Chip
                          size="small"
                          className={
                            page === "requests-received"
                              ? "bg-primary text-white  mr-15"
                              : " mr-15"
                          }
                          label="Requests received"
                        />
                      </Link>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="mt-3 sm-mb-20 h-fit col-12 ">
                      <CommunitySidebar />
                    </div>

<div className="mt-5">
<CourseSuggestions/>
</div>
                
                  </>
                )}
              </div>

              <div
                id="container"
                className="col-lg-5 col-md-7 p-2 feed-container col-12"
              >
                {account ? (
                  <>
                    <div>
                      <StatusUpdate
                        account={account}
                        RefreshPosts={(data) => {
                          RefreshPosts({ data });
                        }}
                      />

                      {device === "mobile" ? (
                        <>
                          <div className="  mt-4 mb-2">
                            <div className="d-flex justify-content-between">
                              <h4>Recommendations</h4>
                              <Link to="/recommendations">View All</Link>
                            </div>
                            <div className="overflow-x pb-4 bg-white">
                              <Connections limit={10} />
                            </div>
                          </div>
                        </>
                      ) : null}
                    </div>
                  </>
                ) : null}

                {beginner === true ? (
                  <>
                    {loading ? (
                      <>
                        <Skeleton
                          variant="rectangular"
                          height={300}
                          className="mt-2 mb-2 w-100"
                        />
                        <Skeleton
                          variant="rectangular"
                          height={300}
                          className="mt-2 mb-2 w-100"
                        />
                        <Skeleton
                          variant="rectangular"
                          height={300}
                          className="mt-2 mb-2 w-100"
                        />
                      </>
                    ) : (
                      <>
                        <Alert className="mt-3 mb-3" severity="info">
                          Welcome to students community ! Remember to guard your
                          privacy and abide by our community guidelines. Also
                          you can access community content and can carry out
                          activities only after registering a course.
                          <hr />
                          <div className="d-flex justify-content-between">
                            <Link to="/community-guidelines" className="">
                              LEARN MORE
                            </Link>
                            <button
                              onClick={() => {
                                AcceptToc();
                                setbeginner(false);
                              }}
                              className="btn btn-link btn-sm"
                            >
                              OK, UNDERSTOOD
                            </button>
                          </div>
                        </Alert>
                      </>
                    )}
                  </>
                ) : (
                  <></>
                )}

                {account && access ? (
                  <>
                    {account.user_id === "518" ? (
                      <>
                        <DemoCommunityPosts />
                      </>
                    ) : (
                      <></>
                    )}
                    {posts ? (
                      <>
                        {posts.map((post) => (
                          <>
                            <PostItem
                              key={post.post_id}
                              account={account}
                              post={post}
                              RefreshPosts={(data) => {
                                RefreshPosts(data);
                              }}
                            />
                          </>
                        ))}
                      </>
                    ) : null}
                  </>
                ) : (
                  <>
                    {/*no enrollments */}
                    <DemoCommunityPosts />
                  </>
                )}
              </div>

              <div className="col-lg-3 d-none col-md-5  d-sm-block ">
              <div className="  p-3">
                  <CommunityLeaderboard account={account} />
                </div>
                <div className=" p-3 mt-3">
                  {account ? (
                    <>
                      <CommunitySuggestions account={account} />
                    </>
                  ) : null}
                </div>

              
              </div>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default Community;
