import React, { useState } from 'react'
import DetectDevice from '../../data/DetectDevice'
import AdditionalOptions from './AdditionalOptions'
import { useEffect } from 'react'
import CustomVideoPlayer from '../../components/elements/CustomVideoPlayer'
import { VolumeUp } from '@material-ui/icons'
import HomeEngagementVideo from './HomeEngagementVideo'
const UserEngagement = ({videos,account}) => {
     const device = DetectDevice()

     useEffect(() => {
        
         window.addEventListener("scroll",TogglePlayer)
     }, []);


     const TogglePlayer = ()=>{
          const video = document.getElementById("mainvideo2")
           if(video){
               const screenheight = window.innerHeight
               const videoposition = parseInt(video.getBoundingClientRect().top.toFixed())
         
               if(((videoposition+200)<screenheight) && videoposition>-100){
                    video.play()
                  
                    if(video.onplaying){
                         video.removeAttribute("muted")  
                    }
                    if((video.onplaying)&&(parseInt(video.currentTime)>5)){
                        
                    }
                    
                  }else{
                    if(video.onplaying){
                         video.pause()
                    }
                   
                  }
        }
     }
     const[muted,setmuted] = useState(true)
  return (
    <div>
     
     <div className="container sm-p-0 sm-mt-20">


    
<div className="d-flex align-items-center flex-wrap justify-content-evenly">







<div className="col-lg-6  sm-p-0  p-0 md-p-3 text-light">
     

<div className="position-relative h-100 w-100">
    
<HomeEngagementVideo/>

    </div>
    
</div>
<div className="col-lg-5 col-md-12 col-sm-12 col-12 sm-mb-0 sm-mt-20 p-0 p-md-2 ">

<AdditionalOptions/>
</div>


</div>

     </div>
     
     
     
     
     
     
     
     </div>
  )
}

export default UserEngagement