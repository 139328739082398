import axios from 'axios'
import ENDPOINT from '../../data/ENDPOINT'
import SecurityHeaders from '../../data/SecurityHeaders'

const  DeletePost = async ({payload})=>{

const data = new FormData()

 //  //console.log("deleting "+payload.post_id)
     data.append("post_id",payload.post_id)

data.append("session",payload.user)
const res = await 
axios.post(ENDPOINT+`student-delete-post.php`,data,SecurityHeaders)
if(res){
    
     return res.data
}

}




export default DeletePost