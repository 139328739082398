import { Avatar } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import GetCourses from '../../actions/GetCourses'
import GetPlacedStudents from '../../actions/GetPlacedStudents'
import GetSacVideos from '../../actions/GetSacVideos'
import ChevronRight from '@mui/icons-material/ChevronRight'
import { ChevronLeft } from '@mui/icons-material'
import AnimatePage from '../../actions/animations/AnimatePage'
const DashboardPlacements = ({mode}) => {



  

  var scrollAmount ;

  
   const buttonclick = ()=> {
        var container = document.getElementById('dashboard-placements');
        sideScroll(container,'right',15,300,10);
    };
    
    
    const back = ()=> {
        var container = document.getElementById('dashboard-placements');
        sideScroll(container,'left',15,300,10);
    };
    
    function sideScroll(element,direction,speed,distance,step){
        scrollAmount = 0;
        var slideTimer = setInterval(function(){
            if(direction === 'left'){
                element.scrollLeft -= step;
            } else {
                element.scrollLeft += step;
            }
            scrollAmount += step;
            if(scrollAmount >= distance){
                window.clearInterval(slideTimer);
            }
        }, speed);
    }
    

const [loading,setloading] = useState(false)


useEffect (()=>{
AnimatePage()
NewVideos()
},[])
    const NewVideos = async()=>{
      setloading(true)
      
      
      
          const res = await GetPlacedStudents(
            query?
            {payload:{limit:limit,query:query}}
            :
            {payload:{limit:limit}}
          )
          
          if(res){
            setloading(false)
          }
          if(res.status==="success"){
           
            setvideos(res.data)
            if(res.videos){
      
              if(res.videos.length===res.total_videos){
                setnomore(true)
              }
              settotalvideos(res.videos.length)
            }
          
           
            setsuggestions(res.suggestions)

      
      
      AnimatePage()
           
          }



        }
        const [query,setquery] = useState(null)
const [limit,setlimit] = useState(12)
const [totalvideos,settotalvideos] = useState(null)
const [nomore,setnomore] = useState(false)
        const [videos,setvideos] = useState(null)
        const [suggestions,setsuggestions] = useState(null)
  
    
  return (
    <div className='dashboard-placements'>
<div className="border p-3 pb-0 rounded">
    

<div className="d-flex mb-2 w-100 align-items-center justify-content-between">
  <h3 className="left-blue-border mn-0 pb-0">Latest Placements</h3>

  <div className="d-flex align-items-center">
    
   <Link to="/reviews" className="sm-10 btn btn-outline-primary rounded-pill">View all</Link>

  <div class="slider-buttons ms-3 mr-15">

    
    <ChevronLeft  id="slideBack" className="me-2"  onClick={back} alt="" type="button" src={"previewsvg"}/>
    <ChevronRight  id="slide" onClick={buttonclick} type="button"  src={"nextsvg"} alt=""/>
   

</div>
  </div>
  
    </div>
<div  id="dashboard-placements"  className="d-flex w-100 mt-3 pb-0 overflow-x-auto">
    

{videos?
<>
{videos.map((item,index)=>(
<>

<div className="position-relative text-center rounded me-2">
<img src={item.image} className='w-100 item border border-dark rounded rounded-bottom-0'></img>
<h6 className='bg-dark W-100 sm-12 rounded rounded-bottom rounded-top-0  px-2 py-1 text-center  text-light'>{item.package} LPA</h6>
</div>

</>

))}

</>:null}



</div>
</div>


    </div>
  )
}

export default DashboardPlacements