import React from 'react'
import ScratchCards from './ScratchCards'

const UnregisteredReferralPage = () => {
  return (
    <div>

<div className="container">

<div className="text-center referral-title mt-5">
       
       <h1 className="sm-25">Earn Upto ₹ 20,000 </h1>
        <p className="col-lg-7 mx-auto col-md-8 col-sm-10 col-12">
 Refer your friend to join Skills Uprise and earn exciting  cash rewards</p>
   
        
   
     </div>

     <div className="mt-3">
        <ScratchCards/>
     </div>
</div>




    </div>
  )
}

export default UnregisteredReferralPage