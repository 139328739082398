import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import GetPersonalRewards from '../../actions/GetPersonalRewards'
import { AuthContext } from '../../context/AuthContext'
import BadgeItem from './BadgeItem'
import Localbase from 'localbase'
import SetPage from '../../data/SetPage'

const PersonalBadges = ({limit}) => {
const {user} = useContext(AuthContext)
const [badges,setbadges] = useState(null)
const [newlimit,setnewlimit] = useState(12)
let db = new Localbase("db")
  const NewBadges =  async()=>{

    const res = await GetPersonalRewards({limit:newlimit,user})
    if(res){
      if(res.status==="success"){
setbadges(res.data)

db.collection("paths").add({
  badges:res.data
},"badges")
      }
       //  //console.log("badges "+JSON.stringify(res))
    }
  }


  useEffect(()=>{
  if(limit){
    setnewlimit(limit)
  }
    db.collection("paths").doc("badges").get().then(data=>{
      setbadges(data.badges)
    }).catch(error=>{
      
    })
  
NewBadges()
  },[])
  return (

     
<>
{badges?
     <>
     {badges.map((badge)=>(

      <>

<BadgeItem badge={badge}/>

      </>
     ))}
     </>:null}
    
  

</>

  
  )
}

export default PersonalBadges