import TextField from '@mui/material/TextField';
import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import { Checkbox } from '@mui/material';
import { Link } from 'react-router-dom';

import Modal from '@mui/material/Modal';
import ModalStyle from '../../data/ModalStyle';
import LogoutAll from '../../actions/LogoutAll';
import Toast from 'react-bootstrap/Toast'
import SetPage from '../../data/SetPage';
import { Helmet } from 'react-helmet';
const SignupPage = () => {
  SetPage("Login")
  useEffect(() => {
    window.scrollTo(0, 0)

 
  }, [])


  const [toast,settoast] = useState(false)
  const [toasttitle,settoasttitle] = useState(null)
  const [toastmessage,settoastmessage] = useState(null)
  const NewLogoutAll = async()=>{
const res = await LogoutAll({email,password})
if(res){
  if(res[0].status==="success"){

    localStorage.setItem("user", JSON.stringify(res[0].data[0]));
    window.location.replace("/dashboard")
   //  //console.log("user "+JSON.stringify(res[0]))   

  }else{

  }
 //  //console.log("logout "+JSON.stringify(res))
}

  }
  const [loading,setloading] = useState(false)
  const [remember,setremember] = useState(true)
  const [email,setemail] = useState(null)
  const [password,setpassword] = useState(null)
  const [modal,setmodal] = useState(false)
  return (
    <div className="main min-vh-90">

<Helmet>‍
        <title>Skills Uprise - SignUp | Create New Account</title>‍
        <meta name="description" content="Create a new account, join Skills Uprise and learn the best skills from our curated courses" />       
        <meta name="twitter:card" content="" />   
        <meta name="twitter:site" content="@skillsuprise" />    
        <meta name="twitter:creator" content="@skillsuprise" />                
        <meta name="twitter:title" content="" />   
        <meta name="twitter:description" content="" />  
        <meta name="twitter:image" content=""/>     
        <meta property="og:title" content="" />  
        <meta property="og:description" content="" />        
        <meta property="og:image" content=""/>
        <meta property="og:url" content=""/>
        <meta property="og:site_name" content="Skills Uprise" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta property="fb:app_id" content="ID_APP_FACEBOOK" />
      </Helmet>


<Toast onClose={() => settoast(false)} show={toast} delay={3000} autohide>
          <Toast.Header>
          
            <strong className="me-auto">{toasttitle}</strong>
           
          </Toast.Header>
          <Toast.Body>{toastmessage}</Toast.Body>
        </Toast>


  <Modal
        open={modal}
        onClose={()=>setmodal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={ModalStyle}>
    
<h4>Device limit exceeded !</h4>
<div className="d-flex justify-content-between">
<button className="btn btn-danger">Cancel</button>
<button className="btn btn-success" onClick={()=>NewLogoutAll({email,password})}>Logout All</button>
</div>
    
        </Box>
      </Modal>

      <div className="container pt-5 pb-5">


<div className="col-lg-5 shadow mx-auto br-5 p-3">
  <h3 className="mb-3">Create New Account</h3>
  <form onSubmit={(e)=>{e.preventDefault();}}>

  <Box
      sx={{
 
        maxWidth: '100%',
      }}
    >

     <div className="d-flex">
     <TextField required value={email} onChange={(e)=>{setemail(e.target.value)}} className="auth-input" fullWidth label="Email or Phone"  />
     <TextField required value={email} onChange={(e)=>{setemail(e.target.value)}} className="auth-input" fullWidth label="Email or Phone"  />
 
     </div>




     <div className="d-flex mt-3">
     <TextField required value={email} onChange={(e)=>{setemail(e.target.value)}} className="auth-input" fullWidth label="Email or Phone"  />
     <TextField required value={email} onChange={(e)=>{setemail(e.target.value)}} className="auth-input" fullWidth label="Email or Phone"  />
 
     </div>
       </Box>
    <Box
      sx={{
        width: 500,
        maxWidth: '100%',
      }}
    >
      <TextField required value={password} onChange={(e)=>{setpassword(e.target.value)}} className="auth-input mt-4" fullWidth label="Password"  />
    </Box>

    <div className="d-flex mt-3 align-items-center justify-content-between">
    <div className="d-flex align-items-center">
  <Checkbox/>
  <p>Remember password</p>
</div>


<div className="d-flex align-items-center">

  <p>Forgot password ?</p>
</div>
    </div>

    <div className="mt-3 mb-4">
      <button  className="btn btn-primary w-100">{loading?"Creating New Account...":"Create New Account"}</button>
    </div>
  </form>

    <div className="text-center">
      <p>Already have an account ?<Link to="/login">Login Here</Link></p>
    </div>
</div>
      </div>

    </div>
  )
}


export default SignupPage