import React, { useContext, useEffect, useState } from 'react'
import GetStudentsTimeline from '../../../actions/GetStudentsTimeline'
import DetectDevice from '../../../data/DetectDevice'
import PostItem from '../../community/PostItem'
import { useParams } from 'react-router-dom'
import { AuthContext } from '../../../context/AuthContext'
const BatchTimeline = () => {

  const params = useParams()
  const {user} = useContext(AuthContext)
  const course_url = params.course_url
useEffect(()=>{


StudentsTimeline()

},[posts])


const DisplayTimeline  = ()=>{
 
    const container = document.getElementById("timeline")
    if(container){

   
    const newdevice = DetectDevice()
    if(newdevice==="mobile"){
    container.classList.add("mobile-timeline")
    }
    else{
      container.style.height = (window.innerHeight - 60)+"px"
 
      container.style.overflowY ="scroll"
    container.classList.add("timeline")
    }
    

    }
  

}

const [posts,setposts] = useState(null)
const StudentsTimeline = async()=>{
  const res = await GetStudentsTimeline({payload:{
    course_url:course_url,
    limit:9,
    user:user.SessionId
  }})

  if(res){
    if(res.status==="success"&&res.posts!==null){
setposts(res.posts)
DisplayTimeline()
    }
     //  //console.log("timeline "+JSON.stringify(res))
  }
}

  return (
    <div>

{posts?
<>
<div id="timeline" className="w-100">
  {posts.map((post)=>(
    <>
    <PostItem post={post}/>
    </>
  ))}


</div>


</>:<>

</>}

    </div>
  )
}

export default BatchTimeline