import { CircularProgress } from '@material-ui/core';
import React from 'react'
import styled from 'styled-components'
import { useState } from 'react'

import Alert from '@mui/material/Alert';
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useContext } from 'react'
import { Toast } from 'react-bootstrap'
import { AuthContext } from '../../context/AuthContext'
import { useRef } from 'react'
import { Link } from 'react-router-dom'
import CloseIcon from '@mui/icons-material/Close';
import Edit from '@mui/icons-material/Edit';
import Business from '@mui/icons-material/Business';
import EditLocation from '@mui/icons-material/EditLocation';
import Language from '@mui/icons-material/Language';
import Phone from '@mui/icons-material/PhoneInTalk';
import ENDPOINT from '../../data/ENDPOINT'
import { Stack } from '@mui/material'
import Chip from '@mui/material/Chip';
import axios from 'axios'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import SetPage from '../../data/SetPage';
import InternshipDetailsTemplate from './InternshipDetailsTemplate';
import { Helmet } from 'react-helmet';
const InternshipDetails = (props) => {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])




  const params = useParams();
  const id = params.internship_id;

  sessionStorage.setItem("iid", id);
  const { user } = useContext(AuthContext);
  const userdata = user;


  const [application, setapplication] = useState(null);
  const [companyinfo, setcompanyinfo] = useState(null)
  const [more, setmore] = useState(false);
  const loginRedirect = ({ redirect }) => {

    sessionStorage.setItem("next", `/internship/${id}`)
    sessionStorage.setItem("msg", "Login to apply internship")
  }
  useEffect(() => {
    window.scrollTo(0, 0)
    SetPage("Internship")
    const getapplication = async () => {

      fetch(`${ENDPOINT}get-internship-details.php?id=${id}`)
        .then(r => r.json())
        .then(result => {

          if (result) {

            if (result.status === "success") {

              setapplication(result.internship[0]);
              setcompanyinfo(result.internship[0].company_description)

            }
            else {
              setapplication(null)
            }
          }

        })
    }
    getapplication();

  }

    , [])





  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  //code dump

  const [toasttitle, settoasttitle] = useState(null)
  const [toastmessage, settoastmessage] = useState(null)
  const [file, setfile] = useState(null)
  const [status, setstatus] = useState(false)
  const [uploadprogress, setuploadprogress] = useState(0)
  const UPLOAD_ENDPOINT = `${ENDPOINT}apply-internship.php`;
  const [toast, settoast] = useState(false)
  const onSubmit = async (e) => {
    setstatus(true)
    e.preventDefault()
    let res = await uploadFile(file);

    if (res) {
      setstatus(false)
    }
    if (res.data.status === "success" && res.data.message === "applied") {
      settoastmessage("Application Successful.")
      settoasttitle("You application has been successfully submitted ")
      settoast(true)
      setShow(false)
      setuploadprogress(0)
      sessionStorage.setItem("iid", null);
    } else if (res.data.status === "success" && res.data.message === "application_exists") {
      setuploadprogress(0)
      setShow(false)
      settoastmessage("You have already applied for this internship.")
      settoasttitle("Application Exists !")
      settoast(true)
    } else {
      setuploadprogress(0)
      setShow(false)
      settoastmessage("Please try again.")
      settoasttitle("Something went wrong !")
      settoast(true)
    }
  }
  const onChange = (e) => {
    setfile(e.target.files[0])

  }
  const uploadFile = async (file) => {
    const config = {
      onUploadProgress: function (progressEvent) {
        var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
        setuploadprogress(percentCompleted)


      }
    }
    const formData = new FormData();
    const user = JSON.parse(localStorage.getItem("user"));
    formData.append('session', user.SessionId);
    formData.append('resume', file);
    formData.append('whyus', document.getElementById('whyus').value)
    formData.append('reason', document.getElementById('reason').value)
    formData.append('workability', document.getElementById('workability').value)
    formData.append('availability', document.getElementById('availability').value)
    formData.append('id', id);

    return await axios.post(UPLOAD_ENDPOINT, formData, config, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    });
  }
  const [loginprompt, setloginprompt] = useState(false)


  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',

    boxShadow: 24,
    p: 4,
  };










  return (


    <>


      <div className="toast-container">

        <Toast className="bg-warning" onClose={() => settoast(false)} show={toast} delay={5000} autohide>
          <Toast.Header>
            <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
            <strong className="me-auto">{toasttitle}</strong>

          </Toast.Header>
          <Toast.Body>{toastmessage}</Toast.Body>
        </Toast>

      </div>



      <Modal
        open={loginprompt}
        onClose={() => setloginprompt(false)}

      >
        <Box className="col-lg-4 col-sm-10 col-10 p-3" sx={style}>


          <div className="text-center">

            <div className="d-flex justify-content-between">
              <h4 className="sm-18">Login into your account to apply</h4>
              <CloseIcon type="button" className="text-danger" onClick={() => setloginprompt(false)} />
            </div>
            <hr />
            <Link to="/login" onClick={() => { loginRedirect({ redirect: "login" }) }} className="btn btn-theme mt-3 mb-4">Login & Continue</Link>

            <div className="text-center">
              <p>Dont have an account ?<Link to="/signup" onClick={() => { loginRedirect({ redirect: "signup" }) }} >Signup & Continue</Link> </p>
            </div>
          </div>

        </Box>
      </Modal>




      {application ?


        <InternshipApplicationStyled className="p-3 container-xl mx-auto pb-0">

          <Helmet>
            <title>{application.title} {application.remote === "1" || 1 ? " Work From Home" : " In-Office"} internship - job | Skills Uprise</title>
            <meta name="description" content={"Apply for " + application.title + "internship, job at skills uprise and get " + application.stipend + " monthly stipend"} />
            <meta name="keywords" content="skills uprise internship,internship,vizag, visakhapatnam, jobs" />
          </Helmet>

          <div className="flex-wrap d-flex justify-content-between mt-20 ">
            <div className="col-g-7 float-right">
              <h2 className="page-title">{application.title}</h2>


              <Stack direction="row" className="mt-3  mb-3" spacing={1}>
                {application.category.map((tag, index) => (
                  <>

                    <Chip size="small" className=" internship-chip bg-primary text-white" label={tag} />

                  </>
                ))}

              </Stack>




              <Modal
                open={show}
                onClose={() => setShow(false)}

              >
                <Box className="col-lg-4 col-md-5 col-sm-10 col-12 p-3 br-5 sm-br-0" sx={style}>

                  <div className="p-0 ">
                    <div className="d-flex justify-content-between mb-4 align-items-center">
                      <h3 className="mb-0">Application</h3>
                      <CloseIcon type="button" className="text-danger" onClick={() => setShow(false)} />
                    </div>


                    <form onSubmit={onSubmit} className="position-relative">

                      <p className="mb-1">1. Why do you want to work with us ?</p>
                      <input className="p-2 mb-3 w-100 form-control" id="whyus" placeholder="Enter your answer" required></input>
                      <p className="mb-1">2. Why should we hire you ?</p>
                      <textarea className="p-2 mb-3 w-100 form-control" id="reason" name="reason" placeholder="Enter your answer" required></textarea>


                      <p className="mb-1">3. Are you available for the internship duration ?</p>
                      <input className="p-2 mb-3 w-100 form-control" id="availability" name="availability" placeholder="example: Yes i'm available" required></input>


                      <p className="mb-1">4. How many hours can you work in a day?</p>
                      <input className="p-2 w-100  form-control" id="workability" name="workability" placeholder="example: 4 to 5 hours" required></input>





                      <p className="mb-1 mt-4">5. Upload your latest resume</p>

                      <Alert className="extra-small mt-2 mb-2 w-100" severity="error">Only PDF files with less than 10 mb are accepted</Alert>

                      <input type="file" className="" placeholder="Photo/Video" accept="application/pdf" onChange={onChange} />
                      <br />

                      <button className="btn btn-primary w-100 mt-5" type="submit">{status ? <CircularProgress className="text-white " size={20} /> : ""} {uploadprogress > 0 ?
                        <>
                          Uploading {uploadprogress}%...
                        </>
                        : "Submit Application"
                      }</button>



                    </form>


                  </div>
                </Box>
              </Modal>













              <div className="d-flex">

                <p className="mt-2"><b>Duration : </b>{application.duration === "1" ? application.duration + " month" : application.duration + " month's"}</p>


              </div><p className="mt-2"><b>Stipend : </b>{application.stipend === "0" ? "Unpaid" : "₹ " + application.stipend + "/month"}</p>
              <p className="mt-2"><b>Apply by : </b>{application.apply_by}</p>

              <p className="mt-2"><b>Internship Mode : </b>{application.remote === "1" || 1 ? " Work From Home" : " In-Office"}</p>

              <p className="mt-2"><b>No of Openings : </b> {application.openings}</p>
            </div>
            <div className="col-lg-5">
              <h5 className="mt-20 mb-3">Hiring Process</h5>
              <ol>

                {application.process.map(step => (
                  <li className="mt-2 mb-2">{step}</li>
                ))}
              </ol>



              <div className=" mt-5  mb-20">

                <Link to="/contact" className="btn btn-primary mr-15"><Phone /> Contact Us</Link>


                {user !== null ?


                  <button onClick={handleShow} className="btn btn-success pl-3 pr-3"><Edit /> Apply Now</button>

                  :

                  <button onClick={() => setloginprompt(true)} className="btn btn-success">Apply Now</button>


                }
              </div>
            </div>

          </div>


          <hr className="w-100" />



          <div className="flex-wrap d-flex mobile-reverse">
            <div className="col-lg-7 col-md-7 col-sm-12 col-12 sm-p-0">




              <div className="internship-skills mt-20 mb-20">
                <h5>Skills Required</h5>



                <ul>
                  {application ? application.skills.map(skill => (


                    <li>{skill}</li>

                  )) : ""}
                </ul>

              </div>
              <div className="internship-skills mt-20 mb-20">
                <h5>Responsibilities</h5>



                <ul>
                  {application ? application.tasks.map(application => (


                    <li>{application}</li>

                  )) : ""}
                </ul>

              </div>


              <div className="benifits">
                <h5>Benifits</h5>
                <ol>
                  {application ? application.benifits.map(perk => (


                    <li>{perk}</li>

                  )) : ""}
                </ol>


              </div>
              {application ?
                <>

                  {application.internship_description ?

                    <>
                      <div className="internship-description mt-20 mb-20">
                        <h5>Aditional Info</h5>
                        <p>{application !== null ? application.internship_description : "No description"}</p>

                      </div>

                    </>
                    : null

                  }
                </> : null}

            </div>
            <div className="col-lg-5 col-md-5 col-sm-12 col-12 mt-20 sm-p-0">
              <h5>Company Overview</h5>


              <div className="d-flex">

                <img src={application.company_logo} className="company-logo mr-3 mt-2" alt="company-logo"></img>

                <div>
                  <div className="d-flex">
                    <Business className="mr-2 text-primary" />
                    <p className="mb-1">{application.company_name} ( {application.company_registered_name} )</p>

                  </div><p className="mb-1"><b><Language className="text-info" /> </b><a href={"https://" + application.website} referrer="no-referrer" target="_blank" >{application.website}</a></p>

                  <p className="mb-1"><b><EditLocation className="text-danger" /> </b>{application.city}</p>

                </div>
              </div><p><b></b></p>
              <hr></hr>
              <h5>About Company</h5>

              <p className={more ? "" : "text-truncate-6"}>{application.company_description}</p>

              {more ?

                <button className="btn btn-link" onClick={() => { setmore(false) }}>Hide More</button>

                :
                <button className="btn btn-link" onClick={() => { setmore(true) }}>Read More</button>

              }

            </div>

          </div>


          <div className="container-fluid br-4 d-flex mb-5 mt-5 bg-primary student-pass-cta justify-content-evenly text-white flex-wrap pt-5 pb-5 align-items-center ">
            <div className="col-lg-6 ">
              <h3 className="font-weight-bold sm-22">Grab your opportunity today !</h3>
              <h5 className="font-weight-normal sm-16">Start working with {application.company_name} as {application.position_title}</h5>
            </div>
            <div className="col-lg-3">


              {user !== null ?


                <button onClick={handleShow} className="btn btn-warning pl-3 pr-3"><Edit /> Apply Now</button>

                :

                <button onClick={() => setloginprompt(true)} className="btn btn-warning">Apply Now</button>


              }
            </div>
          </div>



        </InternshipApplicationStyled>
        : <div className="container">

          <InternshipDetailsTemplate />
        </div>
      }


    </>



  )
}

export default InternshipDetails


export const InternshipApplicationStyled = styled.div`

`