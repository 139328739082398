import axios from 'axios';
import React from 'react'
import ENDPOINT from '../../data/ENDPOINT';
import SecurityHeaders from '../../data/SecurityHeaders';

const GetVerifyCertificate = async ({payload}) => {

    const data = new FormData();

    data.append('certificateid',payload.certificate_id);

    const res = await axios.post(ENDPOINT+`verify-certificate.php`,data,SecurityHeaders);
    if(res){
       
        if(res.data){
            // console.log(res.data[0].data)
         
            return res.data[0]
           
        }
    }
}

export default GetVerifyCertificate