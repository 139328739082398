import React from 'react'
import './style.css'

import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import online from '../icons/online-learning.png'
import code from '../icons/web-programming.png'
import header from '../icons/header.png'

import CollegeContactForm from '../../../components/forms/CollegeContactForm';
import SetPage from '../../../data/SetPage';
import { useEffect } from 'react';
import { Check } from '@material-ui/icons';
const BusinessOverview = () => {

  useEffect(()=>{
    SetPage("Campus")
  },[])
  return (
   <>
 <div className="">


<div className="college-bg">

  <div className="d-flex container sm-p-0 flex-column-reverse flex-sm-column-reverse flex-lg-row flex-wrap">
  <div className="col-lg-6 sm-p-15">
<h1 className=' mt-lg-5 mt-0 mt-md-5 mt-sm-0 sm-mt-0'>Increase Students Employability &<br></br>Attract more students</h1>
<h5 className='font-weight-normal light-gr my-2'>Equip students with the most in-demand skills and prepare them for job success.</h5>


<div className="d-flex align-items-center my-2">
<CheckCircleIcon className='mb-0 text-primary'/>
  <h6 className='mb-0 pb-0 ms-1'>On-demand courses</h6>
</div>

<div className="d-flex align-items-center my-2">
<CheckCircleIcon className='text-primary'/>
  <h6 className='mb-0 pb-0 ms-1 '>AI powered global job search</h6>
</div>

<div className="d-flex align-items-center my-2">
<CheckCircleIcon className='mb-0 text-primary'/>
  <h6 className='mb-0 ms-1 pb-0 text-align-left'>AI-Powered college management system</h6>
</div>
<button className='btn btn-primary mt-3'>Contact Us </button>

</div>
  <div className="col-lg-6 p-0">
<img src="https://skillsuprise.com/resources/images/employability.png" className='w-100'/>
</div>
  </div>


</div>
<div className="container  college-stats">
  <div className="bg-dark text-light p-2 p-sm-3 p-md-4 rounded d-flex align-items-center flex-wrap">

    <div className="col-lg-4 border-right p-2 d-flex align-items-center">
<h3>76%</h3>
<div>
  <p className='text-light sm-12'>
Students are 76% more likely to enroll in a degree program that offers industry micro-credentials</p>
</div>
    </div>
    <hr className='d-md-none text-light border-white w-100 bg-secondary my-1'></hr>
    <div className="col-lg-4 p-2  border-right d-flex align-items-center">
<h3>88%</h3>
<div>
  <p className='text-light sm-12'>
  of employers believe that Professional Certificates strengthen a candidate’s job application</p>
</div>
    </div>

    <hr className='d-md-none text-light border-white w-100 bg-secondary my-1'></hr>
 
    <div className="col-lg-4 d-flex p-2 align-items-center">
<h3>90%</h3>
<div>
  <p className='text-light sm-12'>
  of students agree that a Professional Certificate will help them secure a job</p>
</div>
    </div>


  </div>
</div>

<div className="mt-5 mb-3 container">
        <div className=" d-none justify-content-center">
          <div>
          <h1 className="hero-text theme-gradient text-center">
   Take Your Students Career<br></br> To The Next Levels
        </h1>
            <h1 className="d-none">
              Take your Students Career to The{" "}
              <span className="theme-gradient">Next Level!</span>
            </h1>
            <h6 className="my-3 text-secondary text-center">
              Value added courses & trainings.
            </h6>
          </div>
        </div>

        <div className=" p-4 sm-p-0 rounded col-lg-12 mt-5 d-flex flex-wrap justify-content-between ">
          <div className="col-lg-5 col-12 col-sm-12 col-md-5">
   <h1>We Offer Students Success Through.</h1>

            <div className="nav-item p-2  rounded d-flex align-items-center mt-3">
                <div className="col-1">
                <img src={online} className="w-100 "/>
                </div>
                <h5 className="ms-2 text-dark text-align-left">Value added courses & training programs.</h5>
            </div>


            <div className="nav-item p-2  rounded d-flex align-items-center mt-2">
                <div className="col-1">
                <img src={online} className="w-100 "/>
                </div>
                <h5 className="ms-2 text-dark text-align-left">Industrial trainings & internships.</h5>
            </div>
            <div className="nav-item p-2 mt-2 rounded d-flex ">
                <div className="col-1">
                <img src={header} className="w-100 "/>
                </div>
                <h5 className="ms-2 text-align-left text-dark">Workshops , Webinars & Hackathons.</h5>
            </div>
         
            <div className="nav-item p-2 mt-2 rounded d-flex ">
                <div className="col-1">
                <img src={code} className="w-100 "/>
                </div>
                <h5 className="ms-2 text-dark text-align-left">AI Powered College Management System</h5>
            </div>

          </div>
          <div className="col-lg-6 col-12 col-sm-12 col-md-6 p-2">
            <img className="w-100" src="https://skillsuprise.com/resources/images/college1.png"/>

          </div>
        </div>

      
      </div>
  <div className="my-5">
  <div className=" demo-bg col-lg-12 pt-5 mt-5">
  <div className="container">
  <div className="col-lg-10 mx-auto ">
        <h1 className="hero-text theme-gradient text-center">
        World-Class Features
        </h1>
       <div className="text-center">
        <h5>Every Digital Age Student, College & University Needs</h5>
       </div>
    </div>
 
   

    <div className="justify-content-lg-center college-options h-fit w-100 sm-justify-content-start justify-content-start overflow-x mt-4  d-flex ">

    <button className='btn mx-2 h-fit shadow rounded w-fit rounded-pill'>For Students</button>


    <button className='btn mx-2 h-fit shadow rounded w-fit rounded-pill'>For Students</button>


    <button className='btn mx-2 shadow rounded rounded-pill w-fit'>For Students</button>


    <button className='btn mx-2 shadow rounded rounded-pill w-fit'>For Students</button>


  



    </div>

    <div className="d-flex flex-wrap justify-content-around  my-5 ">
   <div className="col-lg-4 p-3">

   <img className="w-100 shadow" src="https://skillsuprise.com/resources/images/student1.png"/>


   </div>


   <div className="col-lg-4 p-3">

<img className="w-100 shadow" src="https://skillsuprise.com/resources/images/student2.png"/>


</div>


<div className="col-lg-4 p-3">

<img className="w-100 shadow" src="https://skillsuprise.com/resources/images/student3.png"/>


</div>

    </div>
  </div>

   </div>
  </div>

      <div className="position-relative py-5">
        <div className="rbt-shape-bg col-12">
          <img
            className="w-100"
            src="https://rainbowit.net/html/histudy/assets/images/splash/bg/left-right-line-small.svg"
          />
        </div>
        <div className="d-flex flex-wrap  pb-5 pt-5  ">
          <div className="col-lg-6 ">
            <img
              className="w-100 border shadow rounded"
              src="https://skillsuprise.com/resources/images/flow.png"
            />
            
          </div>
          <div className="col-lg-5 container">
            <div className="">
              <h5 className="badge   p-2  sm-mt-3 mt-3 md-mt-0 sm-12   ">Student can achieve anything</h5>
              <h1 className='mt-3'>Give Your Students An Enhanced Learning Experience That Inspire, Educate & Innovate Their Minds.</h1>
              <p className="fs-5">
              Personalised training programs that fits into every student minds.
              </p>
            </div>
        
          
            <div className="d-flex mt-3">
              <div>
                <div className="d-flex align-items-center">
                  <CheckCircleIcon color="primary" sx={{ fontSize: 30 }} />
                  <p className=" my-auto">On-demand courses</p>
                </div>
                <div className="d-flex mt-1 align-items-center">
                  <CheckCircleIcon color="primary" sx={{ fontSize: 30 }} />
                  <p className=" my-auto">Flexible training modes</p>
                </div>
                <div className="d-flex mt-1 align-items-center">
                  <CheckCircleIcon color="primary" sx={{ fontSize: 30 }} />
                  <p className=" my-auto">Pre-recorded practical tutorials</p>
                </div>
                <div className="d-flex mt-1 align-items-center">
                  <CheckCircleIcon color="primary" sx={{ fontSize: 30 }} />
                  <p className=" my-auto">E-books & Placement preparation kits</p>
                </div>

                <div className="d-flex mt-1 align-items-center">
                  <CheckCircleIcon color="primary" sx={{ fontSize: 30 }} />
                  <p className=" my-auto">Mock tests & Challenges</p>
                </div>
              </div>
            
            </div>
          </div>
        </div>
      </div>










      <div className="position-relative py-5 ">
        <div className="rbt-shape-bg col-12">
          <img
            className="w-100"
            src="https://rainbowit.net/html/histudy/assets/images/splash/bg/left-right-line-small.svg"
          />
        </div>
        <div className="d-flex flex-wrap flex-column-reverse flex-sm-column-reverse flex-md-row  pb-5 pt-5  ">
          <div className="col-lg-5 container">
            <div className="">
              <h5 className="badge  p-2  sm-mt-3 mt-3 md-mt-0 sm-12">Dream job at FAANG Companies is not a dream anymore</h5>
              <h1 className='mt-3 sm-mt-3 '>Prepare Your Students For Job Success With Our Dedicated Placement Trainings</h1>
              <p className="fs-5">
                From emerging start-up's to leading MNC's, creating student successful career paths is what our team strives for.
              </p>
            </div>
          
            <div className="d-flex mt-3">
              <div>
                <div className="d-flex align-items-center">
                  <CheckCircleIcon color="primary" sx={{ fontSize: 30 }} />
                  <p className=" my-auto">Mock interviews</p>
                </div>
                <div className="d-flex mt-1 align-items-center">
                  <CheckCircleIcon color="primary" sx={{ fontSize: 30 }} />
                  <p className=" my-auto">Reasoning & Aptitude training</p>
                </div>
                <div className="d-flex mt-1 align-items-center">
                  <CheckCircleIcon color="primary" sx={{ fontSize: 30 }} />
                  <p className=" my-auto">Communication development training</p>
                </div>
                <div className="d-flex mt-1 align-items-center">
                  <CheckCircleIcon color="primary" sx={{ fontSize: 30 }} />
                  <p className=" my-auto">Resume development trainings</p>
                </div>

                <div className="d-flex mt-1 align-items-center">
                  <CheckCircleIcon color="primary" sx={{ fontSize: 30 }} />
                  <p className=" my-auto">Mock tests</p>
                </div>
              </div>
            
            </div>
          </div>
          <div className="col-lg-6 ">
            <img
              className="w-100 shadow border rounded"
              src="https://skillsuprise.com/resources/images/flow2.png"
            />
            
          </div>
        </div>
      </div>











   
      <div className="position-relative  py-5">
        <div className="rbt-shape-bg col-12">
          <img
            className="w-100"
            src="https://rainbowit.net/html/histudy/assets/images/splash/bg/left-right-line-small.svg"
          />
        </div>
        <div className="d-flex flex-wrap  pb-5 pt-5  ">
      
          <div className="col-lg-6 ">
            <img
              className="w-100 shadow border rounded"
              src="https://skillsuprise.com/resources/images/flow2.png"
            />
            
          </div>

          <div className="col-lg-5 container">
            <div className="">
              <h5 className="badge      p-2  sm-mt-3 mt-3 md-mt-0 sm-12">Dream job at FAANG Companies is not a dream anymore</h5>
              <h1 className='mt-3'>We offer everything a student need to succeed</h1>
              <p className="fs-5">
                From emerging start-up's to leading MNC's, creating student successful career paths is what our team strives for.
              </p>
            </div>
          
            <div className="d-flex mt-3">
              <div>
                <div className="d-flex align-items-center">
                  <CheckCircleIcon color="primary" sx={{ fontSize: 30 }} />
                  <p className=" my-auto">Mock interviews</p>
                </div>
                <div className="d-flex mt-1 align-items-center">
                  <CheckCircleIcon color="primary" sx={{ fontSize: 30 }} />
                  <p className=" my-auto">Reasoning & Aptitude training</p>
                </div>
                <div className="d-flex mt-1 align-items-center">
                  <CheckCircleIcon color="primary" sx={{ fontSize: 30 }} />
                  <p className=" my-auto">Communication development training</p>
                </div>
                <div className="d-flex mt-1 align-items-center">
                  <CheckCircleIcon color="primary" sx={{ fontSize: 30 }} />
                  <p className=" my-auto">Resume development trainings</p>
                </div>

                <div className="d-flex mt-1 align-items-center">
                  <CheckCircleIcon color="primary" sx={{ fontSize: 30 }} />
                  <p className=" my-auto">Mock tests</p>
                </div>
              </div>
            
            </div>
          </div>
        </div>
      </div>


  

  
 </div>
 
 
 <div className="container py-5 mb-5">


<div className="d-flex pb-5 justify-content-evenly flex-wrap">

<div className="col-lg-6 col-12">
  <h1>Get in touch with our sales team</h1>

  <div className="mt-3">
    <p>Join the innovative colleges and universities globally that choose Skills Uprise for their students</p>
 
 <div className="mt-3">
  <div className="d-flex my-1">
  <Check className='me-2'/>
    <p>Increase employment for your students</p>
  </div>

  <div className="d-flex my-1">
  <Check className='me-2'/>
    <p>Enhance learning experiences</p>
  </div>


  <div className="d-flex my-1">
    <Check className='me-2'/>
    <p>Manage your college/university effortlessly</p>
  </div>
 </div>
 
  </div>
</div>
<div className="col-lg-5 mt-3 md-mt-0 shadow p-3 rounded col-12">


<CollegeContactForm/>
</div>

</div>

 </div>
 </>
  )
}

export default BusinessOverview