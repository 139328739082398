import React, { useState } from 'react'
import Dropdown from 'react-bootstrap/Dropdown';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Avatar } from '@mui/material';
import Logout from '../authentication/Logout';
import { useContext } from 'react';
import { AuthContext } from '../../context/AuthContext';
import { Close } from '@material-ui/icons';
import ModalStyle from '../../data/ModalStyle';
import LogoutModal from '../../components/modals/LogoutModal';
import EditIcon from '@mui/icons-material/Edit';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import PaymentIcon from '@mui/icons-material/Payment';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import '../../assets/css/style.css';




const AccountOptionsDrowdown = ({account}) => {
const {user} = useContext(AuthContext)
const [logoutmodal,setlogoutmodal] = useState(false  )



const CloseLogoutModal = ()=>{
setlogoutmodal(false)


}
  return (
    <>





<LogoutModal visibility={logoutmodal} HandleClose={CloseLogoutModal}/>



    <Dropdown className="dropdown">
  <Dropdown.Toggle className="bg-transparent">
    <Avatar src={account.profile_pic?account.profile_pic:null}/>

  </Dropdown.Toggle>

  <Dropdown.Menu className="p-3">
   
   
   <div className="mt-1 b-1">
   <Link  to={"/u/"+account.username}>
<Dropdown.Item as="button"><AccountCircleIcon className='me-2 '/>My Account</Dropdown.Item>
      
</Link>
   </div>

   <div className="mt-1 b-1 ">
   <Link  to="/edit-profile">
<Dropdown.Item as="button"><EditIcon className='me-2'/>Edit Profile</Dropdown.Item>
      
</Link>
   </div>
  


<div className="mt-1 mb-1">
<Link to="/wallet">
               <Dropdown.Item as="button">
  <AccountBalanceWalletIcon className='me-2'/>Wallet </Dropdown.Item>
               </Link>
</div>      


<div className="mt-1 mb-1">
<Link to="/payments">
               <Dropdown.Item as="button">
   <PaymentIcon className='me-2'/>Payments</Dropdown.Item>
               </Link>
</div>      


<div className="mt-1 mb-1">
<Link to="/settings">
               <Dropdown.Item as="button">
 <SettingsIcon className='me-2'/>Settings</Dropdown.Item>
               </Link>
</div>      



   


          <div className="mt-1 mb-1">
     
          <Dropdown.Item onClick={()=>{setlogoutmodal(true)}} as="button"><LogoutIcon className='me-2'/>Logout</Dropdown.Item>
  
</div>




   
    </Dropdown.Menu>
</Dropdown>
     

    
    </>
  )
}

export default AccountOptionsDrowdown