import React, { useState } from "react";
import { Chip, TextField, Button, Container, Typography, Box, Paper, Grid, Alert } from "@mui/material";
import axios from "axios";
import ENDPOINT from "../../data/ENDPOINT";
import SecurityHeaders from "../../data/SecurityHeaders";

const SupportPage = () => {
  const [data, setData] = useState(null);
  const [trackingId, setTrackingId] = useState("");
  const [error, setError] = useState("");

  const getData = async (e) => {
    e.preventDefault();
    setError("");
    if (!trackingId) {
      setError("Tracking ID cannot be empty");
      return;
    }
    try {
      const formData = new FormData();
      formData.append("tracking_id", trackingId);
      const res = await axios.post(
        ENDPOINT + "get-support-info.php",
        formData,
        SecurityHeaders
      );

      if (res && res.data.status === "success") {
        setData(res.data.data);
        if(res.data.data === null){
          setError("No data found for this Tracking ID");
        }
      } 
    } catch (error) {
      setError("Error fetching data");
    }
  };

  return (
    <Container className="vh-100" maxWidth="sm" style={{ marginTop: '5rem' }}>
      <Paper elevation={3} style={{ padding: '2rem', textAlign: 'center' }}>
        <Typography variant="h4" gutterBottom>
          Customer Support
        </Typography>
        <Box component="form" onSubmit={getData} noValidate sx={{ mt: 1 }}>
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12}>
              <TextField
                value={trackingId}
                onChange={(e) => setTrackingId(e.target.value)}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="tracking-id"
                label="Enter Tracking ID"
                name="tracking-id"
                autoComplete="tracking-id"
                autoFocus
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                size="large"
              >
                Get Info
              </Button>
            </Grid>
          </Grid>
        </Box>
        {error && <Alert severity="error" style={{ marginTop: '1rem' }}>{error}</Alert>}
        {data && (
          <Box mt={4}>
            {data.map((item, index) => (
              <Paper key={index} elevation={2} style={{ padding: '1rem', marginBottom: '1rem' }}>
                <Grid container spacing={2}>
                  <Grid item xs={8}>
                    <Typography variant="h6">{item.subject}</Typography>
                    <Typography variant="body2" color="textSecondary">{item.message}</Typography>
                  </Grid>
                  <Grid item xs={4} style={{ textAlign: 'right' }}>
                    <Chip
                      size="small"
                      className={item.status === "resolved" ? "bg-success text-white" : "bg-danger text-white"}
                      label={item.status}
                      style={{ marginTop: '0.5rem' }}
                    />
                  </Grid>
                </Grid>
              </Paper>
            ))}
          </Box>
        )}
      </Paper>
    </Container>
  );
};

export default SupportPage;
