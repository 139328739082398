import axios from 'axios'
import ENDPOINT from '../../data/ENDPOINT'
import SecurityHeaders from '../../data/SecurityHeaders'

const  GetContacts = async ({payload})=>{

const data = new FormData()


 
   if(payload.category){
     data.append('category',payload.category); 
   
   }
       

const res = await 
axios.post(ENDPOINT+`get-contact.php`,data,SecurityHeaders)
if(res){

     return res.data[0]
}

}





export default GetContacts