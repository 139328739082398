import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import LoginStatus from './LoginStatus'

const RedirectNext = ({payload}) => {

    sessionStorage.setItem("next",payload)
    
}

export default RedirectNext