import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Chip,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useState, useEffect } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// import { useParams } from "react-router";
// const params = useParams();
// const userId = params.userId;

const TestAnswers = () => {
  const [result, setResult] = useState("");
  const [color, setColor] = useState("");

  const GetAnswers = async () => {
    const data = new FormData();
    data.append("user_id", 283);
    const res = await axios.post(
      "https://www.skillsuprise.com/quiz-attempt-answers.php",
      data,
      { "content-type": "multipart/form-data" }
    );
    if (res) {
      if (res.data) {
        setResult(res.data.data);
      }
    }
  };

  useEffect(() => {
    GetAnswers();
  }, []);

  return (
    <>
      <div className="container">
        <div className="col-lg-5">
          {result ? (
            <>
              {result.map((e) => (
                <div key={e.quiz_answer} className="mt-3">
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography>{e.quiz[0].question}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className="d-flex col-lg-7 justify-content-evenly">
                        <Chip label={e.quiz[0].answer} color="primary" />
                        <Chip label={e.quiz_answer} />
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </div>
              ))}
            </>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default TestAnswers;
