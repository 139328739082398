import { Avatar } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Rating from '@mui/material/Rating';
import {Link} from 'react-router-dom'
import divider from '../../assets/bg/divider.png'
import SetPage from '../../data/SetPage';

import { ForkRight } from '@mui/icons-material';
import ArrowRightIcon from '@mui/icons-material/ArrowRightAlt';
import WatchDemoModal from '../../components/modals/WatchDemoModal';
const WorkshopReviews = () => {

const [show,setshow] = useState(false)
const [currentvideo,setcurrentvideo] = useState(null)
const [poster,setposter] = useState(null)
const ShowVideo = ({video,poster})=>{
setposter(poster)
setcurrentvideo(video)
setshow(true)

}
  return (
    <div className="w-100">
      <WatchDemoModal show={show} video={currentvideo} poster={poster}/>

<div className="text-center">
     <h1 className="font-weight-bold secondary-text ">Rated 4.8 by 10k+ Students</h1>
<h5 className="text-dark">Loved by Students & Respected by professionals</h5>

<div class="divider">
<img src={divider}  alt="divider"></img>
</div>
  

</div>
<div className="container d-flex flex-wrap animate">

  <div className="col-lg-4 col-md-6 col-sm-12 col-12 p-2">
    <video className='w-100 rounded bg-dark'  controls src='https://skillsuprise.com/data/videos/workshop-reviews/review1.mp4' poster='https://skillsuprise.com/data/videos/workshop-reviews/sharon-poster.png'></video>

     </div>

     <div className="col-lg-4 col-md-6 col-sm-12 col-12 p-2">
    <video className='w-100 rounded bg-dark'  controls src='https://skillsuprise.com/data/videos/workshop-reviews/review2.mp4' poster='https://skillsuprise.com/data/videos/workshop-reviews/santosh.png'></video>

     </div>

     <div className="col-lg-4 col-md-6 col-sm-12 col-12 p-2">
    <video className='w-100 rounded bg-dark'  controls src='https://skillsuprise.com/data/videos/workshop-reviews/review3.mp4' poster='https://skillsuprise.com/data/videos/workshop-reviews/review3.png'></video>

     </div>
  

  
  
<div className="col-lg-4 delay05s fade-in-bottom col-md-6 col-sm-12 col-12  sm-p-0 p-3">

<div className="shadow br-5 p-3">

<div className="d-flex">
<img src="https://skillsuprise.com/resources/images/anusha-dash.jpg" className="userdp mr-15 rounded-circle"/>
<div>
<h5 className="sm-16 sm-pb-0">Anusha Dash</h5>
<p className="sm-12">Cyber Security Intern, IBM</p>
<p className="sm-12">West Bengal</p>
<div className="d-flex sm-12">
<Rating name="read-only" value={5} readOnly /> (Rated 5)
</div>

</div>
</div>
<hr/>
<div className="p-2">
  
<p className="sm-14">
It's a great opportunity to us to learn real hacking. The workshop was completely practical and sir has great patience to stay with us and gave answers to every question of the students and really helped us to achieve more in our career. Even the team members of skills uprise are in the workshop ready to clear our doubts anytime and that was really great.
</p>
 
</div>
</div>
</div>

<div className="col-lg-4 delay05s fade-in-bottom col-md-6 col-sm-12 col-12 sm-mt-20  sm-p-0 sm-mb-20 p-3">

<div className="shadow br-5 p-3">

<div className="d-flex">
<img src="https://skillsuprise.com/resources/images/potu-venkatesh.png" className="userdp mr-15 rounded-circle"/>
<div>
<h5 className="sm-16 sm-pb-0">Potu Venkatesh</h5>
<p className="sm-12">Vignan Degree & PG College</p>
<p className="sm-12">Final Year</p>
<div className="d-flex sm-12">
<Rating name="read-only" value={5} readOnly /> (Rated 5)
</div>
</div>
</div>
<hr/>
<div className="p-2">
  <p className="sm-14">


  Manoj sir teaching was different from other teachers, his teaching is like in a very friendly manner and regular way. It felt like our own Brother is guiding us to build our career. He started from fundamentals of hacking and taught us the practical hacking with real websites. That wa really awesome. Im so thankful for making me a part of this workshop sir.
  </p></div>
</div>
</div>


<div className="col-lg-4 delay05s fade-in-bottom col-md-6 col-sm-12 col-12  sm-mb-20 sm-p-0  p-3">

<div className="shadow br-5 p-3">

<div className="d-flex">
<img src="https://skillsuprise.com/resources/images/sai-rahul.png" className="userdp mr-15 rounded-circle"/>
<div>
<h5 className="sm-16 sm-pb-0">Sai Rahul</h5>
<p className="sm-12">Back-end Developer, Pixxel</p>
<p className="sm-12">Telangana</p>
<div className="d-flex sm-12">
<Rating name="read-only" value={5} readOnly /> (Rated 5)
</div>
</div>
</div>
<hr/>
<div className="p-2">
<p className="sm-14">
 
 Sir started teaching from zero levels and also explained doubts in telugu when we asked and every speaker is so encouraging.Their explainations gave motivation for the students that attended the wroskshop and clarity about cyber security career. Thanks a lot Manoj sir for such workshop.
  </p>
</div>
</div>
</div>




{/*linked reviews*/}

<div className="col-lg-4 delay08s fade-in-bottom col-md-6 col-sm-12 col-12  sm-mb-20 sm-p-0  p-3">

<div className=" br-5 p-1">
<img src="https://skillsuprise.com/resources/images/workshop4.png" className="w-100"></img>
</div>
</div>
<div className="col-lg-4 delay08s fade-in-bottom col-md-5 col-sm-12 col-12  sm-mb-20 sm-p-0  p-3">

<div className=" br-5 p-1">
<img src="https://skillsuprise.com/resources/images/workshop1.png" className="w-100"></img>
</div>
</div>




<div className="col-lg-4 delay08s fade-in-bottom col-md-6 col-sm-12 col-12  sm-mb-20 sm-p-0  p-3">

<div className=" br-5 p-1">
<img src="https://skillsuprise.com/resources/images/workshop3.png" className="w-100"></img>
</div>
</div>




{/*end of linkedin reviews*/}

</div>


    </div>
  )
}

export default WorkshopReviews