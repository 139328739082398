import React, { useContext, useState, useEffect } from 'react';
import GetIntro from '../../actions/intros/GetIntro';
import GetWalkthroughs from '../../actions/GetWalkthroughs';
import IntroModal from './IntroModal';
import Localbase from 'localbase';
import DetectDevice from '../../data/DetectDevice';
import { AuthContext } from '../../context/AuthContext';

const GettingStartedDashboard = () => {
  const { user } = useContext(AuthContext);
  const [beginner, setBeginner] = useState(true);
  const db = new Localbase();
  const device = DetectDevice();
  const [videos, setVideos] = useState(null);
  const [suggestions, setSuggestions] = useState(null);
  const [limit, setLimit] = useState(device === 'mobile' ? 3 : 2);
  const [totalVideos, setTotalVideos] = useState(0);
  const [loading, setLoading] = useState(false);
  const [noMore, setNoMore] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    NewVideos()
  }, []);

  const NewVideos = async () => {
    setLoading(true);

    const res = await GetWalkthroughs({
      payload: {
        limit: limit,
    
        SessionId: user ? user.SessionId : null,
      },
    });

    if (res) {
      setLoading(false);
    }

    if (res && res.status === 'success') {
      setVideos(res.videos);

      if (res.videos) {
        if (res.videos.length === res.total_videos) {
          setNoMore(true);
        }
        setTotalVideos(res.videos.length);
      }

      setSuggestions(res.suggestions);

      db.collection('paths').add({
        videos: res.videos,
      }, 'walkthroughs');
    }
  };

  useEffect(() => {
    db.collection('paths')
      .doc('walkthroughs')
      .get()
      .then(data => {
        setVideos(data.videos);
      })
      .catch(error => {
        // Handle error
      });

    const CheckVisitor = async () => {
      const res = await GetIntro({
        payload: {
          user: user ? user.SessionId : null,
          name: 'walkthrough-video',
        },
      });

      if (res && res.status === 'success') {
        setBeginner(res.beginner);
      }
    };

    CheckVisitor();
  }, []);

  const [video, setVideo] = useState(null);
  const [visibility, setVisibility] = useState(false);

  const HandleClose = () => {
    setVisibility(false);
  };
  const [poster,setPoster] = useState(null)
const PlayVideo = ({item})=>{
setVideo(item.url)
setPoster(item.cover_image)
setVisibility(true)
}
  return (
    <div>
      <IntroModal visibility={visibility} nosound={true} poster={poster} video={video} HandleClose={HandleClose} />

      <div className="border bg-white p-3 sm-p-0 border border-0 border-md-1 border-lg-1 rounded mt-3 mt-md-0">
        <div className="">
          <h4>Get Started with Skills Uprise !</h4>
        </div>

        <div className="">
          <div className="pt-0">
            {videos?
            <>
            {videos.map((item)=>(
                <>
                
                <div onClick={()=>PlayVideo({item:item})} class="d-flex click text-dark p-2 px-0 rounded" href="/course-dashboard/ethical-hacking-specialization/1009">
    <div class="col-lg-4 col-5 mr-10">
        <img src={item.cover_image} class="w-100 br-4 course-progress-cover"/></div>
        <div class="col-lg-7 position-relative ">
            <h6 class="truncate truncate2 sm-16">{item.title}</h6>
            <div class="d-flex d-none text-center mb-2 align-items-center">
                                <div class="mr-10">
                                    <h6 class="">Completed</h6>
                                    <p>0%</p></div><div class="">
                                        <h6>Next Class</h6><p>23rd July @ 6pm</p></div></div><button class="btn-continue  d-sm-block">Watch Tutorial</button></div>
                                        
                                        </div>  
                </>))}
            </>:null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GettingStartedDashboard;
