import React, { useState } from 'react'
import MobileHomeReview from './MobileHomeReview'
import { Link } from 'react-router-dom'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import ModalStyle from '../../data/ModalStyle'
import Close from '@mui/icons-material/Close'
const ReviewsCta = () => {

    const [currentvideo,setcurrentvideo] = useState(false)
const [showreview,setshowreview] = useState(false)
const PlayReview = ({data})=>{
setcurrentvideo(data)
setshowreview(true)
}
  return (
<>


<Modal
  open={showreview}
  onClose={()=>setshowreview(false)}

>
  <Box sx={ModalStyle}  className="col-lg-4 col-md-5 br-5 col-sm-6 col-11 p-2">
<div className="d-flex justify-content-between">
     <h4>Student Review</h4>
     <Close className="text-danger" type="button" onClick={()=>setshowreview(false)}/>
</div>
    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
    <video className="w-100 bg-dark"controls  poster={currentvideo.poster}   autoPlay src={currentvideo.video} ></video>
    </Typography>
  </Box>
</Modal>
<div className="bg-theme-gradient p-3 w-100">
<h2 className="left-red-border text-light sm-mb-10">Students Love, Trust & Respect Us</h2>
  {/* <p className="text-light ps-10 sm-mb-20 d-none">Our trainers care and guide every student to acheive their goals and fullfill their dreams by being a better guide throughout their career journey.</p>
 */}


  <div className="container mt-2 sm-p-0 px-0  p-0 d-flex flex-wrap justify-content-evenly animate">


<div className="col-lg-3">
<MobileHomeReview/>
</div>


<div className="col-lg-7 flex-wrap d-flex p-0 mt-2 mt-md-0">
<div className="col-lg-6 col-md-6 col-6 p-2 ps-0 ps-md-2">
<video onClick={()=>PlayReview({data:{video:"https://skillsuprise.com/data/videos/home-review1.mp4",poster:"https://skillsuprise.com/data/videos/home-review1.png"}})}  className='w-100 rounded border bg-dark'  src='https://skillsuprise.com/data/videos/home-review1.mp4' poster='https://skillsuprise.com/data/videos/home-review1.png'></video>

</div>

<div className="col-lg-6 col-md-6 col-6 p-2 pe-0 pe-md-2">
<video  onClick={()=>PlayReview({data:{video:"https://skillsuprise.com/data/videos/home-review2.mp4",poster:"https://skillsuprise.com/data/videos/home-review2.png"}})}   className='w-100 rounded border bg-dark'   src='https://skillsuprise.com/data/videos/home-review2.mp4' poster='https://skillsuprise.com/data/videos/home-review2.png'></video>

</div>

<div className="col-lg-6 col-lmd-6 d-none d-md-block col-12 p-2">
<video className='w-100 rounded border bg-dark' src='https://skillsuprise.com/data/videos/home-review3.mp4' poster='https://skillsuprise.com/data/videos/home-review3.png'></video>

</div>


<div className="col-lg-6 col-lmd-6 d-none d-md-block col-12 p-2">
<video className='w-100 rounded border bg-dark' src='https://skillsuprise.com/data/videos/home-review4.mp4' poster='https://skillsuprise.com/data/videos/home-review4.png'></video>

</div>





</div>





</div>


  {/* <div className=" d-none story-main  p-0 mb-3 mt-2">
  <video id="prime-video2"   muted={muted}  poster="https://skillsuprise.com/data/images/training-features.png"  className="w-100  mb-0 br-5  p-0 bg-white" controlsList="download:false;"  preload={true}  src="https://skillsuprise.com/data/videos/placement-training.mp4"></video>

 
  </div> */}


  {/* <div className="grid grid-2 lg-grid-3 d-none w-100">
  <div className=" br-5 p-0">

  <video id="prime-video3"   onClick={()=>setstudentactivities(true)}   poster="https://skillsuprise.com/data/images/student-activities.png"  className="w-100 mb-0 p-0 br-5 bg-white" controlsList="download:false;"  preload={true}  src="https://skillsuprise.com/data/videos/student-activities.mp4"></video>

</div>
<div className=" br-5 p-0">
<video id="prime-video4"  onClick={()=>setstudentactivities(true)}   poster="https://skillsuprise.com/data/images/student-reviews.png"  className="w-100 mb-0 p-0 br-5  bg-white" controlsList="download:false;"  preload={true}  src="https://skillsuprise.com/data/videos/student-reviews.mp4"></video>


</div>
<div className=" br-5 p-0 d-none d-sm-block">

<video id="prime-video4"   onClick={()=>setstudentactivities(true)}    poster="https://skillsuprise.com/data/images/placement-training.png"  className="w-100 p-0 br-5 mb-0 bg-white" controlsList="download:false;"  preload={true}  src="https://skillsuprise.com/data/videos/placement-trainng.mp4"></video>

</div>

  </div> */}
<div>

</div>

</div>

</>
  )
}

export default ReviewsCta