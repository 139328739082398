import Lock from '@mui/icons-material/Lock'
import React from 'react'
import Tooltip from '@mui/material/Tooltip';
import { Link } from 'react-router-dom';

const truncateText = (text, maxLength) => {
  if (text.length > maxLength) {
    return text.slice(0, maxLength) + '...';
  }
  return text;
};

const CourseBadge = ({badge}) => {
  return (

    <>


    {badge.status==="earned"?
    
  <>
<Link className='text-decoration-none bg-white shadow rounded' to={`/course-badge-earned/${badge.badge_id}`}>
      <div className="shadow text-center br-5">
        <div className="badge-cover">
        </div>
        <img 
          src={badge.image} 
          alt={badge.title} 
          style={{ height: '75%', width: '80%' }} 
          className="br-5 mb-1" 
        />
        <h6 className="mt-2 sm-16">{badge.title}</h6>
        <div className="badge-description   p-3">
        <p className='text-dark truncate truncate3 text-12'>{badge.description}</p>
          {badge.description}
        </div>
      </div>
    </Link>



  
  </>:
  <>
<Link to={`/course-badge/${badge.badge_id}`} className='text-decoration-none bg-white shadow rounded'>
      <div className="position-relative text-center br-5">
        <div className="badge-cover">
          <Lock />
        </div>
        <img 
          src={badge.image} 
          alt={badge.title} 
          style={{ height: '75%', width: '80%' }} 
          className="br-5 mb-1" 
        />
        <div className="badge-description   p-3">
        <p className='text-dark text-12 truncate truncate3'> {badge.description}</p>


        </div>
      </div>
    </Link>
  
  </>}

    
    </>
  )
}

export default CourseBadge