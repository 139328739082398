import React from 'react'
import Skeleton from "@mui/material/Skeleton";

const CheatSheetsSkeleton = () => {
  return (
    <div className="d-flex container flex-wrap justify-content-between">
    <div className="col-lg-6 col-md-6 col-sm-12 col-12  d-flex sm-p-0 p-3">
    <div className="col-12  mt-5 rounded border ">
    <div className="d-flex">
         <div className="col-lg-3 col-3">
         <Skeleton
            sx={{ height: 160 }}
            animation="wave"
            variant="rectangular"
          />
         </div>

         <div className="p-2 col-lg-9 col-9">
         <Skeleton variant="text" className="col-lg-12 mt-1" height={40} />
          
          <div className=" mt-1">
            <Skeleton variant="caption" className="col-7 mt-2" height={15} />
            <Skeleton variant="caption" className="col-7 mt-2" height={15} />
          </div>
          <Skeleton variant="caption" className=" mt-3" height={5} />
          <div className="d-flex justify-content-between">
          <Skeleton variant="caption" className="col-3 mt-2" height={20} />
          <Skeleton variant="caption" className="col-3 mt-2" height={20} />


          </div>

         </div>
        </div>
      </div>
    </div>
    <div className="col-lg-6 col-md-6 col-sm-12 col-12  d-flex sm-p-0 p-3">
    <div className="col-12  mt-5 rounded border ">
    <div className="d-flex">
         <div className="col-lg-3 col-3">
         <Skeleton
            sx={{ height: 160 }}
            animation="wave"
            variant="rectangular"
          />
         </div>

         <div className="p-2 col-lg-9 col-9">
         <Skeleton variant="text" className="col-lg-12 mt-1" height={40} />
          
          <div className=" mt-1">
            <Skeleton variant="caption" className="col-7 mt-2" height={15} />
            <Skeleton variant="caption" className="col-7 mt-2" height={15} />
          </div>
          <Skeleton variant="caption" className=" mt-3" height={5} />
          <div className="d-flex justify-content-between">
          <Skeleton variant="caption" className="col-3 mt-2" height={20} />
          <Skeleton variant="caption" className="col-3 mt-2" height={20} />


          </div>

         </div>
        </div>
      </div>
    </div>


    <div className="col-lg-6 col-md-6 col-sm-12 col-12  d-flex sm-p-0 p-3">
    <div className="col-12  mt-5 rounded border ">
    <div className="d-flex">
         <div className="col-lg-3 col-3">
         <Skeleton
            sx={{ height: 160 }}
            animation="wave"
            variant="rectangular"
          />
         </div>

         <div className="p-2 col-lg-9 col-9">
         <Skeleton variant="text" className="col-lg-12 mt-1" height={40} />
          
          <div className=" mt-1">
            <Skeleton variant="caption" className="col-7 mt-2" height={15} />
            <Skeleton variant="caption" className="col-7 mt-2" height={15} />
          </div>
          <Skeleton variant="caption" className=" mt-3" height={5} />
          <div className="d-flex justify-content-between">
          <Skeleton variant="caption" className="col-3 mt-2" height={20} />
          <Skeleton variant="caption" className="col-3 mt-2" height={20} />


          </div>

         </div>
        </div>
      </div>
    </div>
    <div className="col-lg-6 col-md-6 col-sm-12 col-12  d-flex sm-p-0 p-3">
    <div className="col-12  mt-5 rounded border ">
    <div className="d-flex">
         <div className="col-lg-3 col-3">
         <Skeleton
            sx={{ height: 160 }}
            animation="wave"
            variant="rectangular"
          />
         </div>

         <div className="p-2 col-lg-9 col-9">
         <Skeleton variant="text" className="col-lg-12 mt-1" height={40} />
          
          <div className=" mt-1">
            <Skeleton variant="caption" className="col-7 mt-2" height={15} />
            <Skeleton variant="caption" className="col-7 mt-2" height={15} />
          </div>
          <Skeleton variant="caption" className=" mt-3" height={5} />
          <div className="d-flex justify-content-between">
          <Skeleton variant="caption" className="col-3 mt-2" height={20} />
          <Skeleton variant="caption" className="col-3 mt-2" height={20} />


          </div>

         </div>
        </div>
      </div>
    </div>
   
   
    
      
     </div>
  )
}

export default CheatSheetsSkeleton