import React from "react";
import Skeleton from "@mui/material/Skeleton";

const ShortsSkeleton = () => {
  return (
    <>
     


     <Skeleton
            sx={{ height: '95vh',width:'100%' }}
            animation="wave"
            className="w-100 reel-item mb-2"
            variant="rectangular"
          />


<Skeleton
            sx={{ height: '95vh',width:'100%' }}
            animation="wave"
            className="w-100 reel-item mb-2"
            variant="rectangular"
          />


<Skeleton
            sx={{ height: '95vh',width:'100%' }}
            animation="wave"
            className="w-100 reel-item mb-2"
            variant="rectangular"
          />


<Skeleton
            sx={{ height: '95vh',width:'100%' }}
            animation="wave"
            className="w-100 reel-item mb-2"
            variant="rectangular"
          />
     <div className="">
    {/* <div className="w-100">
    <div className="col-12  mt-5 rounded border ">
        <>
          <Skeleton
            sx={{ height: '95vh',width:'100%' }}
            animation="wave"
            className="w-100 reel-item mb-2"
            variant="rectangular"
          />

          <div className="p-2">
            <div className="d-flex align-items-center">
              <Skeleton
                animation="wave"
                className="col-3"
                variant="circular"
                width={50}
                height={50}
              />
              <div className="col-9 ">
                <Skeleton
                  variant="text"
                  className=" col-12  ms-2"
                  height={30}
                />
                <Skeleton variant="text" className=" col-12 ms-2" height={30} />
                <Skeleton
                  variant="caption"
                  className="col-12 ms-2 mt-2"
                  height={10}
                />
              </div>
            </div>
          </div>
        </>
      </div>
    </div>
    <div className="col-lg-3 col-md-3 col-sm-6 col-12 p-2">
    <div className="col-12  mt-5 rounded border ">
        <>
          <Skeleton
            sx={{ height: 160 }}
            animation="wave"
            variant="rectangular"
          />

          <div className="p-2">
            <div className="d-flex align-items-center">
              <Skeleton
                animation="wave"
                className="col-3"
                variant="circular"
                width={50}
                height={50}
              />
              <div className="col-9 ">
                <Skeleton
                  variant="text"
                  className=" col-12  ms-2"
                  height={30}
                />
                <Skeleton variant="text" className=" col-12 ms-2" height={30} />
                <Skeleton
                  variant="caption"
                  className="col-12 ms-2 mt-2"
                  height={10}
                />
              </div>
            </div>
          </div>
        </>
      </div>
    </div>


    <div className="col-lg-3 col-md-3 col-sm-6 col-12 p-2">
    <div className="col-12  mt-5 rounded border ">
        <>
          <Skeleton
            sx={{ height: 160 }}
            animation="wave"
            variant="rectangular"
          />

          <div className="p-2">
            <div className="d-flex align-items-center">
              <Skeleton
                animation="wave"
                className="col-3"
                variant="circular"
                width={50}
                height={50}
              />
              <div className="col-9 ">
                <Skeleton
                  variant="text"
                  className=" col-12  ms-2"
                  height={30}
                />
                <Skeleton variant="text" className=" col-12 ms-2" height={30} />
                <Skeleton
                  variant="caption"
                  className="col-12 ms-2 mt-2"
                  height={10}
                />
              </div>
            </div>
          </div>
        </>
      </div>
    </div>


    <div className="col-lg-3 col-md-3 col-sm-6 col-12 p-2">
    <div className="col-12  mt-5 rounded border ">
        <>
          <Skeleton
            sx={{ height: 160 }}
            animation="wave"
            variant="rectangular"
          />

          <div className="p-2">
            <div className="d-flex align-items-center">
              <Skeleton
                animation="wave"
                className="col-3"
                variant="circular"
                width={50}
                height={50}
              />
              <div className="col-9 ">
                <Skeleton
                  variant="text"
                  className=" col-12  ms-2"
                  height={30}
                />
                <Skeleton variant="text" className=" col-12 ms-2" height={30} />
                <Skeleton
                  variant="caption"
                  className="col-12 ms-2 mt-2"
                  height={10}
                />
              </div>
            </div>
          </div>
        </>
      </div>
    </div>



    <div className="col-lg-3 col-md-3 col-sm-6 col-12 p-2">
    <div className="col-12  mt-5 rounded border ">
        <>
          <Skeleton
            sx={{ height: 160 }}
            animation="wave"
            variant="rectangular"
          />

          <div className="p-2">
            <div className="d-flex align-items-center">
              <Skeleton
                animation="wave"
                className="col-3"
                variant="circular"
                width={50}
                height={50}
              />
              <div className="col-9 ">
                <Skeleton
                  variant="text"
                  className=" col-12  ms-2"
                  height={30}
                />
                <Skeleton variant="text" className=" col-12 ms-2" height={30} />
                <Skeleton
                  variant="caption"
                  className="col-12 ms-2 mt-2"
                  height={10}
                />
              </div>
            </div>
          </div>
        </>
      </div>
    </div>


    <div className="col-lg-3 col-md-3 col-sm-6 col-12 p-2">
    <div className="col-12  mt-5 rounded border ">
        <>
          <Skeleton
            sx={{ height: 160 }}
            animation="wave"
            variant="rectangular"
          />

          <div className="p-2">
            <div className="d-flex align-items-center">
              <Skeleton
                animation="wave"
                className="col-3"
                variant="circular"
                width={50}
                height={50}
              />
              <div className="col-9 ">
                <Skeleton
                  variant="text"
                  className=" col-12  ms-2"
                  height={30}
                />
                <Skeleton variant="text" className=" col-12 ms-2" height={30} />
                <Skeleton
                  variant="caption"
                  className="col-12 ms-2 mt-2"
                  height={10}
                />
              </div>
            </div>
          </div>
        </>
      </div>
    </div>


    <div className="col-lg-3 col-md-3 col-sm-6 col-12 p-2">
    <div className="col-12  mt-5 rounded border ">
        <>
          <Skeleton
            sx={{ height: 160 }}
            animation="wave"
            variant="rectangular"
          />

          <div className="p-2">
            <div className="d-flex align-items-center">
              <Skeleton
                animation="wave"
                className="col-3"
                variant="circular"
                width={50}
                height={50}
              />
              <div className="col-9 ">
                <Skeleton
                  variant="text"
                  className=" col-12  ms-2"
                  height={30}
                />
                <Skeleton variant="text" className=" col-12 ms-2" height={30} />
                <Skeleton
                  variant="caption"
                  className="col-12 ms-2 mt-2"
                  height={10}
                />
              </div>
            </div>
          </div>
        </>
      </div>
    </div>


    <div className="col-lg-3 col-md-3 col-sm-6 col-12 p-2">
    <div className="col-12  mt-5 rounded border ">
        <>
          <Skeleton
            sx={{ height: 160 }}
            animation="wave"
            variant="rectangular"
          />

          <div className="p-2">
            <div className="d-flex align-items-center">
              <Skeleton
                animation="wave"
                className="col-3"
                variant="circular"
                width={50}
                height={50}
              />
              <div className="col-9 ">
                <Skeleton
                  variant="text"
                  className=" col-12  ms-2"
                  height={30}
                />
                <Skeleton variant="text" className=" col-12 ms-2" height={30} />
                <Skeleton
                  variant="caption"
                  className="col-12 ms-2 mt-2"
                  height={10}
                />
              </div>
            </div>
          </div>
        </>
      </div>
    </div>
     */}
      
     </div>
    </>
  );
};

export default ShortsSkeleton;
