import { Skeleton } from '@mui/material'
import React from 'react'

const InternshipDetailsTemplate = () => {
    return (
        <div className="container-fluid p-2">


<Skeleton variant="rectangle" height={50} className="mt-2 mb-2 col-lg-8 col-md-8 col-sm-12 col-12"/>

<Skeleton variant="rectangle" height={20} className="mt-2 mb-2 col-lg-4 col-md-4 col-sm-6 col-12"/>


<Skeleton variant="rectangle" height={20} className="mt-2 mb-2 col-lg-4 col-md-4 col-sm-6 col-12"/>

<Skeleton variant="rectangle" height={20} className="mt-2 mb-2 col-lg-4 col-md-4 col-sm-6 col-12"/>

<Skeleton variant="rectangle" height={20} className="mt-2 mb-2 col-lg-4 col-md-4 col-sm-6 col-12"/>


<Skeleton variant="rectangle" height={50} className="mt-2 mb-2"/>


<Skeleton variant="rectangle" height={50} className="mt-2 mb-2"/>


<Skeleton variant="rectangle" height={50} className="mt-2 mb-2"/>



<Skeleton variant="rectangle" height={500} className="mt-2 mb-2"/>














        </div>
    )
}

export default InternshipDetailsTemplate
