import React, { useState } from 'react'
import Accordion from 'react-bootstrap/Accordion'
import AddIcon from '@mui/icons-material/Add';
import videoicon from '../../assets/images/course-video-icon.svg'
import SaveUserEvent from '../../actions/SaveUserEvent';
import { useContext } from 'react';
import { AuthContext } from '../../context/AuthContext';
const SyllabusAccordion = ({chapters,mode}) => {
  
const [expand,setexpand] = useState(false)


const [opened,setopened] = useState(false)

const {user} = useContext(AuthContext)
const OpenAccordion = ()=>{
setopened(true)
if(opened){
SaveUserEvent(
    {payload:{session:user.SessionId,
      page:window.location.pathname,
      action:"SyllabusAccordion-opened"
    }});
    
  }


}
  return (
    <div className="w-100 syllabus mb-5">
     <Accordion 
     
     
     onclick={()=>OpenAccordion()}
     
     >
 {chapters.map((chapter,index)=>(
     <>
     {index<=4?
     <>
     <Accordion.Item  eventKey={index}>
    <Accordion.Header><h5>{chapter.chapter_title}</h5></Accordion.Header>
    <Accordion.Body>
     <div className="text-left text-align-left">
     {chapter.topics?
   <>
   <ul>
   {chapter.topics.map((topics)=>(
     <>

     {topics.demo==="1"?
    <>
    
    <div className="d-flex text-primary mt-2 mb-2">
  

  <img className="topic-item-icon" src={videoicon}/>

{topics.topic_title}</div>
    </>:
    <>
    <div className="d-flex mt-2 mb-2">
  

  <img className="topic-item-icon" src={videoicon}/>
<p className="mb-0">{topics.topic_title}</p>
</div>
    
    </>}

     </>
   ))}
    
   </ul>
   
   </>:null}
     </div>

    </Accordion.Body>
  </Accordion.Item>
     
     </>:
     
     <>
{mode==="free"?
<>

</>:
<>
{expand?
<>

<Accordion.Item eventKey={index}>
    <Accordion.Header><h5>{chapter.chapter_title}</h5></Accordion.Header>
    <Accordion.Body>
     <div  className="text-left text-align-left">
     {chapter.topics?
   <>
   <ul>
   {chapter.topics.map((topics)=>(
     <>
 {topics.demo==="1"?
    <>
    
    <div className="d-flex text-primary mt-2 mb-2">
  

  <img className="topic-item-icon" src={videoicon}/>

{topics.topic_title}</div>
    </>:
    <>
    <div className="d-flex mt-2 mb-2">
  

  <img className="topic-item-icon" src={videoicon}/>

{topics.topic_title}</div>
    
    </>}
     </>
   ))}

   </ul>

   </>:

   <>
   
  
   </>
   
   
   }
     </div>

    </Accordion.Body>
  </Accordion.Item>
</>:null}

</>}

     </>
     
     
     }
      




     </>
 ))}

</Accordion>
{chapters.length>5?
<>
{expand?

<>

</>:
<>

<div onClick={()=>{setexpand(true)}} className="border hover-active rounded rounded-2 mt-2  w-100 p-3" type="button">
<h6 className="mb-0 pb-0"><AddIcon/>{chapters.length-5} More Chapters</h6>
   </div>
   
   </>}
</>:null}


    </div>
  )
}

export default SyllabusAccordion