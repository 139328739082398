
import Box from '@mui/material/Box';
import ArrowDown from '@mui/icons-material/ExpandMore';
import Modal from '@mui/material/Modal';
import React, { useContext } from 'react'
import ProgressBar from 'react-bootstrap/ProgressBar'
import LockOpenIcon from '@mui/icons-material/LockOpen';
import ThumbDownIcon from '@mui/icons-material/ThumbDownOffAlt';
import ThumbUpIcon from '@mui/icons-material/ThumbUpOffAlt';
import { useEffect,useState } from 'react'
import { useRef } from 'react'
import Rating from '@mui/material/Rating';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useParams } from 'react-router-dom'
import ENDPOINT from '../../data/ENDPOINT'
import { Dropdown, Offcanvas, Toast } from 'react-bootstrap'
import ShareIcon from '@mui/icons-material/Share';
import { Link } from 'react-router-dom'
import dateFormat, { masks } from "dateformat";
import ModalStyle from '../../data/ModalStyle';
import LockIcon from '@mui/icons-material/Lock';
import axios from 'axios'

import { AuthContext } from '../../context/AuthContext'
import { ChevronRight, Close } from '@material-ui/icons';
import { Chip, Typography } from '@mui/material';
import { Download, RateReview, Share } from '@mui/icons-material';
import SocialShare from '../../components/SocialShare';
import DetectDevice from '../../data/DetectDevice';
import SubmitClassFeedback from '../../actions/course/SubmitClassFeedback';
import CommunityChat from '../chat/CommunityChat';
import CourseBatchChat from '../account/CourseBatchChat';
import SetPage from '../../data/SetPage';
import AnimatePage from '../../actions/animations/AnimatePage';

const WatchLessons = ({bottommenu, userdata,props}) => {
const params = useParams();


const chapterid = params.chapter_id;
const topicid = params.topic_id;
  const course_url = params.course_url;
  const [sharemodal,setsharemodal] = useState(false)
const {user} = useContext(AuthContext)
const [chaptertopics,setchaptertopics] = useState(0);
const [currentduration,setcurrentduration] = useState(null);
const [currenttopic, setcurrenttopic]= useState(null);
const [currenttopicid, setcurrenttopicid]= useState(null);
const [suggestions,setsuggestions] = useState(null)
    const [videos, setvideos] = useState(null);
    const [tags,settags] = useState(null)
    const chapter = useRef();
    const [currentvideo,setcurrentvideo] = useState(null)
const [chapters,setchapters]= useState(null)
const [progress,setprogress] = useState(null)
const [chapterwisewatched,setchapterwisewatched] = useState(0)
const [read,setread] = useState(false)
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [currenttopic])
    
      useEffect(() => {
        getvideos()
      }, [topicid,chapterid,course_url])
const [coursedata,setcoursedata] = useState(null)
      const  getvideos = async ()=>{
        fetch(`${ENDPOINT}get-course-videos.php?session=${user.SessionId}&course_url=${course_url}&chapter_id=${chapterid}&topic_id=${topicid}`)
        .then(r=>r.json())
        .then(result=>{
         //  //console.log("current "+JSON.stringify(result))
        if(result.status==="success"){
setcoursedata(result.data.course)
          setvideos(result.data.videos);
        setsuggestions(result.data.videos)
        setprogress(result.data.progress)
        settags(result.data.tags)
        setchapters(result.data.chapters)
      AnimatePage()
        if(result.data.current_topic){
            setcurrentvideo(result.data.current_topic)
        }else{
            window.location.replace("/lessons/"+course_url+"/"+chapterid+"/"+result.data.videos[0].id)
            setcurrentvideo(result.data.videos[0])
        }
        setchaptertopics(result.data.chapterwise_topics)

        }
   

        if(result){
        if(videos){ 
         
        let videoplayer = document.getElementById("videoplayer");
        videoplayer.play();
        }
           }   
        })
        }; 
useEffect(()=>{


getvideos(); 
},[])//get all topics when chapter changes
useEffect(()=>{
const videoplayer = document.getElementById("videoplayer");
videoplayer.play();
},[videos])//automatically play video when topic changes














const changetopic = (e)=>{
    e.preventDefault();
    
if(currenttopicid){
   if(document.getElementById(currenttopicid).classList.contains("video-active")){
    document.getElementById(currenttopicid).classList.remove("video-active")
   }

}
   // //  //  //console.log(videos[e.currentTarget.id].data_url)
setcurrenttopic((videos[e.currentTarget.id]))
setcurrenttopicid(e.currentTarget.id)
document.getElementById(e.currentTarget.id).classList.add("video-active")
}
    const hovered=(e)=>{
const currentvideo = e.target.id;
const video = document.getElementById(currentvideo);
video.controls = true;

    }

if(currenttopic){
    if((currenttopic.data_url).ondurationchange){
       // //  //  //console.log("changed")
    }
}

    const exit=(e)=>{
const currentvideo = e.target.id;
        const video = document.getElementById(currentvideo);
        
      
        
       
                
            }

   
                 
            
const durationChange = (e)=>{

 // //  //  //console.log("video  duration change"+e)

   const video = document.getElementById("videoplayer");

   video.ontimeupdate = function() {
       setcurrentduration(Math.round(video.currentTime))
////  //  //console.log("rounded div"+Math.round(video.currentTime)%5)
       if(Math.round(video.currentTime)%10===0){
        updateDuration({duration:Math.round(video.currentTime)})
    

       }
       };
  
        

}



//update video watch duration
const updateDuration = async ({duration})=>{
  const formData = new FormData();
    formData.append('topic_id',topicid);
   // //  //  //console.log("updating to server..."+duration)
 if(document.getElementById("videoplayer")){
    formData.append('duration',document.getElementById("videoplayer").currentTime);
 }
    formData.append('session',user.SessionId)

    formData.append('course_url',course_url)
    formData.append('chapter_id',chapterid)


    const res = await axios.post(ENDPOINT+"update-learning-history.php", formData,{
        headers: {
            'content-type': 'multipart/form-data'
        }
    });
}




const [toastmessage,settoastmessage] = useState(null)




const [toast,settoast] = useState(false)


//add favourites
const addFavourite = async ({vid,favid})=>{
        

    const formData = new FormData();
    
    
    //formData.append('whyus',document.getElementById('whyus').value)
    
    formData.append('session',user.SessionId);
    
      formData.append('video_id',vid);
      formData.append('chapter_id',chapterid);
      formData.append('course_url',course_url);
    
    const res = await axios.post(ENDPOINT+"add-favourite.php", formData,{
        headers: {
            'content-type': 'multipart/form-data'
        }
    });
    if(res){
     
     if(res.data.status==="success"){
        const favitem = document.getElementById("fav"+vid);
        
        if(res.data.action==="liked"){

            favitem.classList.add("text-secondary")
         favitem.classList.add("text-danger")
            settoastmessage("Video added to favourites")
            settoast(true)
        }
        else if(res.data.action==="disliked"){
            settoastmessage("Video removed from favourites")
            favitem.classList.remove("text-danger")
            favitem.classList.add("text-secondary")
            settoast(true)
        }
     
     }
     else{
    
     }
    }
    }
    




//add watch later
//add watch later



const addWatchLater = async ({vid,markid})=>{
        

    const formData = new FormData();
    
    
    //formData.append('whyus',document.getElementById('whyus').value)
    
    formData.append('session',user.SessionId);
    
     
      formData.append('chapter_id',chapterid);
      formData.append('course_url',course_url);
    
    const res = await axios.post(ENDPOINT+"add-watch-later.php", formData,{
        headers: {
            'content-type': 'multipart/form-data'
        }
    });
    if(res){
     
     if(res.data.status==="success"){
        const markitem = document.getElementById("mark"+vid);
       
        if(res.data.action==="added"){
            settoastmessage("Video added to watch later")
            markitem.classList.remove("text-secondary")
            markitem.classList.add("text-primary")
            settoast(true)
        }
        else if(res.data.action==="removed"){
            settoastmessage("Video removed from watch later")
            markitem.classList.remove("text-primary")
            markitem.classList.add("text-secondary")
            settoast(true)
        }
     
     }
     else{
    
     }
    }
    }

const device = DetectDevice()



const [submitting,setsubmitting] = useState(false)
const SubmitFeedback = async ()=>{


setsubmitting(true)
const res = await SubmitClassFeedback({
    payload:{
        session:user.SessionId,
        class_id:classid,
        review:review,
        rating:value
    }
})

if(res){
setsubmitting(false)
    if(res.status==="success"){

        settoastmessage("Feedback submitted successfully")
       setmodal(false)
       getvideos()
        settoast(true)
    }else{
        settoastmessage("Something went wrong ")
       
        settoast(true)
    }
}
}

const [path,setpath] = useState(null)
const [loading,setloading] = useState(true)
const [classid,setclassid] = useState(null)
const [modal,setmodal] = useState(false)
const [value, setValue] = useState(null);
const [review,setreview] = useState(null)
const [support,setsupport] = useState(false)

useState(()=>{
if(!sharemodal){
setpath(null)
}
},[sharemodal])
const [warning,setwarning] = useState(false)

useEffect(()=>{
SetPage("Lessons")
},[])
    return (
<div  userdata={userdata}>


<CourseBatchChat/>



<div className="toast-container">
<Toast className="bg-warning" show={toast} delay={6000} autohide onClose={()=>settoast(false)}>
          <Toast.Header className='bg-warning'>
            <img
              src="holder.js/20x20?text=%20"
              className="rounded me-2"
              alt=""
            />
            <strong className="me-auto">{toastmessage}</strong>
         
          </Toast.Header>
          </Toast>
</div>


<Modal
        open={modal}
        onClose={()=>{setmodal(false)}}
      >
        <Box sx={ModalStyle} className="col-lg-4 p-3 col-md-5 col-sm-6 col-11 br-5">
          <div className="modal-header">
            <h4>Submit Class Feedback</h4>
            <Close className="click" onClick={()=>setmodal(false)}/>
          </div>
          <hr/>
         <p className="mb-3"> {parseInt(value)===1?
          <>
        Poor
          </>:
          <>
{parseInt(value)===2?

<>
Below Average
</>:<>

{parseInt(value)===3?

<>
Average
</>:<>

{parseInt(value)===4?

<>
Good
</>:<>

{parseInt(value)===5?

<>
Excellent
</>:<>


</>
} 
</>
} 
</>
} 
</>
}          
          </>}</p>
          <form onSubmit={(e)=>{e.preventDefault();SubmitFeedback()}}>

          <Rating
           size="large"
  name="simple-controlled"
  value={value}
  onChange={(event, newValue) => {
    setValue(newValue);
  }}
/>

<input value={review} onChange={(e)=>setreview(e.target.value)} className="mt-3 w-100 form-control" placeholder="Write your feedback"></input>
        
        <button className="btn mt-4 btn-primary btn-sm w-100" disabled={submitting}>{submitting?"Submitting...":"Submit Class Feedback"}</button>
      

          </form>
      </Box>
      </Modal>

{device==="mobile"?
     <>
       <Offcanvas className="share-post-canvas" show={sharemodal} placement={"bottom"} onHide={()=>setsharemodal(false)} {...props}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Share</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className='p-3 rounded'>
        
        <>
<SocialShare/>

</>

        </Offcanvas.Body>
      </Offcanvas> 
     
     
     </>:
     
     <>
     <Modal
  open={sharemodal}
  onClose={()=>setsharemodal(false)}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
  
>
  <Box sx={ModalStyle} className='p-3 rounded'>
 <div className="d-flex  justify-content-between">
 <Typography id="modal-modal-title"  variant="h6" component="h2">
      Share
    </Typography>
    <Close className='click' onClick={()=>setsharemodal(false)}/>
 </div>
    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
    
<SocialShare/>



    </Typography>
  </Box>
</Modal>
     
     
     </>}




<Modal
  open={support}
  className="col-lg-10"
  onClose={()=>setsupport(false)}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
  <Box >
    
  </Box>
</Modal>


<Modal
  open={warning}
  className="col-lg-10"
  onClose={()=>setwarning(false)}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
  <Box className="bg-white br-5 p-3" style={ModalStyle}>
    <div className="modal-header">
        <h4>Chapter Locked !</h4>
        <Close className="click" onClick={()=>setwarning(false)}/>
    </div>
    <hr/>
    <div className="">
<p className="mb-0">Complete previous chapters to unlock this chapter</p>

    </div>
  </Box>
</Modal>


























<div class="video-page min-vh-100">
<div className="flex container-fluid pt-3 flex-wrap">
   {/*video item start*/}
   <div className="col-lg-8 col-md-8 col-12 video-container">
<video id="videoplayer" preload="auto" className="w-100"  src={currentvideo?currentvideo.data_url:""} controlsList="nodownload" onMouseOver={hovered} onMouseLeave={exit} onDurationChange={(e)=>{durationChange({})}} ></video>



{/*description section*/}
<div className="flex current-video  mt-2">
<div className="video-data w-100">


<div className="d-flex justify-content-between flex-wrap w-100">
<h3>{currentvideo?currentvideo.topic_title:"Untitled Topic"}</h3>



<div className="d-flex  sm-mt-10">
    <a href={currentvideo?currentvideo.data_url:null} download="file" target="_blank">
    <Chip className="click me-3" label={<><Download/>Download </>}/>
   

    </a>
    <Chip className="click me-3" onClick={()=>setsharemodal(true)} label={<><ShareIcon/>Share </>}/>
    <Chip className="click" onClick={()=>{setclassid(currentvideo.id);setmodal(true)}} label={<><RateReview/>Submit Feedback </>}/>
</div>
</div>

{/*description section*/}

<hr/>


{read?

<>
<div className="container-fluid mt-3">
<h4>Notes</h4>

<p>{read}</p>
</div>
</>
:<>
</>}



    </div>
</div>

</div>




{/*video container end*/}

<div className="col-lg-4 col-12 suggestions-column sm-p-0">

   
   {progress?
   <>
    <div className="container-fluid pt-3">
 
    <Dropdown className="w-100 chapters-col   bg-white mb-3">
  <Dropdown.Toggle className="btn chapter-dropdown " id="dropdown-basic">
  {chapters?chapters.map((chapter,index)=>(

<>

{chapter.chapter_id===chapterid?
<>
<div className="d-flex justify-content-between">
    
<div className='col-11'>
<h5 className="truncate truncate1 "><b>{chapter.chapter_id}: </b>
{chapter.chapter_title}</h5>
</div>
<ArrowDown/>
</div>
</>


:null}

</>


)):null}

  </Dropdown.Toggle>

  <Dropdown.Menu>

  {chapters?

<>
{chapters.map((chapter,index)=>(

<>


{chapter.locked===true?
<>
<Dropdown.Item className="my-0 py-2" onClick={()=>setwarning(true)} >
<div className="w-100 d-flex">
<LockIcon className="mr-2"/>


<div className="flex space-between">
<p className="mb-0"><b className="text-primary">  {index+1} : </b>  {chapter.chapter_title}</p>

</div>

</div>
  </Dropdown.Item>
  

</>:
<>
<Dropdown.Item className="my-0 py-2"  to={"/lessons/"+course_url+"/"+(index+1)+"/1"} >
  
  <Link to={"/lessons/"+course_url+"/"+(index+1)+"/1"} >
  
  <div className="w-100 d-flex">
    <LockOpenIcon className="mr-2"/>

 

<div className="flex space-between">
<p className="mb-0"><b className="text-primary">  {index+1} : </b>  {chapter.chapter_title}</p>

</div>

    </div>
  
  </Link>
  
 

  </Dropdown.Item>
  

</>}





</>


))}
</>:
null
}


  </Dropdown.Menu>
</Dropdown>

<div className="d-flex justify-content-between">
<h6>Progress : </h6>
<h6>{progress.completed_topics}/{progress.total_topics}</h6>
</div>

<ProgressBar  className="h-10"   now={progress.percentage} label={`${progress.percentage}%`} />
  


    </div>
   </>
   :null}
    <div >
   



<hr/>
      
     
        </div>

    <div className="video-suggestions animate">


{/*suggestion item*/}





{ suggestions?suggestions.map((topic, index)=>(
 
<>
{topic.watched===true?()=>setchapterwisewatched(chapterwisewatched+1):null}
<div to={"/lessons/"+course_url+"/"+topic.topics_chapter_id+"/"+topic.id} type="button" topic={topic.id} id={index} className={"flex suggestion-item p-1 delay"+(index+1)+"s"} >
 
 <div className="suggestion-cover-container position-relative mr-15">
     <div  type="button" >
     <Link  to={"/lessons/"+course_url+"/"+topic.topics_chapter_id+"/"+topic.id} >
     <img className="suggestion-cover " src={topic.cover_image} alt=""></img>
   
     </Link>  
      </div>
 
      <div className="duration-count">
  <p className="sm-12">{topic.topic_duration} </p>
</div>
 </div>
 <div class="suggestion-info">
     <div className="w-100 pr-2 topic-title">
    
    <Link  to={"/lessons/"+course_url+"/"+topic.topics_chapter_id+"/"+topic.id} >
    <h6 className=" topic-title truncate truncate2">{topic.topic_title}</h6>
 
    </Link>
 
       </div>

       <p className="extra-small truncate truncate2">{topic.description}</p>
   
      
 <div className="flex mt-2 d-none suggestion-actions">
{topic.watched===true?
 <input type="checkbox" className="text-success mr-15 lesson-action" checked ></input>
 : <input type="checkbox" className="mr-15 " checked={false}></input>
}
 <svg type="button" className="text-secondary lesson-action"  onClick={()=>addWatchLater({vid:topic.id})} id={"mark"+topic.id}  stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 16 16" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M8 12l5 3V3a2 2 0 00-2-2H5a2 2 0 00-2 2v12l5-3zm-4 1.234l4-2.4 4 2.4V3a1 1 0 00-1-1H5a1 1 0 00-1 1v10.234z" clip-rule="evenodd"></path></svg>
 <svg type="button" className="text-secondary lesson-action" onClick={()=>addFavourite({vid:topic.id})}   id={"fav"+topic.id}  stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M12,4.595c-1.104-1.006-2.512-1.558-3.996-1.558c-1.578,0-3.072,0.623-4.213,1.758c-2.353,2.363-2.352,6.059,0.002,8.412 l7.332,7.332c0.17,0.299,0.498,0.492,0.875,0.492c0.322,0,0.609-0.163,0.792-0.409l7.415-7.415 c2.354-2.354,2.354-6.049-0.002-8.416c-1.137-1.131-2.631-1.754-4.209-1.754C14.513,3.037,13.104,3.589,12,4.595z M18.791,6.205 c1.563,1.571,1.564,4.025,0.002,5.588L12,18.586l-6.793-6.793C3.645,10.23,3.646,7.776,5.205,6.209 c0.76-0.756,1.754-1.172,2.799-1.172s2.035,0.416,2.789,1.17l0.5,0.5c0.391,0.391,1.023,0.391,1.414,0l0.5-0.5 C14.719,4.698,17.281,4.702,18.791,6.205z"></path></svg>
 
 </div>
 <div className="d-flex mt-2">
    <Download onClick={()=>{}} className="me-3 click text-secondary"/>
    <Share onClick={()=>{setsharemodal(true)}} className="me-3 click text-secondary"/>
    
           <RateReview onClick={()=>{setclassid(topic.id);setmodal(true)}}  className=" text-secondary"  />
        
        
  
 
  </div>
 </div>

 
 
 </div>
 
</>





 )):
 <div>




<div  className="flex suggestion-item p-1" >
 
 <div className="suggestion-cover-container suggestion-cover animate shimmer">
     <div>
    
     </div>
 

 </div>
 <div class="suggestion-info">
     <div className=" topic-title">
     <h6 className=" topic-title h-30 shimmer animate col-10"> </h6>
     </div>


 <div className="flex suggestion-actions">
 <input type="checkbox" ></input>
 <svg className="shimmer animate" type="button" stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 16 16" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M8 12l5 3V3a2 2 0 00-2-2H5a2 2 0 00-2 2v12l5-3zm-4 1.234l4-2.4 4 2.4V3a1 1 0 00-1-1H5a1 1 0 00-1 1v10.234z" clip-rule="evenodd"></path></svg>
 <svg className="shimmer animate"  type="button" stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M12,4.595c-1.104-1.006-2.512-1.558-3.996-1.558c-1.578,0-3.072,0.623-4.213,1.758c-2.353,2.363-2.352,6.059,0.002,8.412 l7.332,7.332c0.17,0.299,0.498,0.492,0.875,0.492c0.322,0,0.609-0.163,0.792-0.409l7.415-7.415 c2.354-2.354,2.354-6.049-0.002-8.416c-1.137-1.131-2.631-1.754-4.209-1.754C14.513,3.037,13.104,3.589,12,4.595z M18.791,6.205 c1.563,1.571,1.564,4.025,0.002,5.588L12,18.586l-6.793-6.793C3.645,10.23,3.646,7.776,5.205,6.209 c0.76-0.756,1.754-1.172,2.799-1.172s2.035,0.416,2.789,1.17l0.5,0.5c0.391,0.391,1.023,0.391,1.414,0l0.5-0.5 C14.719,4.698,17.281,4.702,18.791,6.205z"></path></svg>
 
 </div>

 </div>

 
 
 </div>
 














 <div  className="flex suggestion-item p-1" >
 
 <div className="suggestion-cover-container suggestion-cover animate shimmer">
     <div>
    
     </div>
 

 </div>
 <div class="suggestion-info">
     <div className=" topic-title">
     <h6 className=" topic-title h-30 shimmer animate col-10"> </h6>
     </div>


 <div className="flex suggestion-actions">
 <input type="checkbox" ></input>
 <svg className="shimmer animate" type="button" stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 16 16" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M8 12l5 3V3a2 2 0 00-2-2H5a2 2 0 00-2 2v12l5-3zm-4 1.234l4-2.4 4 2.4V3a1 1 0 00-1-1H5a1 1 0 00-1 1v10.234z" clip-rule="evenodd"></path></svg>
 <svg className="shimmer animate"  type="button" stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M12,4.595c-1.104-1.006-2.512-1.558-3.996-1.558c-1.578,0-3.072,0.623-4.213,1.758c-2.353,2.363-2.352,6.059,0.002,8.412 l7.332,7.332c0.17,0.299,0.498,0.492,0.875,0.492c0.322,0,0.609-0.163,0.792-0.409l7.415-7.415 c2.354-2.354,2.354-6.049-0.002-8.416c-1.137-1.131-2.631-1.754-4.209-1.754C14.513,3.037,13.104,3.589,12,4.595z M18.791,6.205 c1.563,1.571,1.564,4.025,0.002,5.588L12,18.586l-6.793-6.793C3.645,10.23,3.646,7.776,5.205,6.209 c0.76-0.756,1.754-1.172,2.799-1.172s2.035,0.416,2.789,1.17l0.5,0.5c0.391,0.391,1.023,0.391,1.414,0l0.5-0.5 C14.719,4.698,17.281,4.702,18.791,6.205z"></path></svg>
 
 </div>

 </div>

 
 
 </div>
 





 
<div  className="flex suggestion-item p-1" >
 
 <div className="suggestion-cover-container suggestion-cover animate shimmer">
     <div>
    
     </div>
 

 </div>
 <div class="suggestion-info">
     <div className=" topic-title">
     <h6 className=" topic-title h-30 shimmer animate col-10"> </h6>
     </div>


 <div className="flex suggestion-actions">
 <input type="checkbox" ></input>
 <svg className="shimmer animate" type="button" stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 16 16" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M8 12l5 3V3a2 2 0 00-2-2H5a2 2 0 00-2 2v12l5-3zm-4 1.234l4-2.4 4 2.4V3a1 1 0 00-1-1H5a1 1 0 00-1 1v10.234z" clip-rule="evenodd"></path></svg>
 <svg className="shimmer animate"  type="button" stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M12,4.595c-1.104-1.006-2.512-1.558-3.996-1.558c-1.578,0-3.072,0.623-4.213,1.758c-2.353,2.363-2.352,6.059,0.002,8.412 l7.332,7.332c0.17,0.299,0.498,0.492,0.875,0.492c0.322,0,0.609-0.163,0.792-0.409l7.415-7.415 c2.354-2.354,2.354-6.049-0.002-8.416c-1.137-1.131-2.631-1.754-4.209-1.754C14.513,3.037,13.104,3.589,12,4.595z M18.791,6.205 c1.563,1.571,1.564,4.025,0.002,5.588L12,18.586l-6.793-6.793C3.645,10.23,3.646,7.776,5.205,6.209 c0.76-0.756,1.754-1.172,2.799-1.172s2.035,0.416,2.789,1.17l0.5,0.5c0.391,0.391,1.023,0.391,1.414,0l0.5-0.5 C14.719,4.698,17.281,4.702,18.791,6.205z"></path></svg>
 
 </div>

 </div>

 
 
 </div>
 








 
<div  className="flex suggestion-item p-1" >
 
 <div className="suggestion-cover-container suggestion-cover animate shimmer">
     <div>
    
     </div>
 

 </div>
 <div class="suggestion-info">
     <div className=" topic-title">
     <h6 className=" topic-title h-30 shimmer animate col-10"> </h6>
     </div>


 <div className="flex suggestion-actions">
 <input type="checkbox" ></input>
 <svg className="shimmer animate" type="button" stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 16 16" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M8 12l5 3V3a2 2 0 00-2-2H5a2 2 0 00-2 2v12l5-3zm-4 1.234l4-2.4 4 2.4V3a1 1 0 00-1-1H5a1 1 0 00-1 1v10.234z" clip-rule="evenodd"></path></svg>
 <svg className="shimmer animate"  type="button" stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M12,4.595c-1.104-1.006-2.512-1.558-3.996-1.558c-1.578,0-3.072,0.623-4.213,1.758c-2.353,2.363-2.352,6.059,0.002,8.412 l7.332,7.332c0.17,0.299,0.498,0.492,0.875,0.492c0.322,0,0.609-0.163,0.792-0.409l7.415-7.415 c2.354-2.354,2.354-6.049-0.002-8.416c-1.137-1.131-2.631-1.754-4.209-1.754C14.513,3.037,13.104,3.589,12,4.595z M18.791,6.205 c1.563,1.571,1.564,4.025,0.002,5.588L12,18.586l-6.793-6.793C3.645,10.23,3.646,7.776,5.205,6.209 c0.76-0.756,1.754-1.172,2.799-1.172s2.035,0.416,2.789,1.17l0.5,0.5c0.391,0.391,1.023,0.391,1.414,0l0.5-0.5 C14.719,4.698,17.281,4.702,18.791,6.205z"></path></svg>
 
 </div>

 </div>

 
 
 </div>
 















 
<div  className="flex suggestion-item p-1" >
 
 <div className="suggestion-cover-container suggestion-cover animate shimmer">
     <div>
    
     </div>
 

 </div>
 <div class="suggestion-info">
     <div className=" topic-title">
     <h6 className=" topic-title h-30 shimmer animate col-10"> </h6>
     </div>


 <div className="flex suggestion-actions">
 <input type="checkbox" ></input>
 <svg className="shimmer animate" type="button" stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 16 16" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M8 12l5 3V3a2 2 0 00-2-2H5a2 2 0 00-2 2v12l5-3zm-4 1.234l4-2.4 4 2.4V3a1 1 0 00-1-1H5a1 1 0 00-1 1v10.234z" clip-rule="evenodd"></path></svg>
 <svg className="shimmer animate"  type="button" stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M12,4.595c-1.104-1.006-2.512-1.558-3.996-1.558c-1.578,0-3.072,0.623-4.213,1.758c-2.353,2.363-2.352,6.059,0.002,8.412 l7.332,7.332c0.17,0.299,0.498,0.492,0.875,0.492c0.322,0,0.609-0.163,0.792-0.409l7.415-7.415 c2.354-2.354,2.354-6.049-0.002-8.416c-1.137-1.131-2.631-1.754-4.209-1.754C14.513,3.037,13.104,3.589,12,4.595z M18.791,6.205 c1.563,1.571,1.564,4.025,0.002,5.588L12,18.586l-6.793-6.793C3.645,10.23,3.646,7.776,5.205,6.209 c0.76-0.756,1.754-1.172,2.799-1.172s2.035,0.416,2.789,1.17l0.5,0.5c0.391,0.391,1.023,0.391,1.414,0l0.5-0.5 C14.719,4.698,17.281,4.702,18.791,6.205z"></path></svg>
 
 </div>

 </div>

 
 
 </div>
 













 
<div  className="flex suggestion-item p-1" >
 
 <div className="suggestion-cover-container suggestion-cover animate shimmer">
     <div>
    
     </div>
 

 </div>
 <div class="suggestion-info">
     <div className=" topic-title">
     <h6 className=" topic-title h-30 shimmer animate col-10"> </h6>
     </div>


 <div className="flex suggestion-actions">
 <input type="checkbox" ></input>
 <svg className="shimmer animate" type="button" stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 16 16" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M8 12l5 3V3a2 2 0 00-2-2H5a2 2 0 00-2 2v12l5-3zm-4 1.234l4-2.4 4 2.4V3a1 1 0 00-1-1H5a1 1 0 00-1 1v10.234z" clip-rule="evenodd"></path></svg>
 <svg className="shimmer animate"  type="button" stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M12,4.595c-1.104-1.006-2.512-1.558-3.996-1.558c-1.578,0-3.072,0.623-4.213,1.758c-2.353,2.363-2.352,6.059,0.002,8.412 l7.332,7.332c0.17,0.299,0.498,0.492,0.875,0.492c0.322,0,0.609-0.163,0.792-0.409l7.415-7.415 c2.354-2.354,2.354-6.049-0.002-8.416c-1.137-1.131-2.631-1.754-4.209-1.754C14.513,3.037,13.104,3.589,12,4.595z M18.791,6.205 c1.563,1.571,1.564,4.025,0.002,5.588L12,18.586l-6.793-6.793C3.645,10.23,3.646,7.776,5.205,6.209 c0.76-0.756,1.754-1.172,2.799-1.172s2.035,0.416,2.789,1.17l0.5,0.5c0.391,0.391,1.023,0.391,1.414,0l0.5-0.5 C14.719,4.698,17.281,4.702,18.791,6.205z"></path></svg>
 
 </div>

 </div>

 
 
 </div>
 














 
<div  className="flex suggestion-item p-1" >
 
 <div className="suggestion-cover-container suggestion-cover animate shimmer">
     <div>
    
     </div>
 

 </div>
 <div class="suggestion-info">
     <div className=" topic-title">
     <h6 className=" topic-title h-30 shimmer animate col-10"> </h6>
     </div>


 <div className="flex suggestion-actions">
 <input type="checkbox" ></input>
 <svg className="shimmer animate" type="button" stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 16 16" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M8 12l5 3V3a2 2 0 00-2-2H5a2 2 0 00-2 2v12l5-3zm-4 1.234l4-2.4 4 2.4V3a1 1 0 00-1-1H5a1 1 0 00-1 1v10.234z" clip-rule="evenodd"></path></svg>
 <svg className="shimmer animate"  type="button" stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M12,4.595c-1.104-1.006-2.512-1.558-3.996-1.558c-1.578,0-3.072,0.623-4.213,1.758c-2.353,2.363-2.352,6.059,0.002,8.412 l7.332,7.332c0.17,0.299,0.498,0.492,0.875,0.492c0.322,0,0.609-0.163,0.792-0.409l7.415-7.415 c2.354-2.354,2.354-6.049-0.002-8.416c-1.137-1.131-2.631-1.754-4.209-1.754C14.513,3.037,13.104,3.589,12,4.595z M18.791,6.205 c1.563,1.571,1.564,4.025,0.002,5.588L12,18.586l-6.793-6.793C3.645,10.23,3.646,7.776,5.205,6.209 c0.76-0.756,1.754-1.172,2.799-1.172s2.035,0.416,2.789,1.17l0.5,0.5c0.391,0.391,1.023,0.391,1.414,0l0.5-0.5 C14.719,4.698,17.281,4.702,18.791,6.205z"></path></svg>
 
 </div>

 </div>

 
 
 </div>
 

















 
<div  className="flex suggestion-item p-1" >
 
 <div className="suggestion-cover-container suggestion-cover animate shimmer">
     <div>
    
     </div>
 

 </div>
 <div class="suggestion-info">
     <div className=" topic-title">
     <h6 className=" topic-title h-30 shimmer animate col-10"> </h6>
     </div>


 <div className="flex suggestion-actions">
 <input type="checkbox" ></input>
 <svg className="shimmer animate" type="button" stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 16 16" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M8 12l5 3V3a2 2 0 00-2-2H5a2 2 0 00-2 2v12l5-3zm-4 1.234l4-2.4 4 2.4V3a1 1 0 00-1-1H5a1 1 0 00-1 1v10.234z" clip-rule="evenodd"></path></svg>
 <svg className="shimmer animate"  type="button" stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M12,4.595c-1.104-1.006-2.512-1.558-3.996-1.558c-1.578,0-3.072,0.623-4.213,1.758c-2.353,2.363-2.352,6.059,0.002,8.412 l7.332,7.332c0.17,0.299,0.498,0.492,0.875,0.492c0.322,0,0.609-0.163,0.792-0.409l7.415-7.415 c2.354-2.354,2.354-6.049-0.002-8.416c-1.137-1.131-2.631-1.754-4.209-1.754C14.513,3.037,13.104,3.589,12,4.595z M18.791,6.205 c1.563,1.571,1.564,4.025,0.002,5.588L12,18.586l-6.793-6.793C3.645,10.23,3.646,7.776,5.205,6.209 c0.76-0.756,1.754-1.172,2.799-1.172s2.035,0.416,2.789,1.17l0.5,0.5c0.391,0.391,1.023,0.391,1.414,0l0.5-0.5 C14.719,4.698,17.281,4.702,18.791,6.205z"></path></svg>
 
 </div>

 </div>

 
 
 </div>
 















 
<div  className="flex suggestion-item p-1" >
 
 <div className="suggestion-cover-container suggestion-cover animate shimmer">
     <div>
    
     </div>
 

 </div>
 <div class="suggestion-info">
     <div className=" topic-title">
     <h6 className=" topic-title h-30 shimmer animate col-10"> </h6>
     </div>


 <div className="flex suggestion-actions">
 <input type="checkbox" ></input>
 <svg className="shimmer animate" type="button" stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 16 16" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M8 12l5 3V3a2 2 0 00-2-2H5a2 2 0 00-2 2v12l5-3zm-4 1.234l4-2.4 4 2.4V3a1 1 0 00-1-1H5a1 1 0 00-1 1v10.234z" clip-rule="evenodd"></path></svg>
 <svg className="shimmer animate"  type="button" stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M12,4.595c-1.104-1.006-2.512-1.558-3.996-1.558c-1.578,0-3.072,0.623-4.213,1.758c-2.353,2.363-2.352,6.059,0.002,8.412 l7.332,7.332c0.17,0.299,0.498,0.492,0.875,0.492c0.322,0,0.609-0.163,0.792-0.409l7.415-7.415 c2.354-2.354,2.354-6.049-0.002-8.416c-1.137-1.131-2.631-1.754-4.209-1.754C14.513,3.037,13.104,3.589,12,4.595z M18.791,6.205 c1.563,1.571,1.564,4.025,0.002,5.588L12,18.586l-6.793-6.793C3.645,10.23,3.646,7.776,5.205,6.209 c0.76-0.756,1.754-1.172,2.799-1.172s2.035,0.416,2.789,1.17l0.5,0.5c0.391,0.391,1.023,0.391,1.414,0l0.5-0.5 C14.719,4.698,17.281,4.702,18.791,6.205z"></path></svg>
 
 </div>

 </div>

 
 
 </div>
 




























 




 


    </div>
 }
 
 











    </div>








</div>

</div>

{/*video row end*/}

                    </div>


</div>





    )
}

export default WatchLessons
