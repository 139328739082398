import React, { useState } from "react";
import GetVerifyCertificate from "../../actions/certificate/GetVerifyCertificate";
import SearchIcon from "@mui/icons-material/Search";
import {
  TextField,
  Button,
  Container,
  Typography,
  Box,
  Paper,
  CircularProgress,
  Alert,
  Grid,
} from '@mui/material';

const VerifyCertificate = () => {
  const [input, setInput] = useState("");
  const [certificate, setCertificate] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const getCertificate = async (e) => {
    e.preventDefault();
    setError("");
    setSuccess("");
    setLoading(true);

    if (!input) {
      setError("Input cannot be empty");
      setLoading(false);
      return;
    }

    try {
      const res = await GetVerifyCertificate({
        payload: {
          certificate_id: input,
        },
      });

      if (res.status === "success") {
        setCertificate(res.data);
        if(res.data.certificate_url){
          setSuccess("Certificate found!");
        }
        else{
          setError("Certificate Not Found Enter Valid Id ")
        }
      } else {
        setError("No certificate found");
      }
    } catch (error) {
      setError("Error fetching certificate");
    }
    setLoading(false);
  };

  return (
    <Container className="vh-100" maxWidth="md" style={{ marginTop: '5rem' }}>
      <Paper elevation={3} style={{ padding: '2rem', textAlign: 'center' }}>
        <Typography variant="h4" gutterBottom>
          Get Your Certificate
        </Typography>
        <Box component="form" onSubmit={getCertificate} noValidate sx={{ mt: 1 }}>
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={10} sm={8} md={6}>
              <TextField
                value={input}
                onChange={(e) => setInput(e.target.value)}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="certificate-id"
                label="Search Certificate ID"
                name="certificate-id"
                autoComplete="certificate-id"
                autoFocus
              />
            </Grid>
            <Grid item>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                startIcon={<SearchIcon />}
                disabled={loading}
                sx={{ mt: 3, mb: 2 }}
              >
                {loading ? <CircularProgress size={24} /> : "Search"}
              </Button>
            </Grid>
          </Grid>
        </Box>
        {error && <Alert severity="error" style={{ marginTop: '1rem' }}>{error}</Alert>}
        {success && <Alert className="col-4 mx-auto" severity="success" style={{ marginTop: '1rem' }}>{success}</Alert>}
        {certificate && certificate.certificate_url? (
          <Box mt={4}>
            <img src={certificate.certificate_url} alt="Verified Certificate" style={{ width: '80%', borderRadius: '8px' }} />
          </Box>
        ):null}
      </Paper>
    </Container>
  );
};

export default VerifyCertificate;
