// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAPPUUMk2WVHj6iB46jZVh_Fw302yPzXYs",
  authDomain: "skillsuprise-150199.firebaseapp.com",
  databaseURL: "https://skillsuprise-150199-default-rtdb.firebaseio.com",
  projectId: "skillsuprise-150199",
  storageBucket: "skillsuprise-150199.appspot.com",
  messagingSenderId: "74362314462",
  appId: "1:74362314462:web:dfb81a845a95a115f7ac4f",
  measurementId: "G-6C3XLJ42TR",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);

const firebaseApp = initializeApp(firebaseConfig);

export default firebaseConfig;
