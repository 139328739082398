
import { useContext } from 'react'
import { useState } from 'react'


const LoginStatus = () => {

    const user =(localStorage.getItem("user")===null?null:JSON.parse(localStorage.getItem("user")))
    var loggedin = false
    
    if(user!==null){
        if(user.SessionId==="null"||user.SessionId===undefined||user.SessionId===null){
loggedin = false
        }else{
            loggedin = true
        }

    }
  return (loggedin)
}

export default LoginStatus