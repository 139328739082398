import React from 'react'
import Rating from '@mui/material/Rating';
import { Link } from 'react-router-dom';
import Offcanvas from 'react-bootstrap/Offcanvas'
import DetectDevice from '../../data/DetectDevice';
import { useState } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import 'timeago.js'
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {
     EmailIcon,
     EmailShareButton,
   
     LinkedinIcon,
     LinkedinShareButton,
   
    
     TelegramIcon,
   
    
     TelegramShareButton,
     
     TwitterIcon,
     
     TwitterShareButton,
   
     WhatsappIcon,
   
     WhatsappShareButton,
   
   } from "react-share";
import ModalStyle from '../../data/ModalStyle'
import Toast from 'react-bootstrap/Toast';
import { Close, MoreVert, Share } from '@material-ui/icons';
import CopyToClipboard from 'react-copy-to-clipboard';
import RedirectNext from '../../actions/RedirectNext';

import dateFormat from 'dateformat';
import StudentAccessModal from '../../components/modals/StudentAccessModal';
const SlidingVideoItem = ({course,props,account,access}) => {

  const device = DetectDevice()

  const [options,setoptions] = useState(false)
  const [toast,settoast] = useState(false)
  const [share,setshare] = useState(false)
  const [alert,setalert] = useState(false)
  
const CloseStudentAccessModal = ()=>{

  setalert(false)
  }


  const [play,setplay] = useState(false)
  return (
<>



<Offcanvas show={play} placement="bottom" className="min-vh-100 p-0 sm-p-0" onHide={()=>setplay(false)} {...props}>
      <div className="">  
      <Offcanvas.Header closeButton>
          <Offcanvas.Title>Student Review</Offcanvas.Title>
        </Offcanvas.Header>
      </div>
        <Offcanvas.Body className="pt-0 pb-0 min-vh-90 sm-p-0">
  <video className='bg-dark w-100 h-100' poster={course.poster} src={course.url} autoplay={true} muted></video>
           </Offcanvas.Body>
      </Offcanvas>
<Modal
        open={share}
        onClose={()=>setshare(false)}
     
      >
        <Box sx={ModalStyle} className="p-3 col-lg-3 col-md-3 col-sm-4 col-12">
          <div className="modal-header">
          <h4 className="mb-0">Share this video</h4>
          <Close onClick={()=>setshare(false)} className="click"/>
          </div>
    <hr></hr>
<div className="flex  mb-2">
<WhatsappShareButton size={30}   round={true} url={"https://skillsuprise.com/watch-short/"+course.video_id}><WhatsappIcon size={30}  type="button"  round={true} url={"https://skillsuprise.com/watch-short/"+course.video_id} className="mr-2"/></WhatsappShareButton>
<LinkedinShareButton size={30}   round={true} url={"https://skillsuprise.com/watch-short/"+course.video_id}><LinkedinIcon size={30}  type="button"  round={true} url={"https://skillsuprise.com/watch-short/"+course.video_id} className="mr-2"></LinkedinIcon></LinkedinShareButton>
<TelegramShareButton size={30}   round={true} url={"https://skillsuprise.com/watch-short/"+course.video_id}><TelegramIcon size={30}  type="button"  round={true} url={"https://skillsuprise.com/watch-short/"+course.video_id} className="mr-2"/></TelegramShareButton>
<TwitterShareButton size={30}   round={true} url={"https://skillsuprise.com/watch-short/"+course.video_id}><TwitterIcon size={30}  type="button"  round={true} url={"https://skillsuprise.com/watch-short/"+course.video_id} className="mr-2"/></TwitterShareButton>
<EmailShareButton size={30}   round={true} url={"https://skillsuprise.com/watch-short/"+course.video_id}><EmailIcon size={30}  type="button"  round={true} url={"https://skillsuprise.com/watch-short/"+course.video_id} className="mr-2"/></EmailShareButton>


</div>
        </Box>
      </Modal>
    <StudentAccessModal visibility={alert} HandleClose={CloseStudentAccessModal}/>

{device==="mobile"?
<>

<Offcanvas show={options} placement="bottom" onHide={()=>setoptions(false)} {...props}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Options</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="pt-0 pb-0">
          <hr className="mt-0 pt-0"></hr>
         <div className="container-fluid p-0">
         <span className="click" onClick={()=>{setoptions(false);setshare(true)}}><Share/> Share Video</span>
    
        </div>
       <div className="container-fluid mt-2 p-0">
       <CopyToClipboard className="w-100 click"  onCopy={()=>{settoast(true);setoptions(false)}} text={"https://skillsuprise.com/watch-short/"+course.video_id}>
          <span><ContentCopyIcon/> Copy Video Url</span>
          </CopyToClipboard>
        </div> 
           </Offcanvas.Body>
      </Offcanvas>

</>:
<>







<Modal
        open={options}
        onClose={()=>setshare(options)}
     
      >
        <Box sx={ModalStyle} className="p-3 col-lg-3 col-md-3 col-sm-4 col-10">
          <div className="modal-header">
          <h4 className="mb-0">Options</h4>
          <Close onClick={()=>setoptions(false)} className="click"/>
          </div>
    <hr/>
<div className="  mb-2">
<div className="container-fluid p-0">
         <span className="click" onClick={()=>{setoptions(false);setshare(true)}}><Share/> Share Video</span>
    
        </div>
       <div className="container-fluid mt-2 p-0">
       <CopyToClipboard className="w-100 click"  onCopy={()=>{settoast(true);setoptions(false)}} text={"https://skillsuprise.com/watch-short/"+course.video_id}>
          <span> <ContentCopyIcon/>Copy Video Url</span>
          </CopyToClipboard>
        </div> 

</div>
        </Box>
      </Modal>



</>}

<div className="toast-container">

<Toast show={toast} autohide delay={2000} onClose={()=>settoast(false)} className="bg-warning ">
            <Toast.Header  className="bg-warning " closeButton={false}>
          
              <strong className="me-auto text-dark">Video URL Copied</strong>
             
            </Toast.Header>
            </Toast>
</div>



      <div onClick={()=>setplay(true)} className="slider-reel-item position-relative   bg-white text-dark me-2">
<video src={course.url} className="w-100 border border-1 border-secondary  rounded "></video>
    </div>

</>
 
  
  )
}

export default SlidingVideoItem
