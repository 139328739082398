import { Avatar } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'
import PostItem from '../../community/PostItem'
import BatchTimeline from './BatchTimeline'
import CourseBatch from './CourseBatch'
import CourseLeaderboard from './CourseLeaderboard'

const CourseCommunity = () => {
  return (
    <div className="container pt-3 mb-3">
     

     <div className="d-flex flex-wrap justify-content-around">

<div className="col-lg-3 d-none d-sm-block">
<div className="shadow mt-4">

<div className="p-3 pb-2 pt-2 w-100">
<h6>All Posts</h6>
</div>

<div className="p-3 pb-2 pt-2 w-100">
<h6>Knowledgebase</h6>
</div>


<div className="p-3 pb-2 pt-2 w-100">
<h6>Questions & answers</h6>
</div>

<div type="button" className="p-3 pb-2 pt-2 w-100">
<h6>Quizzes</h6>
</div>
</div>

</div>
<div className="col-lg-4 col-md-5 col-sm-6 col-12">
<BatchTimeline/>
</div>

<div className="col-lg-3 d-none d-sm-block">
<CourseBatch/>
</div>
     </div>
 


    </div>
  )
}

export default CourseCommunity