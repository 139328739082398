import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import styled from 'styled-components';
import bg1 from '../../assets/images/students-placement images/images/bg-img.svg';
import img1 from '../../assets/images/students-placement images/images/supraja.png';
import img2 from '../../assets/images/students-placement images/images/sravani.webp';
import img3 from '../../assets/images/students-placement images/images/saif.webp';
import img4 from '../../assets/images/students-placement images/images/sujitha.jpg';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import SuccessStories from '../home/SuccessStories';
import MobileHomeReview from '../home/MobileHomeReview';
import ReviewsCta from '../home/ReviewsCta';


const StudentsPlacement = () => {
    return (
        <StyledStudentsPlacement>
        <>
            <div className='container-fluid p-0'>
                <div className='background-image2'>
                    <div className='text-center mt-4 p-xl-5 p-lg-5 p-md-3 p-sm-0 p-0'>

                        <h1 className=' colr col-xl-8 col-lg-9 col-md-10 col-sm-12 col-12 mx-auto'>Make your Careers 
                        Recession Proof <br/>and powerful</h1>
                        <p className='fs mt-3'>With Skills Uprise courses, expand Your Career Skills and Development.</p>

                       
                        <div className='col-lg-4 col-md-6 col-sm-9 col-12 mx-auto d-flex justify-content-evenly mt-3'>
                            <div className='m-0 p-0'>
                                <h5 className='colr'><b>12k</b></h5>
                                <p>Students</p>
                            </div>
                            <div>
                                <h5 className='colr'><b>4k</b></h5>
                                <p>Subscribers</p>
                            </div>
                            <div>
                                <h5 className='colr'><b>40</b></h5>
                                <p>Certificates</p>
                            </div>
                        </div>

                        <div className='d-flex margin'>
                            <div className='col-lg-3 col-md-3 col-6 p-3'>
                                <img className='w-100 roundl' src={img1}></img>
                            </div>
                            <div className=' mt-5 d-flex'>
                                <div className='col-lg-6 col-md-6 d-lg-block d-md-block d-sm-none d-none p-3'>
                                    <img className='w-100 roundr' src={img2}></img>
                                </div>
                                <div className='col-lg-6 col-md-6 d-lg-block d-md-block d-sm-none d-none p-3'>
                                    <img className='w-100 roundl' src={img3}></img>
                                </div>
                            </div>
                            <div className='col-lg-3 col-md-3 col-6 p-3'>
                                <img className='w-100 roundr' src={img4}></img>
                            </div>

                        </div>

                    </div>
                </div>

<div className="my-5 pb-0 pb-md-5">
    
<SuccessStories/>
</div>




<ReviewsCta/>


            </div>
        </>
        </StyledStudentsPlacement> 
    )
}

export default StudentsPlacement;
const StyledStudentsPlacement = styled.div`
    .background-image2 {
        background-image: url(${bg1});
        background-size: cover;
        background-position: center;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .fs1 {
        font-size: 50px;
        line-height: 60px;
        font-family:serif-font;
    }

    .colr{color:#1034A6}

    .btncl{background: #1034A6;}



      .roundl{
        border-top-left-radius: 15px;
        border-bottom-left-radius: 15px;}

     .roundr{
        border-top-right-radius: 15px;
        border-bottom-right-radius: 15px;}

    .bg{ background-color:#fdff72;}

    .bg1{background-color:#5ced73}
    `