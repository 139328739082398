import React, { useState } from 'react'
import dateFormat from 'dateformat'
import { Link, useParams } from 'react-router-dom'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import ProjectSubmit from './ProjectSubmit'
import ModalStyle from '../../../data/ModalStyle'
import Close from '@mui/icons-material/Close'
import AddClassRemainder from '../../../actions/course/AddClassRemainder';
import { useContext } from 'react';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import Toast from 'react-bootstrap/Toast'
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import SmartDisplayIcon from '@mui/icons-material/SmartDisplay';
import AddAlertIcon from '@mui/icons-material/AddAlert';
import { AuthContext } from '../../../context/AuthContext';
import { MoreVert, NotificationAdd } from '@mui/icons-material';
import DuoIcon from '@mui/icons-material/Duo';
import { Avatar, AvatarGroup } from '@mui/material';
import Dropdown from 'react-bootstrap/Dropdown'
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import JoinClass from '../../../actions/course/JoinClass';
const ClassItem = ({item,index}) => {
     const params = useParams()
     const course_url = params.course_url
     const batch_id = params.batch_id
const {user} = useContext(AuthContext)
     const [submit,setsubmit] = useState(false)
     const [reminder,setreminder] = useState(item.reminder||false)
const AddRemainder = async ()=>{

const res = await AddClassRemainder({
  payload:{
session:user.SessionId,
course_url:course_url,
batch_id:batch_id,
class_id:item.id,
date_time:remindertime,
class_time:item.class_starts
  }
})

if(res){
  settoast(true)
  settoasttitle(res.title)
  settoastmsg(res.msg)
  if(res.status==="success"){
    setsubmit(false)
    setreminder(true)
   
  }
}
} 
const [toast,settoast] = useState(false)
const [toasttitle,settoasttitle] = useState(null)
const [toastmsg,settoastmsg] = useState(null)
const [remindertime,setremindertime] = useState(null)
  return (
    <>



        <div className="toast-container">
        <Toast className="bg-warning" onClose={() => settoast(false)} show={toast} delay={8000} autohide>
          <Toast.Header>
            <img
              src="holder.js/20x20?text=%20"
              className="rounded me-2"
              alt=""
            />
            <strong className="me-auto">{toasttitle}</strong>
           
          </Toast.Header>
          <Toast.Body>{toastmsg}</Toast.Body>
        </Toast>

        </div>
<Modal

        open={submit}
        onClose={()=>{setsubmit(false)}}
      >
        <Box sx={ModalStyle} className="col-lg-3 col-md-4 col-sm-5 col-11 p-3">
          <div className="modal-header">
            <h4>Remind Me</h4>
            <Close className="click" onClick={()=>setsubmit(false)}/>
          </div>
          <hr/>
          <div>

<form method="post" onSubmit={(e)=>{e.preventDefault();AddRemainder()}}>

<select className="form-control" onChange={(e)=>setremindertime(e.target.value)}>
<option value={5}>5 Minutes before class</option>

<option value={15}>15 Minutes before class</option>

<option value={30}>5 Minutes before class</option>
<option value={24}>1 Day before class</option>

        </select>

        <button type="submit" className="btn mt-4 w-100 btn-primary rounded-pill">Schedule Reminder</button>
        
</form>
        

          </div>
</Box>
      </Modal>

    
    <div className=" p-2 mt-2 mb-2 sm-p-0">

<div className={"light-shadow rounded p-3 c"+index}>

<div className="d-flex mb-2 justify-content-between">
<h4 className="sm-16">{item.class_title}</h4>

<Dropdown className="dropdown options-button text-dark">
  <Dropdown.Toggle className="bg-transparent">
  <MoreVert className="text-dark" />
  </Dropdown.Toggle>

  <Dropdown.Menu className="p-3">
   
   
   <div className="mt-1 b-1">

<Dropdown.Item as="button"><ContentCopyIcon/> Copy Meeting Link</Dropdown.Item>
      

   </div>

   <div className="mt-1 b-1">
{reminder?<>


  <Dropdown.Item as="button" className='my-1' disabled><NotificationsActiveIcon/> Reminder added</Dropdown.Item>
    
</>:
<>

<Dropdown.Item as="button" className='my-1' onClick={()=>setsubmit(true)}><NotificationsActiveIcon/> Add Reminder</Dropdown.Item>
    
</>}  

   </div>

   
    </Dropdown.Menu>
</Dropdown>
</div>
<div className="d-flex sm-12 justify-content-between">
  <p><b>Date : </b>{dateFormat(item.class_starts,"dS mmm")}</p>
  <p><b>Time : </b>{dateFormat(item.class_starts,"hh:mm tt")} - {dateFormat(item.class_ends,"hh:mm tt")}</p>
</div>
<div className="d-flex mt-2">
<b className='sm-13'>Topics:</b>

<p className="truncate truncate1 sm-13 sm-truncate2">{item.class_description?item.class_description:"NA"}</p>

</div>
<hr/>
<div className="d-flex mt-3 justify-content-between">
 {reminder?
 <>
 <button disabled target="_blank" className="btn sm-13 rounded-pill btn-sm btn-success"><NotificationsActiveIcon/> Reminder Added</button>

 </>:
 <>
 <div className="d-flex align-items-center">
{item.attendees?
<>
<AvatarGroup   className="d-flex align-items-center" >
  {item.attendees.map((attendee)=>(
    <>
    
    <Avatar  sx={{ width: 30, height: 30 }} alt="Remy Sharp" src={attendee.profile_pic||null} />
 
    </>))}
 <p className="sm-12 mb-0 pb-0">+{item.attendees.length} attending</p>
</AvatarGroup>
</>:
<>
<span></span>
</>}
</div>
 </>}
 
{item.class_status==="completed"?
<>
<Link to={"/recordings/"+course_url+"/"+batch_id+"/"+item.id}  className="btn sm-13 rounded-pill btn-sm btn-outline-primary">Watch Recording</Link>

</>:
<>
<a href={item.meeting_link} onClick={()=>JoinClass({payload:{
  course_url:item.course_url,
  status:"joined",
  batch_id:item.batch_id,
  user:user.SessionId
  ,class_id:item.id

}})} target="_blank" className="btn rounded-pill sm-13 btn-sm btn-primary"><PlayArrowIcon/> Join Class</a>

</>}
  </div>
</div>

</div>
    </>
  )
}

export default ClassItem