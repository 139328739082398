import React, { useEffect, useState } from "react";
import NotesItem from "./NotesItem";
import { PlayCircle } from "@mui/icons-material";
import axios from "axios";
import ENDPOINT from "../../data/ENDPOINT";
import SecurityHeaders from "../../data/SecurityHeaders";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import SearchIcon from '@mui/icons-material/Search';

const NotesListPage = ({ account }) => {
  const [notes, setnotes] = useState(null);
  const GetNotes = async () => {
    const data = new FormData();

    const res = await axios.post(
      ENDPOINT + "get-notes-list.php",
      data,
      SecurityHeaders
    );

    if (res) {
      if (res.data.status === "success") {
        setnotes(res.data.notes);
        setInititalData(res.data.notes)
        setResponse(res.data.notes)
      }
    }
  };
  useEffect(() => {
    GetNotes();
  }, []);
  
  const [initialData, setInititalData] = useState(null);

  const [response, setResponse] = useState(initialData);
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearch = (e) => {
    const term = e.target.value.toLowerCase();

    const filteredData = initialData.filter((item) => {
      return (
        item.notes_title.toLowerCase().includes(term)
      
      );
    });

    setResponse(filteredData);
    setSearchTerm(term);
  };

  return (
    <div className="w-100">
      <Helmet>
        ‍<title>Course Notes - Skills Uprise</title>‍
        <meta
          name="description"
          content="Access Comprehensive Course Notes: Simplify Your Learning Journey with Our Comprehensive Course Materials. Learn, Revise, Excel!"
        />
        <meta name="twitter:card" content="" />
        <meta name="twitter:site" content="@skillsuprise" />
        <meta name="twitter:creator" content="@skillsuprise" />
        <meta name="twitter:title" content="" />
        <meta name="twitter:description" content="" />
        <meta name="twitter:image" content="" />
        <meta property="og:title" content="" />
        <meta property="og:description" content="" />
        <meta property="og:image" content="" />
        <meta property="og:url" content="" />
        <meta property="og:site_name" content="Skills Uprise" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta property="fb:app_id" content="ID_APP_FACEBOOK" />
      </Helmet>
      <div className="container-fluid bg-notes">
        <div className="min-vh-50 container">
          <div className="pt-5 pb-4 d-flex flex-wrap">
            <div className="col-lg-8 sm-mt-10 mt-10 text-light pt-5 sm-pt-0">
              <h1 className="bold prime-title home-title">
                Comprehensive Course Notes<br></br>For Easier Subject Learning
              </h1>
              <p className="text-light mt-3 col-lg-8 col-sm-10 col-12">
                Our power-packed course notes provide a wealth of valuable
                resources for job seekers, including tutorials, interview
                preparation tips, and a list of commonly asked interview
                questions with answers and many more such useful content
              </p>
            </div>

            <div className="col-lg-4">
              <img
                src="https://skillsuprise.com/resources/images/book-side.png"
                className="w-100"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="container pt-5 mb-5">
        <Styled>
     <div className="d-flex justify-content-end mb-3">
    


                            <div className="d-flex p-1 border border-1 align-items-center  col-12 col-sm-10 col-md-4 col-lg-4 ">
            <SearchIcon className="ri-search-line  text-secondary" />
              <input
                onChange={handleSearch}
                type="text"
                className="border border-0"
                placeholder="Search"
              />
      
            </div>
     </div>
                            </Styled>
        <div className="flex-wrap d-flex animation-active flex-wrap d-flex">
          {response ? (
            <>
              {response.map((book) => (
                <>
                  <NotesItem account={account} book={book} />
                </>
              ))}
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};

export default NotesListPage;
export const Styled = styled.div`
.search-box {
  position: relative;}

`;