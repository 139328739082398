import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import GetWorkshops from '../../actions/GetWorkshops'
import Localbase from 'localbase'

const WorkshopsSuggestions = () => {
const [workshops,setworkshops] = useState(null)
let db = new Localbase("db")
const NewGetWorkshops = async()=>{

  const res = await GetWorkshops({limit:2})
  if(res){

    setworkshops(res)
    db.collection("paths").add({
      workshops:res
    },"workshops")
  }
}
useEffect(()=>{

  db.collection("paths").doc("workshops").get().then(data=>{
setworkshops(data.workshops)
  }).catch(error=>{

  })
NewGetWorkshops()
},[])
  return (
 
<>



{workshops?
<>
<div className="w-100 mt-3 p-2 px-0 mb-3">

<div className="d-flex justify-content-between mb-1 align-items-center">
<h4>Upcoming events</h4>
<Link className="dm-sm-block" to="/events">View All</Link>
</div>
{workshops.map((workshop)=>(
  <>
<Link to={"/event/"+workshop.workshop_url} className="d-flex  w-100 mb-2 mt-2">
  <div className="col-lg-4 col-md-5 col-sm-5 col-5 mr-10">
    <img  src={workshop.workshop_cover_url} className="w-100 h-100 br-4 workshop-suggestion-cover"/>
  </div>
     <div className="col-lg-7 position-relative p-2">
          <h6 className="sm-16 truncate truncate2 text-dark">{workshop.workshop_title}</h6>
          
          <div className="sm-mt-10  mb-2 sm-13 align-items-center">
            <div className="mr-10 text-secondary d-flex align-items-center">
            <h6 className="pb-0 mb-0">Date :- </h6>
           <p>{workshop.date}</p>
            </div>
            <div className="mt-2 text-secondary  d-flex align-items-center">
            <h6 className="mb-0 pb-0">Time :- </h6>
            <p>{workshop.workshop_duration}</p>
         
            </div>
         
          </div>
         
      </div>
</Link>

  </>
))}



</div>

</>:null}
</>



  )
}

export default WorkshopsSuggestions