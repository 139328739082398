import axios from 'axios'
import ENDPOINT from '../../data/ENDPOINT'
import SecurityHeaders from '../../data/SecurityHeaders'

const  UnfollowUser = async ({payload})=>{

const data = new FormData()


data.append("session",payload.user)
data.append('following_id',payload.following_id);
            
            
const res = await 
axios.post(ENDPOINT+`unfollow-user.php`,data,SecurityHeaders)
if(res){
    
     return res.data
}

}




export default UnfollowUser