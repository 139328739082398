import axios from 'axios'
import React, { useEffect, useState } from 'react'
import ENDPOINT from '../../data/ENDPOINT'
import SecurityHeaders from '../../data/SecurityHeaders'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import InputLabel from '@mui/material/InputLabel';
import ModalStyle from '../../data/ModalStyle';
import SetPage from '../../data/SetPage';
import { PlayCircle } from '@mui/icons-material';
import Localbase from 'localbase';
import CheatSheetsSkeleton from '../../components/skeletons/CheatSheets';
import { Close } from '@material-ui/icons';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import SearchIcon from "@mui/icons-material/Search";
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';

const CourseCheatsheets = ({ account }) => {
     let db = new Localbase('db')

     db.collection("paths").doc("cheat_sheets").get().then(data => {
          setcategories(data.cheat_sheets)
          // setloading(false)
     }).catch(error => {

     })
     useEffect(() => {
          if (loaded) {

          } else {
               window.scrollTo(0, 0)
          }

     }, [])


     const [query, setquery] = useState(null)

     const SearchCheatsheets = (e) => {

          e.preventDefault()

     }



     const [loaded, setloaded] = useState(false)
     const [categories, setcategories] = useState(null)

     const GetQuizCategories = async () => {
          //setloading(true)
          const data = new FormData()

          const res = await axios.post(ENDPOINT + "get-cheat-sheets.php", data, SecurityHeaders)

          if (res) {
               setloading(false)
               if (res.data.status === "success") {
                    setResponse(res.data.data)
                    setInititalData(res.data.data)

                    db.collection("paths").add({
                         cheat_sheets: res.data.data
                    }, 'cheat_sheets')
                    setcategories(res.data.data)

               }

          }
     }

     useEffect(() => {

          if (categories) {
               setloading(false)
          }




          GetQuizCategories()


          SetPage("Cheat Sheets")
     }, [])
     const [loading, setloading] = useState(true)
     const [modal, setmodal] = useState(false)
     const [initialData, setInititalData] = useState(null);

     const [response, setResponse] = useState(initialData);
     const [searchTerm, setSearchTerm] = useState("");

     const handleSearch = (e) => {
          const term = e.target.value.toLowerCase();

          const filteredData = initialData.filter((item) => {
               return item.title.toLowerCase().includes(term);
          });

          setResponse(filteredData);
          setSearchTerm(term);
     };
     return (


          <>






               <Modal
                    open={modal}
                    onClose={() => { setmodal(false) }}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
               >
                    <Box className="col-lg-4 col-md-5 col-sm-6 col-11 p-3 br-5" sx={ModalStyle}>

                         <div className="modal-header">
                              <h4>    Student Access Only !</h4>
                              <Close className="click" onClick={() => setmodal(false)} />
                         </div>

                         <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                              {account ?
                                   <>
                                        Please register a course to access this section

                                   </> :
                                   <>
                                        Login into your account to access this section

                                   </>}

                              <div className="d-flex justify-content-between mt-4">
                                   <button onClick={() => setmodal(false)} className="btn col-5  btn-outline-danger rounded-pill">Cancel</button>
                                   {account ?
                                        <>
                                             <Link to="/courses" className="btn btn-primary  col-5 rounded-pill">Browse Courses</Link>

                                        </> :
                                        <>
                                             <Link to="/login" className="btn btn-primary col-5  rounded-pill">Login</Link>

                                        </>}
                              </div>
                         </Typography>
                    </Box>
               </Modal>

               <div className="interview-prep-cover bg-dark sm-p-15 pt-4 text-light text-center min-vh-30">

                    <h2>Course Cheat Sheets</h2>
                    <div className="col-lg-7 col-md-8 col=sm-10 col-12 mx-auto text-center mt-3">
                         {/* <p className="text-light">Get ahead with our comprehensive cheat sheets. Designed to help you learn and retain information quickly. Access important information and concepts with our easy-to-use reference guides.</p> */}
                         <Paper
                              component="form"
                              onSubmit={(e) => SearchCheatsheets(e)}
                              className="mt-3 col-lg-8 col-md-9 col-12 mb-4 mx-auto"
                              sx={{ p: '2px 4px', display: 'flex', alignItems: 'center' }}
                         >

                              <InputBase
                                   onChange={handleSearch}
                                   value={query}
                                   sx={{ ml: 1, flex: 1 }}
                                   placeholder="Search Mocktests"
                                   inputProps={{ 'aria-label': 'search Mocktets' }}
                              />
                              <IconButton type="submit" sx={{ p: '10px' }} aria-label="search">
                                   {query ?
                                        <>
                                             {/* <CloseIcon onClick={() => setquery(null)} /> */}
                                        </> : <SearchIcon />}
                              </IconButton>

                         </Paper>

                    </div>

               </div>

               <div className="min-vh-100 interview-praparation pb-5">

                    {/* <div className="d-flex justify-content-end mt-5 mb-3 container">
                         <div className="d-flex p-1 border border-1 align-items-center  col-12 col-sm-10 col-md-4 col-lg-4 ">
                              <SearchIcon className="ri-search-line  text-secondary" />
                              <input
                                   onChange={handleSearch}
                                   type="text"
                                   className="border border-0"
                                   placeholder="Search"
                              />

                         </div>
                    </div> */}

                    <div className="container-lg mt-3 d-flex flex-wrap">



                         {response ?

                              <>



                                   <>
                                        {response.map((item) => (
                                             <>
                                                  <div className="col-lg-6 col-md-6 col-sm-12 col-12  d-flex sm-p-0 p-0 p-md-3 ">


                                                       <div className="quiz-category d-flex border w-100">
                                                            <img src={item.image} className="cheat-cover" />

                                                            <div onClick={() => setmodal(true)} className="   p-3">
                                                                 <div className="d-flex w-100">
                                                                      <div className="w-100">
                                                                           <h5>{item.title}</h5>

                                                                           <p className="text-secondary"><b>Pages : </b>{item.pages ? item.pages : "80"}</p>
                                                                           <p className="text-secondary"><b>Size : </b>{item.size ? item.size : "35"} mb</p>
                                                                           <hr />
                                                                           <div className="d-flex justify-content-between mt-3">
                                                                                <button className="btn   btn-primary  btn-sm ">Read Now</button>
                                                                                <button className="btn text-primary d-none d-md-block btn-sm">Download</button>
                                                                           </div>

                                                                      </div>
                                                                 </div>
                                                            </div>


                                                       </div>






                                                  </div>

                                             </>
                                        ))}
                                   </>

                              </>

                              :
                              <>
                                   {loading ?
                                        <>

                                             <div className="mt-0 w-100 text-center">

                                                  <CheatSheetsSkeleton />
                                             </div>
                                        </> : null}

                              </>}








                    </div>

               </div>

          </>

     )
}

export default CourseCheatsheets