import CheckIcon from '@mui/icons-material/Check';
import SendIcon from '@mui/icons-material/Send';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import React, { useState } from 'react';
import Form from 'react-bootstrap/Form'
import { Avatar } from '@material-ui/core';
import axios from 'axios';
import dateFormat from 'dateformat';
import { useContext } from 'react';
import { AuthContext } from '../../context/AuthContext';
import ENDPOINT from '../../data/ENDPOINT';
import { useEffect } from 'react';
import {useRef} from 'react'
import Badge from '@mui/material/Badge';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom'
import * as timeago from 'timeago.js'
import Box from '@mui/material/Box';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import Modal from '@mui/material/Modal';
import SetPage from '../../data/SetPage';
import Localbase from 'localbase';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { ref, push, onValue,getDatabase, set } from 'firebase/database';
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import 'firebase/auth';
import firebase from 'firebase/app';
import FitHeight from '../../components/FitHeight';
import firebaseConfig from '../../firebase-Config';
const CommunityChat = () => {

const [chatheads,setchatheads] = useState(null)
const params = useParams()
const conversation_id = params.course_url+params.batch_id;


const db = new Localbase("db")
const scrollRef = useRef()
const width = window.innerWidth;
const [device,setdevice] = useState("lg")

const {user} = useContext(AuthContext)
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional


// Initialize Firebase

useEffect(()=>{
SetPage("Messages")
},[])





const [messages,setmessages] = useState(null)

const updateMessageReadStatus = ()=>{

const desktopchatbox = document.getElementById("d-chatbox");
if(desktopchatbox){
  desktopchatbox.style.height = window.innerHeight - 170+"px"
  
desktopchatbox.style.overflowY = "scroll"
desktopchatbox.scrollTo()

if(conversation!==null){

  if(width>=576 && conversation.length>5){
    if(desktopchatbox.scrollTop>50){
      //  //  //console.log("message read")
    }
      }
}

   //desktopchatbox.onscroll(desktopchatbox.scrollTop)
 // //  //  //console.log(desktopchatbox.scrollTop);
}
}



const [conversation,setconversation] = useState(null)
const [receiver,setreceiver] = useState(null)
          const getConversation = async (status)=>{
        

               const formData = new FormData();
               
               
               //formData.append('whyus',document.getElementById('whyus').value)
               
               formData.append('session',user.SessionId);
     
               formData.append('course_url',params.course_url);
     
               formData.append('batch_id',params.batch_id);
     
                 const res = await axios.post(ENDPOINT+"get-community-messages.php", formData,{
                   headers: {
                       'content-type': 'multipart/form-data'
                   }
               });
               if(res){


              

             //  //  //  //console.log("conversation "+JSON.stringify(res.data))
                if(res.data.status==="success"&&res.data.messages!==null){
                  console.log(res.data)
                  
setmessages(res.data.messages)
                if(scrollRef){
                 }
                
                }
   
               }
               }
               

const [totalmessages,settotalmessages] = useState(null)

const [message,setmessage] = useState(null)

               const sendMessage = async (status)=>{
        
////  //  //console.log("sending "+message)
                    const formData = new FormData();
                    
                    
                    //formData.append('whyus',document.getElementById('whyus').value)
                    
                    formData.append('session',user.SessionId);
          
                    formData.append('course_url',params.course_url);
                    formData.append('batch_id',params.batch_id);
                    formData.append('message',message);
          
                    
                    const res = await axios.post(ENDPOINT+"send-community-message.php", formData,{
                        headers: {
                            'content-type': 'multipart/form-data'
                        }
                    });
                    if(res){
                   //  //  //  //console.log("sent "+JSON.stringify(res.data.status))
                     if(res.data.status==="success"){
sendFirebaseMessage()
                         setmessage("");
                         
                 
                     }
                     else{
                    
                     }
                    }
                    }






    

          
                

useEffect(()=>{
  getConversation()
},[])

                
useEffect(()=>{

const container =document.getElementById("container")


container.style.height = (window.innerHeight - 65 )+"px"


if(conversation!==null){

if(conversation.length>5 && scrollRef){
  scrollRef.current.scrollIntoView({behaviour:"smooth",block: "end"})
 // //  //  //console.log(JSON.stringify("scrolled "+JSON.stringify(scrollRef)))
}

   
}
           
},[totalmessages])




const selectedChat = ({i})=>{
     const chats = document.getElementsByClassName('chat-head');
////  //  //console.log("chats "+chats.length)
const selectedchat = chats[i];
const inputwindow = document.getElementById("input-window")
if(inputwindow){
  inputwindow.classList.add("chat-input-window")
}
for(var k=0;k<chats.length;k++){
  if(chats[k].classList.contains("active-chat")){
     chats[k].classList.remove("active-chat")
   }  
}
selectedchat.classList.add("active-chat")
}


const style = {
  position: 'absolute',
zindex:600000,
bottom:0,
screenLeft:0,
 width:window.innerWidth,
  bgcolor: 'background.paper',
  border: '0px solid #000',
  boxShadow: 0,
  p: 4,
};

const [chat,setchat] = useState(false)







const openChat = ()=>{

  if(width<576){


    setchat(true)
  setHeight()
  }
}



const setHeight = ()=>{
  const mchatbox = document.getElementById("m-chatbox");
  if(mchatbox!==null){

    mchatbox.style.height = (window.innerHeight - 180)+"px";

  }


}


//firebase section

const dbRef = ref(getDatabase(), 'conversations/'+conversation_id);

useEffect(()=>{
    // Listen for changes in the 'messages' node in the database
    onValue(dbRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
      
       //getConversation();
       
             }
    });

},[])
    const sendFirebaseMessage = () => {
      if (message.trim() !== '') {
        // Push a new message to the 'messages' node in the database
        set(dbRef, {
        
          timestamp: new Date().getTime(),
          
        });
     
        
        getConversation();
      }
    };


    const messagesRef = useRef(null);

    useEffect(() => {
      // Scroll to the last message when messages change
      scrollToBottom();
    }, [messages]);
  
    const scrollToBottom = () => {
      if (messagesRef.current) {
        const scrollHeight = messagesRef.current.scrollHeight;
        const clientHeight = messagesRef.current.clientHeight;
        const scrollBottom = scrollHeight - clientHeight;
  
        messagesRef.current.scrollTop = scrollBottom;
      }
    };
  return(

    <>
    
    
<div id="container" className="container p-2  ">




<FitHeight>

<div id="conversation" className="col-lg-12">
{/*chat section*/}
<div className=" position-relative p-2 pt-0">






<FitHeight>
<div 
 className=" position-relative d-chatbox">

<div id="d-chatbox"  ref={messagesRef} className="scroll-vert">
{messages?


<>
{messages.map((message,index)=>(

<>
{message.own===true?

<>
<div ref={scrollRef} className={messages.length===(index+1)?"justify-right w-100 mt-1 mb-1 final-msg":"justify-right w-100 mt-1 mb-1"}>
<div className="message">
       <p className="mb-1">{message.message}</p>
   <div className="flex w-100 justify-right extra-small text-right">
        <p className="mb-0">{dateFormat(message.create_datetime,"h:mm TT")}</p>
<CheckIcon className="ml-2"/>
   </div>
</div>

</div>


</>

:<>


<div ref={scrollRef} className={messages.length===(index+1)?"message mt-1 mb-1 final-msg":"message mt-1 mb-1"}>
  <div className="d-flex">
<Avatar src={message.profile_pic?message.profile_pic:""} />
  <p>{message.message}</p>
   <div className="flex w-100 justify-right extra-small text-right">
        <p className="mb-0">{timeago.format(message.create_datetime)}</p>
<CheckIcon className="ml-2"/>
   </div>
  </div>
</div>


</>


}

</>


))}
</>
:
<div className="container-fluid pt-5 pb-5 h-100 justify-content-center d-flex align-items-center">

<p className="text-center">No Messages</p>
</div>}



</div>
































</div>


</FitHeight>




</div>
{/*chat section*/}
</div>
<div className="bg-white bottom-0 left-0  container-fluid  br-4">
<Form onSubmit={(e)=>{e.preventDefault();sendMessage()}}>
<div className="flex align-items-center p-2 space-between">
<Form.Group className=" w-100 mb-0" controlId="exampleForm.ControlInput1">

    <input type="text" className="form-control" onChange={(e)=>{setmessage(e.target.value)}} value={message} placeholder="Enter Message..." />
  </Form.Group>
<div className="flex ml-2 align-items-center">
<AttachFileIcon  type="button" className="mr-2"/>
<SendIcon  onClick={(e)=>{e.preventDefault();sendMessage()}} type="button"/>
</div>
</div>
 
</Form>
</div>
</FitHeight>


</div>




    
    </>
  )
};

export default CommunityChat;
