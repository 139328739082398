import React from 'react'
import ModalStyle from '../../../data/ModalStyle'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Close } from '@material-ui/icons';

const MilestonesModal = ({visibility,HandleModal,data}) => {




  return (
   <>
   
   <Modal
   fade
  open={visibility}
  onClose={HandleModal}
>
  <Box sx={ModalStyle} className='col-11 col-lg-4 col-md-4 p-3 rounded'>
 <div className="d-flex justify-content-between">
 {data.title?data.title:null}

<Close className="click" onClick={HandleModal}/>
 </div>
 <hr></hr>
    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
      {data.description?data.description:null}
    </Typography>
  </Box>
</Modal>
   </>
  )
}

export default MilestonesModal