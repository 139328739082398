import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { useEffect } from "react";
import { useState } from "react";
import React from "react";
import Modal from "@mui/material/Modal";
import { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import UserProfileBasic from "./UserProfileBasic";
import styled from "styled-components";
import ENDPOINT from "../../data/ENDPOINT";
import Localbase from "localbase";
import EditIcon from "@mui/icons-material/Edit";
import Close from "@mui/icons-material/Close";
import EducationList from "./education/EducationList";
import ExperienceList from "./experience/ExperienceList";
import CertificationsList from "./certifications/CertificationsList";
import HonoursList from "./honours/HonoursList";
import TechnicalSkillsList from "./skills/TechnicalSkillsList";
import InterpersonalSkillsList from "./skills/InterpersonalSkillsList";
import HobbiesList from "./skills/HobbiesList";
import axios from "axios";
import UserAccount from "./UserAccount";
import SetPage from "../../data/SetPage";
import { useParams } from "react-router-dom";
import PublicUserAccount from "./PublicUserAccount";
import { Helmet } from "react-helmet";
const AccountPage = ({ account }) => {
  const params = useParams();
  const username = params.username;

  const { user } = useContext(AuthContext);

  const session = user.SessionId;
  const db = new Localbase("db");

  const [self, setself] = useState(false);
  const [modal, setmodal] = useState(false);

  useEffect(() => {
    SetPage("My Account");
    EditAbout({ action: "get" });
    if (account) {
      console.log("acc " + account.username + " " + params.username);
      if (account.username === params.username) {
        GetMyAccount();
        setself(true);
      } else {
        setself(false);
      }
    }
  }, [account, params.username]);

  const GetMyAccount = async () => {
    fetch(`${ENDPOINT}get-account.php?session=${session}`)
      .then((r) => r.json())
      .then((result) => {
        //setaccount(result[0].data);
      });
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",

    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

  const logoutall = () => {
    fetch(`${ENDPOINT}logoutall.php?session=${user.SessionId}`)
      .then((r) => r.json())
      .then((result) => {
        if (result[0].status === "success") {
          db.collection("paths").doc("user").delete();
          db.collection("paths").doc("notifications").delete();
          localStorage.setItem("user", null);

          if (
            sessionStorage.getItem("next") === "null" ||
            sessionStorage.getItem("next") === "" ||
            sessionStorage.getItem("next") === null
          ) {
            window.location.replace("/");
          } else {
            window.location.replace("/");
          }

          sessionStorage.setItem("next", "");
        } else {
        }
      });
  };

  const [editbio, seteditbio] = useState(false);

  const [about, setabout] = useState(null);

  const EditAbout = async ({ action }) => {
    const data = new FormData();
    data.append("session", user.SessionId);
    if (action === "get") {
      data.append("get", true);
    } else {
      data.append("about", about);
    }
    const res = await axios.post(ENDPOINT + "user-about-edit.php", data, {
      headers: { "content-type": "multipart/form-data" },
    });

    if (res) {
      ////  //  //console.log("about "+JSON.stringify(res))
      if (res.data.status === "success") {
        if (action === "get") {
          setabout(res.data.data);
        } else if (action === "edit") {
          seteditbio(false);
        }
      }
    }
  };

  return (
    <>
      <Helmet>
        ‍<title>My Account - Skills Uprise</title>‍
        <meta name="description" content="Skills Uprise" />
        <meta name="twitter:card" content="" />
        <meta name="twitter:site" content="@skillsuprise" />
        <meta name="twitter:creator" content="@skillsuprise" />
        <meta name="twitter:title" content="" />
        <meta name="twitter:description" content="" />
        <meta name="twitter:image" content="" />
        <meta property="og:title" content="" />
        <meta property="og:description" content="" />
        <meta property="og:image" content="" />
        <meta property="og:url" content="" />
        <meta property="og:site_name" content="Skills Uprise" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta property="fb:app_id" content="ID_APP_FACEBOOK" />
      </Helmet>
      <AccountStyled className="container-fluid  sm-p-0 account-page pb-5 lg-mt-10 sm-mt-0 shadow p-0">
        <Modal
          open={modal}
          onClose={() => setmodal(false)}
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Logout !
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              Are you sure to logout ?
            </Typography>

            <div className="flex mt-3 w-100 space-between">
              <button
                className="btn btn-danger"
                onClick={() => setmodal(false)}
              >
                Cancel
              </button>
              <button className="btn btn-primary" onClick={logoutall}>
                Confirm Logout
              </button>
            </div>
          </Box>
        </Modal>

        <Modal
          open={editbio}
          onClose={() => seteditbio(false)}
          
        >
          <Box
            sx={style}
            className="p-3 br-5 col-lg-6 col-md-6 col-sm-12 col-12"
          >
            <div className="flex space-between">
              <h5>Edit About</h5>
              <Close
                onClick={() => {
                  seteditbio(false);
                }}
                type="buton"
                className="text-danger click"
              />
            </div>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                EditAbout({ action: "edit" });
              }}
            >
              <textarea
                value={about}
                onChange={(e) => setabout(e.target.value)}
                type="text"
                required
                minLength={5}
                maxLength={500}
                className="form-control"
              ></textarea>
              <button type="submit" className="btn btn-primary w-100 mt-4">
                Save Changes
              </button>
            </form>
          </Box>
        </Modal>

        <div className="flex-wrap sm-p-0 d-flex container justify-content-between w-100 mb-5">
          <div className="col-xl-7 col-lg-9  sm-p-0 col-md-12 col-sm-12 col-12">
            {account ? (
              <>
                {self ? (
                  <>
                    <UserAccount account={account} />
                  </>
                ) : (
                  <>
                    <PublicUserAccount account={account} />
                  </>
                )}
              </>
            ) : (
              <></>
            )}

            <div className=" account-info">
              {self ? (
                <>
                  <div className="container-fluid sm-p-15 border br-5 p-3 mt-3">
                    <div className="flex space-between">
                      <h5>About</h5>
                      {self ? (
                        <>
                          <EditIcon
                            onClick={() => seteditbio(true)}
                            type="button"
                          />
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className="w-100">
                      <p>{about ? about : null}</p>
                      <div class="onesignal-customlink-container"></div>
                    </div>
                  </div>
                </>
              ) : null}

              {account ? (
                <>
                  <div className=" mt-3">
                    <EducationList username={username} self={self} />
                  </div>

                  <div className=" mt-3">
                    <ExperienceList username={username} self={self} />
                  </div>

                  <div className=" mt-3">
                    <CertificationsList username={username} self={self} />
                  </div>

                  <div className="mt-3">
                    <HonoursList username={username} self={self} />
                  </div>
                </>
              ) : null}
            </div>
          </div>

          <div className="col-xl-4  d-xl-block col-12 col-sm-12  account-info">
            {account ? (
              <>



              {/* <div className="border mb-3 p-3 rounded">
              <h4>Scan your profile with ATS</h4>
<div className="">
  <div className="">
    

<p>Check your ATS score, get recommendations, improve your profile and get hired fast</p>
  </div>
  <div className="mt-3 d-flex">
    <button className="btn btn-primary me-3 rounded rounded-pill btn-sm"> Scan Now</button>
    <button className="btn btn-success rounded  rounded-pill btn-sm">Get Expert Recommendations</button>
  </div>
</div>

              </div> */}
                <div className="">
                  <TechnicalSkillsList />

                  <div className="mt-3">
                    <InterpersonalSkillsList self={self} />
                  </div>

                  <div className="mt-3">
                    <HobbiesList self={self} />
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      </AccountStyled>
    </>
  );
};

export default AccountPage;

export const AccountStyled = styled.div`
  .home-options {
    display: flex !important;
  }
  .carousel {
    margin-top: 20px;
  }
`;
