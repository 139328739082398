import React from 'react'
import moneyicon from '../../assets/images/money-icon.svg'
import offlineicon from '../../assets/icons/offline-class.png'
import recordings from '../../assets/icons/recordings.svg'
import group from '../../assets/images/businessman.svg'
import tasks from '../../assets/icons/tasks.svg'
import community from '../../assets/icons/community-icon.svg'
import assignments from '../../assets/icons/assignments-icon.svg'
import support from '../../assets/images/job.svg'
import placement from '../../assets/icons/placement.svg'
import clock from '../../assets/images/clock.svg'
import lor from '../../assets/images/lor.svg'
import divider from '../../assets/bg/divider.png'
import certificateicon from '../../assets/images/cericon.svg'
import { useEffect } from 'react'
const BenifitsList = ({mode,course}) => {

     const Animate = ()=>{
          const items = document.getElementsByClassName("animate")
         
          const screenheight = window.innerHeight;
          for (var i =0;i<=items.length;i++){
        const currentelement = items[i]
        
        if(currentelement){
          const elementposition = parseInt(currentelement.getBoundingClientRect().top.toFixed())
         
          if(elementposition<screenheight){
            if(items[i].classList.contains("animation-active")){
          
            }else{
              items[i].classList.add("animation-active")
            }
          }
        
        }
        
        
           
          }
        }
   
        
        useEffect(()=>{
//window.addEventListener("scroll",Animate)
        },[])

  return (
    <div className="w-100   ">

<div className="text-center">
     <h1 className="prime-title secondary-text">Extra-Ordinary Benifits</h1>
     <h2 className="sec-title primary-text">For Exceptional Growth</h2>

     <div class="divider">
<img src={divider}  alt="divider"></img>
</div>
</div>

<div className="container animate sm-p-0 flex-wrap d-flex justify-content-between">


<>

<div className="col-lg-4 fade-in-bottom delay3s col-md-6 col-sm-6 col-12 p-3">
<div className="feature-item slide-in-down shadow d-flex p-3 br-4">
     <img src={offlineicon}/>
<div>
<h5>Free Training</h5>
<p>Get our courses for free</p>
</div>
</div>
</div>


<div className="col-lg-4 delay3s  fade-in-bottom col-md-6 col-sm-6 col-12 p-3">
<div className="feature-item  slide-in-down shadow d-flex p-3 br-4">
     <img src={moneyicon}/>
<div>
<h5>Monthly Stipend</h5>
<p>Earn upto  ₹ 20,000 per month</p>
</div>
</div>
</div>



<div className="col-lg-4 delay3s fade-in-bottom col-md-6 col-sm-6 col-12 p-3">
<div className="feature-item slide-in-down  shadow d-flex p-3 br-4">
     <img src={certificateicon}/>
<div>
<h5>Certificate of Internship</h5>
<p>Add extra weightage to your resume</p>
</div>
</div>
</div>


</>

<div className="col-lg-4  delay4s  fade-in-bottom col-md-6 col-sm-6 col-12 p-3">
<div className="feature-item shadow d-flex p-3 br-4">
     <img src={lor}/>
<div>
<h5>Letter of recommendation</h5>
<p>Get better opportunities easily</p>
</div>
</div>
</div>


<div className="col-lg-4  delay4s  fade-in-bottom col-md-6 col-sm-6 col-12 p-3">
<div className="feature-item shadow d-flex p-3 br-4">
     <img src={group}/>
<div>
<h5>Build Professional Contacts</h5>
<p>Work with industry leaders</p>
</div>
</div>
</div>



<div className="col-lg-4  delay4s  fade-in-bottom col-md-6 col-sm-6 col-12 p-3">
<div className="feature-item shadow d-flex p-3 br-4">
     <img src={clock}/>
<div>
<h5>Flexible Work Hours</h5>
<p>Work from anywhere & anytime</p>
</div>
</div>
</div>







<div className="col-lg-4 delay7s  fade-in-bottom col-md-6 col-sm-6 col-12 p-3">
<div className="feature-item shadow d-flex p-3 br-4">
     <img src={placement}/>
<div>
<h5>Placement trainings</h5>
<p>We make you job ready</p>
</div>
</div>
</div>



<div className="col-lg-4 delay8s  fade-in-bottom col-md-6 col-sm-6 col-12 p-3">
<div className="feature-item shadow d-flex p-3 br-4">
     <img src={support}/>
<div>
<h5>Placement Support</h5>
<p>We help you land your dream job</p>
</div>
</div>
</div>



<div className="col-lg-4  delay9s  fade-in-bottom col-md-6 col-sm-6 col-12 p-3">
<div className="feature-item shadow d-flex p-3 br-4">
     <img src={assignments}/>
<div>
<h5>Career Guidance</h5>
<p>We help you grow in your career</p>
</div>
</div>
</div>










</div>
    </div>
  )
}

export default BenifitsList