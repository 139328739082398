import React from 'react'
import VideoItem from './VideoItem'

const VideosList = ({videos,account}) => {
  return (
    <>

{videos.map((video)=>(


<>
<div className="p-2">
     <VideoItem account={account} video={video} />
</div>

</>
))}



    </>
  )
}

export default VideosList