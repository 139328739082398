import axios from 'axios'
import ENDPOINT from '../../data/ENDPOINT'
import SecurityHeaders from '../../data/SecurityHeaders'

const  GetCourseQuiz = async ({payload})=>{

const data = new FormData()


 
     
     
          data.append("session",payload.user)
       data.append("test_code",payload.test_code)
if(payload.quiz_id){
     data.append("quiz_id",payload.quiz_id)
}
if(payload.test_type){
     data.append("test_type",payload.test_type)
}
if(payload.course_url){
     data.append("course_url",payload.course_url)
}
const res = await 
axios.post(ENDPOINT+`course-quiz-questions.php`,data,SecurityHeaders)
if(res){
    
     return res.data
}

}



export default GetCourseQuiz