import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';

import CircularProgress from '../../dashboard/CircularProgress';
import dateFormat from 'dateformat';
import { Alert } from '@mui/material';
export default function ProgressTracker({progress,journey}) {
  return (
    <div>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>
               <div className="d-flex align-items-center">
               <CircularProgress value={progress}/> 
           <h5 className="pb-0 ml-15">Course Progress</h5>
               </div>
            
                  </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          
          









          <React.Fragment>
      <Timeline position="alternate">




       {journey.enrolled_on?
       <>
        
        <TimelineItem>
          <TimelineOppositeContent className="sm-12"  color="text.secondary">
           {dateFormat(journey.enrolled_on,"dS mmm yy HH:MM tt")}
          </TimelineOppositeContent>
          <TimelineSeparator >
            <TimelineDot  className="bg-primary" />
            <TimelineConnector  className="bg-primary" />
          </TimelineSeparator>
          <TimelineContent className="sm-14">Registered the course</TimelineContent>
        </TimelineItem>
     {journey.interacted_on?<>
     
     </>:
     <>
     
     <div className="d-flex justify-content-center mt-4">

<Alert severity="info">Next Step : Interactive session with your trainer</Alert>
</div>

     </>}
       </>:
       <>
       
       </>}





       {journey.interacted_on?
       <>
        
        <TimelineItem>
          <TimelineOppositeContent className="sm-12"  color="text.secondary">
           {dateFormat(journey.interacted_on,"dS mmm yy HH:MM tt")}
          </TimelineOppositeContent>
          <TimelineSeparator >
            <TimelineDot  className="bg-primary" />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent className="sm-14">Interacted with trainer</TimelineContent>
        </TimelineItem>

      {journey.started_training?
      <>
      
      </>:
      <>
        <div className="d-flex justify-content-center mt-4">

<Alert severity="info">Next Step : Start your training program</Alert>
</div>
      
      </>}
       </>:
       <>
       
       </>}


      

       {journey.started_training?
       <>
        
        <TimelineItem>
          <TimelineOppositeContent className="sm-12"  color="text.secondary">
           {dateFormat(journey.started_training,"dS mmm yy HH:MM tt")}
          </TimelineOppositeContent>
          <TimelineSeparator >
            <TimelineDot  className="bg-primary" />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent className="sm-14">Started training</TimelineContent>
        </TimelineItem>
       {journey.professional_interaction?
       <>
       
       
       </>:
       <>
        <div className="d-flex justify-content-center mt-4">

<Alert severity="info">Next Step : Attend group discussions</Alert>
</div>
       
       </>}

       </>:
       <>
       
       </>}





       {journey.professional_interaction?
       <>
        
        <TimelineItem>
          <TimelineOppositeContent className="sm-12"  color="text.secondary">
           {dateFormat(journey.professional_interaction,"dS mmm yy HH:MM tt")}
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent className="sm-14">Professional Interaction</TimelineContent>
        </TimelineItem>

      {journey.group_discussion?
      <>
      
      </>
      :
      <>
        <div className="d-flex justify-content-center mt-4">

<Alert severity="info">Next Step : Work as a team leader for your course batch</Alert>
</div>
      </>}
       </>:
       <>
       
       </>}






       {journey.group_discussion?
       <>
        
        <TimelineItem>
          <TimelineOppositeContent className="sm-12"  color="text.secondary">
           {dateFormat(journey.group_discussion,"dS mmm yy HH:MM tt")}
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent className="sm-14">Attend group discussion</TimelineContent>
        </TimelineItem>
      {journey.lead_team?
      <>
      
      </>:
      <>
        <div className="d-flex justify-content-center mt-4">

<Alert severity="info">Next Step : Interactive session with a working professional of a company</Alert>
</div>
      </>}

       </>:
       <>
       
       </>}





       {journey.lead_team?
       <>
        
        <TimelineItem>
          <TimelineOppositeContent className="sm-12"  color="text.secondary">
           {dateFormat(journey.lead_team,"dS mmm yy HH:MM tt")}
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent className="sm-14">Work as team leader</TimelineContent>
        </TimelineItem>
       {journey.completed_assignments?
       <>
       
       </>:
       <>
        <div className="d-flex justify-content-center mt-4">

<Alert severity="info">Next Step : Complete course assignments</Alert>
</div>
       </>}

       </>:
       <>
       
       </>}



       {journey.completed_assignments?
       <>
        
        <TimelineItem>
          <TimelineOppositeContent className="sm-12"  color="text.secondary">
           {dateFormat(journey.completed_assignments,"dS mmm yy HH:MM tt")}
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent className="sm-14">Completed assignments</TimelineContent>
        </TimelineItem>
       {journey.completed_projects?
       <>
       
       
       </>:
       <>
        <div className="d-flex justify-content-center mt-4">

<Alert severity="info">Next Step : Complete your realtime projects</Alert>
</div>

       </>}
       </>:
       <>
       
       </>}





       {journey.completed_projects?
       <>
        
        <TimelineItem>
          <TimelineOppositeContent className="sm-12"  color="text.secondary">
           {dateFormat(journey.completed_projects,"dS mmm yy HH:MM tt")}
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent className="sm-14">Completed projects</TimelineContent>
        </TimelineItem>

      {journey.completed_placement_training?
      <>
      
      </>:
      <>
        <div className="d-flex justify-content-center mt-4">

<Alert severity="info">Next Step : Attend placement trainings</Alert>
</div>
      </>}
       </>:
       <>
       
       </>}


       



       {journey.completed_placement_training?
       <>
        
        <TimelineItem>
          <TimelineOppositeContent className="sm-12"  color="text.secondary">
           {dateFormat(journey.completed_placement_training,"dS mmm yy HH:MM tt")}
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent className="sm-14">Completed placement training</TimelineContent>
        </TimelineItem>

     {journey.final_certification_date?
     <>
     
     </>:
     <>
        <div className="d-flex justify-content-center mt-4">

<Alert severity="info">Next Step : Attempt certification exam</Alert>
</div>
     </>}
       </>:
       <>
       
       </>}





       {journey.final_certification_date?
       <>
        
        <TimelineItem>
          <TimelineOppositeContent className="sm-12"  color="text.secondary">
           {dateFormat(journey.final_certification_date,"dS mmm yy HH:MM tt")}
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent className="sm-14">Final certification date</TimelineContent>
        </TimelineItem>

        <div className="d-flex justify-content-center mt-4">

<Alert severity="info">Next Step : Get internship/placement opportunity</Alert>
</div>
       </>:
       <>
       
       </>}


       



      </Timeline>



    </React.Fragment>











          </Typography>
        </AccordionDetails>
      </Accordion>
      
      
    </div>
  );
}
