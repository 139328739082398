import axios from 'axios'
import ENDPOINT from '../data/ENDPOINT'
import SecurityHeaders from '../data/SecurityHeaders'

const GetCourseBatch = async ({payload})=>{

const data = new FormData()
data.append("course_url",payload.course_url);
data.append("batch_id",payload.batch_id)
if(payload.limit){
     data.append("limit",payload.limit)
}
data.append("session",payload.user)
const res = await 
axios.post(ENDPOINT+`my-team.php`,data,SecurityHeaders)
if(res){
    
     return res.data











}

}




//payload = [{"title":"this is product","price":456},{"title":"this is product 2","price":870}]




export default GetCourseBatch