import axios from 'axios'
import ENDPOINT from '../../data/ENDPOINT'
import SecurityHeaders from '../../data/SecurityHeaders'

const GetCourseMilestones = async ({payload})=>{
    
const data = new FormData()
data.append("course_url",payload.course_url)
data.append("batch_id",payload.batch_id)
data.append("session",payload.user)
const res = await 
axios.post(ENDPOINT+`get-course-milestones.php`,data,SecurityHeaders)
if(res){
    
     return res.data
}

}



export default GetCourseMilestones