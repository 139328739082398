import React from 'react'

import resetimage from '../../assets/reset-password.svg'
import { useRef } from 'react'
import { useState } from 'react'
import TextField from '@mui/material/TextField';
import ENDPOINT from '../../data/ENDPOINT'
import { Modal,Button, Toast, Form } from 'react-bootstrap'
import { Helmet } from 'react-helmet';
const PasswordReset = ({props}) => {



const [email,setemail] = useState(null)
const password = useRef();
const confpassword = useRef()
const code = useRef()
const [toast,settoast] = useState(false);
const [input,setinput] =  useState(false)

const [toastTitle,settoastTite] = useState(null);
const [toastMessage,settoastMessage] = useState(null);

const closeToast = ()=>{
    settoast(false);
}

const  resetPassword = async ()=>{



  fetch(`${ENDPOINT}reset-password-otp.php?email=${email}`)
  .then(r=>r.json())
  .then(result=>{
         
     
          if(result[0].status==="success"){
           
            if(result[0].message==="sent"){
              setinput(true)
              settoastTite("Success !");
              settoastMessage("OTP sent");
              settoast(true);
            }
            else if(result[0].message==="otp failed"){
              settoastTite("Sending OTP failed !");
              settoastMessage("Please try again");
              settoast(true);
            }
            else if(result[0].message==="log error"){
              settoastTite("Something went wrong !");
              settoastMessage("Please try again");
              settoast(true);
            }
            else if(result[0].message==="no email"){
              settoastTite("No Email found !");
              settoastMessage("Enter correct email address.");
              settoast(true);
            }
          }
      else{
    
        
         settoastTite("Error !");
         settoastMessage("Something went wrong. Please try again.");
         settoast(true);
             }
  
  

      
  })
  
  }    ;













  const  changePassword = async (e)=>{
e.preventDefault()
 //  //console.log(email)
    fetch(`${ENDPOINT}change-pasword.php?email=${email}&password=${password.current.value}&code=${code.current.value}`)
     
    .then(r=>r.json())
    .then(result=>{
         
         if(result[0].status==="success"){
          settoastTite("Password Changed !");
          settoastMessage("Login into your account");
          settoast(true);
          window.location.replace("/login");
         }  
   else if((result[0].status==="error")&&(result[0].message==="invalid otp")){
    settoastTite("Invalid OTP !");
    settoastMessage("Please enter correct OTP");
    settoast(true);
   }
   else if((result[0].status==="error")&&(result[0].message==="reset error")){
    settoastTite("Something went wrong !");
    settoastMessage("Please try again");
    settoast(true);
   }
   else{
    settoastTite("Something went wrong !");
    settoastMessage("Please try again");
    settoast(true);
   }
        
    })
    
    }    ;






    return (
       <>
         <Helmet>
    <title>Change or reset your skills uprise account password</title>
    <meta name="description" content="Login back into your skills uprise account. Change or rest your password"/>
    <meta name="keywords" content="change or reset skills uprise password"/>
    </Helmet>  
       <div>
               <div className="flex-wrap d-flex pt-5 pb-5 justify-content-center min-vh-100">

<div className="col-lg-5 col-md-4 col-sm-5 text-center">
<img src={resetimage} className="col-lg-9 col-md-10 col-sm-9 col-7" alt=""></img>

</div>

<div className="col-lg-3 sm-p-15 col-md-4 col-sm-5 col-12">
<h2 className="page-title">Reset Your Password</h2>
<div className=" mt-3 reset-border p-0 col-lg-11 col-sm-12 col-md-11  pr-0 col-12">

<form onSubmit={(e)=>{e.preventDefault();resetPassword()}}>
<TextField

label="Email"
type="email"

value={email}
onChange={(e)=>setemail(e.target.value)}
required

className=" col-sm-6 w-100"
minLength={10}
maxLength={100}
/>
<br/>

<button type="submit" className="btn w-100 mt-3 btn-primary  border-0" >Reset Password</button>


</form>


</div>




<div className="info mt-5">
<h6>Have trouble resetting your password ?</h6>
<a href="/contact" to="">Contact Support</a>

</div>



</div>


               </div>
               


               <Modal
      {...props}
     
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      keyboard={false}
      show = {input}
    >

               
      <Modal.Body>
      <div>
          <h5>Change Password</h5>
<p>We have sent an OTP to your email address</p>
          </div>
      <Form>
  <Form.Group className="mb-3" controlId="formBasicEmail">
    <Form.Control type="number" ref={code} placeholder="Enter 6 digit OTP" minLength={6} maxLength={6} />
  </Form.Group>

  <Form.Group className="mb-3" controlId="formBasicPassword">
    <Form.Control type="password" ref={password} placeholder="New Password" minLength={6} maxLength={30}  />
  </Form.Group>

  <Form.Group className="mb-3" controlId="formBasicPassword">
    <Form.Control type="password" ref={confpassword} placeholder="Confirm New Password" minLength={6} maxLength={30}  />
  </Form.Group>

  <Button variant="primary" className="w-100" type="submit" onClick={changePassword}>
    Change Password
  </Button>
</Form>
      </Modal.Body>
      
    </Modal>










    <div className="position-absolute fixed-bottom justify-center justify-content-center right-0 toast-container">

<div  className="mb-2 col-lg-3 col-md-3 col-sm-4 col-10">
         
         <Toast show={toast} onClose={closeToast} delay={5000} autohide className="space-between bg-warning">
           <Toast.Header>
             <img
               src="holder.js/20x20?text=%20"
               className="rounded me-2"
               alt=""
             />
             <strong className="me-auto">{toastTitle}</strong>
            
           </Toast.Header>
           <Toast.Body>{toastMessage}</Toast.Body>
         </Toast>
       </div>
       

</div>


               
               </div> 
       </>
    )
}

export default PasswordReset
