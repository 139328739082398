import axios from 'axios'
import ENDPOINT from '../../data/ENDPOINT'
import SecurityHeaders from '../../data/SecurityHeaders'

const  Contact = async ({payload})=>{

const data = new FormData()


 
     data.append('name',payload.username);    
     data.append('email',payload.email);
       data.append('phone',payload.phone); 
       data.append('subject',payload.subject);  
       data.append('message',payload.message); 
       if(payload.user){
          data.append("session",payload.user)
       }

const res = await 
axios.post(ENDPOINT+`send-message.php`,data,SecurityHeaders)
if(res){
    
     return res.data[0]
}

}




export default Contact