
import defaultboy from '../../assets/user.png'
import React, { useState } from 'react'
import axios from 'axios'
import ENDPOINT from '../../data/ENDPOINT'
import { useContext } from 'react';
import { AuthContext } from '../../context/AuthContext';
import { Link, useParams } from 'react-router-dom';
import {useEffect} from 'react'
import Localbase from 'localbase';
import UserProfileBasic from '../account/UserProfileBasic';
import ConnectionsList from './ConnectionsList';
import UserAccount from '../account/UserAccount';
import ConnectionItem from './ConnectionItem';
import ConnectionsTemplate from './ConnectionsTemplate';
import SetPage from '../../data/SetPage';
const RequestsReceivedPage = ({post,account}) => {
const params = useParams()
const type = params.type;
const {user}= useContext(AuthContext)


const [connections,setconnections] = useState(null)
let db = new Localbase("db")
    const getConnections = async ()=>{
        

        const formData = new FormData();
        formData.append('session',user.SessionId);
        

        
        const res = await axios.post(ENDPOINT+"requests-received.php", formData,{
            headers: {
                'content-type': 'multipart/form-data'
            }
        });
        if(res){
        //  //console.log("conn "+JSON.stringify(res))
         if(res.data.status==="success" && res.data.followers!==null){
setconnections(res.data.followers)

         }
         else if(res.data.status==="success" && res.data.data===null){
      
         }
        }
        }




useEffect(()=>{
   // //  //  //console.log("getting conn")



getConnections()

},[type])


useEffect(()=>{
    SetPage("Requests received")
    },[])









const startConversation = async ({status,receiver_id,initial_id,e})=>{
    sessionStorage.setItem('cid',initial_id)




                const formData = new FormData();
                
                
                //formData.append('whyus',document.getElementById('whyus').value)
                
                formData.append('session',user[0].SessionId);
      
               formData.append('receiver_id',receiver_id);
             
                const res = await axios.post(ENDPOINT+"start-conversation.php", formData,{
                    headers: {
                        'content-type': 'multipart/form-data'
                    }
                });
                if(res){
                   if(res.data.status==="success"){
                     

                 // //  //  //console.log("conversation started");
                 }
                 else{
                
                 }
                }
                }








                const updateConnection = async (status,connection)=>{
             
     
                    const formData = new FormData();
                    
                    
                    //formData.append('whyus',document.getElementById('whyus').value)
                    
                    formData.append('session',user[0].SessionId);
                    
                      formData.append('id',connection.id);
                      formData.append('status',"pending");
                    
                    const res = await axios.post(ENDPOINT+"update-connection-request.php", formData,{
                        headers: {
                            'content-type': 'multipart/form-data'
                        }
                    });
                    if(res){
                      if(res.data.status==="success"){
                
                     }
                     else if(res.data.status==="error"){
                     
                     }
                    }
                    }
                    const [access,setaccess] = useState(false)
        useEffect(()=>{
if(account){
    if(account.purchases!==null){
        if(account.purchases.length>0){

            setaccess(true)
        }
    }
}
 //  //console.log("Access is "+access)
        },[account])       

    return (
        <>
<ConnectionsTemplate>


<div className="col-lg-8 pb-5">

{connections?
<>

<div className="grid grid-2 sm-grid-4 lg-grid-5">
<ConnectionsList connections={connections} account={account} access={access}/>


</div>

</>:<>



<div className="w-100 justify-content-center d-flex pt-5">


<div className="col-lg-7 text-center">
<h4>No Pending Requests</h4>
<p>Register a course to connect with students from various colleges across the globe.</p>


</div>

</div>
</>}

</div>

 
</ConnectionsTemplate>
        
        </>
    )
}

export default RequestsReceivedPage
