
import defaultboy from '../../assets/user.png'
import React, { useState } from 'react'
import axios from 'axios'
import ENDPOINT from '../../data/ENDPOINT'
import { useContext } from 'react';
import { AuthContext } from '../../context/AuthContext';
import { Link, useParams } from 'react-router-dom';
import {useEffect} from 'react'
import Localbase from 'localbase';
import Alert from '@mui/material/Alert';
import ConnectionItem from './ConnectionItem';
import ConnectionsTemplate from './ConnectionsTemplate';
import SecurityHeaders from '../../data/SecurityHeaders';
import SetPage from '../../data/SetPage';
import { Helmet } from 'react-helmet';

const Connections = ({post,account,access,limit}) => {
const params = useParams()

const {user}= useContext(AuthContext)


const [connections,setconnections] = useState(null)
let db = new Localbase("db")



useEffect(()=>{
    if(limit){
    setnewlimit(limit) 
    
    }
    else{
        setnewlimit(20)
    }

},[limit])
const [newlimit,setnewlimit] = useState(limit)
    const getConnections = async ()=>{
        

        const formData = new FormData();
        formData.append('session',user.SessionId);
        
        
        formData.append("limit",newlimit)
        
        const res = await axios.post(ENDPOINT+"connections.php", formData,SecurityHeaders);
        if(res){
         //console.log("connections "+JSON.stringify(res))
         if(res.data.status==="success" && res.connections!==null){
setconnections(res.data.connections)

         }
       
        }
        }



useEffect(()=>{
    SetPage("Recommendations")
    getConnections()

},[])
















    const [alert,setalert] = useState(false)       

    return (
        <>


<Helmet>‍
        <title>Connections - Skills Uprise</title>‍
        <meta name="description" content="Skills Uprise" />       
        <meta name="twitter:card" content="" />   
        <meta name="twitter:site" content="@skillsuprise" />    
        <meta name="twitter:creator" content="@skillsuprise" />                
        <meta name="twitter:title" content="" />   
        <meta name="twitter:description" content="" />  
        <meta name="twitter:image" content=""/>     
        <meta property="og:title" content="" />  
        <meta property="og:description" content="" />        
        <meta property="og:image" content=""/>
        <meta property="og:url" content=""/>
        <meta property="og:site_name" content="Skills Uprise" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta property="fb:app_id" content="ID_APP_FACEBOOK" />
      </Helmet>

{connections?
<>

{connections.map((connection)=>(
    <>
    
   <ConnectionItem className="connection-item"  key={connection.user_id} access={access} account={account} connection={connection}/> 
    
    </>
))}


</>:null}




        
        </>
    )
}

export default Connections
