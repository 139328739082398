import axios from 'axios'
import ENDPOINT from '../../data/ENDPOINT'
import SecurityHeaders from '../../data/SecurityHeaders'

const  LikePost = async ({payload})=>{

const data = new FormData()


     data.append("post_id",payload.post_id)

data.append("session",payload.user)
const res = await 
axios.post(ENDPOINT+`like-student-post.php`,data,SecurityHeaders)
if(res){
    
     return res.data
}

}


export default LikePost