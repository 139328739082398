import axios from 'axios'
import ENDPOINT from '../../data/ENDPOINT'
import SecurityHeaders from '../../data/SecurityHeaders'

const PostComment = async ({payload})=>{

const data = new FormData()


     data.append("comment",payload.comment)

data.append("session",payload.user)
data.append("course_url",payload.course_url)
data.append("post_id",payload.post_id)
const res = await 
axios.post(ENDPOINT+`add-student-comment.php`,data,SecurityHeaders)
if(res){
    
     return res.data
}

}


export default PostComment