import React, { useEffect, useState } from 'react'
import CoursesList from './CoursesList'
import SetPage from '../../data/SetPage'
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import ModalStyle from '../../data/ModalStyle'
import Close from '@mui/icons-material/Close'
import { PlayCircle } from '@mui/icons-material'
import Box from '@mui/material/Box';
import { Helmet } from 'react-helmet';
const SpecializationCourses = () => {
  
useEffect(()=>{
  SetPage("Specialization Courses")
  window.scrollTo(0,0)
  },[])
  

  const [demo,setdemo] = useState(false)
  return (

    <>

<Helmet>‍
        <title>Job Guaranteed Courses & Training Programs, Specialization Courses - Skills Uprise</title>‍
        <meta name="description" content="Unlock Your Career Potential with Our 3-4 Month Courses: Guaranteed Internships and Placement Opportunities Included. Elevate Your Skills with Skills Uprise and Land your dream job today." />       
        <meta name="twitter:card" content="" />   
        <meta name="twitter:site" content="@skillsuprise" />    
        <meta name="twitter:creator" content="@skillsuprise" />                
        <meta name="twitter:title" content="" />   
        <meta name="twitter:description" content="" />  
        <meta name="twitter:image" content=""/>     
        <meta property="og:title" content="" />  
        <meta property="og:description" content="" />        
        <meta property="og:image" content=""/>
        <meta property="og:url" content=""/>
        <meta property="og:site_name" content="Skills Uprise" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta property="fb:app_id" content="ID_APP_FACEBOOK" />
      </Helmet>
<Modal
  open={demo}
  onClose={()=>setdemo(false)}

>
  <Box sx={ModalStyle}  className="col-lg-4 col-md-5 br-5 col-sm-6 col-11 p-2">
<div className="d-flex justify-content-between">
     <h4>Introduction</h4>
     <Close className="text-danger" type="button" onClick={()=>setdemo(false)}/>
</div>
    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
    <video  poster="https://skillsuprise.com/data/images/specialization-trainings-poster.png"  className="w-100" autoPlay src="https://skillsuprise.com/data/videos/specialization-demo.mp4"></video>
    </Typography>
  </Box>
</Modal>

    <div className="specialization-crumb pb-5 bg-dark">

<div className="container  d-flex justify-content-between flex-wrap text-white pt-2 pt-md-5">
 

<div className="col-lg-5 col-12 col-sm-12 sm-mt-10">

<img src="https://skillsuprise.com/resources/images/specialization-trainings.png" className="w-100 border border-2 border-secondary rounded"/>

</div>

 <div className="col-lg-7 sm-mt-30 col-12 ps-0 ps-md-3">
 <h1>Specialization Courses</h1>
  <div className=" mt-2">
    <hr/>
<h5 className="pb-0 mb-0">3-4 months long courses with guaranteed internship & placement opportunities to help you excel in your career.</h5>
  
<button onClick={()=>setdemo(true)}  className="btn btn-warning rounded-pill mt-3"><PlayCircle/> Watch Demo</button>
  
  </div>

 </div>
 



</div>


</div>
<div className="container sm-p-0 pb-5 mb-5">


<CoursesList mode={"specialization"}/>
</div>
 
    
    </>

  )
}

export default SpecializationCourses