import { Avatar, Skeleton } from '@mui/material'
import React, { useState } from 'react'
import { useEffect } from 'react'
import { useContext } from 'react'
import { Link, useParams } from 'react-router-dom'
import GetCourseBatch from '../../actions/GetCourseBatch'
import { AuthContext } from '../../context/AuthContext'
import Localbase from 'localbase'
import axios from 'axios'

import ENDPOINT from '../../data/ENDPOINT'
import SecurityHeaders from '../../data/SecurityHeaders'
import InfoIcon from '@mui/icons-material/Info';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
const CommunityLeaderboard = () => {

const params = useParams()
     const course_url = params.course_url

let db = new Localbase('db')
const {user} = useContext(AuthContext)
const [batch,setbatch] = useState(null)
const GetBatch = async()=>{
setloading(true)
     const data = new FormData()
     data.append("session",user.SessionId)
     const res = await axios.post(ENDPOINT+"community-leaderboard.php",data,SecurityHeaders)
     if(res){
          setloading(false)
           //  //console.log("community  "+JSON.stringify(res))
          if(res.data.status==="success"&&res.data.data!==null){

setbatch(res.data.data)

db.collection("paths").add({
     comunity_leaderboard:res.data.data
},'community_leaderboard')
          }
        
     }
}


useEffect(()=>{

db.collection("paths").doc("batch").get().then(batch=>{
setbatch(batch.community_leaderboard)
}).catch(error=>{

})

GetBatch()
},[])

const [loading,setloading] = useState(true)
  return (
    <div className="w-100 section pt-0 mt-0">
   
    {batch?
    <>
     
    
  {loading?
  <>
  
  </>:
  <>
     <div className="p-2 list-header d-flex justify-content-between">

<div className="d-flex align-items-center">
<h5 className="mr-5">Community Toppers</h5>
<Tooltip className="bg-white" disableFocusListener title={
     <>
     <div className="p-0 bg-white">
<div className="p-2">
<p>Share images, memes, polls, knowledgeable resources and win cash rewards every week.</p>
    
</div>
     </div>
     </>
}><InfoIcon className="text-primary click"/>
          </Tooltip>
</div>

     </div>
  
  </>}
     <hr className="mt-0"/>
    {batch.map((user,index)=>(

     <>
 <div className="d-flex w-100 justify-content-between align-items-center mt-3 mb-3">
    <div className="d-flex align-items-center text-dark col">
    <Avatar src={user.profile_pic} className="mr-5"/>
     <div>
          <h6 className="pb-0 truncate truncate1">{user.first_name+" "+user.last_name}</h6>
          <p className="truncate truncate1 text-grey extra-small">@{user.username}</p>
     </div>
    </div>
    <div className="mr-15 text-grey">
    <p>Rank <b className="text-primary">#{index+1}</b></p>
    <p className="extra-small">{user.marks?user.marks:0} points</p>
    </div>
    
       </div>
     





     </>
    ))}

    </>:
    
    
    <>
    
{loading?
<>
<div className="d-flex mt-1 w-100 b-1">
     <Avatar/>
     <div className="ml-1 w-100">
<Skeleton height={20} className=" mb-1 w-100"/>

<Skeleton height={10} className=" mb-1 w-100"/>

     </div>
</div>



<div className="d-flex mt-1 w-100 b-1">
     <Avatar/>
     <div className="ml-1 w-100">
<Skeleton height={20} className=" mb-1 w-100"/>

<Skeleton height={10} className=" mb-1 w-100"/>

     </div>
</div>



<div className="d-flex mt-1 w-100 b-1">
     <Avatar/>
     <div className="ml-1 w-100">
<Skeleton height={20} className=" mb-1 w-100"/>

<Skeleton height={10} className=" mb-1 w-100"/>

     </div>
</div>



<div className="d-flex mt-1 w-100 b-1">
     <Avatar/>
     <div className="ml-1 w-100">
<Skeleton height={20} className=" mb-1 w-100"/>

<Skeleton height={10} className=" mb-1 w-100"/>

     </div>
</div>

<div className="d-flex mt-1 w-100 b-1">
     <Avatar/>
     <div className="ml-1 w-100">
<Skeleton height={20} className=" mb-1 w-100"/>

<Skeleton height={10} className=" mb-1 w-100"/>

     </div>
</div>

</>

:
<>

<h4>No Students Found !</h4>
    <p>It seems like no student have joined the community yet. </p>
  

</>}

  </>
    
    }


    
     

    </div>
  )
}

export default CommunityLeaderboard