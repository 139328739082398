
import React, { useState } from 'react'
import axios from 'axios'
import ENDPOINT from '../../data/ENDPOINT'
import { useContext } from 'react';
import { AuthContext } from '../../context/AuthContext';
import { Link, useParams } from 'react-router-dom';
import {useEffect} from 'react'
import MainSidebanner from '../community/MainSidebanner';
import DetectDevice from '../../data/DetectDevice';
import { Chip } from '@mui/material';
import CourseSuggestions from '../community/CourseSuggestions';

const ConnectionsTemplate = ({post,account,children}) => {
const params = useParams()
const type = params.type;
const {user}= useContext(AuthContext)


const [connections,setconnections] = useState(null)

    const getConnections = async (account)=>{
        

        const formData = new FormData();
        formData.append('session',user.SessionId);
        
          formData.append('type',type);
        
        
        const res = await axios.post(ENDPOINT+"followers.php", formData,{
            headers: {
                'content-type': 'multipart/form-data'
            }
        });
        if(res){
        //  //console.log("conn "+JSON.stringify(res))
         if(res.data.status==="success" && res.data.followers!==null){
setconnections(res.data.followers)
         }
         else if(res.data.status==="success" && res.data.data===null){
      
         }
        }
        }




useEffect(()=>{
   // //  //  //console.log("getting conn")
    setconnections(null)
getConnections()

},[type])












const startConversation = async ({status,receiver_id,initial_id,e})=>{
    sessionStorage.setItem('cid',initial_id)




                const formData = new FormData();
                
                
                //formData.append('whyus',document.getElementById('whyus').value)
                
                formData.append('session',user[0].SessionId);
      
               formData.append('receiver_id',receiver_id);
             
                const res = await axios.post(ENDPOINT+"start-conversation.php", formData,{
                    headers: {
                        'content-type': 'multipart/form-data'
                    }
                });
                if(res){
                   if(res.data.status==="success"){
                     

                 // //  //  //console.log("conversation started");
                 }
                 else{
                
                 }
                }
                }








                const updateConnection = async (status,connection)=>{
             
     
                    const formData = new FormData();
                    
                    
                    //formData.append('whyus',document.getElementById('whyus').value)
                    
                    formData.append('session',user[0].SessionId);
                    
                      formData.append('id',connection.id);
                      formData.append('status',"accepted");
                    
                    const res = await axios.post(ENDPOINT+"update-connection-request.php", formData,{
                        headers: {
                            'content-type': 'multipart/form-data'
                        }
                    });
                    if(res){
                      if(res.data.status==="success"){
                
                     }
                     else if(res.data.status==="error"){
                     
                     }
                    }
                    }
            
               const device = DetectDevice()
                    useEffect(()=>{
setpage(JSON.parse(sessionStorage.getItem("page")))
                    },[window.location])


const [page,setpage] = useState( window.location.pathname.replace("/",""))
                    useEffect(()=>{

setpage(window.location.pathname.replace("/",""))
                    },[window.location.pathname])
    return (
        <>
        
<div className="container-xl pb-5 mx-auto flex-wrap space-evenly min-vh-100">

<div className="flex-wrap d-block d-sm-flex d-lg-flex mt-3 justify-content-between  d-md-flex w-100">


{device==="mobile"?
<>
<div className="d-flex overflow-x p-2">
  <Link to="/recommendations">
  <Chip size="small" 
    className={page==="Recommendations"?"bg-primary text-white mr-15":" mr-15"}
    label="Recommendations"/>

</Link>
<Link to="/followers">
<Chip size="small" 
    className={page==="followers"?"bg-primary text-white  mr-15":" mr-15"}
    label="Followers"/>
</Link>



<Link to="/following">
<Chip size="small" 
    className={page==="following"?"bg-primary text-white mr-15":" mr-15"}
    label="Following"/>
</Link>



    <Link to="/requests-sent">
    <Chip size="small" 
    className={page==="requests-sent"?"bg-primary text-white  mr-15":" mr-15"}
    label="Requests sent"/>

    </Link>


<Link to="/requests-received">
<Chip size="small" 
    className={page==="requests-received"?"bg-primary text-white  mr-15":" mr-15"}
    label="Requests received"/>
</Link>


</div>
</>:<>

<div className="col-lg-3 sm-mb-20 h-fit col-12 col-sm-12 col-md-3">
<div className="border br-5 p-3">
    <Link to="/recommendations">
    <div className={page==="recommendations"?"active-side-option":"side-option"}>
    <h6 className="mb-0 pb-0">Recommendations</h6>
    </div>
    </Link>

    <Link to="/followers">
    <div className={page==="followers"?"active-side-option":"side-option"}>
    <h6  className="mb-0 pb-0">Followers</h6>
    </div>
    </Link>
    <Link to="/following">
    <div className={page==="following"?"active-side-option":"side-option"}>
    <h6 className="mb-0 pb-0">Following</h6>
    </div>
    </Link>

    <Link to="/requests-sent">
    <div className={page==="requests-sent"?"active-side-option":"side-option"}>
    <h6 className="mb-0 pb-0">Requests sent</h6>
    </div>
    </Link>
    <Link to="/requests-received">
    <div className={page==="requests-received"?"active-side-option":"side-option"}>
    <h6 className="mb-0 pb-0">Requests received</h6>
    </div>
    </Link>



</div>



<div className="d-none d-md-block mt-5">
<CourseSuggestions limit={4}/>
</div>

</div>


</>}

<>


{children}


</>
</div>






</div>
        
        </>
    )
}

export default ConnectionsTemplate
