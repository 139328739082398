import React from 'react'
import ContactSection from '../../components/ContactSection';
import { useContext } from 'react';
import { AuthContext } from '../../context/AuthContext';
import ENDPOINT from '../../context/ENDPOINT';
import { useState } from 'react';
import { useEffect } from 'react';
import CampusAmbassador from './CampusAmbassador';
import SetPage from '../../data/SetPage';
import { Link } from 'react-router-dom/cjs/react-router-dom';

const AmbassadorDashboard = ({account}) => {
    
    const {user} =  useContext(AuthContext);
    const userdata = user;

    useEffect(() => {
      window.scrollTo(0, 0)
    }, [])
  


const loginRedirect = ()=>{

sessionStorage.setItem("next","/campus-ambassador-internship")
sessionStorage.setItem("msg","Login into your account to apply for campus ambassador internship");

}


const [loginprompt,setloginprompt] = useState(false)


        const  apply = async ()=>{
    
            fetch(`${ENDPOINT}cai-register.php?session=${userdata.SessionId}`)
            .then(r=>r.json())
            .then(result=>{
            
                
                
            if(result.status==="success"){
                
window.location.reload();
            }
            
            
                
            })
            
            }    ;
 
           
   
const [ambassador,setambassador] = useState(null);
const [cai,setcai] = useState(false)
const [caistatus,setcaistatus] = useState(null);
const [referrals,setreferrals] = useState(null);
const [caiaccount,setcaiaccount] = useState(null)
useEffect(()=>{

    const  checkAmbassador = async ()=>{

        fetch(`${ENDPOINT}check-ambassador.php?session=${userdata.SessionId}`)
        .then(r=>r.json())
        .then(result=>{
        if(result){

           if(result.status==="success"){
                

                setcaistatus(result.data.status);
                if(result.data.status==="active"){
                 
if(result.data.code!==null){
 
    getReferrals();
    getLeaderboard();
getMilestones();
}

                }
          
                setambassador(true);
        
            }
          


        }
        
        
            
        })
        
        }    ;
        if(userdata){
            checkAmbassador();
           // //  //  //console.log("chacking ambassador")
        }

       
}

,[])



useEffect(() => {
SetPage("Campus Ambassador Internship")
}, [])




    const  getReferrals = async ()=>{

        fetch(`${ENDPOINT}get-referrals.php?session=${userdata.SessionId}`)
        .then(r=>r.json())
        .then(result=>{
        
            if(result.status==="success"){
              

setcaiaccount(result.data[0])
             //setreferrals(result.data[0].referrals)
                 }
          
        
        
            
        })
        
        }   


const [leaderboard,setleaderboard] = useState(null)

        const  getLeaderboard = async ()=>{

            fetch(`${ENDPOINT}ambassadors-leaderboard.php?session=${userdata.SessionId}`)
            .then(r=>r.json())
            .then(result=>{
            
                if(result.status==="success"){
                  

                    setleaderboard(result.data)
                     }
              
            
            
                
            })
            
            }  

            function titleCase(str) {
              return str.toLowerCase().replace(/\b(\w)/g, s => s.toUpperCase());
            }
            


const [milestones,setmilestones] = useState(null)
            const  getMilestones = async ()=>{

                fetch(`${ENDPOINT}ambassador-milestones.php?session=${userdata.SessionId}`)
                .then(r=>r.json())
                .then(result=>{
                
                    if(result.status==="success"){
                      
    
                        setmilestones(result.data)
                         }
                  
                
                
                    
                })
                
                }  



       
                const [show, setShow] = useState(false);

                const handleClose = () => setShow(false);
                const handleShow = () => setShow(true);

                const style = {
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  bgcolor: 'background.paper',
             
                  boxShadow: 24,
                  p: 4,
                };
            
  return (
    <div>

<div className=" min-vh-90">

    <div className=" ">



    <>

<div className=" mb-0">

         
{caistatus==="active"?
caiaccount?
<>

<CampusAmbassador cai={caiaccount} referrals={referrals} leaderboard={leaderboard} milestones={milestones} />


</>


:""
:caistatus==="applied"?

<div className="container-fluid d-flex flex-wrap pt-5 pb-5">
  <div className="col-lg-6 col-md-6 mx-auto col-sm-10 col-12 border-lg-1 border-md-1 border-sm-1 border-0">

  <div>
        <h3  className="text-center border-bottom pb-2 mb-2">Application Successful</h3>
    </div>
    <p className="mb">Your application will be reviewed shortly. 
    We have successfully received your application. It takes us 48 hours to review your application. 
    You will receive an email about the status of your application through your registered email.
    <br></br>
    <br></br>
    Please visit this page again after 48 hours to track the progress of your application.</p>


<div className="text-center mt-4">
<Link to="/internships" className="btn btn-primary">Browse Other Internships</Link>

</div>



  </div>

  
</div>
:
caistatus==="selected"?
<>
<div className="container-fluid flex-wrap d-flex pt-5 pb-5">
  <div className="col-lg-6  mx-auto text-center col-md-6 col-sm-12 mx-auto col-12">

  <div>
        <h1  className="text-center sm-22 border-bottom pb-2 mb-2">Congragulations !</h1>
    </div>
    <p className="mb-4 mt-3">Dear <b> {account?titleCase(account.first_name)+" "+titleCase(account.last_name):""} </b> you have been selected for the internship !</p>
   
 
    

  </div>



</div>

</>

:

caistatus==="rejected"?
<>
<div className="container-fluid flex-wrap d-flex pt-5 pb-5">
  <div className="col-lg-5 col-md-6 col-sm-6 mx-auto col-12">

  <div>
        <h3  className="textcenter border-bottom pb-2 mb-2">Application Rejected</h3>
    </div>
    <p className="mb-4 mt-3"><b className="mb-2">We are sorry !</b><br/>
    Your application cannot be processed further. Try applying to this position after 1 month or apply for other internships that suits your profile . Kindly check your email for a detailed information about your application status or Contact us at <a href="mailto:cai@skillsuprise.com">cai@skillsuprise.com</a> for more info regarding your Campus Ambassador Internships Status.</p>




  </div>



</div>


</>








:

caistatus==="denied"?


<>


<div className="container-fluid flex-wrap pt-5 pb-5">
  <div className="col-lg-6 mx-auto col-md-6 col-sm-10 col-12">

  <div>
        <h2  className="textcenter   mb-4">Account Inactive</h2>
  
  </div>
  <hr className="pt-2 pb-2"/>
    <p className="mb-3">You can no longer access this page. Contact us at <a href="mailto:cai@skillsuprise.com">cai@skillsuprise.com</a> for more info regarding your Campus Ambassador Internships Status.</p>






  </div>


 
</div>



</>







:
caistatus==="completed"?
<>

<div className="container-fluid flex-wrap pt-5 pb-5">
  <div className="col-lg-6 col-md-6 col-sm-10 col-12">

  <div>
        <h2  className="textcenter   mb-4">Wooohooo.....!</h2>
        <h2>You internship is complete.</h2>
  
  </div>
  <hr className="pt-2 pb-2"/>
 




  </div>


  <div className="col-lg-6">

  </div>
</div>
</>
:
<>


         

<div className=" mt-5 mb-5 pt-5  sm-p-15">




    </div>































    








</>




}

               </div>

</>
    </div>
</div>


    </div>
  )
}

export default AmbassadorDashboard