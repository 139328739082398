import React from 'react'
import Rating from '@mui/material/Rating';
import { Link } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import { BookSharp } from '@material-ui/icons';
import Box from '@mui/material/Box';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { useState } from 'react';
import ModalStyle from '../../data/ModalStyle';
import { Close } from '@mui/icons-material';
const NotesItem = ({course,slider,index,book,account}) => {

  const [alert,setalert] = useState(false)
  return (

    <>
    
    
<Modal
  open={alert}
  onClose={()=>setalert(false)}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
  <Box sx={ModalStyle} className="p-3 br-5 col-11 col-sm-8 col-md-4 col-lg-4">
   <div className="d-flex justify-content-between">
    <h4 className="text-danger">Access Denied !</h4>
    <Close className="click" onClick={()=>setalert(false)}/>
   </div>
   <hr/>
   <div>
   {account?<>
    <p>Register a course to access notes.</p>
   </>:<>
   <p>Signin into your account to access notes.</p>
   </>}
   
   </div>
   <div className="d-flex justify-content-between mt-4">
    <button onClick={()=>setalert(false)} className="btn col-5 btn-outline-danger rounded-pill">Cancel</button>
    {account?<>
      <Link to="/courses" className="btn col-5 btn-primary rounded-pill">Browse Courses</Link>
  
   </>:<>
   <Link to="/login" className="btn col-5 btn-primary rounded-pill">SignIn</Link>
  
   </>}
   
   </div>
  </Box>
</Modal>


<div  className={slider?"col-lg-3 col-md-4 col-sm-6 col-12  col-md-3 col-sm-4 col-12 p-3 slider-course-container fade-in-bottom "+"delay"+index+"s":"col-lg-3 col-md-4 col-sm-6 col-12 course-container col-md-3 col-sm-4 col-12 p-3 fade-in-bottom "+"delay"+index+"s"}>
  
  <div className="course-content">

 
  <img src={book.notes_cover} className="course-cover"/>

<div className="info">
<h5 className="title course-title truncate truncate2">{book.notes_title}</h5>


{"course.mode"==="free"?
<>
<div className="d-flex justify-content-between">

<p className="text-orangered course-price">Free  </p>

</div>
</>:
<>
<div className="mt-2">
<Rating name="read-only" precision={0.5} value={5} readOnly />

</div>

<div className="d-flex justify-content-between mt-2">
<p className=" text-dark mr-15 sm-14"> <b>Size : </b> {book.notes_size} Mb  </p>
<p className="mb-0 mt-0 text-dark sm-14"><b>Pages : </b>{book.pages}</p>
</div>

</>}


<div className="d-flex mt-2 d-sm-none justify-content-between">
     <Link onClick={()=>setalert(true)} className="">Download</Link>
     <vr/>
     <Link  onClick={()=>setalert(true)}  className="">Read</Link>
</div>
</div>



<div className="d-none d-sm-flex buttons-holder">
     <button  onClick={()=>setalert(true)}  className="btn">Download</button>
     <vr/>
     <button  onClick={()=>setalert(true)}  className="btn">Read Notes</button>
</div>

  </div>
 
    </div>
    
    </>
  
  )
}

export default NotesItem