import { Avatar } from '@mui/material'
import axios from 'axios'
import React, { useEffect } from 'react'
import { useState } from 'react'
import ENDPOINT from '../../data/ENDPOINT'
import SecurityHeaders from '../../data/SecurityHeaders'
import divider from '../../assets/bg/divider.png'
import { Link } from 'react-router-dom'
import Rating from '@mui/material/Rating';
import DetectDevice from '../../data/DetectDevice'
import { ForkRight } from '@mui/icons-material'
import { ArrowRightOutlined, LinkedIn } from '@material-ui/icons'
import styled from 'styled-components'
import MobileGallery from '../gallery/MobileGallery'
const AlumniSection = () => {
const [alumni,setalumni] = useState(null)
const device = DetectDevice()
const GetAlumni = async ()=>{
const data = new FormData()
const res = await axios.post(ENDPOINT+"alumni-data.php",data,SecurityHeaders)

if(res){

if(res.data.status==="success"){
     setalumni(res.data.alumni)
}

}

}
var scrollAmount ;

  
const buttonclick = ()=> {
     var container = document.getElementById('alumni-container');
     const elements = document.getElementsByClassName("rev-item")
     if(elements){
var width = 0
          for(var i =0;i<=elements.length;i++){
width = width+ parseInt(elements[0].style.width)
          }
          width = (width/elements.length)
          sideScroll(container,'right',15,width,10);
     }
     
 };
 
 
 const back = ()=> {
     var container = document.getElementById('alumni-container');
     const elements = document.getElementsByClassName("rev-item")
     if(elements){
var width = 0
          for(var i =0;i<=elements.length;i++){
width = width+ parseInt(elements[0].style.width)
          }
          width = (width/elements.length)
          sideScroll(container,'left',15,width,10);
     }
     
 };
 
 function sideScroll(element,direction,speed,distance,step){
     scrollAmount = 0;
     var slideTimer = setInterval(function(){
         if(direction === 'left'){
             element.scrollLeft -= step;
         } else {
             element.scrollLeft += step;
         }
         scrollAmount += step;
         if(scrollAmount >= distance){
             window.clearInterval(slideTimer);
         }
     }, speed);
 }
 




useEffect(()=>{
GetAlumni()
},[])


  return (
     <StyledAlumni>

<div className="pt-5 pb-5">
{alumni?
<>

     
<div className="container">
          <div className="text-center mb-4">
          <h2 className="font-weight-bold text-primary">Few Words From
          
          <>{device==="mobile"?<><br/>Our Students Alumni </>:<> Our Students Alumni</>}</></h2>

<div className="mx-auto text-center d-flex justify-content-center">
<div className="d-flex mx-auto w-fit align-items-center">
     <h5 className="mb-0 pb-0">4.8</h5>
<Rating name="read-only" value={4.5} readOnly /> (6,548 Ratings)
</div>
</div>

</div>

<div class="divider text-center mb-2">
<img src={divider}  alt="divider"></img>
</div>
  

               <div className="grid d-none lg-grid-5 mx-auto md-grid-3 sm-grid-3 grid-2 mt-2 ">

{alumni.map((item)=>(
     <>
     
     <div className=" alumni-item br-5">
     <div className="shadow  br-5 p-2">

<div className="alumni-avatar">
     <img className="w-100" src={item.image}/>
</div>
<hr/>
<div className="text-left p-2">
     <h5 className="truncate truncate1">{item.first_name+" "+item.last_name}</h5>
     <p className="truncate d-flex truncate1 mt-2"><b className="d-none d-sm-block">Company : </b> {item.company}</p>
     <p className="truncate d-flex truncate1"><b  className="d-none d-sm-block">Role :</b> {item.role} </p>
     <p className="truncate d-flex truncate1"><b  className="d-none d-sm-block">Package :</b> {item.ctc} Lakhs/Year</p>
</div>
     </div>
</div>

     
     </>
))}










               </div>
        


<div className="container sm-p-0">
<div className="d-flex alumni-container overflow-auto  sm-px-0">
     <div className="col-lg-4 col-sm-5 col-11 p-2 rev-item">
<div className="shadow br-5 border text-align-left p-3">
     <div className="d-flex justify-content-center text-align-center">
          <img src="https://skillsuprise.com/data/images/alumni1.png" className="alumni-avatar"></img>
       
     </div>
     <div className="text-center mt-3">
     <h5 className="text-primary">Saif Khan Saleem Khan</h5>

<h6  className="truncate truncate1">Associate Engineer</h6>
             <p className="mb-0 pb-0 truncate truncate1"> @Dassault systemes</p>
          

     </div>
     <div className="p-2 alumni-review">
          <p>Manoj Sir <span className='highlighted-review'>always keeps students and teams motivated and shares the right knowledge. </span>
          
          He is open to discuss ideas and suggestions and will be ready to help you through any means for your career growth. <span className='highlighted-review'>He is one of the best mentor</span>, he has knowledge in multiple domains like web development, cyber security, cloud computing, programming and many more.I had the plesure of learning and working under him</p>
     
   
     
     </div>
</div>

</div>


<div className="col-lg-4 d-none col-sm-5 col-11 p-2 rev-item">
<div className="shadow br-5 border text-align-left p-3">
     <div className="d-flex justify-content-center">
          <img src="https://skillsuprise.com/data/images/alumni2.png" className="alumni-avatar mr-2"></img>
       
     </div>
     <div className="text-center">
               <h5 className=" text-primary mt-3">Saketh dwaraka gandham</h5>
               <h6  className="truncate truncate1">Chief Executive Officer </h6>
             <p className="mb-0 pb-0 truncate truncate1">@Trigovex Technologoes</p>
            
               </div>
     <div className="p-2 alumni-review">
          <p>The training overall was great. It helped me formalize and think more deeply about the work i do. Also, i was extremely impressed
               with Manoj sir, specifically his ability to make complicated concepts seem very simple. Manoj sir is a great instructor , he makes sure that every student gets engaged in the trainings (even in virtual setting). Really learnt good content that i can put to use right away that will increase quality of work output.
          </p>
     </div>
</div>

</div>



<div className="col-lg-4 col-sm-5 col-11 p-2 rev-item">
<div className="shadow br-5 border text-align-left p-3">
     <div className="d-flex justify-content-center">
          <img src="https://skillsuprise.com/data/images/alumni3.png" className="alumni-avatar mr-2"></img>
       
     </div>
 
     <div className="text-center">
               <h5 className=" text-primary mt-3">Kaki Mani Kumari</h5>
               <h6  className="truncate truncate1">Application Developer</h6>
               <p className="mb-0 pb-0">@ IBM</p>
            
               </div>
     <div className="p-2 alumni-review">
          <p><span className='highlighted-review'>sir explained every concept very well</span> and the way he clarified doubts showed his abundance of knowledge. The patience he had throughout the sessions has inspired me a lot. 
               
               <span className='highlighted-review'>

               Interactive sessions with batchmates and working professions of various companies were super exciting 
               </span>
               where we can learn many things about other companies. Manoj sir knows 30+ programming languages and this is why We have benifited a lot with the training sessions .</p>
     </div>

</div>

</div>


<div className="col-lg-4  col-sm-5 col-11 p-2 rev-item">
<div className="shadow br-5 border text-align-left p-3">
     <div className="d-flex justify-content-center text-center">
          <img src="https://skillsuprise.com/data/images/alumni4.png" className="alumni-avatar mr-2"></img>
         
     </div>
     <div className="text-center">
          <h5 class="text-primary mt-3">Reshma Ganta</h5>
          <h6 class="truncate truncate1">Programmer Analyst Trainee</h6>
          <p class="mb-0 pb-0">@ Cognizant</p></div>
     <div className="p-2 alumni-review">
          <p><span className='highlighted-review'>It is one such training that takes you always up and with practical sessions</span>, we are always boosted to learn more. The initial move through interactive sessions with trainers and professionals of other companies as a part of our training program instructors has brought a strong belief 
               that 
               
<span className='highlighted-review'>this training program is extraordinary and completely different from the regular courses we go through</span>. And to be honest,
               manoj sir proved me right throught the trainings. 
          </p>
     </div>
</div>

</div>

     </div>


</div>




<div id="alumni-container" className="container d-flex   col-md-12 col-sm-12 col-12 mx-auto justify-content-start ">


</div>




<div className="text-center mt-3">
     <Link to="/reviews" className="btn rounded-pill btn-lg btn-sm mt-4 btn-primary ">1000s of happy students reviews <ArrowRightOutlined/></Link>
</div>



     </div>
</>:
<>




</>}
     </div>

     </StyledAlumni>
  
  )
}

export default AlumniSection

const StyledAlumni = styled.div`

     .reviews-horiz{
       flex-wrap: nowrap !important;
       overflow-x: scroll !important;
       justify-content:flex-start !important;
      
   }`