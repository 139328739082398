import React from 'react'

import { useEffect } from 'react'
import { useState } from 'react'
import axios from 'axios'

import { styled } from '@mui/material/styles';
import {Container} from 'react-bootstrap';


import { Modal } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import defaultboy from '../../assets/user.png'

import ENDPOINT from '../../data/ENDPOINT'

import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import CircularProgress, {
  circularProgressClasses,
} from '@mui/material/CircularProgress';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import CourseSuggestions from '../community/CourseSuggestions';
import SetPage from '../../data/SetPage';
import DetectDevice from '../../data/DetectDevice';
import SocialShare from '../../components/SocialShare';
import dateFormat from 'dateformat';
const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
  },
}));


const PersonalBadgeEarned = ({account,props}) => {
const params = useParams();
const badge_id = params.badge_id;


    useEffect(() => {
        window.scrollTo(0, 0)
        SetPage("Badge Details")
      }, [])
    
    
    
    
    
    
    
    
    
   
    
    //get course badges


    
    
    const [badge,setbadge] = useState(null);
    
    useEffect(()=>{
       
    
        const  getbadge = async ()=>{
          ////  //  //console.log(badge_id)
      const result2 =  await 
      axios.post(`${ENDPOINT}personal-badge-earned.php?badge_id=${badge_id}`);
  
  if(result2!==null){
      const list = result2.data;
      setbadge(list.data);
    // //  //  //console.log("bdage "+JSON.stringify(result2))
                    
  }          
            }
            
            
    getbadge();
           
    }
    
    ,[])
    
    
    
    
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    
  
  
const device = DetectDevice()



    return (

<>


<Modal show={show} onHide={handleClose} 

{...props}
size="lg"
aria-labelledby="contained-modal-title-vcenter"
centered
>
      <Modal.Header closeButton>
        <Modal.Title></Modal.Title>
      </Modal.Header>
      <video height="auto" autoPlay width="auto" src="https://skillsuprise.com/videos/rewards-demo.mp4" title="Offline Trainings Demo" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen controls controlsList="nodownload"></video>
     
    </Modal>

    
<>



<div className="d-flex min-vh-100  flex-wrap container mt-2">






<div className="col-lg-9 mx-auto col-12 col-md-9 col-sm-12 sm-mt-30 sm-mb-30">


{/*badge section start*/}


{badge?


<>



            <div className="d-flex  w-100 justify-content-between flex-wrap">
                <div className=" col-lg-6">
               
<div className="justify-content-center mb-5 w-100">
<div className=" p-2 text-center mx-auto blue-bg badge-info-card br-5 h-fit shadow col-10">
<img className="w-50 " src={badge.image?badge.image:defaultboy}/>
<h3 className="mt-3 sm-22 text-white">{badge.title}</h3>
<h5 className="text-white">Points Earned : {badge.points}</h5>





  </div>



</div>

                    
<div className="text-center">
<h3 className="sm-22">Description</h3>
  <p>{badge.description}</p>



</div>
                    
             
                
                </div>
                <div className="col-lg-5 col-md-12 col-sm-12  col-12 position-relative">
                <div className="shadow sm-mt-30 p-3 w-100 mb-5  br-5">
 <h5 className="mb-0">Rewarded To</h5>
 <hr/>
  <div className="d-flex mt-2">
    <img src={badge.profile_pic} className="course-userdp mr-15"/>
  
  <div>
    <h5>{badge.first_name+" "+badge.last_name}</h5>
    <p>{badge.username}</p>

    <p className="extra-small">{dateFormat(badge.date_time,"dS mmm yyyy, hh:mm tt")}</p>
  </div>
  </div>



<div className="mt-4">
  <h6>Share with friends</h6>

<div className="mt-2">
<SocialShare/>
</div>

</div>

</div>
            
                </div>
            </div>
      



</>
:

<>

</>}


</div>






</div>













</>
</>
    )
}

export default PersonalBadgeEarned






