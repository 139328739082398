import React from 'react';
import LoginSlider from '../home/LoginSlider';

const AuthFeatures = () => {
  return <>


{/*main content*/}

<div className="position-relative w-100 h-100">





<img src="https://skillsuprise.com/resources/images/login-img.png"  className="w-100 h-100 login-slider-item"/>
        

<div className="flex-wrap d-none  space-evenly">






<div className=" col-6  p-1  mt-3 ">
    
<div className="shadow align-items-center  d-flex bg-white p-2 br-4">

<img src="https://skillsuprise.com/resources/images/signup-community.svg" className="auth-icon mt-0"/>
<div>
    <h6 className="sm-16 font-weight-normal">Trusted by 5,000+ Students</h6>
    <h6 className="sm-14  font-weight-normal">Rated 4.8 / 5</h6>
</div>

</div>


</div>



<div className="mt-3 col-6 p-1 ">

<div className="shadow  align-items-center  d-flex br-4 bg-white p-2">

<img src="https://skillsuprise.com/resources/images/signup-placements.svg" className="auth-icon mt-0"/>

<div>
<h6 className="sm-16 font-weight-normal">Guaranteed Internships & Placements</h6>
    
</div>
</div>


</div>

<div className="flex mt-3  p-1 col-6 ">

<div className="shadow d-flex align-items-center bg-white br-4 p-2">

<img src="https://skillsuprise.com/resources/images/signup-trainings.svg" className="auth-icon mt-0"/>

<div>
    <h6 className="sm-16 font-weight-normal">Interactive & practical gamified trainings</h6>
  </div>
</div>





</div>





<div className="flex mt-3  col-6 p-1 ">

<div className="d-flex align-items-center  shadow bg-white br-4 p-2">
<img src="https://skillsuprise.com/resources/images/signup-opportunities.svg" className="auth-icon mt-0"/>

<div>

    <h6 className="sm-16  font-weight-normal">Industry Ready Certification An X-Factor to your resume</h6>
</div>
</div>


</div>





</div>














<div className="text-center d-none text-white mt-5">

  <h1>Turn your passion into profession</h1>

  <div className="justify-content-center text-center">
  <h6 className="font-weight-normal login-desc mt-3 mx-auto w-75">
    Learn | Intern | Get a Job<br/>
Explore 100+ Reliable Training Programs & Career Opportunities

   </h6>
  </div>


















</div>


</div>
{/*end of main content*/}





  </>;
};

export default AuthFeatures;
