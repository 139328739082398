import { Avatar } from '@mui/material'
import React, { useState } from 'react'
import SavePost from '../../actions/post/SavePost';
import Shareicon from '@mui/icons-material/Share';
import Commenticon from '@mui/icons-material/Chat';
import Likeicon from '@mui/icons-material/FavoriteBorder';
import Saveicon from '@mui/icons-material/Bookmark';
import Dropdown from 'react-bootstrap/Dropdown'
import optionsicon from '../../assets/icons/options.svg'
import * as timeago from 'timeago.js'
import TextareaAutosize from '@mui/material/TextareaAutosize';
import Skeleton from '@mui/material/Skeleton';
import AvatarGroup from '@mui/material/AvatarGroup';
import DetectDevice from '../../data/DetectDevice';
import { Link } from 'react-router-dom';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import PostComment from '../../actions/post/PostComment';
import { useContext } from 'react';
import { AuthContext } from '../../context/AuthContext';
import LikePost from '../../actions/post/LikePost';
import Offcanvas from 'react-bootstrap/Offcanvas'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import ModalStyle from '../../data/ModalStyle';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import DirectionsIcon from '@mui/icons-material/Directions';
import {
     EmailIcon,
     EmailShareButton,
   
     FacebookIcon,
   
     FacebookShareButton,
   
     LinkedinIcon,
     LinkedinShareButton,
   
    
     TelegramIcon,
   
    
     TelegramShareButton,
     
     TwitterIcon,
     
     TwitterShareButton,
   
     WhatsappIcon,
   
     WhatsappShareButton,
   
   } from "react-share";
import DeletePost from '../../actions/post/DeletePost';
import ReportPost from '../../actions/post/ReportPost';
import GetPost from '../../actions/post/GetPost';
import MyTimeline from '../../actions/post/MyTimeline';
import { AccountBalance, Close } from '@mui/icons-material';
import { useEffect } from 'react';
import Localbase from 'localbase';
const PostItem = ({post,RefreshPosts,props,account,commentpage}) => {
const [newpost,setnewpost] = useState(post)
const device = DetectDevice()
const [liked,setliked] = useState(post.liked)
const [comment,setcomment] = useState(null)
const {user} = useContext(AuthContext)
const [saved,setsaved] = useState(post.saved)
const db = new Localbase("db")
const [totalcomments,settotalcomments] = useState(parseInt(post.total_comments))
const NewPostComment = async ()=>{


     if(comment!==null&&comment.length>1){
          const res = await PostComment({
               payload:{
                    comment:comment,
                    post_id:post.post_id,
                    course_url:post.course_url,
                    user:user.SessionId
               }
          })
          if(res){
               SinglePost()
           
          }
     }
  
}


const SinglePost = async ()=>{

     const res = await GetPost({payload:
     {
          post_id:post.post_id,
          user:user.SessionId,

     }})
if(res){

     if(res.status==="success"){
          setnewpost(res.posts[0])
     }
}
    
}

const Like = async()=>{
     const res = await LikePost({
          payload:{
               user:user.SessionId,
               post_id:post.post_id
          }
     })

     if(res){
          if(res.status==="success"){

               if(res.message==="liked"){
                    setliked(true)
                    settotallikes(totallikes+1)
               }
               else{
                    setliked(false)
                    settotallikes(totallikes-1)
               }
          }
        
     }
}

const [sharemodal,setsharemodal] = useState(false)


const SaveUserPost = async ()=>{
     const res = await SavePost({
     payload:{post_id:post.post_id,
          user:user.SessionId }})
if(res){   
     if(res[0].status==="success"){
          setsaved(res[0].saved)
     }}}
const DeleteUserPost = async()=>{
const res = await DeletePost({
     payload:{user:user.SessionId,
          post_id:post.post_id}})
if(res){
   if(res.status==="success"){
     //delete posts from browser cache
     db.collection("paths")
     .doc("posts")
     .delete();
      //  //console.log("refreshing posts "+JSON.stringify(res))
     RefreshPosts()

}}}
     
const Report = async()=>{
     const res = await ReportPost({payload:{
          show:false,
          user:user.SessionId,
          post_id:post.post_id
     }})
}
const [totallikes,settotallikes] = useState(parseInt(post.total_likes))
const [reportmodal,setreportmodal] = useState(false)



















  return (

<>

   
<>
  



  <Modal
  open={reportmodal}
  onClose={()=>setreportmodal(false)}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
  <Box sx={ModalStyle} className="p-3 col-lg-5 col-md-6 col-sm-10 col-12">

  <div className="modal-header">
<h4>Report post</h4>
<Close onClick={()=>setreportmodal(false)}/>
  </div>
   
    <TextareaAutosize

  minRows={3}
  placeholder="Write your report..."
 className="w-100 mt-2"

/>
 
 <button className="w-100 btn btn-primary bt-sm mt-3">Submit Report</button>
  </Box>
</Modal>
     
     











     {device==="mobile"?
     <>
       <Offcanvas className="share-post-canvas" show={sharemodal} placement={"bottom"} onHide={()=>setsharemodal(false)} {...props}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Share</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
        
        <>
<WhatsappShareButton size={30}   round={true} url={"https://skillsuprise.com/newsfeed/"+post.post_id}><WhatsappIcon size={30}  type="button"  round={true} url={"https://skillsuprise.com/newsfeed/"+post.post_id} className="mr-2"/></WhatsappShareButton>
<LinkedinShareButton size={30}   round={true} url={"https://skillsuprise.com/newsfeed/"+post.post_id}><LinkedinIcon size={30}  type="button"  round={true} url={"https://skillsuprise.com/newsfeed/"+post.post_id} className="mr-2"/></LinkedinShareButton>
<FacebookShareButton size={35}  round={true} url={"https://skillsuprise.com/newsfeed/"+post.post_id}> <FacebookIcon size={30} round={true} className="mr-2"/></FacebookShareButton>
<TelegramShareButton size={30}   round={true} url={"https://skillsuprise.com/newsfeed/"+post.post_id}><TelegramIcon size={30}  type="button"  round={true} url={"https://skillsuprise.com/newsfeed/"+post.post_id} className="mr-2"/></TelegramShareButton>
<TwitterShareButton size={30}   round={true} url={"https://skillsuprise.com/newsfeed/"+post.post_id}><TwitterIcon size={30}  type="button"  round={true} url={"https://skillsuprise.com/newsfeed/"+post.post_id} className="mr-2"/></TwitterShareButton>
<EmailShareButton size={30}   round={true} url={"https://skillsuprise.com/newsfeed/"+post.post_id}><EmailIcon size={30}  type="button"  round={true} url={"https://skillsuprise.com/newsfeed/"+post.post_id} className="mr-2"/></EmailShareButton>


</>

        </Offcanvas.Body>
      </Offcanvas> 
     
     
     </>:
     
     <>
     <Modal
  open={sharemodal}
  onClose={()=>setsharemodal(false)}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
  <Box sx={ModalStyle}>
    <Typography id="modal-modal-title" variant="h6" component="h2">
      Share
    </Typography>
    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
    
    <WhatsappShareButton size={30}   round={true} url={"https://skillsuprise.com/newsfeed/"+post.post_id}><WhatsappIcon size={30}  type="button"  round={true} url={"https://skillsuprise.com/newsfeed/"+post.post_id} className="mr-2"/></WhatsappShareButton>
<LinkedinShareButton size={30}   round={true} url={"https://skillsuprise.com/newsfeed/"+post.post_id}><LinkedinIcon size={30}  type="button"  round={true} url={"https://skillsuprise.com/newsfeed/"+post.post_id} className="mr-2"/></LinkedinShareButton>
<FacebookShareButton size={35}  round={true} url={"https://skillsuprise.com/newsfeed/"+post.post_id}> <FacebookIcon size={30} round={true} className="mr-2"/></FacebookShareButton>
<TelegramShareButton size={30}   round={true} url={"https://skillsuprise.com/newsfeed/"+post.post_id}><TelegramIcon size={30}  type="button"  round={true} url={"https://skillsuprise.com/newsfeed/"+post.post_id} className="mr-2"/></TelegramShareButton>
<TwitterShareButton size={30}   round={true} url={"https://skillsuprise.com/newsfeed/"+post.post_id}><TwitterIcon size={30}  type="button"  round={true} url={"https://skillsuprise.com/newsfeed/"+post.post_id} className="mr-2"/></TwitterShareButton>
<EmailShareButton size={30}   round={true} url={"https://skillsuprise.com/newsfeed/"+post.post_id}><EmailIcon size={30}  type="button"  round={true} url={"https://skillsuprise.com/newsfeed/"+post.post_id} className="mr-2"/></EmailShareButton>





    </Typography>
  </Box>
</Modal>
     
     
     </>}
     

<div key={newpost.post_id} className={commentpage?"shadow post-item  w-100  br-5 sm-br-0 sm-shadow-none":"shadow post-item mt-2 mb-2 w-100  br-5"}>
{commentpage?
<>

</>:
<>
<div className="d-flex justify-content-between  p-2">
          <div className="d-flex">
          <Avatar src={newpost.profile_pic?newpost.profile_pic:null} className="mr-10"/>
          <div className="d-flex justify-content-between">
          <div className="w-100">
               <h6 className="mb-0 pb-0">{newpost.first_name+" "+newpost.last_name}</h6>
              <div className="d-flex sm-12 align-items-center">
               <Link  to={"/course/"+newpost.course_url+"/live"} className="mr-15 truncate truncate1 text-dark">{newpost.course_url.replace("-"," ")}</Link>
               <p>{timeago.format(newpost.date_time)} </p>
              </div>
          </div>
          <div>

          </div>
          </div>

          </div>
          <Dropdown className="dropdown">
  <Dropdown.Toggle className="bg-transparent">
  <img  className="post-action m-0"  src={optionsicon}/>
  
  </Dropdown.Toggle>

  <Dropdown.Menu>
    <Dropdown.Item href="#/action-1">Copy Post</Dropdown.Item>
    <Dropdown.Item onClick={()=>setreportmodal(true)}>Report</Dropdown.Item>
 
 {account.user_id===post.user_id?
 <>
   <Dropdown.Item onClick={()=>DeleteUserPost()}>Delete </Dropdown.Item>
 
 </>:
 <>
  
 </>}
  </Dropdown.Menu>
</Dropdown>

     </div>


</>}

{post.description?
<>
<div className="pb-10 p-2">
               <p className="truncate truncate3">{post.description}</p>
          </div>
</>:null}
   
     <div className="post-content ">


<img className="w-100" src={newpost.image}/>
</div>



     <div className="post-footer sm-shadow-none">
     <div className="d-flex align-items-center justify-content-between">
<div className="d-flex align-items-center">

{liked?
<>
<Likeicon  onClick={()=>{Like()}} className=" text-danger post-action click"  />

</>:
<>
<Likeicon  onClick={()=>{Like()}} className="  post-action click"  />

</>}

{device==="mobile"?
<>
<Link to={"/newsfeed/"+newpost.post_id}>
<Commenticon  className="post-action text-dark click" />
</Link>
</>:
<>
<Link to={"/newsfeed/"+newpost.post_id}>
<Commenticon  className="post-action text-dark click" />
</Link>
</>}
<Shareicon onClick={()=>setsharemodal(true)}  className="post-action click" />
</div>{saved?
<>
<Saveicon  onClick={()=>SaveUserPost()} className="post-action text-primary click" />

</>:
<>
<BookmarkBorderIcon  onClick={()=>SaveUserPost()} className="post-action click" />

</>}
     </div>




     <div>

          {totallikes===0?<>
          
          </>:
          <>
          {totallikes>2?
     <>
     <div className="d-flex mt-2 likes-group mr-15 align-items-center">
     <AvatarGroup max={3}>
  <Avatar size="small" alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
  <Avatar  size="small"  alt="Travis Howard" src="/static/images/avatar/2.jpg" />
  <Avatar size="small"  alt="Cindy Baker" src="/static/images/avatar/3.jpg" />
 </AvatarGroup>
 <p className="extra-small"> +{totallikes} others Liked</p>
    
     </div>
  
     </>
:
<>
<p className="extra-small">{totallikes} Like</p>
      
</>}


              </>}
       
          {totalcomments>0?
          <>
         {commentpage?
         <>
         
         </>:
         <>
           <Link  to={"/newsfeed/"+newpost.post_id}>
       <p  className="view-comment-text click">View all {newpost.total_comments} comments</p>

       </Link> 
         </>}
          </>:
          <>
          
          </>}
            </div>
</div>
{commentpage?
<>

<div className="d-flex justify-content-between  p-2">
          <div className="d-flex">
          <Avatar src={newpost.profile_pic?newpost.profile_pic:null} className="mr-10"/>
          <div className="d-flex justify-content-between">
          <div className="w-100">
               <h6 className="mb-0 pb-0">{newpost.first_name+" "+newpost.last_name}</h6>
              <div className="d-flex sm-12 align-items-center">
               <Link  to={"/course/"+newpost.course_url+"/live"} className="mr-15 truncate truncate1 text-dark">{newpost.course_url.replace("-"," ")}</Link>
               <p>{timeago.format(newpost.date_time)} </p>
              </div>
          </div>
          <div>

          </div>
          </div>

          </div>
          <Dropdown className="dropdown">
  <Dropdown.Toggle className="bg-transparent">
  <img  className="post-action m-0"  src={optionsicon}/>
  
  </Dropdown.Toggle>

  <Dropdown.Menu>
    <Dropdown.Item href="#/action-1">Copy Post</Dropdown.Item>
    <Dropdown.Item onClick={()=>setreportmodal(true)}>Report</Dropdown.Item>
 
 {account.user_id===post.user_id?
 <>
   <Dropdown.Item onClick={()=>DeleteUserPost()}>Delete </Dropdown.Item>
 
 </>:
 <>
  
 </>}
  </Dropdown.Menu>
</Dropdown>

     </div>
<hr/>
</>:<>

<div  className="post-comment align-items-center d-flex">
     <input value={comment} onChange={(e)=>setcomment(e.target.value)} className="border-0 "></input>
     <button onClick={()=>NewPostComment()} className="btn btn-link">Post</button>
  </div>
     
</>}
     </div>  
     </>

</>
 
  )
}

export default PostItem