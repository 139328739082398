import axios from 'axios'
import ENDPOINT from '../../data/ENDPOINT'
import SecurityHeaders from '../../data/SecurityHeaders'

const  SavePost = async ({payload})=>{

const data = new FormData()


     data.append("post_id",payload.post_id)

data.append("session",payload.user)
const res = await 
axios.post(ENDPOINT+`save-post.php`,data,SecurityHeaders)
if(res){
    
     return res.data
}

}



export default SavePost