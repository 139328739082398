import Localbase from 'localbase'
import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import GetMyWorkshops from '../../actions/GetMyWorkshops'
import { AuthContext } from '../../context/AuthContext'
import DetectDevice from '../../data/DetectDevice'
import SetPage from '../../data/SetPage'
import NoData from '../../templates/NoData'
import CourseSuggestions from '../community/CourseSuggestions'
import OffersSlider from '../community/OffersSlider'

const MyWorkshops = () => {

     useEffect(() => {
          window.scrollTo(0, 0)
      
       
        }, [])
const [workshops,setworkshops] = useState(null)
const {user} = useContext(AuthContext)
let db = new Localbase("db")
const NewMyWorkshops = async()=>{

     const res = await GetMyWorkshops({user})
     if(res){
          if(res[0].status==="success"){
setworkshops(res[0].data)
db.collection("paths").add({
     my_workshops:res[0].data
},"my_workshops")
          }
           //  //console.log("my workshops"+JSON.stringify(res[0].status))
     }
}


useEffect(()=>{
     SetPage("My Workshops")
     db.collection("paths").doc("my_workshops").get().then(data=>{
setworkshops(data.my_workshops)
     }).catch(error=>{

     })
NewMyWorkshops()
},[])



const device = DetectDevice()
  return (
     <div className="pt-2 pb-5">
     <div className="container  min-vh-90 justify-content-between d-sm-flex d-block flex-wrap">
{device==="mobile"?
<>

</>:
<>

<div className="col-lg-4  col-12 h-fit ">
     <div className=" p-3 sm-mt-30 br-5 ">
    <CourseSuggestions />

   </div>

   

  
    
    </div>  
</>}

<div className="col-lg-7 col-12">
{workshops?
<>
{workshops?
<>
{workshops.map((workshop)=>(
  <>
<Link to={"/workshop/"+workshop.workshop_url} className="d-flex  mb-2 mt-2">
  <div className="col-lg-4 col-5 mr-10">
    <img  src={workshop.workshop_cover_url} className="w-100 h-100 br-4 workshop-suggestion-cover"/>
  </div>
     <div className="col-lg-7 position-relative p-2">
          <h5 className="sm-16">{workshop.workshop_title}</h5>
          
          <div className=" h-max-content mb-2 sm-13 align-items-center">
            <div className="mr-10 d-flex align-items-center">
            <h6 className="pb-0 mb-0">Date :- </h6>
           <p>{workshop.date}</p>
            </div>
            <div className="mt-2 d-flex align-items-center">
            <h6 className="mb-0 pb-0">Time :- </h6>
            <p>{workshop.workshop_duration}</p>
         

           </div>
          
          </div>
          <a target="_blank" rel="noreferrer" className="btn btn-sm mt-auto btn-primary rounded-pill" href={workshop.meeting_link}>Join Workshop</a>
         
      </div>
</Link>

  </>
))}
</>:

<>




</>}


</>:

<>

<div className="w-75">

  <div className="text-center">
  <NoData/>
<h4 className="mt-3">No registrations found</h4>


<Link to="/workshops" className="btn btn-primary rounded-pill mt-4">Browse Workshops</Link>

  </div>
</div>



</>}
       


</div>


  

     </div>
    </div>
  )
}

export default MyWorkshops