import * as React from "react";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";

const CoursesSkeleton = () => {
  return (
    <>
     <div className="d-flex flex-wrap justify-content-between">
     <div className="col-lg-3 col-md-4 col-sm-6 col-12  col-md-3 col-sm-4 col-12 p-3 slider-course-container sm-p-0  course-container  mt-3 md-mt-0 ">
      
      <div className="border rounded d-flex d-md-block">
      <>
          <Skeleton
            sx={{ height: 160 }}
            animation="wave"
            className="col-3 col-md-12"
            variant="rectangular"
          />

         <div className="p-2 col-9 col-md-12">
         <Skeleton variant="text" className="col-11 mt-2" height={40} />
          <Skeleton variant="text" className="col-11" height={40} />

          <Skeleton variant="caption" className="col-10 mt-2" height={10} />
          <div className="d-none d-md-flex justify-content-between mt-3">
            <Skeleton variant="caption" className="col-5 mt-2" height={30} />
            <Skeleton variant="caption" className="col-5 mt-2" height={30} />
          </div>
         </div>
        </>

      </div>
      
       
      </div>


      <div className="col-lg-3 col-md-4 col-sm-6 col-12  col-md-3 col-sm-4 col-12 p-3 slider-course-container sm-p-0  course-container  mt-3 md-mt-0 ">
      
      <div className="border rounded d-flex d-md-block">
      <>
          <Skeleton
            sx={{ height: 160 }}
            animation="wave"
            className="col-3 col-md-12"
            variant="rectangular"
          />

         <div className="p-2 col-9 col-md-12">
         <Skeleton variant="text" className="col-11 mt-2" height={40} />
          <Skeleton variant="text" className="col-11" height={40} />

          <Skeleton variant="caption" className="col-10 mt-2" height={10} />
          <div className="d-none d-md-flex justify-content-between mt-3">
            <Skeleton variant="caption" className="col-5 mt-2" height={30} />
            <Skeleton variant="caption" className="col-5 mt-2" height={30} />
          </div>
         </div>
        </>

      </div>
      
       
      </div>



      <div className="col-lg-3 col-md-4 col-sm-6 col-12  col-md-3 col-sm-4 col-12 p-3 slider-course-container sm-p-0  course-container  mt-3 md-mt-0 ">
      
      <div className="border rounded d-flex d-md-block">
      <>
          <Skeleton
            sx={{ height: 160 }}
            animation="wave"
            className="col-3 col-md-12"
            variant="rectangular"
          />

         <div className="p-2 col-9 col-md-12">
         <Skeleton variant="text" className="col-11 mt-2" height={40} />
          <Skeleton variant="text" className="col-11" height={40} />

          <Skeleton variant="caption" className="col-10 mt-2" height={10} />
          <div className="d-none d-md-flex justify-content-between mt-3">
            <Skeleton variant="caption" className="col-5 mt-2" height={30} />
            <Skeleton variant="caption" className="col-5 mt-2" height={30} />
          </div>
         </div>
        </>

      </div>
      
       
      </div>


      <div className="col-lg-3 col-md-4 col-sm-6 col-12  col-md-3 col-sm-4 col-12 p-3 slider-course-container sm-p-0  course-container  mt-3 md-mt-0 ">
      
      <div className="border rounded d-flex d-md-block">
      <>
          <Skeleton
            sx={{ height: 160 }}
            animation="wave"
            className="col-3 col-md-12"
            variant="rectangular"
          />

         <div className="p-2 col-9 col-md-12">
         <Skeleton variant="text" className="col-11 mt-2" height={40} />
          <Skeleton variant="text" className="col-11" height={40} />

          <Skeleton variant="caption" className="col-10 mt-2" height={10} />
          <div className="d-none d-md-flex justify-content-between mt-3">
            <Skeleton variant="caption" className="col-5 mt-2" height={30} />
            <Skeleton variant="caption" className="col-5 mt-2" height={30} />
          </div>
         </div>
        </>

      </div>
      
       
      </div>
     </div>
    </>
  );
};

export default CoursesSkeleton;
