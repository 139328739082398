import React from 'react'

import { useEffect } from 'react'
import { useState } from 'react'
import axios from 'axios'

import { styled } from '@mui/material/styles';
import {Container,Col} from 'react-bootstrap';
import reward from '../../assets/reward.svg';
import user from '../../assets/user.png';
import HiLocationMarker from '@mui/icons-material/LocationOn';

import FaHandshake from '@mui/icons-material/Handshake';

import { Modal } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import defaultboy from '../../assets/user.png'

import ENDPOINT from '../../data/ENDPOINT'


import Box from '@mui/material/Box';

import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
  },
}));


const ScratchCardDetails = ({userdata,props}) => {
const params = useParams();
const id = params.id;


    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    
    
    
    
    
    
    
    
    
   
    
    //get course badges


    
    
    const [badge,setbadge] = useState(null);
    
    useEffect(()=>{
       
    
        const  getbadge = async ()=>{
          ////  //  //console.log(id)
      const result2 =  await 
      axios.post(`${ENDPOINT}scratch-card-info.php?badge_id=${id}`);
  
  if(result2!==null){
      const list = result2.data;
      setbadge(list.data);
    // //  //  //console.log("bdage "+JSON.stringify(result2))
                    
  }          
            }
            
            
    getbadge();
           
    }
    
    ,[])
    
    
    
    
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    
  
  




    return (

<>


<Modal show={show} onHide={handleClose} 

{...props}
size="lg"
aria-labelledby="contained-modal-title-vcenter"
centered
>
      <Modal.Header closeButton>
        <Modal.Title></Modal.Title>
      </Modal.Header>
      <video height="auto" autoPlay width="auto" src="https://skillsuprise.com/videos/rewards-demo.mp4" title="Offline Trainings Demo" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen controls controlsList="nodownload"></video>
     
    </Modal>

    
<>












{/*badge section start*/}


{badge?


<>


<Container className="min-vh-100">
            <div className="d-flex p-5 sm-p-15 mt-5 flex-wrap">
                <div className="col-lg-5  col-md-5 col-sm-6 col-12 ">
               
<div className="justify-center mb-5 w-100">
<div className=" p-2 text-center  blue-bg br-4 shadow mx-auto col-10">
<img className="w-100" src={badge.image?badge.image:defaultboy}/>





  </div>



</div>

                    

                    
             
                
                </div>

                <div className="col-lg-6">
                <div className="">
<h3 className="sm-22">How to earn ?</h3>
  <p>{badge.description}</p>

</div>
                </div>
              
            </div>
        </Container>



</>
:

<>

</>}







</>
</>
    )
}

export default ScratchCardDetails






