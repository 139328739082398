import React, { useState } from 'react'
import Shareicon from '@mui/icons-material/Share';
import Commenticon from '@mui/icons-material/Chat';
import Likeicon from '@mui/icons-material/FavoriteBorder';
import Saveicon from '@mui/icons-material/Bookmark';
import Dropdown from 'react-bootstrap/Dropdown'
import optionsicon from '../../assets/icons/options.svg'
import { Avatar, Skeleton} from '@mui/material'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import ModalStyle from '../../data/ModalStyle';
import axios from 'axios';
import ENDPOINT from '../../data/ENDPOINT';
import SecurityHeaders from '../../data/SecurityHeaders';
import { useEffect } from 'react';
import { useContext } from 'react';
import dateFormat from 'dateformat';
import * as timeago from 'timeago.js'
import { AuthContext } from '../../context/AuthContext';
import { Link } from 'react-router-dom';
import { Close } from '@material-ui/icons';
import Carousel from 'react-bootstrap/Carousel'
import ImagesCarousel2 from './ImagesCarousel2';
import ImagesCarousel3 from './ImagesCarousel3';
import ImagesCarousel4 from './ImagesCarousel4';
import ImagesCarousel5 from './ImagesCarousel5';
import ImagesCarousel from './ImagesCarousel';
const DemoCommunityPosts = () => {


const {user} = useContext(AuthContext)

const [modal,setmodal] = useState(false)

const [quiz,setquiz] = useState(null)
const [posts,setposts] = useState(null)
const GetPosts = async()=>{
const data = new FormData()
data.append("session",user.SessionId)
const res = await axios.post(ENDPOINT+"student-posts2.php",data,SecurityHeaders)

if(res){
  setloading(false)
  if(res.data.status==="success"){
setposts(res.data.posts)
setquiz(res.data.quiz)
setquizpostion(Range(res.data.posts.length))
 //  //console.log("position is "+quizposition)
  }
   //  //console.log("res si "+JSON.stringify(res))
}

} 



useEffect(()=>{
GetPosts()
},[])


const [loading,setloading] = useState(true)


function Range(max) { 
  return Math.floor(Math.random() * (max  + 1))
}





  const ShowWarning = ()=>{


setmodal(true)
  }


  const [quizposition,setquizpostion] = useState(2)

  return (
    <div>


<Modal

  open={modal}
  onClose={()=>setmodal(false)}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
  <Box sx={ModalStyle} className="p-3 max-vh-80 br-5 col-lg-4 col-md-5 col-sm-6 col-11 mx-auto">
  <div>

<div className="d-flex justify-content-between">
  <h5>Student access only  !</h5>
  <Close className="click" onClick={()=>setmodal(false)}/>
</div>
<hr/>
<p>Please register a course to access all the features.</p>
 
 
 <div className="d-flex mt-4 align-items-center justify-content-between">
 
 <button className="btn btn-outline-danger btn-sm rounded-pill col-5">Cancel</button>

  <Link to="/courses" className="btn btn-primary col-5 btn-sm rounded-pill">Browse Courses</Link>
  </div>
 
  </div>
  </Box>
</Modal>






<div className="mt-4 mb-4 red-title">
  <h5 className="pb-0 mb-0">Top 10 posts of the month</h5>
</div>


{posts?
<>



{posts.map((post,index)=>(
  <>

<div  className="shadow post-item mt-2 mb-2 w-100  br-5">
     <div onClick={()=>ShowWarning()} className="d-flex justify-content-between  p-2">
          <div className="d-flex">
          <Avatar src={post.profile_pic} className="mr-10"/>
          <div className="d-flex justify-content-between">
          <div className="w-100">
               <h6 className="mb-0 pb-0">{post.username}</h6>
           <div className="d-flex align-items-center">
            <Link className="mr-15 text-dark" to={"/course/"+post.course_url+"/live"}>{post.course_url}</Link>
           <p>{timeago.format(post.date_time)}</p>
            </div>   
          </div>
          <div>

          </div>
          </div>

          </div>
          <Dropdown className="dropdown">
  <Dropdown.Toggle className="bg-transparent">
  <img  className="post-action m-0"  src={optionsicon}/>
  
  </Dropdown.Toggle>

  <Dropdown.Menu>
   
  </Dropdown.Menu>
</Dropdown>

     </div>

     <div onClick={()=>ShowWarning()} className="pb-10 p-2">
               <p className="truncate truncate4">{post.description}</p>
          </div>
     <div className="post-content ">
{post.image.length===2?
<>


<ImagesCarousel2 postimages={post.image}/>


</>:
<>
{post.image.length===3?
<>
<ImagesCarousel3  postimages={post.image}/>
</>:

<>
{post.image.length===4?
<>
<ImagesCarousel4  postimages={post.image}/>
</>:

<>

{post.image.length===5?
<>
<ImagesCarousel5  postimages={post.image}/>
</>:

<>

<img className="w-100" src={post.image[0]}/>
</>

}
</>

}

</>

}


</>}



</div>



     <div onClick={()=>ShowWarning()} className="post-footer sm-shadow-none">
     <div className="d-flex align-items-center justify-content-between">
<div className="d-flex align-items-center">


<Likeicon   className=" text-danger post-action click"  />




<Commenticon  className="post-action text-dark click" />

<Shareicon   className="post-action click" />

</div>
<BookmarkBorderIcon   className="post-action click" />

     </div>

     <div>

        
             <p className="extra-small">{post.total_likes} Likes</p>
     
       <div className="mt-2">
{post.comments?
<>
{post.comments.map((comment)=>(
  <>

<div className="d-flex comment2">
  <Avatar src={comment.profile_pic} className="comment-avatar" />
  <div>
    <h6 className="mb-0 pb-0">{comment.username}</h6>
    <p className="extra-small truncate truncate1">{comment.comment}</p>
  </div>
</div>
  </>
))}

</>:null}

       </div>
          <p  className="view-comment-text click mt-2">View all {post.total_comments} comments</p>
            </div>
</div>
  <div onClick={()=>ShowWarning()} className="post-comment align-items-center d-flex">
     <input  className="border-0 "></input>
     <button  className="btn btn-link">Post</button>
  </div>
     
     </div>  


{index===1?
<>





<div className="shadow d-none mt-4 mb-4 p-3">

<h4>Quiz Section</h4>

<h5>{quiz.question}</h5>

<div className="quiz-option">
  <h6>{quiz.option1}</h6>
</div>

<div className="quiz-option">
  <h6>{quiz.option2}</h6>
</div>


<div className="quiz-option">
  <h6>{quiz.option3}</h6>
</div>


<div className="quiz-option">
  <h6>{quiz.option4}</h6>
</div>
</div>

</>
:
<>

</>}


  </>
))}

</>:

<>

{loading?
<>
<Skeleton className="w-100 mt-2 mb-2" variant="rectangular" height={300}/>

<Skeleton className="w-100 mt-2 mb-2" variant="rectangular" height={300}/>

<Skeleton className="w-100 mt-2 mb-2" variant="rectangular" height={300}/>



</>
:
<>


</>}




</>}



 
     
    </div>
  )
}

export default DemoCommunityPosts