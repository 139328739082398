import axios from 'axios'
import ENDPOINT from '../../data/ENDPOINT'
import SecurityHeaders from '../../data/SecurityHeaders'
import Localbase from 'localbase'
const MyTimeline = async ({payload})=>{

const data = new FormData()


const db = new Localbase("db")

data.append("session",payload.user)
const res = await 
axios.post(ENDPOINT+`students-posts.php`,data,SecurityHeaders)
if(res){

   
          return res.data


  
}else{
    
}

}

export default MyTimeline