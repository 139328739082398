import { Avatar } from '@mui/material';
import React, { useState, useEffect, useContext, useRef } from 'react';
import { Link, useHistory } from 'react-router-dom';
import * as timeago from 'timeago.js';
import Box from '@mui/material/Box';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import Button from '@mui/material/Button';
import BackIcon from '@mui/icons-material/KeyboardBackspace';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { AuthContext } from '../../context/AuthContext';
import ModalStyle from '../../data/ModalStyle';
import { Close, FavoriteBorder, MoreVert, Send } from '@material-ui/icons';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FormatViews from './FormatViews';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import Offcanvas from 'react-bootstrap/Offcanvas';
import StudentAccessModal from '../../components/modals/StudentAccessModal';
import ShareIcon from './ShareIcon';
import SocialShare from '../../components/SocialShare';
import SocialShareModal from '../../components/modals/SocialShareModal';
import ENDPOINT from '../../data/ENDPOINT';
import axios from 'axios';

const ShortItem = ({ video, account, fullview }) => {

  const { user } = useContext(AuthContext);
  const [alert, setAlert] = useState(false);
  const [access, setAccess] = useState(false);

  useEffect(() => {
    if (account) {
      if (account.purchases && account.purchases.length > 0) {
        setAccess(true);
      }
    }
  }, [account]);

  const CloseStudentAccessModal = () => {
    setAlert(false);
  };

  return (
    <>
      <StudentAccessModal visibility={alert} HandleClose={CloseStudentAccessModal} />
      {account ? (
        access ? (
          fullview ? (
            <Link to={"/short/" + video.video_id}>
              <Design user={user} video={video} setAlert={setAlert} />
            </Link>
          ) : (
            <Design user={user} video={video} setAlert={setAlert} />
          )
        ) : (
          <Design user={user} video={video} setAlert={setAlert} />
        )
      ) : (
        <Design user={user} video={video} setAlert={setAlert} />
      )}
    </>
  );
};

export default ShortItem;

const Design = ({ video,user, setAlert }) => {
  const history = useHistory();
  const [showCaption, setShowCaption] = useState(false);
  const [share, setShare] = useState(false);
  const [liked, setliked] = useState(video.liked);
  const [likes,setlikes] = useState(parseInt(video.likes));
  const [playing, setPlaying] = useState(true);
  const videoRef = useRef(null);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.play();
    }
  },[]);

  const handleVideoClick = () => {
    if (videoRef.current) {
      if (playing) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setPlaying(!playing);
    }
  };

  const ToggleLike = async ()=>{
    console.log(' likes in '+likes)
        
    setliked(!liked)

         const formData = new FormData();
         
         
         //formData.append('whyus',document.getElementById('whyus').value)
         
         formData.append('session',user.SessionId);
         
           formData.append('video_id',video.video_id);
         
         
         const res = await axios.post(ENDPOINT+"short-like.php", formData,{
             headers: {
                 'content-type': 'multipart/form-data'
             }
         });
         if(res){
           //  //console.log("favouri "+JSON.stringify(res))
          if(res.data.status==="success"){
            
           
      setliked(res.data.liked)
        
           if(res.data.liked===true){
            setlikes((likes+1))
           }else{
            setlikes((likes-1))
           }
          
          }
          else{
         
          }
         }
         }
         
         
  return (
    <>
      <SocialShareModal handleClose={() => setShare(false)} visibility={share} data={{ title: "Share video", path: "https" }} />
      <Offcanvas placement='bottom' className="caption-canvas inactive-caption" show={showCaption} onHide={() => setShowCaption(false)}>
        <Offcanvas.Body onClick={() => setShowCaption(false)} className='d-flex justify-content-start'>
          <div className="position-relative caption-inner">
            <div className="d-flex align-items-center">
              <Avatar src={video.profile_pic} sx={{ height: 28, width: 28 }} />
              <div>
                <h6 className='ms-1 p-0 m-0 text-light'>{video.posted_by}</h6>
              </div>
            </div>
            <div className="pt-1 click" onClick={() => setShowCaption(true)}>
              <p className='text-12 text-light'>{video.description ? video.description : null}</p>
            </div>
          </div>
          {video.caption ? video.caption : null}
        </Offcanvas.Body>
      </Offcanvas>

      <div className="reel-item">
        <div className="reel-top d-flex align-items-center">
          <div onClick={() => { history.goBack() }} className="container click d-flex align-items-center">
            <BackIcon className='text-light' />
            <h6 className="p-0 m-0 ms-2 text-light">Short Videos</h6>
          </div>
        </div>
        <video
          ref={videoRef}
          poster={video.poster ? video.poster : null}
          onClick={handleVideoClick}
          className='w-100'
          src={video.url}
        ></video>

        {showCaption ? null : (
          <div className="reel-title">
            <div className="d-flex align-items-center">
              <Avatar src={video.profile_pic} sx={{ height: 28, width: 28 }} />
              <div>
                <h6 className='ms-1 p-0 m-0 text-light'>{video.posted_by}</h6>
              </div>
            </div>
            <div className="pt-1 click" onClick={() => setShowCaption(true)}>
              <p className={`text-12 text-light truncate truncate2 `}>{video.description ? video.description : null}</p>
            </div>
          </div>
        )}

        <div className="reel-actions text-light">
          <div>
            <div className="click" onClick={() =>ToggleLike()}>{!liked ? <FavoriteBorder /> : <FavoriteIcon className='text-danger' />}</div>
            <p>{likes ? likes : 0}</p>
          </div>
          <div onClick={() => setShare(true)} className='mt-2'>
            <ShareIcon className='send' />
            <p>{video.shares ? video.shares : 0}</p>
          </div>
          <div>
            <MoreVert />
          </div>
        </div>
      </div>
    </>
  );
};
