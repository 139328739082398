import axios from 'axios'
import ENDPOINT from '../data/ENDPOINT'
import SecurityHeaders from '../data/SecurityHeaders'

const GetWalkthroughs = async ({payload})=>{

const data = new FormData()
if(payload.limit){
     data.append("limit",payload.limit)
}
if(payload.query){
     data.append("query",payload.query)
}if(payload.SessionId){
     data.append("session",payload.SessionId)
}
const res = await 
axios.post(ENDPOINT+`get-walkthroughs.php`,data,SecurityHeaders)
if(res){
    
     return res.data
}

}
export default GetWalkthroughs

