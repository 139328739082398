import React, { useEffect, useState } from 'react'
import Carousel from 'react-bootstrap/Carousel'
const ImagesCarousel2 = ({postimages}) => {
     const [images,setimages] = useState(postimages)


     useEffect(()=>{
         
setimages(postimages)
     },[images])
  return (
<>

<Carousel variant="dark" wrap={false} >

 


  <Carousel.Item interval={99999999999999}>

   <img src={images[0]}  className="w-100"/>
  
      </Carousel.Item>
      <Carousel.Item interval={99999999999999}>

   <img src={images[1]}  className="w-100"/>
  
      </Carousel.Item>


 
  

  
    </Carousel>




</>
  )
}

export default ImagesCarousel2