import axios from 'axios'
import ENDPOINT from '../../data/ENDPOINT'
import SecurityHeaders from '../../data/SecurityHeaders'

const SubmitClassFeedback = async ({payload})=>{
    
const data = new FormData()
data.append("class_id",payload.class_id)

data.append("session",payload.session)
data.append("rating",payload.rating)
data.append("review",payload.review)
data.append("batch_id",payload.batch_id)
data.append("course_url",payload.course_url);
const res = await 
axios.post(ENDPOINT+`submit-class-feedback.php`,data,SecurityHeaders)
if(res){
    
     return res.data
}

}



export default SubmitClassFeedback