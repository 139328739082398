import React, { useEffect } from 'react'
import SetPage from '../../../data/SetPage'
import DoubtDiscussions from '../../trainer-support/discussions.jsx/DoubtDiscussions'
import StudentSupport from './StudentSupport'
import TrainerDropdown from './TrainerDropdown'

const TrainerSupport = ({account}) => {
     useEffect(()=>{
SetPage("Trainer Support")

     },[])
  return (
    <div className="min-vh-90">

   
    <div className="w-100  ">
{account?
<>

<StudentSupport account={account}/>
   
</>:
<>

</>}

</div>



    </div>
  )
}

export default TrainerSupport