import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import SearchCourses from '../../actions/SearchCourses'
import CourseItem from '../courses/CourseItem'

const SearchPage = () => {
const params = useParams()
    const NewSearch = async ()=>{

   
    
        if(params.query>2){
          const res = await SearchCourses({
            payload:{
              category:"courses",
              limit:12,
              search:params.query
            }
          })
          if(res){
            if(res.status==="success"){
              setresults(res.data)
            }
           
          }
        }
      
       }
const [results,setresults] = useState(null)
       useEffect(()=>{
NewSearch()
       },[])
  return (
    <div className="container-fluid">
        

        <div className="container">


{results?<>



<CourseItem/>

</>:
<>

</>}


        </div>
        
        
        </div>
  )
}

export default SearchPage