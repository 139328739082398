import Row from 'react-bootstrap';
import Button from 'react-bootstrap/Button'
import {useState,useEffect} from 'react'
import React from 'react';
import axios from "axios";
const Examportal=()=>{
const [questions,setquestion]=useState();
const [questionnumber,setquestionnumber]=useState("1")
useEffect(() => {
    Fetch_question();
  }, [questionnumber]);
  const Fetch_question = async (e) => {
    const formdata = new FormData();
    formdata.append('q_id',questionnumber);
    const res = await axios.post(
      "https://skillsuprise.com/question_polls.php",
      formdata,
      {
        headers: { "content-type": "multipart/form-data" },
      }
    );
    if (res) {
      //console.log("" + JSON.stringify(res));
      if (res.data.status === "success") {
        setquestion(res.data.data);
      }
    }
  };






    return(
        


        
        <div className="container-fluid Examportal">
         

            <div className="d-flex flex-wrap space-evenly mt-5">
                <div className="Q-section col-lg-7 col-md-7 col-sm-7 text-left">
                    <div className="question">
kg
                    {questions?
                        <>
                        {questions.map(question=>(
                        <>
                        <div key={question.q_id}>
                        <p className="m-0">{question.q_id}</p>
                        </div>

                        </>
                        ))}
                        </>
                        :<>
                        no results
                        </>}


                    <p>1.</p>
                    </div>
                    <div>
                    <Button className="options mt-1 text-dark w-100">A.</Button>
                    <Button className="options mt-1 text-dark w-100">B.</Button>
                    <Button className="options mt-1 text-dark w-100">C.</Button>
                    <Button className="options mt-1 text-dark w-100">D.</Button>
                    </div>

                    <div className="d-flex flex-wrap mt-5 justify-content-around">
                    <Button className="col-lg-2 col-md-3 col-sm-12 p-button mt-5">Previos</Button>
                    <Button className="col-lg-2 col-md-3 col-sm-12 m-button mt-5">Mark</Button>
                    <Button className="col-lg-2 col-md-3 col-sm-12 n-button mt-5">Next</Button>
                    
                    </div>
                    
                </div>
                <div className="Q-menu col-lg-4 col-md-4 col-sm-4 rounded d-flex flex-wrap ms-3 h-100">
                    <div>
                    <div className="btn btn-round pt-2 text-light rounded-circle m-2" id="1" onClick={(e)=>{setquestionnumber(e.target.id);}}>1</div>
                    <div className="btn btn-round pt-2 text-light rounded-circle m-2"id="2" onClick={(e)=>{setquestionnumber(e.target.id);}}>2</div>
                    <div className="btn btn-round pt-2 text-light rounded-circle m-2"id="3" onClick={(e)=>{setquestionnumber(e.target.id);}}>3</div>
                    <div className="btn btn-round pt-2 text-light rounded-circle m-2"id="4" onClick={(e)=>{setquestionnumber(e.target.id);}}>4</div>
                    <div className="btn btn-round pt-2 text-light rounded-circle m-2"id="5" onClick={(e)=>{setquestionnumber(e.target.id);}}>5</div>
                    </div>
                   

                   
                </div>
                
                
            </div>

                  </div>
                
             
    );
}
export default Examportal;
