import axios from 'axios';
import React from 'react'
import ENDPOINT from '../../data/ENDPOINT';

const TrackJourney = async ({payload})=>{
        

     const formData = new FormData();
     
     
     //formData.append('whyus',document.getElementById('whyus').value)
     
     formData.append('session',payload.user);
     
       formData.append('course_url',payload.course_url);
       formData.append('batch_id',payload.batch_id);
     
     
     const res = await axios.post(ENDPOINT+"get-journey.php", formData,{
         headers: {
             'content-type': 'multipart/form-data'
         }
     });
     if(res){
      

      return res.data
     }
     }
export default TrackJourney