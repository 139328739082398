import React, { useEffect } from 'react'
import CourseRewards from './CourseRewards'
import SetPage from '../../../data/SetPage'
const CourseRewardsPage = () => {
  useEffect(()=>{
SetPage("Course Rewards")
  },[])
  return (
    <div>
     
   <div className="blue-bg">
   <div className="container  justify-content-between d-flex flex-wrap">

<div className="col-lg-3 d-none d-md-block">

  <img src="https://skillsuprise.com/resources/images/course-badges.svg" className="w-100"/>
</div>

<div className="col-lg-8  text-light pt-5 sm-mb-50">

<h1 className="sm-20">Learn Better Skills & Earn Best Rewards</h1>
<p className="light-grey">Everytime you learn something new or do a heroic job, you will earn rewards and certificates.</p>
</div>


</div>
  
     </div>  

<div className="container pb-5  pt-4">

<div className="grid lg-grid-6 md-grid-5 sm-grid-3 grid-2">
<CourseRewards/>

</div>
</div>


</div>
  )
}

export default CourseRewardsPage