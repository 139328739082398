import React from 'react';
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import CommunityChat from '../chat/CommunityChat';
import chaticon from '../../assets/images/chat.png'
import Close from '@mui/icons-material/Close';
import { Avatar } from '@mui/material';
function CourseBatchChat({course_title,data}) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
{show?
<>


</>:
<>

<div  onClick={()=>handleShow()} className="course-batch-chat click">
  <img src={chaticon} className="w-100"/>

    </div>
</>}
 
  

      <Offcanvas placement='end' className="community-chat-offcanvas" show={show} onHide={()=>handleClose()}>
       
       <div className="p-3 border border-2 border-light bg-dark text-light d-flex justify-content-between">
<div className="d-flex">
 <img src={data.course_cover} className='h-100'/>
<div>
<h5>{course_title}</h5>
<p className='text-light'>Community Chat</p>
</div>
</div>
<Close onClick={handleClose} className='click'/>
       </div>
       
      
        <Offcanvas.Body>
<CommunityChat />
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default CourseBatchChat;