import { CircularProgress } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import GetCourses from '../../actions/GetCourses'
import CourseItem from './CourseItem'
import CourseItemSkeleton from './CourseItemSkeleton'
import Localbase from 'localbase'
import CoursesSkeleton from '../../components/skeletons/Courses'
const CoursesList = ({mode,limit}) => {
const [loading,setloading] = useState(true)
const db = new Localbase("db")
  const NewGetCourses = async({mode})=>{
    //setloading(true)
    const res = await GetCourses({payload:{
      mode:mode,
      limit:limit||50
    }});
    if(res){
    setloading(false)
      if(mode==="offline"){
        db.collection("paths").add({
          offline_courses:res
        },"offline_courses")
      }
      else if(mode==="specialization"){
        db.collection("paths").add({
          specialization_courses:res
        },"specialization_courses")
      }
      else if(mode==="live"){
        db.collection("paths").add({
          live_courses:res
        },"live_courses")
      }   else if(mode==="selfpaced"){
        db.collection("paths").add({
          selfpaced_courses:res
        },"selfpaced_courses")
      }
      setcourses(res)
     
    }
  }

  useEffect(()=>{
    db.collection("paths").doc("free_courses").get().then(data=>{
      setcourses(data.free_courses)
    }).catch(error=>{
      
    })
    db.collection("paths").doc("offline_courses").get().then(data=>{
      setcourses(data.offline_courses)
    }).catch(error=>{
      
    })
    db.collection("paths").doc("selfpaced_courses").get().then(data=>{
      setcourses(data.selfpaced_courses)
    }).catch(error=>{
      
    })
    db.collection("paths").doc("live_courses").get().then(data=>{
      setcourses(data.live_courses)
    }).catch(error=>{
      
    })
    db.collection("paths").doc("specialization_courses").get().then(data=>{
      setcourses(data.specialization_courses)
    }).catch(error=>{
      
    })
    if(courses){
      setloading(false)
    }
NewGetCourses({mode}) 
  },[window.location,mode])


  var scrollAmount ;

  
   const buttonclick = ()=> {
        var container = document.getElementById('container'+mode);
        sideScroll(container,'right',15,300,10);
    };
    
    
    const back = ()=> {
        var container = document.getElementById('container'+mode);
        sideScroll(container,'left',15,300,10);
    };
    
    function sideScroll(element,direction,speed,distance,step){
        scrollAmount = 0;
        var slideTimer = setInterval(function(){
            if(direction === 'left'){
                element.scrollLeft -= step;
            } else {
                element.scrollLeft += step;
            }
            scrollAmount += step;
            if(scrollAmount >= distance){
                window.clearInterval(slideTimer);
            }
        }, speed);
    }
    






  const [courses,setcourses] = useState(null) 
  return (
    <div className="w-100 d-flex flex-wrap">

      <div className="d-flex w-100 justify-content-between">

<div>
  
</div>

    
      </div>
     

{loading?
<>
<div className=" mt-3 mb-3 container">

<CoursesSkeleton/>
</div>
</>:
<>
<div  className="d-flex animation-active flex-wrap">




{courses?

<>
{courses.map((course,index)=>(
<>

<CourseItem course={course} index={index+1}/>



</>

))}
</>:

<>
<div className="justify-content-center text-center mt-3 mb-3 mx-auto">
<CircularProgress />
</div>
</>}



</div>
</>}

    </div>
  )
}

export default CoursesList