import React, { useEffect } from 'react'
import Rating from '@mui/material/Rating';
import { Link } from 'react-router-dom';
import axios from 'axios';
import ENDPOINT from '../../data/ENDPOINT';
import SecurityHeaders from '../../data/SecurityHeaders';
import { useState } from 'react';
import { Skeleton } from '@mui/material';
const CourseSuggestions = ({limit}) => {

const [courses,setcourses] = useState(null)
const GetCourse = async()=>{
const data = new FormData()
data.append("limit",limit?limit:5)
data.append("category","top")
data.append("mode","live")
const res = await axios.post(ENDPOINT+"get-courses.php",data,SecurityHeaders)

if(res){
     setloading(false)
     setcourses(res.data)
      //  //console.log("courses are "+JSON.stringify(res))
}



}

useEffect(()=>{
GetCourse()
},[])
const [loading,setloading] = useState(true)
  return (
    <div className="course-suggestions">


{courses?
<>

<div className="d-flex justify-content-between align-items-center">
<h5>Popular Courses</h5>
 <Link to="/courses" className="">View All</Link>
</div>
<hr className="mt-0"/>
{courses.map((course)=>(
     <>
     <Link className="text-dark popular-course" to={"/course/"+course.course_url+"/"+course.mode}>

     <div className="d-flex mt-1 mb-1">
     <img src={course.course_cover_url} className="cover"/>
     <div>
          <h6 className="truncate truncate2">{course.course_title}</h6>
                  <div className="d-flex mt-2 align-items-center">
                  <Rating size='small' name="read-only" value={course.rating} readOnly /> 
({course.ratings})
          </div>
          
     </div>
</div>

     </Link>



     </>
))}

</>:
<>

{loading?
<>

<Skeleton variant="rectangular" height={100} className="mt-2 mb-2 w-100"/>
<Skeleton variant="rectangular" height={100} className="mt-2 mb-2 w-100"/>


<Skeleton variant="rectangular" height={100} className="mt-2 mb-2 w-100"/>
<Skeleton variant="rectangular" height={100} className="mt-2 mb-2 w-100"/>
<Skeleton variant="rectangular" height={100} className="mt-2 mb-2 w-100"/>

</>:
<>


</>}


</>}

 






    </div>
  )
}

export default CourseSuggestions