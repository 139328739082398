import React, { useContext, useEffect, useState } from 'react'
import ENDPOINT from '../../../data/ENDPOINT'
import SecurityHeaders from '../../../data/SecurityHeaders'
import { AuthContext } from '../../../context/AuthContext'
import axios from 'axios'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { Avatar } from '@mui/material'
import GetAssignments from '../../../actions/course/GetAssignments'
import SetPage from '../../../data/SetPage'

const BatchProgress = () => {
    const params = useParams();
    const {course_url,batch_id} = params;

const {user} = useContext(AuthContext)

    const [submissions,setsubmissions] = useState([])
const BatchSubmission = async()=>{
const data = new FormData()
data.append("session",user.SessionId)
data.append("course_url",course_url)
data.append("batch_id",batch_id)

  const res = await axios.post(ENDPOINT+"batch-assignment-submissions-overview.php",data,SecurityHeaders)

  if(res){

    if(res.data.status==="success"){
setsubmissions(res.data.data)


    }
  }
}



useEffect(()=>{
BatchSubmission()
},[])
const [report, setreport] = useState(null)

const NewAssignments = async () => {
  const res = await GetAssignments({
    payload: {
      user: user.SessionId,
      course_url: course_url,
      batch_id: batch_id
    }
  })
  if (res) {
    // setloading(false)
    ////console.log(" assignments"+JSON.stringify(res))
    if (res.status === "success" && res.data !== null) {
      //  //console.log("Report "+JSON.stringify(res))
      // setassignents(res.data)
      setreport(res.report[0])
     
    }

  }
}
 
console.log(report)
useEffect(() => {
  SetPage("Batch Progress")
NewAssignments()

}, [])
  return (
    <div className='container'>
        <div className='d-flex min-vh-90 pt-3 flex-wrap justify-content-between'>
   <div className='col-lg-6 col-12 overflow-y invisible-scrollbar'>

     {
          submissions?<>  {submissions.map((item,index)=>(
            <div className='col-12 '  key={index}>
          
           
         
           <div className=' col-12 '>
            <div className='d-flex hover  rounded-4 p-3'>
              <Avatar src={item.profile_pic} alt="Remy Sharp" sx={{ width: 55, height: 55 }} />
              <div className='ms-2 w-100'>
                <h6 className='m-0 p-0'>{item.first_name+" "+item.last_name}</h6>
                <p className='m-0 p-0'>{item.submission_count } Submitted</p>
                <div class="progress" role="progressbar" aria-label="Example 1px high" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100" style={{ height: '06px' }}>
                  <div class="progress-bar" style={{ width: '50%' }}></div>
                </div>
              </div>
            </div>
          </div>
          
          
            
        
  
            
            </div>
  
          ))}</>:null
        }

   </div>
   <div className='col-lg-5 col-12 d-none d-md-block bg-white shadow mb-5'>
<div className='p-4'>
<h5 className='mb-3'>
  How to earn your points
</h5>
<ol>
  <li>Make sure you submit assignment on or before deadline to gain more points</li>
  <li>Also reach your milestones to get more points</li>
</ol>
</div>
   </div>
 

</div>
      
    </div>


  )
}

export default BatchProgress
