import React from 'react'
import { useContext } from 'react'
import { AuthContext } from '../../context/AuthContext'
import Box from '@mui/material/Box';
import defaultdp from '../../assets/user.png'
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { useState } from 'react';
import ModalStyle from '../../data/ModalStyle';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { Close } from '@mui/icons-material';
import CollegeContactForm from '../forms/CollegeContactForm';
const CollegeContactModal = ({visibility,HandleClose,access}) => {
    const {user} = useContext(AuthContext)
  const [student,setstudent] = useState(access)
  return (
    <>
    
    
<Modal
  open={visibility}
  onClose={HandleClose}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
  <Box sx={ModalStyle} className="p-3 br-5  p-2 rounded  col-11 col-sm-6 col-md-3 col-lg-4">

    <div className="d-flex justify-content-between">
<h4>Contact Us</h4>
<Close className='click' onClick={HandleClose}/>
    </div>
    <hr></hr>
<CollegeContactForm/>
  </Box>
</Modal>

    </>
  )
}

export default CollegeContactModal