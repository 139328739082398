import axios from 'axios'
import ENDPOINT from '../data/ENDPOINT'
import SecurityHeaders from '../data/SecurityHeaders'

const FooterCoursesList = async ({payload})=>{

const data = new FormData()

data.append("mode",payload.mode)
const res = await 
axios.post(ENDPOINT+`footer-courses-list.php`,data,SecurityHeaders)
if(res){
    
     return res.data
}

}

export default FooterCoursesList