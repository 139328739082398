import axios from 'axios'
import React, { useEffect, useState } from 'react'
import ENDPOINT from '../../data/ENDPOINT'
import SecurityHeaders from '../../data/SecurityHeaders'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import InputLabel from '@mui/material/InputLabel';
import ModalStyle from '../../data/ModalStyle';
import SetPage from '../../data/SetPage';
import { PlayCircle } from '@mui/icons-material';
import Localbase from 'localbase';
import CheatSheetsSkeleton from '../../components/skeletons/CheatSheets';
import { Close } from '@material-ui/icons';

import Rating from '@mui/material/Rating';
import { Link } from 'react-router-dom/cjs/react-router-dom';
const FreeResources = ({account}) => {
let db = new Localbase('db')

db.collection("paths").doc("free_resources").get().then(data=>{
     setcategories(data.cheat_sheets)
    // setloading(false)
   }).catch(error=>{
     
   })
useEffect(()=>{
     if(loaded){

     }else{
          window.scrollTo(0,0)
     }

},[])

const [loaded,setloaded] = useState(false)
const [categories,setcategories] = useState(null)

const GetQuizCategories = async()=>{
//setloading(true)
     const data = new FormData()
     
     const res = await axios.post(ENDPOINT+"get-free-resources.php",data,SecurityHeaders)

     if(res){
setloading(false)
if(res.data.status==="success"){


     db.collection("paths").add({
          cheat_sheets:res.data.data
     },'free_resources')
setcategories(res.data.data)

}

     }
}

useEffect(()=>{

  if(categories){
setloading(false)
}
     
GetQuizCategories()
     
     
SetPage("Cheat Sheets")
},[])
const [loading,setloading] = useState(true)
const [modal,setmodal] = useState(false)

  return (


     <>



     


<Modal
  open={modal}
  onClose={()=>{setmodal(false)}}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
  <Box className="col-lg-4 col-md-5 col-sm-6 col-11 p-3 br-5"  sx={ModalStyle}>
    
      <div className="modal-header">
          <h4>    Student Access Only !</h4>
          <Close className="click" onClick={()=>setmodal(false)}/>
      </div>

    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
   {account?
   <>
   Please register a course to access this section

   </>:
   <>
    Login into your account to access this section

   </>} 

     <div className="d-flex justify-content-between mt-4">
<button onClick={()=>setmodal(false)} className="btn col-5  btn-outline-danger rounded-pill">Cancel</button>
{account?
<>
<Link to="/courses" className="btn btn-primary  col-5 rounded-pill">Browse Courses</Link>
 
</>:
<>
<Link to="/login" className="btn btn-primary col-5  rounded-pill">Login</Link>
 
</>}
    </div>
    </Typography>
  </Box>
</Modal>

<div className="interview-prep-cover bg-dark sm-p-15 pt-4 text-light text-center min-vh-30">

<h2>Free Resources</h2>
<div className="col-lg-6 col-md-6 col=sm-10 col-12 mx-auto text-center mt-3">
<p className="text-light">Get ahead with our comprehensive resources. Designed to help you learn and retain information quickly. Access important information and concepts with our easy-to-use reference guides.</p>
</div>

</div>

         <div className="min-vh-100 interview-praparation pb-5">
     













     <div className="container-lg mt-3 d-flex flex-wrap">



{categories?

<>


     
<>
{categories.map((item)=>(
     <>
<div className="col-lg-3 col-md-3 col-sm-4 col-6  sm-p-0 p-3 bg-white rounded shadow p-3">

<img src={item.image} className="w-100 "/>
 
<div    className="   p-3">
<div className=" w-100">
     <div className="w-100">
     <h5>{item.title}</h5>
     <Rating name="read-only" value={item.rating?item.rating:5} readOnly />
<div className="d-flex justify-content-between">
<p className="text-secondary"><b>Pages : </b>{item.pages?item.pages:"80"}</p>
<p className="text-secondary"><b>Size : </b>{item.size?item.size:"35"} mb</p>
</div>

<div className="d-flex justify-content-between mt-3">
<Link to={"/resource/"+item.resource_url} className="btn btn-primary rounded rounded-0  btn-sm w-100 ">View</Link>

</div>

     </div>
</div>
</div>
  





</div>



     </>
))}
</>

</>

:
<>
{loading?
<>

<div className="mt-0 w-100 text-center">

<CheatSheetsSkeleton/>
</div>
</>:null}

</>}








     </div>
     
     </div>
     
     </>

  )
}

export default FreeResources