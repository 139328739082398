import axios from 'axios'
import React, { useContext, useState } from 'react'
import { AuthContext } from '../../context/AuthContext'
import ENDPOINT from '../../data/ENDPOINT'
import UserAccount from '../account/UserAccount'
import Localbase from 'localbase'
import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import MyPostsTemplate from './MyPostsTemplate'
import SetPage from '../../data/SetPage'
const MyPosts = ({account}) => {

const db = new Localbase("db")
const {user} = useContext(AuthContext)


     const GetPosts = async (status)=>{
          setloadmore(false)
  
    const formData = new FormData();
    formData.append('session',user.SessionId);
    formData.append('limit',30)
    const res = await axios.post(ENDPOINT+"my-posts.php", formData,{
        headers: {
            'content-type': 'multipart/form-data'
        }
    });
    if(res){
     //  //console.log("student posts"+JSON.stringify(res.data))
     if(res.data.status==="success"){
  
  
  
     
      
  
      if(append){
        setposts(res.data.posts)
        
       
      
  
   
    
  
        if((res.data.posts).length>=10){
  setloadmore(true)
  
  
  
        }
      }
      else{
        setposts(res.data.posts)
      }
     }
     else{
    
     }
    }
    }
  
  


const [posts,setposts] = useState(null)
const [loadmore,setloadmore] = useState(false)

const [append,setappend] = useState(false)
useEffect(()=>{
GetPosts()
SetPage("My Posts")
},[])

  return (
 <>
 <MyPostsTemplate>
<>

{posts?
<>
<div className="mt-3 grid lg-grid-3 md-grid-3 grid-2">

{posts.map((post)=>(
     <>


     <div className="my-post border-1">
          <Link className="w-100" to={"/newsfeed/"+post.post_id}>
          <img className="w-100" src={post.image}/>
          
          
          </Link>


     </div>
     
     
     </>
))}
</div>
</>:


<>

<div className="text-center mt-5">
<p>No Posts Found</p>
</div>
</>

}



</>

 </MyPostsTemplate>


 
 </>
  )
}

export default MyPosts