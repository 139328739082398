import React from 'react'
import { Link } from 'react-router-dom'

const StudentPassCta = () => {
  return (
    <div>

<div className="container mt-5 sm-p-15 sm-br-0 text-light br-5  p-5 mb-5 bg-primary student-pass-cta">

<div className="d-flex ">
<div className="col-lg-10">
<h6 >Offer ends on 25th Jan !</h6>
     <h2 className="text-warning">Get Access to 20+ Courses <span>@ ₹9,999</span></h2>
     
     <p className="text-light d-none d-sm-block">lifetime access to all online & selfpaced courses, placement training programs, placement preparation kits, students communities, and many more. As we honour our teachers missions this teachers day by providing quality education at affordable price for every student.</p>

     <div className="d-flex align-items-center mt-3 col-md-6 col-lg-5">
      <div className="w-60">
      <a href="https://rzp.io/l/skp-studentpass" target="_blank" className="btn br-5 btn-warning w-100  mr-15">Buy Student Pass</a>

      </div>
<Link to="/student-pass" className="btn w-fit btn-link text-white sm-text-center ">Read More</Link>
 </div>
</div>


</div>




</div>





    </div>
  )
}

export default StudentPassCta