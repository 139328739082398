import React from 'react'

const LabPage = () => {
  return (
    <div className="min-vh-100">
    <div className="d-flex">

    <div className="col-lg-3 p-3">
<h2>Instructions</h2>


     </div>
     <div className="col-lg-9 bg-dark min-vh-100">


     </div>
     
    </div>
     </div>
  )
}

export default LabPage