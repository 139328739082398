import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Close from '@mui/icons-material/Close'
import ModalStyle from '../data/ModalStyle';

const LearnIntern = ({ novideo }) => {

  const [demo, setdemo] = useState(false)


  const Animate = () => {
    //  //console.log("sc "+window.scrollY)
    if (window.scrollY > 400) {
      if (document.getElementById("animate")) {
        document.getElementById("animate").classList.add("animated")

      }
    }

  }
  useEffect(() => {

  }, [])
  return (
    <div id="animate" className="text-center">


      <Modal
        open={demo}
        onClose={() => setdemo(false)}

      >
        <Box sx={ModalStyle} className="col-lg-4 rounded col-md-5 br-5 col-sm-6 col-11 p-2">
          <div className="d-flex justify-content-between">
            <h4>Introduction</h4>
            <Close className="text-danger" type="button" onClick={() => setdemo(false)} />
          </div>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <video className="w-100" controls autoPlay src="https://skillsuprise.com/data/videos/feedback1.mp4"></video>
          </Typography>
        </Box>
      </Modal>

      <h1 className="font-weight-bold tracking-in-expand "><b className="text-primary">Learn </b>- <b className="text-danger ">Intern</b> - <b className="secondary-text">Get a job</b></h1>
      <p className="col-lg-7 col-md-8 col-12 text-center mx-auto">With our special approach of Learn | Intern |
        Get a job model, 98% of our students got
        internships within 3-6 months after completion
        of trainings and 95% students got placement
        opportunities within 1 year.</p>

      {novideo ? <>

      </> :
        <>
          <button className="btn btn-sm rounded-pill">Watch Demo</button>

          <div class="d-flex justify-content-center align-items-center mt-5">
            <div class="pulse-center " type="button" onClick={() => setdemo(true)} >
              <div class="pulse">
                <svg xmlns="http://www.w3.org/2000/svg" width="31.499" height="36.002" viewBox="0 0 31.499 36.002">
                  <path id="Icon_awesome-play" data-name="Icon awesome-play" d="M29.841,15.1,5.091.464A3.356,3.356,0,0,0,0,3.368V32.625a3.372,3.372,0,0,0,5.091,2.9L29.841,20.9a3.372,3.372,0,0,0,0-5.808Z" transform="translate(0 -0.002)" fill="#fff"></path></svg>
              </div></div>
          </div>

        </>}



    </div>
  )
}

export default LearnIntern