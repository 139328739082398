
import React, { useContext } from 'react'
import axios from 'axios'
import ENDPOINT from '../../data/ENDPOINT'
import { AuthContext } from '../../context/AuthContext'
import { useState } from 'react'
import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import MyPostsTemplate from '../community/MyPostsTemplate'
import SuggestionItem from './SuggestionItem'
const WatchLaterPage = () => {
const {user} = useContext(AuthContext)



const [history,sethistory] = useState(null)
     const getDoubts = async ()=>{
        
     
          const formData = new FormData();
          
          
          //formData.append('whyus',document.getElementById('whyus').value)
          
          formData.append('session',user.SessionId);
          
     
          
          const res = await axios.post(ENDPOINT+"watch-later-list.php?limit=10", formData,{
              headers: {
                  'content-type': 'multipart/form-data'
              }
          });
          if(res){
           // //  //  //console.log(JSON.stringify(res.data.data))
     
           if(res.data.status==="success"){
         
 sethistory(res.data.data)
                 
           }
           else{
          
           }
          }
          }




    
     
     
     
     useEffect(() => {
     window.scrollTo(0, 0)
     getDoubts()
     }, [])








     





     return (
          <>
               
      <MyPostsTemplate>

     <>
     
     
     <div className="w-100 vh-100 overflow-y">




{history?

<>
{/*HISTORY EXISTS*/}
{history.map((suggestion)=>(

<>

<SuggestionItem suggestion={suggestion} />

</>

))}



<div className="text-center">
<p type="button">Load More</p>
</div>
</>

:<>

<div className="text-center mt-5">
     <h4>No Videos</h4>
</div>
{/*NO VIDEOS*/}
</>
}











</div>
     </>
      </MyPostsTemplate>
          
          </>
     )
}

export default WatchLaterPage









