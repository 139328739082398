import axios from 'axios'
import { useState } from 'react'
import ENDPOINT from '../../data/ENDPOINT'
import SecurityHeaders from '../../data/SecurityHeaders'

const JoinClass = async ({payload})=>{

const data = new FormData()
data.append("course_url",payload.course_url)
data.append("status","joined")
data.append("batch_id",payload.batch_id)
data.append("class_id",payload.class_id)
if(payload.student_id){
     data.append("student_id",payload.student_id)
}
if(payload.user){

     data.append("session",payload.user)
}

const res = await 

axios.post(ENDPOINT+`join-class.php`,data,SecurityHeaders)
if(res){
    
     return res.data
}

}





export default JoinClass