import React, { useEffect, useState } from 'react'
import ENDPOINT from '../../data/ENDPOINT'
import axios from 'axios'
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
import { Badge } from 'react-bootstrap';
import SecurityHeaders from '../../data/SecurityHeaders';


const JobSearchComponent = () => {


    const [jobs, setJobs] = useState([])
    const [companyname, setCompanyname] = useState([])
    const [searched, setSearched] = useState(false)
    const [searchtype, setSearchType] = useState([])

    const GetJobs = async (payload) => {

        const data = new FormData()
        if (payload === "company_name") {
            data.append('key', searchtype)
            data.append('search_name', companyname)
        }

        try {
            const response = await axios.post(ENDPOINT + "get-job-hunt.php", data, { 'content-type': 'multipart/form-data' })

            if (response.data.status === 'success') {
                setJobs(response.data.jobs);
            } else {
                console.error('Error fetching jobs:', response.data.message);
            }
        } catch (error) {
            console.error('Error fetching jobs:', error);
        }
        setSearched(true); // Mark search as performed
    };


    return (
        <>
            <div className='bg-fourth p-3 pt-5 rounded-4 ms-2 me-2'>

                {/* <div className='text-center'>
                    <h1 className='mx-auto'>Recomended Jobs For You</h1>

                    <small className='mx-auto'>Explore Position curated  specifically for your skills and preference</small>

                    <div className='bg-white mt-3 d-flex p-2 col-xl-5 col-lg-5 col-md-10 col-sm-12 col-12 mx-auto rounded-5 shadow'>
                        <div className='col-4' role="button" tabindex="0" onClick={() => GetJobs()}>
                            <p className='fw-semibold text-light bg-dark rounded-4 m-0 fs-xl-5 fs-lg-5 fs-md-5 fs-sm-6 fs-6'>Latest Jobs</p>
                        </div>

                        <div className='col-4' role="button" tabindex="0" onClick={() => GetJobs("popular_companies")}>
                            <p className='fw-semibold text-secondary rounded-4 m-0 fs-xl-5 fs-lg-5 fs-md-5 fs-sm-6 fs-6'>popular company</p>
                        </div>

                        <div className='col-4' role="button" tabindex="0" onClick={() => GetJobs("trending_jobs")}>
                            <p className='fw-semibold text-secondary rounded-4 m-0 fs-xl-5 fs-lg-5 fs-md-5 fs-sm-6 fs-6'>Trending Jobs</p>
                        </div>
                    </div>

                </div> */}

                <form onSubmit={(event) => { event.preventDefault(); GetJobs() }}>

                    <div className='col-lg-4 col-md-6 col-sm-6 mx-auto d-flex mt-5'>

                        <Paper
                            className='w-100'
                            component="form"
                            sx={{ p: '2px 4px', display: 'flex', alignItems: 'center' }}
                        >
                            <select onChange={(e) => setSearchType(e.target.value)}>
                                <option value={"all"}>All</option>

                                <option value={"popular_companies"} onClick={() => GetJobs()}>Popular Companies</option>
                                <option value={"trending_jobs"}>Trending Jobs</option>
                                <option value={"latest_jobs"}>Latest Jobs</option>
                            </select>
                            <div className='border-start'>
                                <InputBase onChange={(e) => { setCompanyname(e.target.value) }}
                                    sx={{ ml: 1, flex: 1 }}
                                    placeholder="Search Jobs"
                                    inputProps={{ 'aria-label': 'search google maps' }}
                                />
                            </div>
                            <IconButton onClick={() => GetJobs('company_name')} type="button" sx={{ p: '10px', ml: 3 }} aria-label="search">
                                <SearchIcon />
                            </IconButton>


                        </Paper>

                    </div>
                </form>


                <div className='d-flex flex-wrap container mt-3'>

                    {searched && jobs ? <>
                        {jobs.map((item) => (
                            <div className='col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 p-xl-4 p-lg-2 p-md-2 p-sm-3 p-3'>
                                <div className='bg-white rounded-4 shadow p-3 d-xl-block d-lg-block d-md-flex d-sm-flex d-flex flex-wrap'>


                                    <div className='col-xl-8 col-lg-8 col-md-4 col-sm-6 col-6  logo-section ps-3'>

                                        <div>
                                            <b>{item.company_name}</b>
                                            <small className='d-block'>2 months ago</small>
                                        </div>
                                    </div>

                                    <div className='d-flex col-xl-7 col-lg-7 col-md-8 col-sm-12 col-12 justify-content-between'>

                                        <div className='col-xl-12 col-lg-12 col-md-5 col-sm-5 col-8 p-xl-3 p-lg-3 p-md-3 p-sm-2 '>
                                            <Badge className='bg-fourth p-2 rounded-3 '>
                                                <small className='p-0 m-0 fw-semibold '>{item.location}</small>
                                            </Badge>
                                        </div>

                                        <div className='col-xl-12 col-lg-12 col-md-5 col-sm-5 col-5 p-xl-3 p-lg-3 p-md-3 p-sm-2 '>
                                            <Badge className='bg-fourth p-2 rounded-3 '>
                                                <small className='p-0 m-0 fw-semibold '>{item.mode}</small>
                                            </Badge>
                                        </div>

                                        <div className='col-xl-2 col-lg-2 col-md-1 col-sm-1 col-1 '>

                                        </div>




                                    </div>

                                    <div className='ps-3 d-xl-block d-lg-block d-md-flex d-sm-flex d-block '>
                                        <div>
                                            <h4 className='p-0 m-0'>{item.title}</h4>

                                            <small>{item.responsibilities}</small>
                                        </div>

                                        <div className='col-xl-6 col-lg-6 col-md-4 col-sm-4 col-12 mt-3'>
                                            <div className="btn btn-transparant border border-3 border-success d-block rounded-3">
                                                <p className='fw-semibold text-success w-100'>Apply Now</p>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        ))}
                    </> :
                        <>

                        </>}

                </div>

                {jobs.length > 0 ? <div className='d-flex justify-content-center mt-4 mb-3'>
                    <div className="btn btn-success rounded-3 d-xl-block d-lg-block d-md-flex d-sm-flex d-flex flex-wrap ">
                        <p className='m-0'>Browse More Jobs</p>
                    </div>
                </div> : null}

            </div>
        </>
    )
}

export default JobSearchComponent