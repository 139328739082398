import axios from 'axios'
import ENDPOINT from '../data/ENDPOINT'
import SecurityHeaders from '../data/SecurityHeaders'

const GetCourseDetails = async (course_url,mode,user)=>{

const data = new FormData()
data.append("course",course_url)
data.append("mode",mode)
if(user){
     data.append("session",user.SessionId)
}
const res = await 
axios.post(`https://skillsuprise.com/coursedata.php`,data,SecurityHeaders)
if(res){
    
     return res.data
}

}
export default GetCourseDetails