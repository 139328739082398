import React, { useContext } from 'react'
import { useEffect, useState } from 'react'
import axios from 'axios';
import { Link } from 'react-router-dom'
import rewardduplicate from '../../assets/reward-duplicate.png'
import ENDPOINT from '../../data/ENDPOINT';
import { AuthContext } from '../../context/AuthContext';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Lock from '@mui/icons-material/Lock';
import Localbase from 'localbase'
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import DoneIcon from '@mui/icons-material/Done';
import RedeemIcon from '@mui/icons-material/Redeem';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const ScratchCards = () => {
 


    return (
        <Styled>
            <>
           
                <div className='container-fluid p-0 p-md-5  col-lg-12 '>

                  
                        <div className='d-flex flex-wrap justify-content-between '>
                            <div className='  col-xl-4 col-lg-4 col-md-5 col-sm-12 col-12 p-3'>
                           <div className="border card bg-white rounded-4 border mb-4">
                           <div className='  p-2 p-md-3 p-lg-3 '>
                                    <h2 className='text-center mt-3'>Online Course</h2>
                                    <p className='text-center mt'>Everything you need to learn a new skill from your home</p>


                                    <div className='mt-3'>
                                        <div className='d-flex'>
                                            <DoneIcon color="success" />
                                       
                                            <p className='ms-1'>Daily online class</p>
                                        </div>
                                        <div className='d-flex'>
                                            <DoneIcon color="success" />
                                       
                                            <p className='ms-1'>Daily class recording</p>
                                        </div>
                                        <div className='d-flex'>
                                            <DoneIcon color="success" />
                                       
                                            <p className='ms-1'>24/7 Mentor support</p>
                                        </div>
                                        <div className='d-flex'>
                                            <DoneIcon color="success" />
                                       
                                            <p className='ms-1'>Premium Notes & Softwares</p>
                                        </div>
                                        <div className='d-flex'>
                                            <DoneIcon color="success" />
                                       
                                            <p className='ms-1'>Placement trainings</p>
                                        </div>
                                        <div className='d-flex'>
                                            <DoneIcon color="success" />
                                       
                                            <p className='ms-1'>100% Placement assistance</p>
                                        </div>
                                    
                                    </div>

                                    <div className='bg1 mt-3 ps-3'>
                                        <div className='d-flex mb-2'>
                                            <p className='m-0'>They pay</p>
                                            <div className='border border-dark rounded rounded-3 ms-2 '>-20%</div>
                                        </div>
                                        <div className='d-flex'>
                                            <s>₹2,628.00</s>
                                            <p className='ms-1'><b>₹2,102.40</b></p>
                                        </div>
                                    </div>

                                    <div className='d-flex align-items-center text-success bg2 pt-2 ps-2 mt-3 rounded'>
                                        <RedeemIcon color="success" />
                                        <p className='ms-1 sm-18 m-0 p-0'>Your commission</p>
                                        <h5 className='ms-3 sm-16 m-0 p-0'>₹420.48</h5>
                                    </div>

                                    <div className='d-flex mt-4 mb-2'>
                                        <button className='border rounded bg-white p-2 copyb'>
                                            <ContentCopyIcon />
                                        </button>
                                        <button className='border ms-2 w-100 text-center bg-white rounded refer pt-2 copyb'>
                                            Refer to Friend</button>
                                    </div>
                                </div>
                           </div>
                            </div>




                            <div className=' col-xl-4 col-lg-4 col-md-5 col-sm-12 col-12 p-3 '>
                              <div className="border  card bg-white rounded-4 border bd mb-4 sm-mt-4">
                              <div className='p-2 p-md-3 p-lg-2'>
                                    <div className='bg-primary text-white rounded-4 w-50 text-center mt28 mx-auto p-0 p-md-1 p-lg-1 sm-18'>MOST POPULAR</div>

                                    <h2 className='text-center mt-3'>Specialization Course</h2>
                                    <p className='text-center mt'>Everything you need to land your dream job</p>

                                    <div className='mt-3'>
                                        <div className='d-flex'>
                                            <DoneIcon color="success" />
                                       
                                            <p className='ms-1'>Daily online class</p>
                                        </div>
                                        <div className='d-flex'>
                                            <DoneIcon color="success" />
                                       
                                            <p className='ms-1'>Daily class recording</p>
                                        </div>
                                        <div className='d-flex'>
                                            <DoneIcon color="success" />
                                       
                                            <p className='ms-1'>24/7 Mentor support</p>
                                        </div>
                                        <div className='d-flex'>
                                            <DoneIcon color="success" />
                                       
                                            <p className='ms-1'>Premium Notes & Softwares</p>
                                        </div>
                                        <div className='d-flex'>
                                            <DoneIcon color="success" />
                                       
                                            <p className='ms-1'>Placement trainings</p>
                                        </div>
                                        <div className='d-flex'>
                                            <DoneIcon color="success" />
                                       
                                            <p className='ms-1'>100% Placement assistance</p>
                                        </div>
                                    
                                    </div>

                                    <div className='bg1 mt-3 ps-3'>
                                        <div className='d-flex mb-2'>
                                            <p className='m-0'>They pay</p>
                                            <div className='border border-dark rounded rounded-3 ms-2'>-20%</div>
                                        </div>
                                        <div className='d-flex'>
                                            <s>₹4,540.00</s>
                                            <p className='ms-1'><b>₹3,638.40</b></p>
                                        </div>
                                    </div>

                                    <div className='d-flex text-success bg2 pt-2 ps-2 mt-3 rounded'>
                                        <RedeemIcon color="success" />
                                        <p className='ms-1 sm-18 m-0 p-0'>Your commission</p>
                                        <h5 className='ms-3 sm-16 m-0 p-0'>₹727.68</h5>
                                    </div>

                                    <div className='d-flex mt-4 mb-2'>
                                        <button className=' rounded p-2 bg3 btn'>
                                            <ContentCopyIcon sx={{ color: 'white' }} />
                                        </button>
                                        <button className=' ms-2 w-100 text-center btn text-light rounded refer pt-2 bg3'>
                                            Refer to Friend</button>
                                    </div>

                                </div>
                              </div>

                            </div>





                            <div className=' col-xl-4 col-lg-4 col-md-5 col-sm-12 col-12 p-3 '>

                                <div className="border card bg-white border rounded-4 mb-4">

                                <div className='p-2 p-md-3 p-lg-2'>
                                    <h2 className='text-center mt-3'>Offline Course</h2>
                                    <p className='text-center mt'>Everything you need to master skills with us</p>

                                    <div className='mt-3'>
                                        <div className='d-flex'>
                                            <DoneIcon color="success" />
                                       
                                            <p className='ms-1'>Daily online class</p>
                                        </div>
                                        <div className='d-flex'>
                                            <DoneIcon color="success" />
                                       
                                            <p className='ms-1'>Daily class recording</p>
                                        </div>
                                        <div className='d-flex'>
                                            <DoneIcon color="success" />
                                       
                                            <p className='ms-1'>24/7 Mentor support</p>
                                        </div>
                                        <div className='d-flex'>
                                            <DoneIcon color="success" />
                                       
                                            <p className='ms-1'>Premium Notes & Softwares</p>
                                        </div>
                                        <div className='d-flex'>
                                            <DoneIcon color="success" />
                                       
                                            <p className='ms-1'>Placement trainings</p>
                                        </div>
                                        <div className='d-flex'>
                                            <DoneIcon color="success" />
                                       
                                            <p className='ms-1'>100% Placement assistance</p>
                                        </div>
                                    
                                    </div>

                                    <div className='bg1 mt-3 ps-3'>
                                        <div className='d-flex mb-2'>
                                            <p className='m-0'>They pay</p>
                                            <div className='border border-dark rounded rounded-3 ms-2'>-20%</div>
                                        </div>
                                        <div className='d-flex'>
                                            <s>₹10,188.00</s>
                                            <p className='ms-1'><b>₹8,150.40</b></p>
                                        </div>
                                    </div>

                                    <div className='d-flex text-success bg2 pt-2 ps-2 mt-3 rounded'>
                                        <RedeemIcon color="success" />
                                        <h6 className='ms-1 sm-18 m-0 p-0'>Your commission</h6>
                                        <h5 className='ms-3 sm-16 m-0 p-0'>₹2,852.64</h5>
                                    </div>

                                    <div className='d-flex mt-4 mb-2'>
                                        <button className='border rounded p-2 bg-white'>
                                            <ContentCopyIcon />
                                        </button>
                                        <button className='border ms-2 w-100 text-center bg-white rounded pt-2 refer'>
                                            Refer to Friend</button>
                                    </div>

                                </div>
                                </div>
                             

                            </div>


                        </div>
                    

                </div>

            </>

        </Styled>



    )
}
export default ScratchCards
const Styled = styled.div`
.bg{
   background-color:#c5cde926}
.bg1{ 
   background-color:#fafbff}

 .bg2{ 
   background-color:#D6EAF8}
.text{ 
   color:#2f1c6a}
.refer{
  color:#673de6} 
.bg3{
   background-color:#673de6}  
.bd{
  border: 1px solid #673de6 !important;
}
.mt28{
   margin-top:-28px;}  

`
