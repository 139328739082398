import { Avatar } from '@mui/material'
import React, { useState } from 'react'
import Dropdown from 'react-bootstrap/Dropdown'
import optionsicon from '../../assets/icons/options.svg'
import { Link } from 'react-router-dom'
import { Close } from '@material-ui/icons';
import ModalStyle from '../../data/ModalStyle';
import dp from '../../assets/images/connection/user.jpeg'
import { useEffect } from 'react'
import Box from '@mui/material/Box';
import defaultdp from '../../assets/user.png'
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import FollowUser from '../../actions/connection/FollowUser'
import { useContext } from 'react'
import { AuthContext } from '../../context/AuthContext'
import UnfollowUser from '../../actions/connection/UnfollowUser'
import AcceptRequest from '../../actions/connection/AcceptRequest'
const ConnectionItemSidebarSuggestion = ({connection,account,access}) => {
const {user} = useContext(AuthContext)
  const [student,setstudent] = useState(access)
const [status,setstatus] = useState(connection.status)

  useEffect(()=>{
if(access){
  setstudent(access)
}
else{
  setstudent(false)
}

  },[])

const [loading,setloading] = useState(false)


const [alert,setalert] = useState(false)

const NewFollowUser = async ()=>{
 //  //console.log("follow "+connection.user_id)
 setloading(true)
  const res = await FollowUser({
    payload:{
      user:user.SessionId,
      following_id:connection.user_id
    }
  })

  if(res){
    setloading(false)
    if(res.status==="success"){
      setstatus(res.following_status)
    }
     //  //console.log("following "+JSON.stringify(res))
  }
}



const NewAcceptRequest = async()=>{
setloading(true)
   //  //console.log("accepting "+connection.user_id)
  const res = await AcceptRequest({
    payload:{
      user:user.SessionId,
      follower_id:connection.user_id
    }
  })

  if(res){
    setloading(false)
    if(res.status==="success"){
      setstatus(res.follower_status)
    }
     //  //console.log("accepted it "+JSON.stringify(res))
  }

}

const NewUnfollowUser = async ()=>{
   //  //console.log("follow "+connection.user_id)
   setloading(true)
    const res = await UnfollowUser({
      payload:{
        user:user.SessionId,
        following_id:connection.user_id
      }
    })
  
    if(res){
      setloading(false)
      if(res.status==="success"){
        setstatus(res.following_status)
      }else{
        
      }
       //  //console.log("following "+JSON.stringify(res))
    }
  }
  return (

    <div>

<Modal
  open={alert}
  onClose={()=>setalert(false)}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
  <Box sx={ModalStyle} className="p-3 br-5 col-11 col-sm-8 col-md-4 col-lg-4">
   <div className="d-flex justify-content-between">
    <h4>Student only access !</h4>
    <Close className="click"  onClick={()=>setalert(false)}/>
   </div>
   <hr/>
   <div>
    <p>Register a course to connect with friends and learn together.</p>
   </div>
   <div className="d-flex justify-content-between mt-4">
    <button onClick={()=>setalert(false)} className="btn btn-outline-danger rounded-pill">Cancel</button>
    <Link to="/courses" className="btn btn-primary rounded-pill">Browse Courses</Link>
   </div>
  </Box>
</Modal>



<div className="connection">








<div key={connection.user_id}  onClick={()=>setalert(true)} className="d-flex click w-100 justify-content-between align-items-center mt-3 mb-3">
    <div  className="d-flex align-items-center text-dark col">
    <Avatar src={connection.profile_pic} className="mr-5"/>
     <div>
          <h6 className="pb-0 truncate truncate1">{connection.first_name+" "+connection.last_name}</h6>
          <p className="truncate truncate1 text-grey extra-small">@{connection.username}</p>
     </div>
    </div>
    
    
<div className="btns">
{connection.type=="follower"||connection.type=="following"?
<>

<Link to={"/chat/"+connection.conversation_id} className="btn btn-primary btn-sm  w-100">Message</Link>
  


{/*follower or following end */}   
</>:
<>




{connection.type==="request received"?
<>
{/*requests received  start*/}

{status==="pending"?
<>
<button disabled={loading} onClick={()=>NewAcceptRequest()} className="btn btn-primary btn-sm  w-100">{loading?"Accept":"Accepting..."}</button>
  

</>:
<>

{status==="unfollowed"?
<>
<button   onClick={()=>NewFollowUser()}  disabled={loading}  className="btn btn-primary btn-sm  w-100">{loading?"Following...":"Follow"} </button>
 
</>:
<>
<button  className="btn btn-primary btn-sm  w-100" disabled={loading} >{loading?"Cancelling...":"Cancel Invitation"} </button>
 
</>}

</>}





{/*requests received  end*/}

</>:

<>
{/*requests sent  start*/}


{connection.type="request sent"?
<>
{status==="pending"?
<>
<button onClick={()=>NewUnfollowUser()} className="btn btn-primary btn-sm  w-100" disabled={loading}>{loading?"Withdrawing...":"Withdraw"}</button>
  

</>:
<>
{status==="unfollowed"?
<>
<button  onClick={()=>NewFollowUser()}  className="btn btn-primary btn-sm  w-100" disabled={loading}>{loading?"Following...":"Follow Back"}</button>
  
</>:
<>
<button onClick={()=>NewFollowUser()} className="btn btn-primary btn-sm  w-100" disabled={loading}>{loading?"Following...":"Follow"}</button>
  
</>}
</>}

</>:
<>


</>}



{/*requests sent  end*/}

</>}

{/*requests received or sent end*/}



</>}
       
</div>
    
      </div>







      
     
</div>
      </div>
    
  )
}

export default ConnectionItemSidebarSuggestion