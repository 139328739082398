import axios from 'axios'
import ENDPOINT from '../data/ENDPOINT'
import SecurityHeaders from '../data/SecurityHeaders'

const RegisterCourse = async ({payload})=>{

const data = new FormData()
data.append("courseurl",payload.course_url)
data.append("mode",payload.mode)
data.append("session",payload.user)
const res = await 
axios.post(ENDPOINT+`register-course.php`,data,SecurityHeaders)
if(res){
    
     return res.data
}

}

export default RegisterCourse