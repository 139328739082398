import axios from 'axios'
import ENDPOINT from '../data/ENDPOINT'
import SecurityHeaders from '../data/SecurityHeaders'

const GetInternships = async ()=>{

const data = new FormData()


const res = await 
axios.post(ENDPOINT+`get-internships.php`,data,SecurityHeaders)
if(res){
    
     return res.data
}

}
export default GetInternships