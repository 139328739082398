import React from 'react'
import { WhatsApp } from '@mui/icons-material'
import SocialShare from '../../components/SocialShare'
const HackingWorkshopConfirmation = () => {
  return (
    <div>


        <div className="container min-vh-100 ">


            <div className="col-lg-6 mx-auto">
                <div className="text-center mb-4">
                <img src="https://skillsuprise.com/resources/images/thankyou.svg" className="w-50 mx-auto"></img>

                </div>
           
                <p>Your registration is successfull. You will receive further updates about the workshop through your registered WhatsApp number. Also join our WhatsApp group for realtime updates about the workshop.</p>
       
      
       <div className="d-flex justify-content-center mt-3">
         <a href="https://chat.whatsapp.com/BbXuJfDHUSBE6scdifhgs5" target="_blank" className="btn btn-success rounded rounded-pill"><WhatsApp/> Join WhatsApp Group</a>
       </div>

       <hr></hr>

       <div className="text-center">
       <h4>Share about workshop with your friends</h4>

<div className="mx-auto d-flex justify-content-center">

<SocialShare path={"hacking-workshop"}></SocialShare>
</div>
       </div>
        </div>
        </div>


    </div>
  )
}

export default HackingWorkshopConfirmation