import React, { useEffect, useState } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { ref, push, onValue,getDatabase } from 'firebase/database';

const FirebaseChat = ({account}) => {
  const [user, setUser] = useState(null);
  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState([]);
  const dbRef = ref(getDatabase(), 'messages');

  useEffect(() => {

    // Listen for changes in the 'messages' node in the database
    onValue(dbRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const messageList = Object.values(data);
        
      }
    });

 
  }, []);

  const sendMessage = () => {
    if (message.trim() !== '') {
      // Push a new message to the 'messages' node in the database
      push(dbRef, {
        text: message,
        timestamp: new Date().getTime(),
        user: account ? account.username : 'Anonymous',
      });
      setMessage('');
    }
  };

  return (
    <div>
      <div>
        {messages.map((message, index) => (
          <div key={index}>
            {message.user}: {message.text}
          </div>
        ))}
      </div>
      {account ? (
        <div>
          <input
            type="text"
            placeholder="Type your message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
          <button onClick={sendMessage}>Send</button>
        </div>
      ) : (
        <div>You need to sign in to send messages.</div>
      )}
    </div>
  );
};

export default FirebaseChat;
