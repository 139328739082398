import axios from 'axios'
import ENDPOINT from '../../data/ENDPOINT'
import SecurityHeaders from '../../data/SecurityHeaders'

const GetTrainer = async ({payload})=>{
    
const data = new FormData()
data.append("trainer",payload.trainer_id)

const res = await 
axios.post(ENDPOINT+`get-trainer.php`,data,SecurityHeaders)
if(res){
    
     return res.data[0]
}

}



export default GetTrainer