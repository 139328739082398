import React from 'react'
import axios from 'axios'
import ENDPOINT from '../../data/ENDPOINT'
import SecurityHeaders from '../../data/SecurityHeaders'
import { useState } from 'react'
import { useEffect } from 'react'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { useContext } from 'react'
import { AuthContext } from '../../context/AuthContext'
import { Close } from '@material-ui/icons'
import ReactTyped from 'react-typed'
import ModalStyle from '../../data/ModalStyle'
import GetIntro from '../../actions/intros/GetIntro'
import DetectDevice from '../../data/DetectDevice'
import { Link } from 'react-router-dom'
const WhatsappBotActivate = ({account}) => {


const {user} = useContext(AuthContext)

const [banners,setbanners] = useState(null)

const GetOfferBanners = async()=>{
const data = new FormData();
data.append("user",user.SessionId)
const res = await axios.post(ENDPOINT+"offer-sliders.php",data,SecurityHeaders)
if(res){

if(res.data.status==="success"){


setbanners(res.data.banners)
 //  //console.log("banners "+JSON.stringify(res.data.banners))

}

}


}



useEffect(()=>{
  const message1 = "Get Latest Job alerts !"
  const message2 = "Directly at your Whatsapp"
if(account){
  setmessages([message1,message2])
}
},[account])


const [modal,setmodal] = useState(false)
const [messages,setmessages] = useState([ ])
const [beginner,setbeginner] = useState(false)

const CheckBeginner = async ({payload})=>{

  const res = await GetIntro({payload})
  if(res){
    if(res.status==="success"){
    setbeginner(res.beginner)
    }
     //  //console.log("sofy is "+JSON.stringify(res))
  }
}

 useEffect(()=>{
  CheckBeginner({payload:{
       user:user.SessionId,
       name:"sofy"
  }})
  if(!beginner){
  }

},[beginner])


const ActivateSofy = ()=>{
  CheckBeginner({payload:{
    user:user.SessionId,
    name:"sofy",
    value:"false"
}})
}


const device = DetectDevice()

  return (

    <>
    



    <Modal
  open={modal}
  onClose={()=>{setmodal(false)}}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
  <Box className="col-lg-8 col-md-11 col-sm-11 col-11 p-2" sx={ModalStyle}>
  

   <div className="d-flex w-100 flex-wrap">
<div className="col-lg-6 col-sm-12 col-md-7 col-12 p-2">

<div className="d-flex modal-header">

<h5>Activate Sofy</h5>
<Close className="click" onClick={()=>setmodal(false)}/>

</div>
<hr/>
{device==="mobile"?
<>

<div className="d-flex">
<p>Send a WhatsApp message
<b> ACTIVATE SOFY </b><br/>
 to +91-9381683945 to activate sofy or <a href="https://api.whatsapp.com/send/?phone=919381683945&text=ACTIVATE+SOFY" target="_blank">Click Here</a>.</p>
  
</div>
<hr/>
<div className="d-flex">
<p>Send a WhatsApp message
<b> DEACTIVATE SOFY </b><br/>
 to +91-9381683945 to de-activate sofy or <a href="https://api.whatsapp.com/send/?phone=919381683945&text=DEACTIVATE+SOFY">Click Here</a></p>
  
</div><hr/>



</>:
<>

<div className="text-center">
<h5>Scan the QR Code to activate/deactivate sofy</h5>
<div className="col-lg-4 mx-auto">
<img src="https://skillsuprise.com/resources/images/qr.png" className="w-100"/>
</div>

</div>

<hr/>
</>}
<p>Sofy can talk to you only through WhatsApp.  It can do all the actions mentioned below.</p>

<ul>
<li>Send daily reminders of class schedules</li>
<li>Send daily class recordings, notes & quizzes</li>
  <li>Answers your questions and asks course related questions and quizzes</li>
  <li>Connects you and your trainer via a WhatsApp, Phone call or even schedules Zoom meeting etc to talk to your trainer.</li>
<li>Share realtime updates of your course activities</li>
<li>+ Many more</li>
</ul>


</div>

<div className="col-lg-6 d-none d-sm-none d-md-block col-12 p-0">
 <img src={""} className="w-100"/> 
</div>
   </div>
  </Box>
</Modal>

 

{beginner?
<>
<div className="w-100 bg-black br-5 sm-br-0 d-flex mb-3">
   
<div className="w-75 text-white p-3">
   
   <h6 className="">
     <ReactTyped
     className="bg-transparent text-white"
     strings={messages}
       typeSpeed={30}
       backSpeed={80}
     
       loop >
   
     
     </ReactTyped></h6>
   
     <p className="extra-small light-grey">Get updates on new openings for your preferred job role at your dream company through WhatsApp. Whether it's Google, Amazon or even a start-up company, we keep you updated with new job opportunities</p>
     
     <div className="mt-3 d-flex align-items-center">   <Link to="/job-alerts" className='btn mr-15 btn-sm btn-primary rounded-pill'>Browse Jobs</Link>
     </div>
     </div>
   <div className="w-25">
   
     <img src={""} className="w-100 br-5 sm-br-0 sofy"/>
   </div>
       </div>

</>:
<>



</>}







    </>
    
  )
}



export default WhatsappBotActivate