import React from 'react'
import './style.css'


import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import valueEducation from '../../../assets/icons/value-education.svg'
import workshop from '../../../assets/icons/workshop-icon.svg'
import IndustrialIcon from '../../../assets/icons/Industrial trainings-icon.svg'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Close from '@mui/icons-material/Close'
import CollegeContactForm from '../../../components/forms/CollegeContactForm';
import SetPage from '../../../data/SetPage';
import { useEffect } from 'react';
import { Check, Phone } from '@material-ui/icons';
import CollegeContactModal from '../../../components/modals/CollegeContactModal';
import { useState } from 'react';
import { PlayCircle } from '@mui/icons-material';
import ModalStyle from '../../../data/ModalStyle';
const Home = () => {

  useEffect(()=>{
    SetPage("Campus")
    window.scrollTo(0,0)
  },[])
  const [modal,setmodal] = useState(false)
  const HandleClose = ()=>{
  setmodal(false)}


  const [demo,setdemo] = useState(false)
  return (
   <>
 <div className="animate">


 <Modal
  open={demo}
  onClose={()=>setdemo(false)}

>
  <Box sx={ModalStyle}  className="col-lg-4 col-md-5 br-5 col-sm-6 col-11 p-2">
<div className="d-flex justify-content-between">
     <h4>Introduction</h4>
     <Close className="text-danger" type="button" onClick={()=>setdemo(false)}/>
</div>
    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
    <video className="w-100"  poster="https://skillsuprise.com/data/videos/college-demo.png"  autoPlay src="https://skillsuprise.com/data/videos/college-demo.mp4"></video>
    </Typography>
  </Box>
</Modal>

<CollegeContactModal visibility={modal} HandleClose={HandleClose}/>

<div className="college-bg  position-relative">

  <div className="d-flex container mt-md-5 sm-p-0  flex-lg-row flex-wrap">
  
  
  <div className="col-lg-6 ">
<img src="https://skillsuprise.com/images/college1.jpg" className='delay2s w-100  '/>
</div>
  <div className="col-lg-6 ps-3 sm-p-15">
<h1 className='  mt-0  mt-sm-10 sm-mt-10 sm-22'>Increase Students Employability &<br></br>Attract more students</h1>
<h5 className='font-weight-normal light-gr my-2'>Equip students with the most in-demand skills and prepare them for job success.</h5>


<div className="d-flex align-items-center my-2">
<CheckCircleIcon className='mb-0 text-primary'/>
  <h6 className='mb-0 pb-0 ms-1'>On-Demand courses</h6>
</div>
<div className="d-flex align-items-center my-2">
<CheckCircleIcon className='text-primary'/>
  <h6 className='mb-0 pb-0 ms-1 '>CRT Trainings</h6>
</div>
<div className="d-flex align-items-center my-2">
<CheckCircleIcon className='text-primary'/>
  <h6 className='mb-0 pb-0 ms-1 '>Workshops, Webinars & Hackathons</h6>
</div>
<div className="d-flex align-items-center my-2 position-relative">
<CheckCircleIcon className='mb-0 text-primary'/>
  <h6 className='mb-0 ms-1 pb-0 text-align-left'>AI-Powered LMS Portal</h6>
</div>


<div className="d-flex mt-3">
  
<button onClick={()=>setmodal(true)} className='btn btn-primary '><Phone/> Contact Us</button>


<button className='btn btn-warning ms-3' onClick={()=>setdemo(true)}><PlayCircle/> Watch Demo</button>
</div>
</div>
 
  </div>


</div>
<div className="container-fluid sm-p-0 w-100 left-0 bottom-0 d-flex justify-content-center">
 <div className="container sm-p-0 md-mx-auto  college-stats  ">
  <div className="bg-dark text-light p-2 p-sm-3 p-md-4 rounded d-flex align-items-center flex-wrap">

    <div className="col-lg-4 border-right p-2 d-flex align-items-center">
<h3>76%</h3>
<div>
  <p className='text-light sm-12'>
Students are 76% more likely to enroll in a degree program that offers industry micro-credentials</p>
</div>
    </div>
    <hr className='d-md-none text-light border-white w-100 bg-secondary my-1'></hr>
    <div className="col-lg-4 p-2  border-right d-flex align-items-center">
<h3>88%</h3>
<div>
  <p className='text-light sm-12'>
  of employers believe that Professional Certificates strengthen a candidate’s job application</p>
</div>
    </div>

    <hr className='d-md-none text-light border-white w-100 bg-secondary my-1'></hr>
 
    <div className="col-lg-4 d-flex p-2 align-items-center">
<h3>90%</h3>
<div>
  <p className='text-light sm-12'>
  of students agree that a Professional Certificate will help them secure a job</p>
</div>
    </div>


  </div>
</div>
 </div>

 <div className="mt-5 mb-3 container">
        <div className=" d-none justify-content-center">
          <div>
          <h1 className="hero-text theme-gradient text-center">
   Take Your Students Career<br></br> To The Next Levels
        </h1>
            <h1 className="d-none">
              Take your Students Career to The{" "}
              <span className="theme-gradient">Next Level!</span>
            </h1>
            <h6 className="my-3 text-secondary text-center">
              Value added courses & trainings.
            </h6>
          </div>
        </div>

        <div className=" p-4 sm-p-0 rounded col-lg-12 mt-5 d-flex flex-wrap justify-content-between ">
  
          <div className="col-lg-6 col-12 col-sm-12 col-md-5 p-2">
            <img className="w-100" src="https://skillsuprise.com/images/college2.jpg"/>

          </div>

          <div className="col-lg-6 col-12 col-sm-12 col-md-7 ps-md-3 ps-0">
   <h3>Not Just Trainings </h3>
   <h1>An AI Powered Principal<br></br>For Your College</h1>
   <hr></hr>
<p>From generating a student id card, tracking the fee payments, monitoring faculty performances to predicting students placements, our AI-Powered College Management Systems got you covered.</p>
        
   <div className="d-flex align-items-center my-2 position-relative">
<CheckCircleIcon className='mb-0 text-primary'/>
  <h6 className='mb-0 ms-1 pb-0 text-align-left'>College Management System - AI-Powered </h6>
</div>

   <div className="d-flex align-items-center my-2">
<CheckCircleIcon className='mb-0 text-primary'/>
  <h6 className='mb-0 pb-0 ms-1'>Placements Predictions </h6>
</div>
<div className="d-flex align-items-center my-2">
<CheckCircleIcon className='text-primary'/>
  <h6 className='mb-0 pb-0 ms-1 '>Student Growth Recommendations  </h6>
</div>
<div className="d-flex align-items-center my-2">
<CheckCircleIcon className='text-primary'/>
  <h6 className='mb-0 pb-0 ms-1 '>Job Search from across the World</h6>
</div>

        
         
         
          </div>
        </div>

      
      </div>


  {/* <div className="my-5">
  <div className=" demo-bg col-lg-12 pt-5 mt-5">
  <div className="container">
  <div className="col-lg-10 mx-auto ">
        <h1 className="hero-text theme-gradient text-center">
    AI Powered World-Class Features
        </h1>
       <div className="text-center">
        <h5>Every Digital Age Student, College & University Needs</h5>
       </div>
    </div>
 
   

    <div className="justify-content-lg-center college-options pb-3 h-fit w-100 sm-justify-content-start justify-content-start overflow-x mt-4  d-flex ">

    <button className='btn mx-2 h-fit shadow rounded w-fit rounded-pill'>For Students</button>


    <button className='btn mx-2 h-fit shadow rounded w-fit rounded-pill'>For Faculty</button>


    <button className='btn mx-2 shadow rounded rounded-pill w-fit'>For Placement Officers</button>


    <button className='btn mx-2 shadow rounded rounded-pill w-fit'>For Administration</button>


  



    </div>

    <div className="d-flex flex-wrap justify-content-around  my-5 ">
   <div className="col-lg-4 col-12 colmd-4 p-3">

   <img className="w-100 shadow" src="https://skillsuprise.com/resources/images/college1.png"/>


   </div>


   <div className="col-lg-4 col-md-4 col-12 p-3">

<img className="w-100 shadow" src="https://skillsuprise.com/resources/images/college2.png"/>


</div>


<div className="col-lg-4 col-md-4 col-12 p-3">

<img className="w-100 shadow" src="https://skillsuprise.com/resources/images/college3.png"/>


</div>

    </div>
  </div>

   </div>
  </div>

  */}








 










   
      <div className="position-relative container d-none  py-5">
        <div className="rbt-shape-bg col-12">
          <img
            className="w-100"
            src="https://rainbowit.net/html/histudy/assets/images/splash/bg/left-right-line-small.svg"
          />
        </div>
        <div className="d-flex flex-wrap  pb-5 pt-5  ">
      
          <div className="col-lg-6 ">
            <img
              className="w-100 shadow border rounded"
              src="https://skillsuprise.com/resources/images/flow2.png"
            />
            
          </div>

          <div className="col-lg-5 container">
            <div className="">
              <h5 className="badge      p-2  sm-mt-3 mt-3 md-mt-0 sm-12">Dream job at FAANG Companies is not a dream anymore</h5>
              <h1 className='mt-3'>We offer everything a student need to succeed</h1>
              <p className="fs-5">
                From emerging start-up's to leading MNC's, creating student successful career paths is what our team strives for.
              </p>
            </div>
          
            <div className="d-flex mt-3">
              <div>
                <div className="d-flex my-2 align-items-center">
                  <CheckCircleIcon color="primary"   />
                  <p className=" my-auto">Mock interviews</p>
                </div>
                <div className=" d-flex my-2 align-items-center">
                  <CheckCircleIcon color="primary"   />
                  <p className=" my-auto">Reasoning & Aptitude training</p>
                </div>
                <div className=" d-flex my-2 align-items-center">
                  <CheckCircleIcon color="primary"   />
                  <p className=" my-auto">Communication development training</p>
                </div>
                <div className=" d-flex my-2 align-items-center">
                  <CheckCircleIcon color="primary"   />
                  <p className=" my-auto">Resume development trainings</p>
                </div>

                <div className=" d-flex my-2 align-items-center">
                  <CheckCircleIcon color="primary"   />
                  <p className=" my-auto">Mock tests</p>
                </div>
              </div>
            
            </div>
          </div>
        </div>
      </div>


  

  
 </div>
 

 <div className="container py-5 mb-5">


<div className="d-flex pb-5 justify-content-evenly flex-wrap">

<div className="col-lg-6 col-12">
  <h1>Get in touch with our sales team</h1>

  <div className="mt-3">
    <p>Join the innovative colleges and universities globally that choose Skills Uprise for their students</p>
 
 <div className="mt-3">
  <div className="d-flex my-1">
  <Check className='me-2'/>
    <p>Increase employment for your students</p>
  </div>

  <div className="d-flex my-1">
  <Check className='me-2'/>
    <p>Enhance learning experiences</p>
  </div>


  <div className="d-flex my-1">
    <Check className='me-2'/>
    <p>Manage your college/university effortlessly</p>
  </div>
 </div>
 
  </div>
</div>
<div className="col-lg-5 mt-3 md-mt-0 shadow p-3 rounded col-12">


<CollegeContactForm/>
</div>

</div>

 </div>
 </>
  )
}

export default Home