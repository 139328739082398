import React from 'react'

const ReportCard = () => {
  return (
    <div>
    <div className="container">
     <div className="shadow p-3">


<div className="d-flex justify-content-between">
<div className="d-flex">
     <div className="userdp mr-15">

     </div>
     <div>
          <h4>Manoj Kumar</h4>
          <p>@Manoj Kumar</p>
          <p>Designation : Foounder & CEO (awetecks)</p>
     </div>
</div>


<div>
     <p className="text-success">Online</p>
</div>
</div>


     </div>





     
    </div>



<div className="container mb-5 shadow mt-3 p-3">
     <div className="flex-wrap justify-content-between d-flex">
<div className="col-lg-6">

<div className="">
<h4>Feedback</h4>
           <ul>
<li>Ethical Hacking & Cyber Security</li>
<li>Website development</li>
<li>Python programming</li>

           </ul>

</div>

</div>




<div className="col-lg-5">
<h4>Activity</h4>



<hr/>
<ul>
<li>Ethical Hacking & Cyber Security</li>
<li>Website development</li>
<li>Python programming</li>

           </ul>
<hr/>












</div>
     </div>
</div>
    </div>
  )
}

export default ReportCard