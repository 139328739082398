import { Check, WhatsApp } from '@mui/icons-material'
import React from 'react'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'

const DashboardCta = () => {
  return (
    <div>
        
        
        
        
        
        
        <div className="d-flex dashboard-cta pb-5 rounded-0 pb-sm-3 p-lg-4 p-3 p-md-3 border-1 border-none border-lg border-lg-1 rounded-lg-2 col-lg-10 mx-auto flex-wrap">


<div className="col-lg-6 col-12">
<img src="https://skillsuprise.com/data/images/dashboard-cta.png" className='w-100 rounded'></img>

</div>

<div className="col-lg-6 sm-mt-10 col-12 text-light ps-lg-5 ps-0 ps-md-3">
<h3 className='mt-2'><b>Land Your Dream Job With Our Top Courses</b></h3>

<div className="d-flex mt-0">
   <Check className='me-2'/>
   <p className='text-light'>Expert Led Training</p>
  
</div>


<div className="d-flex my-1">
   <Check className='me-2'/>
   <p className='text-light'>Flexible Learning Methods</p>
  
</div>


<div className="d-flex my-1">
   <Check className='me-2'/>
   <p className='text-light'>Real-time Projects</p>
  
</div>

<div className="d-flex my-1">
   <Check className='me-2'/>
   <p className='text-light'>24/7 Doubt Support</p>
  
</div>
<div className="d-flex my-1">
   <Check className='me-2'/>
   <p className='text-light'>100% Placement assistance</p>
  
</div>
<div className="d-flex mt-4">
    <a href="tel:919490174876" className='btn btn-warning' target="_blank">Contact Us</a>
    <a href='https://api.whatsapp.com/send/?phone=919490174876' target='_blank' className='btn ms-2 btn-success'><WhatsApp className='text-light'/> Chat With Us</a>
</div>
</div>






</div></div>
  )
}

export default DashboardCta