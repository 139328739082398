import axios from 'axios'
import { useState } from 'react'
import ENDPOINT from '../../data/ENDPOINT'
import SecurityHeaders from '../../data/SecurityHeaders'

const RegisterWorkshop = async ({payload})=>{

const data = new FormData()
data.append("name",payload.video_id)


const res = await 

axios.post(ENDPOINT+`register-workshop.php`,data,SecurityHeaders)
if(res){
    
     return res.data
}

}





export default RegisterWorkshop