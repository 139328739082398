import { Chip } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'
import dateFormat from 'dateformat'
const InternshipItem = ({internship}) => {
  return (
    <div className="p-3 mt-3 mb-3 br-5 shadow w-100">
     <h5>{internship.title}</h5>
     <hr/>
     <div className="d-flex">
          <div className="col">
               <h6>Location</h6>
               <p>{internship.mode}</p>
          </div>
          <div className="col">
               <h6>Applied On</h6>
               <p>{dateFormat(internship.applied_date,"dS mmm yy")}</p>
          </div>
          <div className="col">
               <h6>Status</h6>
               <p>{internship.status}</p>
          </div>
     </div>
     <div className="d-flex mt-2 justify-content-between">
       <span></span>
<Link to={"/internship/"+internship.internship_id} className="btn btn-primary btn-sm rounded-pill">View Details</Link>
     </div>
     </div>
  )
}

export default InternshipItem