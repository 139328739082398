import React, { useEffect } from 'react'
import AmbassadorUnregistered from '../cai/AmbassadorUnregistered'
import SetPage from '../../data/SetPage'

const CampusAmbassadorPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0)

 SetPage("Campus Ambassador Internship")
  }, [])
  return (
    <div>
  
      <AmbassadorUnregistered/>
    </div>
  )
}

export default CampusAmbassadorPage