const ModalStyle  = {
     position: 'absolute',
     top: '50%',
     left: '50%',
     transform: 'translate(-50%, -50%)',
    
     bgcolor: 'background.paper',
   
     boxShadow: 24,
     pt: 2,
     px: 4,
     pb: 3,
   };

   export default ModalStyle