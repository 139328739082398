import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import GetCourses from '../../actions/GetCourses';
import SliderCourseItem from './SliderCourseItem'

const PopularCoursesSlider = () => {

    const NewGetCourses = async()=>{
        const res = await GetCourses({payload:{
          mode:"live",
          limit:8
        }});
        if(res){
        
          
          setcourses(res)
         
        }
      }


      const [courses,setcourses] = useState(null);
useEffect(() => {
  NewGetCourses()
}, []);
  return (
    <div className="w-100">
    

    <div className="d-flex mb-2 justify-content-between align-items-center">
    <h4 className="left-blue-border">Popular Courses</h4>
    <Link to="/courses" className="sm-10 btn btn-outline-primary rounded-pill">View All</Link>
    </div>
<div className="mt-1 overflow-x w-100 p-1">
{courses?
<>
{courses.map((course)=>(

    <>
    <SliderCourseItem course={course}/>
    </>
)
    
)}

</>:null}

</div>

    </div>
  )
}

export default PopularCoursesSlider
