import Lock from '@mui/icons-material/Lock'
import React from 'react'
import Tooltip from '@mui/material/Tooltip';
import { Link } from 'react-router-dom';

const truncateText = (text, maxLength) => {
  if (text.length > maxLength) {
    return text.slice(0, maxLength) + '...';
  }
  return text;
};

const BadgeItem = ({badge}) => {
  return (

    <>


    {badge.status==="earned"?
    
  <>
     <Link to={`/badge-earned/${badge.earned_id}`} className='text-decoration-none bg-white border  rounded'>
      <div className="position-relative text-center br-5">
        <img 
          src={badge.image} 
          alt={badge.title} 
          style={{ height: '75%', width: '80%' }} 
          className="br-5 mb-1" 
        />
        <div className="badge-description  p-3">
         <p className='text-dark truncate truncate3 text-12'>{badge.description}</p>
        </div>
      </div>
    </Link>
  
  </>:
  <>
     <Link to={`/badge/${badge.id}`} className='text-decoration-none bg-white border  rounded'>
      <div className="position-relative text-center br-5">
        <div className="badge-cover">
          <Lock />
        </div>
        <img 
          src={badge.image} 
          alt={badge.title} 
          style={{ height: '75%', width: '80%' }} 
          className="br-5 mb-1" 
        />
        <div className="badge-description   p-3">
        <p className='text-dark sm-12 text-12 truncate truncate3 '> {badge.description}</p>


        </div>
      </div>
    </Link>
  
  </>}

    
    </>
  )
}

export default BadgeItem