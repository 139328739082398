import axios from 'axios'
import ENDPOINT from '../data/ENDPOINT'
import SecurityHeaders from '../data/SecurityHeaders'
     
     const GetEnrolledCourses = async({user,limit}) => {

     const data = new FormData()
     data.append("session",user.SessionId)
   if(limit){
     data.append("limit",limit)
   }
     const res = await 
     axios.post(ENDPOINT+`enrolled-courses.php`,data,SecurityHeaders)
     if(res){
      
          return res.data
     }
     
     }
  
export default GetEnrolledCourses